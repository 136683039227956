import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnumCaptionPipe } from './enum-caption.pipe';
import { EnumSvgPipe } from './enum-svg.pipe';
import { EnumTextPipe } from './enum-text.pipe';
import { PhoneBrPipe } from './phone-br.pipe';


@NgModule({
  declarations: [EnumTextPipe, PhoneBrPipe, EnumSvgPipe, EnumCaptionPipe],
  imports: [
    CommonModule
  ],
  exports: [EnumTextPipe, PhoneBrPipe, EnumSvgPipe, EnumCaptionPipe]
})
export class PipesModule { }
