import {
  ComplementInformationEnum, DeviceSignalingEnum,
  DimensionInformationEnum, HorizontalSignalingEnum,
  IndicationVerticalEnum, ItemUnitEnum, MaintenanceTaskActivityTypeEnum, MarkingTypeEnum,
  PriorityEnum, RegulationVerticalEnum,
  SignalingTypeEnum, SupportMaterialTypeEnum,
  TaskActionEnum, TaskActivityStatusEnum, TaskStatusEnum, TaskTypeEnum,
  VerticalSignalizationTypeEnum, WarningVerticalEnum
} from 'src/enumerators';
import {
  ContractDeviceItem,
  ContractHorizontalItem,
  ContractServiceItem,
  ContractVerticalItemSign,
  ContractVerticalItemSupport,
  RoadSignMarkingPaint
} from './contract';
import { ProjectDeviceSignaling, ProjectHorizontalSignaling, ProjectSupport, ProjectTrafficSign } from './project';
import { IRest } from './rest.interface';
import { Team } from './team';
import { Vehicle } from './vehicle';
import { Address } from './signaling';

export class TaskActivitiesProgress implements IRest {
  className: 'Tarefa';

  taskId: string;
  activities: number;
  completedActivities: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      taskId: this.taskId || undefined,
      activities: this.activities,
      completedActivities: this.completedActivities || undefined,
    };
    return out;
  }
  Deserialize(data: any) {
    this.taskId = data['taskId'] || '';
    this.activities = data['activities'] || '';
    this.completedActivities = data['completedActivities'] || null;

    return this;
  }
}

export class Task implements IRest {
  className: 'Tarefa';

  id: string;
  name: string;
  amount: number;
  serviceOrderId: string;
  contractId: string;
  signalingType: SignalingTypeEnum;
  taskType: TaskTypeEnum;
  description: string;
  taskFiles: any[];
  latitude: number;
  longitude: number;
  priority: string | PriorityEnum;
  status: TaskStatusEnum;
  executionDate: Date;
  vehicleId: string;
  teamId: string;
  observation: string;
  address: Address;
  workspaceId: string;
  createdByMemberId: string;
  paths: boolean;
  trafficSignTaskActivities: TrafficSignTaskActivities[];
  supportTaskActivities: SupportTaskActivities[];
  deviceSignalingTaskActivities: DeviceSignalingTaskActivities[];
  horizontalSignalingTaskActivities: HorizontalSignalingTaskActivities[];
  removalTrafficSignTaskActivities?: RemovalTrafficSignTaskActivity[];
  removalDeviceSignalingTaskActivities?: RemovalDeviceSignalingTaskActivity[];
  removalHorizontalSignalingTaskActivities?: RemovalHorizontalSignalingTaskActivity[];
  maintenanceTrafficSignTaskActivities?: MaintenanceTaskActivity[];
  maintenanceHorizontalSignalingTaskActivities?: MaintenanceTaskActivity[];
  maintenanceDeviceSignalingTaskActivities?: MaintenanceTaskActivity[];
  replacementHorizontalSignalingTaskActivities?: ReplacementHorizontalSignalingTaskActivity[];
  replacementVerticalSignalingTaskActivities?: ReplacementVerticalSignalingTaskActivity[];
  routeId: string;
  contractServiceItems?: TaskContractServiceItem[];
  vehicle: Vehicle;
  team: Team;
  selectedSigns: any;
  taskHistory: TaskHistory[];
  totalVerticalQuantities: { totalSign: number, totalSupport: number }
  signQuantity: number;
  responsable: any;
  projectId: string;
  createdBy: any;
  endDate: Date;
  resolutionTime: number;
  updatedAt: Date;
  progress: any;
  contractState: boolean; // verifica se a opção 'sem vinculo' está marcada
  check: boolean;
  deviceSignalingOccurrenceId: string;
  horizontalSignalingOccurrenceId: string;
  verticalSignalingOccurrenceId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      deviceSignalingOccurrenceId: this.deviceSignalingOccurrenceId || undefined,
      horizontalSignalingOccurrenceId: this.horizontalSignalingOccurrenceId || undefined,
      verticalSignalingOccurrenceId: this.verticalSignalingOccurrenceId || undefined,
      amount: this.amount,
      contractId: this.contractId || undefined,
      routeId: this.routeId || undefined,
      signalingType: this.signalingType,
      taskType: this.taskType,
      description: this.description,
      latitude: this.latitude,
      longitude: this.longitude,
      serviceOrderId: this.serviceOrderId || undefined,
      priority: this.priority,
      status: this.status,
      taskFiles: this.taskFiles,
      executionDate: this.executionDate,
      vehicleId: this.vehicleId || undefined,
      teamId: this.teamId || undefined,
      observation: this.observation,
      address: this.address,
      workspaceId: this.workspaceId || undefined,
      createdByMemberId: this.createdByMemberId || undefined,
      endDate: this.endDate,
      resolutionTime: this.resolutionTime,
      paths: this.paths,
      trafficSignTaskActivities: this.trafficSignTaskActivities.map((v) => v.Serialize()),
      supportTaskActivities: this.supportTaskActivities.map((v) => v.Serialize()),
      horizontalSignalingTaskActivities: this.horizontalSignalingTaskActivities.map((v) => v.Serialize()),
      deviceSignalingTaskActivities: this.deviceSignalingTaskActivities.map((v) => v.Serialize()),
      removalTrafficSignTaskActivities: this.removalTrafficSignTaskActivities.map((v) => v.Serialize()),
      removalHorizontalSignalingTaskActivities: this.removalHorizontalSignalingTaskActivities.map((v) => v.Serialize()),
      removalDeviceSignalingTaskActivities: this.removalDeviceSignalingTaskActivities.map((v) => v.Serialize()),
      maintenanceTrafficSignTaskActivities: this.maintenanceTrafficSignTaskActivities.map((v) => v.Serialize()),
      maintenanceHorizontalSignalingTaskActivities: this.maintenanceHorizontalSignalingTaskActivities.map((v) =>
        v.Serialize(),
      ),
      maintenanceDeviceSignalingTaskActivities: this.maintenanceDeviceSignalingTaskActivities.map((v) => v.Serialize()),
      replacementHorizontalSignalingTaskActivities: this.replacementHorizontalSignalingTaskActivities.map((v) =>
        v.Serialize(),
      ),
      replacementVerticalSignalingTaskActivities: this.replacementVerticalSignalingTaskActivities.map((v) =>
        v.Serialize(),
      ),
      taskHistory: this.taskHistory.map((v) => v.Serialize()),
      selectedSigns: this.selectedSigns,
      totalVerticalQuantities: this.totalVerticalQuantities,
      projectId: this.projectId || undefined,
      updatedAt: this.updatedAt,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || '';
    this.name = data['name'] || '';
    this.executionDate = data['executionDate'] || new Date(2000, 1, 1);
    this.contractId = data['contractId'] || '';
    this.routeId = data['routeId'] || undefined;
    this.contractState = data['contractState'] || false;
    this.signalingType = data['signalingType'] || '';
    this.amount = data['amount'] || 0;
    this.taskType = data['taskType'] || TaskTypeEnum.Implantation;
    this.description = data['description'] || '';
    this.latitude = data['latitude'] || 0;
    this.longitude = data['longitude'] || 0;
    this.priority = data['priority'] || '';
    this.status = data['status'] || 'TO_START';
    this.serviceOrderId = data['serviceOrderId'] || undefined;
    this.vehicleId = data['vehicleId'] || '';
    this.teamId = data['teamId'] || '';
    this.observation = data['observation'] || '';
    this.address = data['address'] || '';
    this.taskFiles = data['taskFiles'] || [];
    this.workspaceId = data['workspaceId'] || '';
    this.createdByMemberId = data['createdByMemberId'] || '';
    this.paths = data['paths'] || false;
    this.trafficSignTaskActivities = (data['trafficSignTaskActivities'] || []).map(
      (v) => new TrafficSignTaskActivities(v),
    );
    this.supportTaskActivities = (data['supportTaskActivities'] || []).map((v) => new SupportTaskActivities(v));
    this.horizontalSignalingTaskActivities = (data['horizontalSignalingTaskActivities'] || []).map(
      (v) => new HorizontalSignalingTaskActivities(v),
    );
    this.deviceSignalingTaskActivities = (data['deviceSignalingTaskActivities'] || []).map(
      (v) => new DeviceSignalingTaskActivities(v),
    );
    this.removalTrafficSignTaskActivities = (data['removalTrafficSignTaskActivities'] || []).map(
      (v) => new RemovalTrafficSignTaskActivity(v),
    );
    this.removalHorizontalSignalingTaskActivities = (data['removalHorizontalSignalingTaskActivities'] || []).map(
      (v) => new RemovalHorizontalSignalingTaskActivity(v),
    );
    this.removalDeviceSignalingTaskActivities = (data['removalDeviceSignalingTaskActivities'] || []).map(
      (v) => new RemovalDeviceSignalingTaskActivity(v),
    );
    this.maintenanceTrafficSignTaskActivities = (data['maintenanceTrafficSignTaskActivities'] || []).map(
      (v) => new MaintenanceTaskActivity(v),
    );
    this.maintenanceHorizontalSignalingTaskActivities = (
      data['maintenanceHorizontalSignalingTaskActivities'] || []
    ).map((v) => new MaintenanceTaskActivity(v));
    this.maintenanceDeviceSignalingTaskActivities = (data['maintenanceDeviceSignalingTaskActivities'] || []).map(
      (v) => new MaintenanceTaskActivity(v),
    );
    this.replacementVerticalSignalingTaskActivities = (data['replacementVerticalSignalingTaskActivities'] || []).map(
      (v) => new ReplacementVerticalSignalingTaskActivity(v),
    );
    this.replacementHorizontalSignalingTaskActivities = (
      data['replacementHorizontalSignalingTaskActivities'] || []
    ).map((v) => new ReplacementHorizontalSignalingTaskActivity(v));
    this.contractServiceItems = data['contractServiceItems'] || [].map((v) => new TaskContractServiceItem(v));
    this.taskHistory = (data['taskHistory'] || []).map((v) => new TaskHistory(v));
    this.selectedSigns = data['selectedSigns'] || {};
    this.totalVerticalQuantities = data['totalVerticalQuantities'] || { totalSign: 0, totalSupport: 0 }
    this.endDate = data['endDate'] || null;
    this.updatedAt = data['updatedAt'] || new Date(2000, 0, 1);
    this.resolutionTime = data['resolutionTime'] || null;
    this.projectId = data['projectId'] || '';
    this.deviceSignalingOccurrenceId = data['deviceSignalingOccurrenceId'] || undefined;
    this.horizontalSignalingOccurrenceId = data['horizontalSignalingOccurrenceId'] || undefined;
    this.verticalSignalingOccurrenceId = data['verticalSignalingOccurrenceId'] || undefined;
    return this;
  }
}

export class TaskContractServiceItem implements IRest {
  className: 'Atividade de Manutenção de Placa';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItem: ContractServiceItem;
  quantity: number;
  cost?: number;
  selectedActivities: Array<any>;
  lastSelectedActivitie: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItem: this.contractServiceItem,
      cost: this.cost,
      quantity: this.quantity,
    };
    return out;
  }
  Deserialize(data: any) {

    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.cost = data['cost'] || 0;
    this.contractServiceItemId = data['contractServiceItemId'] || undefined;
    this.contractServiceItem = new ContractServiceItem(data['contractServiceItem'] || {});
    this.cost = data['cost'] || 0;
    this.quantity = data['quantity'] || 0;
    this.selectedActivities = data['selectedActivities'] || [];

    return this;
  }
}

export class MaintenanceTaskActivity implements IRest {
  className: 'Atividade de Manutenção de Placa';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItem: ContractServiceItem;
  signalingId: string;
  trafficSignId: string;
  horizontalSignalingId: string;
  deviceSignalingId: string;
  cost: number;
  sign: any;
  type: MaintenanceTaskActivityTypeEnum;
  signalingType: SignalingTypeEnum

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItem: this.contractServiceItem,
      trafficSignId: this.trafficSignId || undefined,
      deviceSignalingId: this.deviceSignalingId || undefined,
      horizontalSignalingId: this.horizontalSignalingId || undefined,
      cost: this.cost,
      sign: this.sign,
      type: this.type,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.cost = data['cost'] || 0;
    this.contractServiceItemId = data['contractServiceItemId'] || undefined;
    this.contractServiceItem = new ContractServiceItem(data['contractServiceItem'] || {});
    this.sign = data['trafficSign'] || data['horizontalSignaling'] || data['deviceSignaling'] || data['sign'] || undefined;
    this.type = data['type'] || undefined
    this.signalingType = data['signalingType'] || (data['trafficSign'] ? SignalingTypeEnum.Vertical : data['horizontalSignaling'] ? SignalingTypeEnum.Horizontal : SignalingTypeEnum.Device)

    if (this.signalingType == SignalingTypeEnum.Vertical) this.trafficSignId = data['trafficSignId'] || undefined;
    else if (this.signalingType == SignalingTypeEnum.Horizontal) this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
    else if (this.signalingType == SignalingTypeEnum.Device) this.deviceSignalingId = data['deviceSignalingId'] || undefined;
    return this;
  }
}

export class RemovalTrafficSignTaskActivity implements IRest {
  className: 'Atividade de Remoção de Placa';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItem: ContractServiceItem;
  trafficSignId: string;
  cost: number;
  sign: any;
  trafficSign: any;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItem: this.contractServiceItem,
      trafficSignId: this.trafficSignId,
      cost: this.cost,
      sign: this.trafficSign,
      trafficSign: this.trafficSign,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.cost = data['cost'] || 0;
    this.trafficSignId = data['trafficSignId'] || undefined;
    this.contractServiceItemId = data['contractServiceItemId'] || undefined;
    this.contractServiceItem = new ContractServiceItem(data['contractServiceItem'] || {});
    this.trafficSign = data['trafficSign'] || null;
    this.sign = data['sign'] || null;
    return this;
  }
}

export class RemovalHorizontalSignalingTaskActivity implements IRest {
  className: 'Atividade de Remoção de Horizontal';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItem: ContractServiceItem;
  horizontalSignalingId: string;
  contractHorizontalItemId: string;
  contractHorizontalItem: ContractHorizontalItem;
  cost: number;
  horizontalSignaling: any;
  sign: any;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItem: this.contractServiceItem,
      contractHorizontalItem: this.contractHorizontalItem,
      contractHorizontalItemId: this.contractHorizontalItemId,
      horizontalSignalingId: this.horizontalSignalingId,
      cost: this.cost,
      horizontalSignaling: this.horizontalSignaling,
      sign: this.sign,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.cost = data['cost'] || 0;
    this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
    this.contractServiceItemId = data['contractServiceItemId'] || undefined;
    this.contractHorizontalItemId = data['contractHorizontalItemId'] || undefined;
    this.contractHorizontalItem = data['contractHorizontalItem'] || undefined;
    this.contractServiceItem = new ContractServiceItem(data['contractServiceItem'] || {});
    this.horizontalSignaling = data['horizontalSignaling'] || null;
    this.sign = data['sign'] || null;
    return this;
  }
}

export class RemovalDeviceSignalingTaskActivity implements IRest {
  className: 'Atividade de Remoção de Dispositivos';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItem: ContractServiceItem;
  deviceSignalingId: string;
  cost: number;
  deviceSignaling: any;
  sign: any;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItem: this.contractServiceItem,
      deviceSignalingId: this.deviceSignalingId,
      cost: this.cost,
      deviceSignaling: this.deviceSignaling,
      sign: this.sign,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.cost = data['cost'] || 0;
    this.deviceSignalingId = data['deviceSignalingId'] || undefined;
    this.contractServiceItemId = data['contractServiceItemId'] || undefined;
    this.contractServiceItem = new ContractServiceItem(data['contractServiceItem'] || {});
    this.deviceSignaling = data['deviceSignaling'] || null;
    this.sign = data['sign'] || null;

    return this;
  }
}

export class TrafficSignTaskActivities implements IRest {
  className: 'Atividade de Placa';

  id: string;
  taskId: string;
  projectTrafficSignId: string;
  projectTrafficSign: ProjectTrafficSign;
  complementInformation: string;
  complementInformationType: ComplementInformationEnum;
  contractVerticalItemId: string;
  contractVerticalItem: ContractVerticalItemSign;
  quantity: number;
  verticalType: VerticalSignalizationTypeEnum;
  verticalCode: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  diameter: number;
  sideLength: number;
  cost: number;
  warranty: Date;
  maximumRetroreflectionValue?: number;
  minimumRetroreflectionValue?: number;
  quantityRegistered: number;
  quantityFromProject: number;
  type: SignalingTypeEnum.Vertical;
  stockedTrafficSignId: string;
  internalCaption: number;
  showInternalCap: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      projectTrafficSignId: this.projectTrafficSignId,
      projectTrafficSign: this.projectTrafficSign.Serialize(),
      complementInformation: this.complementInformation,
      complementInformationType: this.complementInformationType,
      contractVerticalItemId: this.contractVerticalItemId,
      contractVerticalItem: this.contractVerticalItem,
      quantity: this.quantity || 0,
      verticalType: this.verticalType,
      verticalCode: this.verticalCode,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      dimension: this.dimension || 0,
      diameter: this.diameter || 0,
      sideLength: this.sideLength || 0,
      warranty: this.warranty,
      cost: this.cost,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      quantityRegistered: this.quantityRegistered,
      type: SignalingTypeEnum.Vertical,
      quantityFromProject: this.quantityFromProject,
      stockedTrafficSignId: this.stockedTrafficSignId,
      internalCaption: this.internalCaption,
      showInternalCap: this.showInternalCap,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || '';
    this.taskId = data['taskId'] || undefined;
    this.projectTrafficSignId = data['projectTrafficSignId'] || undefined
    this.projectTrafficSign = new ProjectTrafficSign(data['projectTrafficSign'] || {})
    this.complementInformation = data['complementInformation'] || '';
    this.complementInformationType = data['complementInformationType'] || undefined;
    this.contractVerticalItemId = data['contractVerticalItemId'] || '';
    this.contractVerticalItem = new ContractVerticalItemSign(data['contractVerticalItem'] || {});
    this.quantity = data['quantity'] || 0;
    this.verticalCode = data['verticalCode'] || '';
    this.verticalType = data['verticalType'] || '';
    this.cost = data['cost'] || 0;
    this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
    this.dimensionX = data['dimensionX'] || 0;
    this.dimensionY = data['dimensionY'] || 0;
    this.dimension = data['dimension'] || 0;
    this.diameter = data['diameter'] || 0;
    this.sideLength = data['sideLength'] || 0;
    this.warranty = data['warranty'] ? new Date(data['warranty']) : new Date(2000, 1, 1);
    this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0
    this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0
    this.quantityRegistered = data['quantityRegistered'] || 0;
    this.type = SignalingTypeEnum.Vertical;
    this.quantityFromProject = data['quantityFromProject'] || 0
    this.stockedTrafficSignId = data['stockedTrafficSignId'] || undefined
    this.internalCaption = data['internalCaption'] || undefined;
    this.showInternalCap = data['showInternalCap'] || false

    return this;
  }
}

export class SupportTaskActivities implements IRest {
  className: 'Atividade de Suporte';

  id: string;
  taskId: string;
  projectSupportId: string;
  projectSupport: ProjectSupport;
  contractVerticalItemId: string;
  contractVerticalItem: ContractVerticalItemSupport;
  quantity: number;
  cost: number;
  quantityFromProject: number;
  quantityRegistered: number;
  stockedSupportId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      projectSupportId: this.projectSupportId,
      projectSupport: this.projectSupport.Serialize(),
      contractVerticalItemId: this.contractVerticalItemId || undefined,
      contractVerticalItem: this.contractVerticalItem,
      quantity: this.quantity,
      cost: this.cost,
      quantityFromProject: this.quantityFromProject,
      quantityRegistered: this.quantityRegistered,


      stockedSupportId: this.stockedSupportId,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.projectSupportId = data['projectSupportId'] || undefined;
    this.projectSupport = new ProjectSupport(data['projectSupport'] || {})
    this.contractVerticalItemId = data['contractVerticalItemId'] || '';
    this.quantity = data['quantity'] || 0;
    this.cost = data['cost'] || 0;
    this.contractVerticalItem = new ContractVerticalItemSupport(data['contractVerticalItem'] || {});
    this.quantityFromProject = data['quantityFromProject'] || 0
    this.quantityRegistered = data['quantityRegistered'] || 0;

    this.stockedSupportId = data['stockedSupportId'] || undefined;
    return this;
  }
}

export class HorizontalSignalingTaskActivities implements IRest {
  className: 'Atividade de Horizontal';

  id: string;
  taskId: string;
  contractHorizontalItemId: string;
  contractHorizontalItem: ContractHorizontalItem;
  quantity: number;
  markingType: MarkingTypeEnum;
  horizontalCode: HorizontalSignalingEnum;
  units: number;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  dimensionType: DimensionInformationEnum;
  cost: number;
  warranty: Date;
  maximumRetroreflectionValue?: number;
  minimumRetroreflectionValue?: number;
  quantityRegistered: number;
  type: SignalingTypeEnum;
  quantityFromProject: number;
  projectHorizontalSignalingId: string;
  projectHorizontalSignaling: ProjectHorizontalSignaling

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      projectHorizontalSignalingId: this.projectHorizontalSignalingId,
      projectHorizontalSignaling: this.projectHorizontalSignaling.Serialize(),
      contractHorizontalItemId: this.contractHorizontalItemId,
      contractHorizontalItem: this.contractHorizontalItem,
      quantity: this.quantity || 0,
      markingType: this.markingType,
      horizontalCode: this.horizontalCode,
      units: this.units || 0,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      cost: this.cost,
      warranty: this.warranty,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      quantityRegistered: this.quantityRegistered,
      type: SignalingTypeEnum.Horizontal,
      quantityFromProject: this.quantityFromProject,

    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.projectHorizontalSignalingId = data['projectHorizontalSignalingId'] || undefined;
    this.projectHorizontalSignaling = new ProjectHorizontalSignaling(data['projectHorizontalSignaling'] || {})
    this.contractHorizontalItemId = data['contractHorizontalItemId'] || undefined;
    this.contractHorizontalItem = new ContractHorizontalItem(data['contractHorizontalItem'] || {});
    this.quantity = data['quantity'] || 0;
    this.horizontalCode = data['horizontalCode'] || '';
    this.units = data['units'] || 0;
    this.dimensionX = data['dimensionX'] || 0;
    this.dimensionY = data['dimensionY'] || 0;
    this.dimension = data['dimension'] || 0;
    this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
    this.cost = data['cost'] || 0;
    this.warranty = data['warranty'] ? new Date(data['warranty']) : new Date(2000, 1, 1);
    this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0
    this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0
    this.quantityRegistered = data['quantityRegistered'] || 0;
    this.type = SignalingTypeEnum.Horizontal
    this.quantityFromProject = data['quantityFromProject'] || 0

    return this;
  }
}

export class DeviceSignalingTaskActivities implements IRest {
  className: 'Atividade de Dispositivo';

  id: string;
  taskId: string;
  projectDeviceSignalingId: string;
  projectDeviceSignaling: ProjectDeviceSignaling;
  contractDeviceItemId: string;
  contractDeviceItem: ContractDeviceItem;
  deviceCode: DeviceSignalingEnum;
  units: number;
  dimension: number;
  cost: number;
  warranty: Date;
  type: SignalingTypeEnum;
  quantityFromProject: number;
  stockedDeviceSignalingId: string;

  constructor(data?: any, isStock?: boolean) {
    if (data) this.Deserialize(data, isStock);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      taskId: this.taskId || undefined,
      projectDeviceSignalingId: this.projectDeviceSignalingId || undefined,
      projectDeviceSignaling: this.projectDeviceSignaling.Serialize(),
      contractDeviceItemId: this.contractDeviceItemId || undefined,
      contractDeviceItem: this.contractDeviceItem,
      deviceCode: this.deviceCode,
      units: this.units || 1,
      dimension: this.dimension || 1,
      warranty: this.warranty,
      cost: this.cost,
      type: SignalingTypeEnum.Device,
      quantityFromProject: this.quantityFromProject,

      stockedDeviceSignalingId: this.stockedDeviceSignalingId,
    };
    return out;
  }
  Deserialize(data: any, isStock?: boolean) {
    this.id = data['id'] || undefined;
    this.taskId = data['taskId'] || undefined;
    this.projectDeviceSignalingId = data['projectDeviceSignalingId'] || undefined
    this.projectDeviceSignaling = new ProjectDeviceSignaling(data['projectDeviceSignaling'] || {})
    this.contractDeviceItemId = data['contractDeviceItemId'] || undefined;
    this.contractDeviceItem = new ContractDeviceItem(data['contractDeviceItem'] || {});
    this.deviceCode = data['deviceCode'] || DeviceSignalingEnum.Tacha;
    this.units = isStock ? 1 : (data['units'] || 1);
    this.dimension = data['dimension'] || 1;
    this.cost = data['cost'] || 0;
    this.warranty = data['warranty'] || new Date(2000, 1, 1);
    this.type = SignalingTypeEnum.Device;
    this.quantityFromProject = data['quantityFromProject'] || 0

    this.warranty = data['warranty'] ? new Date(data['warranty']) : new Date(2000, 1, 1);
    this.stockedDeviceSignalingId = data['stockedDeviceSignalingId'] || undefined
    return this;
  }
}

export class TaskHistory implements IRest {
  className: 'Atividade de Dispositivo';

  id: string;
  action: TaskActionEnum;
  taskId: string;
  memberId: string;
  createdAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      action: this.action,
      taskId: this.taskId || undefined,
      memberId: this.memberId,
      createdAt: this.createdAt,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.action = data['action'] || TaskActionEnum.ToStart;
    this.taskId = data['taskId'] || undefined;
    this.memberId = data['memberId'] || '';
    this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
    return this;
  }
}

export class ReplacementHorizontalSignalingTaskActivity implements IRest {
  className: 'Atividade de Substituição de Horizontais';

  id?: string;
  taskId: string;
  contractServiceItemId: string;
  contractServiceItemIds: Array<string>;
  contractHorizontalItem: ContractHorizontalItem;
  contractHorizontalItemId: string;
  horizontalSignalingId: string;
  roadSignMarkingPaint: RoadSignMarkingPaint;
  status: TaskActivityStatusEnum;
  sign: any;
  horizontalCode: HorizontalSignalingEnum;
  horizontalSignaling: any;
  units: number;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  dimensionType: DimensionInformationEnum;
  warranty: Date;
  cost: number;
  maximumRetroreflectionValue?: number;
  minimumRetroreflectionValue?: number;
  serviceItems: Array<ContractServiceItem>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractServiceItemId: this.contractServiceItemId || undefined,
      contractServiceItemIds: this.contractServiceItemIds || undefined,
      contractHorizontalItem: this.contractHorizontalItem.Serialize(),
      contractHorizontalItemId: this.contractHorizontalItemId,
      horizontalSignalingId: this.horizontalSignalingId,
      horizontalSignaling: this.horizontalSignaling,
      roadSignMarkingPaint: this.roadSignMarkingPaint.Serialize(),
      status: this.status,
      horizontalCode: this.horizontalCode,
      units: this.units || 1,
      sign: this.sign,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      warranty: this.warranty,
      cost: this.cost,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      serviceItems: this.serviceItems,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractServiceItemId = data['contractServiceItemId'] || null;
    this.contractServiceItemIds = data['contractServiceItemIds'] || [];
    this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
    this.horizontalSignaling = data['horizontalSignaling'] || null
    this.contractHorizontalItemId = data['contractHorizontalItemId'] || undefined;
    this.roadSignMarkingPaint = new RoadSignMarkingPaint(data['roadSignMarkingPaint'] || {});
    this.contractHorizontalItem = new ContractHorizontalItem(data['contractHorizontalItem'] || {})
    this.status = data['status'] || TaskActivityStatusEnum.NotCompleted;
    this.sign = data['sign'] || data['horizontalSignaling'] || null;
    this.horizontalCode = data['horizontalCode'] || '';
    this.units = data['units'] || 1;
    this.dimensionX = data['dimensionX'] || 0;
    this.dimensionY = data['dimensionY'] || 0;
    this.dimension = data['dimension'] || 0;
    this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
    this.warranty = data['warranty'] || new Date(2000, 0, 1);
    this.cost = data['cost'] || 0;
    this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0;
    this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0;
    this.serviceItems = data['serviceItems'] || [];
    return this;
  }
}

export class ReplacementVerticalSignalingTaskActivity implements IRest {
  className: 'Atividade de Substituição de Verticais';

  id?: string;
  taskId: string;
  verticalSignalingId: string;
  verticalSignaling: any;
  status: TaskActivityStatusEnum;
  replacementTrafficSignTaskActivity?: ReplacementTrafficSignTaskActivity;
  replacementSupportTaskActivity?: ReplacementSupportTaskActivity;
  cost: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      verticalSignalingId: this.verticalSignalingId || undefined,
      replacementTrafficSignTaskActivity: this.replacementTrafficSignTaskActivity.Serialize(),
      replacementSupportTaskActivity: this.replacementSupportTaskActivity.Serialize(),
      taskId: this.taskId,
      cost: this.cost,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.replacementTrafficSignTaskActivity = new ReplacementTrafficSignTaskActivity(data['replacementTrafficSignTaskActivity'] || {});
    this.replacementSupportTaskActivity = new ReplacementSupportTaskActivity(data['replacementSupportTaskActivity'] || {});
    this.verticalSignalingId = data['verticalSignalingId'] || undefined;
    this.status = data['status'] || undefined;
    this.verticalSignaling = data['verticalSignaling'] || null
    this.taskId = data['taskId'] || undefined
    this.cost = data['cost'] || 0;
    return this;
  }
}

export class ReplacementTrafficSignTaskActivity implements IRest {
  className: 'Atividade de Substituição de Verticais';

  id?: string;
  replacementVerticalSignalingTaskActivityId: string;
  keep: boolean;
  trafficSignId?: string;
  contractVerticalItemId?: string;
  verticalType?: VerticalSignalizationTypeEnum;
  verticalSignalingId: string;
  verticalCode?: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  sign: any;
  sideLength?: number;
  diameter?: number;
  dimensionX?: number;
  dimensionY?: number;
  dimension?: number;
  dimensionType?: DimensionInformationEnum;
  warranty?: Date;
  cost?: number;
  complementInformation: string;
  complementInformationType: ComplementInformationEnum;
  trafficSign?: any;
  contractItem?: ContractVerticalItemSign;
  maximumRetroreflectionValue?: number;
  minimumRetroreflectionValue?: number;
  stockedTrafficSignId: string;
  internalCaption: number;
  showInternalCap: boolean;
  contractServiceItemIds: Array<any>;
  serviceItems: Array<ContractServiceItem>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      verticalSignalingId: this.verticalSignalingId || undefined,
      contractVerticalItemId: this.contractVerticalItemId || undefined,
      contractItem: this.contractItem,
      complementInformation: this.complementInformation,
      complementInformationType: this.complementInformationType,
      keep: this.keep,
      trafficSignId: this.trafficSignId || undefined,
      verticalCode: this.verticalCode,
      verticalType: this.verticalType,
      sideLength: this.sideLength || 0,
      diameter: this.diameter || 0,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      sign: this.sign,
      warranty: this.warranty,
      cost: this.cost,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      stockedTrafficSignId: this.stockedTrafficSignId,
      internalCaption: this.internalCaption,
      showInternalCap: this.showInternalCap,
      contractServiceItemIds: this.contractServiceItemIds,
      serviceItems: this.serviceItems,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractVerticalItemId = data['contractVerticalItemId'] || '';
    this.complementInformation = data['complementInformation'] || undefined;
    this.complementInformationType = data['complementInformationType'] || undefined;
    this.replacementVerticalSignalingTaskActivityId = data['replacementVerticalSignalingTaskActivityId'] || null;
    this.contractItem = new ContractVerticalItemSign(data['contractItem'] || {});
    this.keep = data['keep'] || false;
    this.trafficSignId = data['trafficSignId'] || null;
    this.sign = data['sign'] || data['trafficSign'] || null;
    this.verticalCode = data['verticalCode'] || RegulationVerticalEnum.R1;
    this.verticalSignalingId = data['verticalSignalingId'] || undefined;
    this.verticalType = data['verticalType'] || VerticalSignalizationTypeEnum.Regulation;
    this.sideLength = data['sideLength'] || 0;
    this.diameter = data['diameter'] || 0;
    this.dimensionX = data['dimensionX'] || 0;
    this.dimensionY = data['dimensionY'] || 0;
    this.dimension = data['dimension'] || 0;
    this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
    this.cost = data['cost'] || 0;
    this.warranty = data['warranty'] || new Date(2000, 0, 1);
    this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0
    this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0
    this.stockedTrafficSignId = data['stockedTrafficSignId'] || undefined
    this.internalCaption = data['internalCaption'] || undefined;
    this.showInternalCap = data['showInternalCap'] || false;
    this.contractServiceItemIds = data['contractServiceItemIds'] || [];
    this.serviceItems = data['serviceItems'] || [];

    return this;
  }
}

export class ReplacementSupportTaskActivity implements IRest {
  className: 'Atividade de Substituição de Verticais';

  id?: string;
  replacementVerticalSignalingTaskActivityId: string;
  keep: boolean;
  trafficSignId?: string;
  contractVerticalItemId?: string;
  supportMaterialType: SupportMaterialTypeEnum;
  verticalSignalingId: string;
  verticalCode?: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  sign: any;
  dimensionX?: number;
  dimensionY?: number;
  dimension?: number;
  warranty?: Date;
  cost?: number;
  stockedSupportId: string;
  contractServiceItemIds: Array<string>;
  trafficSign?: any;
  contractItem?: ContractVerticalItemSupport;
  serviceItems: Array<ContractServiceItem>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      verticalSignalingId: this.verticalSignalingId || undefined,
      contractVerticalItemId: this.contractVerticalItemId || undefined,
      contractItem: this.contractItem.Serialize(),
      keep: this.keep,
      trafficSignId: this.trafficSignId || undefined,
      supportMaterialType: this.supportMaterialType || undefined,
      cost: this.cost,
      contractServiceItemIds: this.contractServiceItemIds,
      stockedSupportId: this.stockedSupportId,
      serviceItems: this.serviceItems,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractVerticalItemId = data['contractVerticalItemId'] || undefined;
    this.contractItem = new ContractVerticalItemSupport(data['contractItem'] || {});
    this.replacementVerticalSignalingTaskActivityId = data['replacementVerticalSignalingTaskActivityId'] || null;
    this.keep = data['keep'] || false;
    this.trafficSignId = data['trafficSignId'] || null;
    this.verticalSignalingId = data['verticalSignalingId'] || undefined;
    this.supportMaterialType = data['supportMaterialType'] || SupportMaterialTypeEnum.Steel;
    this.cost = data['cost'] || 0;
    this.contractServiceItemIds = data['contractServiceItemIds'] || [];
    this.stockedSupportId = data['stockedSupportId'] || undefined;
    this.serviceItems = data['serviceItems'] || [];
    return this;
  }
}

export class StreetViewTaskMarkerList implements IRest {
  className: 'Marcadores que tem no StreetView, relacionado com as tarefas';

  list: Array<StreetViewTaskMarker>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    return this.list.map(m => m.Serialize());
  }

  Deserialize(data: any) {
    this.list = (data['list'] || []).map(l => new StreetViewTaskMarker(l));
    return this;
  }
}


export class StreetViewTaskMarker implements IRest {
  className: 'Marcadores que tem no StreetView, relacionado com as tarefas';

  taskActivityId: string;
  position: { lat: number, lng: number, povHeading: number, povPitch: number };
  order: number;
  taskActivityIndex: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      taskActivityId: this.taskActivityId || undefined,
      position: this.position || undefined,
      order: this.order || 1,
    };
    return out;
  }
  Deserialize(data: any) {
    this.taskActivityId = data['taskActivityId'] || undefined;
    this.position = data['position'] || undefined;
    this.order = data['order'] || undefined;
    this.taskActivityIndex = data['taskActivityIndex'] || undefined;
    return this;
  }
}

export class StreetViewPanorama implements IRest {
  className: 'Panorama do StreetView';
  id: string;
  panoId: string;
  povHeading: number;
  povPitch: number;
  position: { lat: number, lng: number };
  zoom: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      panoId: this.panoId || undefined,
      povHeading: this.povHeading || undefined,
      povPitch: this.povPitch || undefined,
      position: this.position || undefined,
      zoom: this.zoom || undefined,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.panoId = data['panoId'] || undefined;
    this.povHeading = data['povHeading'] || undefined;
    this.povPitch = data['povPitch'] || undefined;
    this.position = data['position'] || undefined;
    this.zoom = data['zoom'] || undefined;
    return this;
  }
}

