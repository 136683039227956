import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { enumerators } from 'src/dictionaries/enum.pt-br';

interface IDetail {
  key: string;
  error: string;
};

@Injectable()
export class ValidationErrorInterceptor implements HttpInterceptor {
  constructor(
    private _toastService: ToastService,
  ) { };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => { },
        (error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            setTimeout(() => {
              const response = error.error;
              if (response && response.details) {

                if (response.details.length > 1) {
                  this._toastService.showError(`Ocorreu um erro!`);
                };

                response.details.forEach((detail: IDetail) => {
                  const key = detail.key;
                  let message = detail.error;
                  const inputElement = document.getElementsByName(key)[0];

                  if (response.details.length === 1) this._toastService.showError(message);

                  if (inputElement) {
                    inputElement.classList.add('is-invalid');

                    const enumValidationValues = this.extractValidValues(message);

                    if (enumValidationValues) {
                      const translations = enumValidationValues.filter((value) => value !== null).map((value) => this.getTranslation(value));
                      message = this.replaceArray(message, `[${translations.slice(0, -1).join(', ')} ou ${translations[translations.length - 1]}]`);
                    };

                    let errorMessageElement = inputElement.nextSibling as HTMLElement;

                    if (errorMessageElement && errorMessageElement.nodeType === Node.ELEMENT_NODE && errorMessageElement.tagName === 'SMALL') {
                      errorMessageElement.innerHTML = `<i class="fa-regular fa-exclamation-circle text-danger"></i> ${message}`;
                      errorMessageElement.style.whiteSpace = 'nowrap';
                    } else {
                      errorMessageElement = document.createElement('small');
                      errorMessageElement.innerHTML = `<i class="fa-regular fa-exclamation-circle text-danger"></i> ${message}`;
                      errorMessageElement.style.whiteSpace = 'nowrap';
                      inputElement.parentNode.insertBefore(errorMessageElement, inputElement.nextSibling);
                    };
                  };
                });
              };
            }, 500);
          };
        },
      ),
    );
  };

  extractValidValues(inputString: string): Array<string | null> {
    const arrayRegex = /\[[^\]]*\]/;
    const arrayMatch = inputString.match(arrayRegex);

    if (arrayMatch) {
      const valuesString = arrayMatch[0].replace(/^\[|\]$/g, '');
      const values = valuesString.split(',').map((value) => {
        const trimmedValue = value.trim().replace(/'/g, '');
        return trimmedValue === 'null' ? null : trimmedValue;
      });

      return values;
    };
  };

  replaceArray(inputString: string, replacement: string): string {
    const arrayRegex = /\[[^\]]*\]/;
    const result = inputString.replace(arrayRegex, replacement);
    return result.trim();
  };

  getTranslation(searchValue: string): string {
    let translation: string = '';

    for (let key in enumerators) {
      if (enumerators.hasOwnProperty(key)) {
        const value = enumerators[key][searchValue];
        if (value) {
          translation = value;
          break;
        };
      };
    }; 

    return translation;
  };
};
