import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FilterService } from 'src/app/services/filter.service';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import {
  DelimitationHorizontalSignalingEnum,
  DeviceSignalingEnum,
  IndicationVerticalEnum,
  ItemUnitEnum,
  LongitudinalHorizontalSignalingEnum,
  MarkingTypeEnum,
  PavementHorizontalSignalingEnum,
  PlumbingHorizontalSignalingEnum,
  PositioningArrowsEnum,
  RegulationVerticalEnum,
  SignalingTypeEnum,
  SpecialHorizontalEnum,
  SpecialVerticalEnum,
  TransversalsHorizontalSignalingEnum,
  VerticalSignalingEnum,
  VerticalSignalizationTypeEnum,
  WarningVerticalEnum,
} from 'src/enumerators';
import { ContractDeviceGroup, ContractDeviceItem, ProjectDeviceSignaling } from 'src/models';

@Component({
  selector: 'app-sign-list-modal',
  templateUrl: './sign-list-modal.component.html',
  styleUrls: ['./sign-list-modal.component.css'],
})
export class SignListModalComponent implements OnInit {
  @Input() currentSign: any;
  @Input() project: any;
  @Input() stockEntries: any[];
  @Input() contract: any;
  @Input() signType;
  @Input() modalTrafficIndex = 0;
  @Input() projectItemList: Array<any> = [[], [], []];
  @Input() projectAppliedList: Array<any> = [[], [], []];
  @Input() measure = ''; // A medida da dimensão da sinalização, apenas para modal com projeto vinculado
  @Input() signModalSelect = undefined; // A sinalização escolhida dentro do modal
  @Input() classModalSelect = undefined; // A classe da sinalização dentro do modal

  @Output() shareSelectSign = new EventEmitter<any>();
  @Output() shareCloseModal = new EventEmitter<any>();
  verticalSignEnum = VerticalSignalingEnum;

  signModalInput = ''; // Texto escrito dentro do input do modal
  itemModalSelect = undefined; // O item escolhido dentro do modal, caso tenha vínculo projetual
  appliedModalSelect = undefined; // A aplicação escolhida dentro do modal, caso tenha viínculo projetual
  entryModalSelect = undefined;

  verticalTypeEnum = VerticalSignalizationTypeEnum; // Enum de classes de verticais
  verticalSignalings = {
    warning: Object.values(WarningVerticalEnum),
    regulation: Object.values(RegulationVerticalEnum),
    indication: Object.values(IndicationVerticalEnum),
    special: Object.values(SpecialVerticalEnum),
  }; // Os enums de vertical separado por classe
  verticalTypes = Object.values(VerticalSignalizationTypeEnum); // As classes de vertical

  markTypeEnum = MarkingTypeEnum; // Enum de marks de horizontal
  horizontalSignalings = {
    longitudinal: Object.values(LongitudinalHorizontalSignalingEnum),
    transversal: Object.values(TransversalsHorizontalSignalingEnum),
    channeling: Object.values(PlumbingHorizontalSignalingEnum),
    delimitation: Object.values(DelimitationHorizontalSignalingEnum),
    inscriptions: Object.values(PavementHorizontalSignalingEnum),
    positioning: Object.values(PositioningArrowsEnum),
    special: Object.values(SpecialHorizontalEnum),
  }; // Os enums de horizontal separado por classe
  markinTypes = Object.values(MarkingTypeEnum); // As classes de horizontal

  deviceSignalings = Object.values(DeviceSignalingEnum); // Os enums de dispositivos

  typeSignEnum = SignalingTypeEnum; // Enum do tipo HVD
  enumSign = enumerators.Signaling;

  modalsRef?: Array<BsModalRef> = [];

  constructor(private _modalService: BsModalService, private _filterService: FilterService) {}

  ngOnInit(): void {

  }

  selectSignModal(): void {
    this.shareSelectSign.emit(
      !!this.stockEntries?.length
        ? [this.signModalSelect, 'stock']
        : [this.signModalSelect, this.itemModalSelect],
    );
  }

  // Fecha o último modal aberto
  closeModal() {
    this.shareCloseModal.emit();
  }

  // Abre MODAL
  openModalXL(template: TemplateRef<any>) {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  filterModalSigns(list: Array<any>): Array<any> {
    return this._filterService.filterListInput(list, this.signModalInput, enumerators.Signaling);
  }

  // Verifica se já tem uma sinalização do mesmo tipo - evita duas R-1 na mesma conjugada
  findTrafficCode(code) {
    if (this.currentSign && this.signType == SignalingTypeEnum.Vertical) {
      const index = this.currentSign.trafficSigns.findIndex(
        (traffic, index) => traffic.signCode == code && index != this.modalTrafficIndex,
      );

      if (index == -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  // Função que filtra os dispositivos de acordo com o contrato, apenas para dispositivos
  filterContractDevice(device): boolean {
    let bool = false;
    if (this.currentSign?.contract?.id) {
      this.currentSign.contract.deviceGroups.forEach((group: ContractDeviceGroup) => {
        group.deviceItems.forEach((item: ContractDeviceItem, index) => {
          if (device == item.signaling) {
            bool = true;
            return bool;
          }
        });
      });
      if (!bool) return false;
    }
    return true;
  }

  // Função que retorna se a sinalização pertence a tal classe
  findSignClass(sign): boolean {
    let bool = true;
    if (this.classModalSelect) {
      switch (this.signType) {
        case SignalingTypeEnum.Vertical:
          if (!this.verticalSignalings[this.classModalSelect.toLowerCase()].includes(sign)) {
            bool = false;
          }
          break;
        case SignalingTypeEnum.Horizontal:
          if (!this.horizontalSignalings[this.classModalSelect.split('_')[0].toLowerCase()].includes(sign)) {
            bool = false;
          }
          break;
        case SignalingTypeEnum.Device:
          break;
      }
    }
    return bool;
  }

  // Define a classe escolhida, clica novamente para reiniciar
  selectClass(type) {
    if (this.classModalSelect == type) {
      this.classModalSelect = undefined;
    } else {
      this.classModalSelect = type;
    }
  }

  // Quantidade do item, restante/total
  setCountSign(item: any): string {
    let res = '';

    res = `${item.quantityRegistered || 0}/${item.quantity || 1}`;

    return res;
  }

  // Texto do item, descrição
  itemText(item: any): string {

    let text: string;
    if (this.signType == SignalingTypeEnum.Vertical) {
      text = `${item.dimensionY ? item.dimensionY + 'm x' : ''} ${item.dimensionX ? item.dimensionX + 'm =' : ''} ${(item.dimension)}m²`;
    }
    else if (this.signType == SignalingTypeEnum.Horizontal) {
      text = `${item.units ? item.units + 'und x' : ''} ${item.dimensionY ? item.dimensionY + 'm x' : ''} ${item.dimensionX ? item.dimensionX + 'm =' : ''} ${(item.dimension)}m²`;
    }
    else {
      text = `${(item.contractDeviceItem.unit == ItemUnitEnum.m ? item.dimension : item.units)} ${item.contractDeviceItem.unit}`;
    }
    return text;
  }
}
