export enum TaskTypeEnum {
  Implantation = 'IMPLANTATION',
  Removal = 'REMOVAL',
  Maintenance = 'MAINTENANCE',
  Replacement = 'REPLACEMENT',
  Cataloging = 'CATALOGING',
  Revitalizing = 'REVITALIZING'
};

export enum MaintenanceTaskActivityTypeEnum {
  generalMaintenance = 'GENERAL_MAINTENANCE',
  genericMaintenance = 'GENERIC_MAINTENANCE',
  cleaning = 'CLEANING',
  vegetationPruning = 'VEGETATION_PRUNING',
  drilling = 'DRILLING',
  identificationOfInvadedAreas = 'IDENTIFICATION_OF_INVADED_AREAS',
}
