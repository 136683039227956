import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LicenseTypeEnum } from 'src/enumerators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseGuard  {
  helper = new JwtHelperService(); // Instancia do servico angular-jwt para decodificar o Token

  constructor(private _auth: AuthService, private _router: Router) { }

  // Verifica a licença da organização e caso seja light, envia para tela Home.
  canActivate(): boolean {
    const decodedJWT = this.helper.decodeToken(this._auth.getJwtToken());

    if (decodedJWT['license']['type'] == LicenseTypeEnum.Full || decodedJWT['license']['type'] == LicenseTypeEnum.Dev) {
      return true;
    } else {
      this._router.navigate(['/']);
      return false;
    }
  }
}
