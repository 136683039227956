export enum DeviceSignalingEnum {
  AtenuadorImpactoFixo = 'ATENUADOR_IMPACTO_FIXO',
  AtenuadorImpactoMovel = 'ATENUADOR_IMPACTO_MOVEL',
  Balizador = 'BALIZADOR',
  BalizadorRetrorefletivo = 'BALIZADOR_RETRORREFLETIVO',
  BarreiraAcustica = 'BARREIRA_ACUSTICA',
  BarreiraAntiofuscamentoManufaturada = 'BARREIRA_ANTIOFUSCAMENTO',
  BarreiraConcreto = 'BARREIRA_CONCRETO',
  BarreiraMetalicaRemovivel = 'BARREIRA_METALICA_REMOVIVEL',
  CercaViva = 'CERCA_VIVA',
  CilindroDelimitador = 'CILINDRO_DELIMITADOR',
  DefensaMetalica = 'DEFENSA_METALICA',
  DispositivoConcreto = 'DISPOSITIVO_CONCRETO',
  DispositivoContencao = 'DISPOSITIVO_CONTENCAO',
  FaixaElevadaParaTravessiaDePedestres = 'FAIXA_ELEVADA_PEDESTRES',
  GradilFixo = 'GRADIL_FIXO',
  GradilMovel = 'GRADIL_MOVEL',
  Segregador = 'SEGREGADOR',
  MarcadorAlinhamento = 'MARCADOR_ALINHAMENTO',
  MarcadorObstaculos = 'MARCADOR_OBSTACULOS',
  MarcadorPerigo = 'MARCADOR_PERIGO',
  OndulacaoTransversal = 'ONDULACAO_TRANSVERSAL',
  PainelEletronicoFixo = 'PAINEL_ELETRONICO_FIXO',
  PavimentoColorido = 'PAVIMENTO_COLORIDO',
  PavimentoMicrofresado = 'PAVIMENTO_MICROFRESADO',
  Pontalete = 'PONTALETE',
  RevestimentoRugoso = 'REVESTIMENTO_RUGOSO',
  RevestimentoSonorizadorLongitudinal = 'REVESTIMENTO_SONORIZADOR_LONGITUDINAL',
  SemiPorticoTrelicadoProjetado = 'SEMI_PORTICO_TRELICADO_PROJETADO',
  Sonorizador = 'SONORIZADOR',
  Tacha = 'TACHA',
  Tachao = 'TACHAO',
  TerminalAbsorvedor = 'TERMINAL_ABSORVEDOR',
  TerminalAereo = 'TERMINAL_AEREO',
  TerminalEnterrado = 'TERMINAL_ENTERRADO',
  Vaso = 'VASO',
  DispositivoPersonalizado = 'DISPOSITIVO_PERSONALIZADO',
}
