export enum VehicleTypeEnum {
  Bongo = 'BONGO',
  Carroceria = 'CARROCERIA',
  PinturaFrio = 'PINTURA_A_FRIO',
  PinturaFrioManual = 'PINTURA_FRIO_MANUAL',
  PinturaFrioAspersaoSimples = 'PINTURA_FRIO_ASPERSAO_SIMPLES',
  PinturaFrioAspersaoDupla = 'PINTURA_FRIO_ASPERSAO_DUPLA',
  PinturaQuenteExtrudado = 'PINTURA_QUENTE_EXTRUDADO',
  PinturaQuenteHotSpray = 'PINTURA_QUENTE_HOTSPRAY',
  BateEstacas = 'BATE_ESTACAS',
  Munck = 'MUNCK',
  Plataforma = 'PLATAFORMA',
  VeiculoPasseio = 'VEICULO_PASSEIO',

}

