<!-- Template Modal Selecionar Sinalização Filtro-->
<div class="modal-header" style="align-items: center;">
  <h3 class="modal-title">Selecione a sinalização que deseja cadastrar</h3>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <i aria-hidden="true" class="fas fa-xmark"></i>
  </button>
</div>

<div class="modal-body">

  <div class="col-12">
    <div class="form-group mb-5">
      <input type="text" class="form-search form-control" autocomplete="off" placeholder="Procure pela sinalização aqui"
        [(ngModel)]="signModalInput">
    </div>
  </div>

  <div class="d-flex justify-content-between">
    <!-- Botões de filtro -->
    <div class="col-3 pr-4">
      <div *ngIf="signType == typeSignEnum.Horizontal">
        <div *ngFor="let type of markinTypes">
          <div class="row m-0 pb-4">
            <button class="button-sign" (click)="selectClass(type)" [ngClass]="{'active': classModalSelect == type}">
              <div class="d-flex align-items-start flex-column">
                <p class="p-info">{{type | enumText: 'HorizontalType'}}</p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="signType == typeSignEnum.Vertical">
        <div *ngFor="let type of verticalTypes">
          <div class="row m-0 pb-4">
            <button class="button-sign" (click)="selectClass(type)" [ngClass]="{'active': classModalSelect == type}">
              <div class="d-flex align-items-start flex-column">
                <p class="p-info">{{type | enumText: 'SignalizationType'}}</p>
              </div>
            </button>
          </div>
        </div>
      </div>

    </div>
    <!-- Botões de filtro -->

    <!-- Sinalizações do filtro -->
    <div class="col-9 list-signs" *ngIf="!currentSign?.projectId && !stockEntries?.length">
      <ng-container>
        <!-- VERTICAL -->
        <ng-container *ngIf="signType == typeSignEnum.Vertical">
          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Warning || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['warning'])">
              <div *ngIf="!findTrafficCode(sign)" [title]="(sign|enumText: 'Signaling').split(' – ')[1]"
                class="select-sign col-30" (click)="signModalSelect=sign"
                [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode))
                || ((this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode == sign) && !this.signModalSelect) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Regulation || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['regulation'])">
              <div *ngIf="!findTrafficCode(sign)" [title]="(sign|enumText: 'Signaling').split(' – ')[1]"
                class="select-sign col-30" (click)="signModalSelect=sign"
                [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode))
                || ((this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode == sign) && !this.signModalSelect) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Indication || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['indication'])">
              <div *ngIf="!findTrafficCode(sign)" [title]="sign|enumText: 'Signaling'" class="select-sign col-30"
                (click)="signModalSelect=sign"
                [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode))
                || ((this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode == sign) && !this.signModalSelect) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == verticalTypeEnum.Special">
            <ng-container *ngFor="let sign of filterModalSigns(verticalSignalings['special'])">
              <div *ngIf="!findTrafficCode(sign)" [title]="sign|enumText: 'Signaling'" class="select-sign col-30"
                (click)="signModalSelect=sign"
                [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode))
                || ((this.currentSign?.trafficSigns[this.modalTrafficIndex].signCode == sign) && !this.signModalSelect) }">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px; object-fit: contain;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- VERTICAL -->

        <!-- HORIZONTAL -->
        <ng-container *ngIf="signType == typeSignEnum.Horizontal">
          <ng-container *ngIf="classModalSelect == markTypeEnum.longitudinal || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['longitudinal'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.transversal || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['transversal'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">

                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.channeling || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['channeling'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.delimitation || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['delimitation'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.inscriptions || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['inscriptions'])">
              <div [title]="(sign|enumText: 'Signaling').split(' – ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.positioningArrows || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['positioning'])">
              <div [title]="(sign|enumText: 'Signaling').split(' - ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="classModalSelect == markTypeEnum.special || !classModalSelect">
            <ng-container *ngFor="let sign of filterModalSigns(horizontalSignalings['special'])">
              <div [title]="(sign|enumText: 'Signaling').split(' - ')[1]" class="select-sign col-30"
                (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
                || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
                <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
                <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
              </div>
            </ng-container>
          </ng-container>

        </ng-container>
        <!-- HORIZONTAL -->

        <!-- DISPOSITIVO -->
        <ng-container *ngIf="signType == typeSignEnum.Device">
          <ng-container *ngFor="let sign of filterModalSigns(deviceSignalings)">
            <div *ngIf="filterContractDevice(sign)" [title]="sign|enumText: 'Signaling'" class="select-sign col-30"
              (click)="signModalSelect=sign" [ngClass]="{'active': (sign == this.signModalSelect && (this.signModalSelect != this.currentSign?.signCode))
              || ((this.currentSign?.signCode == sign) && !this.signModalSelect)}">
              <img class="mr-3" [src]="sign| enumSvg: 'Signaling': signType" style="height: 64px;">
              <h4 class="name-sign">{{sign|enumText: 'Signaling'}}</h4>
            </div>
          </ng-container>
        </ng-container>
        <!-- DISPOSITIVO -->
      </ng-container>
    </div>

    <!-- PROJETO -->
    <ng-container *ngIf="currentSign?.projectId">
      <div class="list-item-modal">
        <ng-container *ngFor="let item of projectItemList">
          <div [title]="item?.signCode|enumText: 'Signaling'" class="select-sign col-12 justify-content-between"
            (click)="itemModalSelect = item.id" [ngClass]="{'active': (
              ((signType == typeSignEnum.Vertical
              && this.currentSign?.trafficSigns[this.modalTrafficIndex].projectSignalingItemId == item.id)
            || (signType != typeSignEnum.Vertical
             && this.currentSign?.projectSignalingItemId == item.id))
              && !itemModalSelect)
            || itemModalSelect == item.id}">
            <div class="d-flex justify-content-center align-items-center">
              <img class="mr-3" [src]="item?.signCode| enumSvg: 'Signaling': signType"
                style="height: 64px;  object-fit: contain;">
              <div class="d-flex flex-column">
                <h4 class="name-sign">{{item?.signCode|enumText: 'Signaling'}}</h4>
                <h4 class="name-sign">{{itemText(item)}}</h4>
              </div>
            </div>
            <span class="count-sign" style="position: inherit; border: none; height: 32px;">
              {{setCountSign(item)}}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Estoque -->
    <ng-container *ngIf="!currentSign?.projectId && signType == typeSignEnum.Vertical">
      <div class="d-flex flex-column w-100 px-3"
        style="gap: 16px; max-height: 450px; overflow-y: scroll; overflow-x: hidden; border-left: 2px solid var(--snow-dark);">
        <ng-container *ngFor="let entry of filterModalSigns(stockEntries); index as indexOfEntry">
          <div *ngIf="!findTrafficCode(entry.verticalCode) && findSignClass(entry.verticalCode)" [class.active]="entryModalSelect == entry.id"
            [title]="entry.verticalCode|enumText: 'Signaling'" class="select-sign col-12 justify-content-between"
            (click)="entryModalSelect = entry.id; signModalSelect = entry">
            <div class="d-flex justify-content-center align-items-center">
              <img class="mr-3" [src]="entry.verticalCode| enumSvg: 'Signaling': signType"
                style="height: 64px; object-fit: contain;">
              <div class="d-flex flex-column">
                <h4 class="name-sign">{{entry.verticalCode|enumText: 'Signaling'}}</h4>
                <h4 class="name-sign">{{itemText(entry, indexOfEntry)}}</h4>
              </div>
            </div>
            <span class="count-sign" style="position: inherit; border: none; height: 32px;"
              *ngIf="signType != typeSignEnum.Device">
              {{(entry.quantity - entry.quantityRegistered)+' / '+entry.quantity}}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!currentSign?.projectId && signType == typeSignEnum.Device">
      <div class="d-flex flex-column w-100 px-3"
        style="gap: 16px; max-height: 450px; overflow-y: scroll; overflow-x: hidden; border-left: 2px solid var(--snow-dark);">
        <ng-container *ngFor="let entry of filterModalSigns(stockEntries); index as indexOfEntry">
          <div *ngIf="!findTrafficCode(entry.deviceCode) && findSignClass(entry.deviceCode)" [class.active]="entryModalSelect == entry.id"
            [title]="entry.deviceCode|enumText: 'Signaling'" class="select-sign col-12 justify-content-between"
            (click)="entryModalSelect = entry.id; signModalSelect = entry">
            <div class="d-flex justify-content-center align-items-center">
              <img class="mr-3" [src]="entry.deviceCode| enumSvg: 'Signaling': signType"
                style="height: 64px;">
              <div class="d-flex flex-column">
                <h4 class="name-sign">{{entry.deviceCode|enumText: 'Signaling'}}</h4>
                <h4 class="name-sign">{{itemText(entry, indexOfEntry)}}</h4>
              </div>
            </div>
            <span class="count-sign" style="position: inherit; border: none; height: 32px;"
              *ngIf="signType != typeSignEnum.Horizontal">
              {{(entry.quantity - entry.quantityRegistered)+' / '+entry.quantity}}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>


    <!-- Sinalizações do filtro -->
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" style="margin:0em;" (click)="closeModal();">Cancelar</button>
  <button [disabled]="!itemModalSelect && !signModalSelect && !entryModalSelect" type="button" class="btn btn-primary"
    (click)="selectSignModal(); closeModal();">SELECIONAR</button>
</div>

<!-- Template Modal Selecionar Sinalização Filtro-->
