export type SignalType = 'vertical' | 'horizontal' | 'device';

export type VerticalCategory =
  | 'none'
  | 'warning'
  | 'regulation'
  | 'indication'
  | 'longitudinal'
  | 'delimitation'
  | 'canalization'
  | 'transversals'
  | 'registration';

export interface SignalElement {
  type: SignalType;
  category: VerticalCategory;
  name: string;
  legend: string;
  svg: string
}

export const SignalsElement: SignalElement[] = [
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-1',
    legend: 'R-1 - Parada Obrigatória',
    svg: 'assets/img/signals/R-1.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-2',
    legend: 'R-2 - Dê a Preferência',
    svg: 'assets/img/signals/R-2.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-3',
    legend: 'R-3 - Sentido Proibido',
    svg: 'assets/img/signals/R-3.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-4a',
    legend: 'R-4a - Proibido Virar À Direita',
    svg: 'assets/img/signals/R-4a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-4b',
    legend: 'R-4b - Proibido Virar à Esquerda',
    svg: 'assets/img/signals/R-4b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-5a',
    legend: 'R-5a - Proibido Retorno à Direita',
    svg: 'assets/img/signals/R-5a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-5b',
    legend: 'R-5b - Proibido Retorno à Esquerda',
    svg: 'assets/img/signals/R-5b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-6b',
    legend: 'R-6b - Permitido Estacionar',
    svg: 'assets/img/signals/R-6b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-6c',
    legend: 'R-6c - Proibido Parar e Estacionar',
    svg: 'assets/img/signals/R-6c.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-6a',
    legend: 'R-6a - Proibido Estacionar',
    svg: 'assets/img/signals/R-6a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-7',
    legend: 'R-7 - Proibido Ultrapassar',
    svg: 'assets/img/signals/R-7.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-8a',
    legend: 'R-8a - Proibido mudar de faixa ou pista de trânsito da esquerda para direita',
    svg: 'assets/img/signals/R-8a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-8b',
    legend: 'R-8b - Proibido mudar de faixa ou pista de trânsito da direita para esquerda',
    svg: 'assets/img/signals/R-8b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-9',
    legend: 'R-9 - Proibido trânsito de caminhões',
    svg: 'assets/img/signals/R-9.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-10',
    legend: 'R-10 - Proibido trânsito de veículos automotores',
    svg: 'assets/img/signals/R-10.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-11',
    legend: 'R-11 - Proibido trânsito de veículos de tração animal',
    svg: 'assets/img/signals/R-11.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-12',
    legend: 'R-12 - Proibido trânsito de bicicletas',
    svg: 'assets/img/signals/R-12.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-13',
    legend: 'R-13 - Proibido trânsito de tratores e máquinas de obras',
    svg: 'assets/img/signals/R-13.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-14',
    legend: 'R-14 - Peso bruto total máximo permitido',
    svg: 'assets/img/signals/R-14.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-15',
    legend: 'R-15 - Altura máxima permitida',
    svg: 'assets/img/signals/R-15.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-16',
    legend: 'R-16 - Largura máxima permitida',
    svg: 'assets/img/signals/R-16.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-17',
    legend: 'R-17 - Peso máximo permitido por eixo',
    svg: 'assets/img/signals/R-17.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-18',
    legend: 'R-18 - Comprimento máximo permitido',
    svg: 'assets/img/signals/R-18.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-19',
    legend: 'R-19 - Velocidade Máxima Permitida',
    svg: 'assets/img/signals/R-19.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-20',
    legend: 'R-20 - Proibido acionar buzina ou sinal sonoro',
    svg: 'assets/img/signals/R-20.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-21',
    legend: 'R-21 - Alfândega',
    svg: 'assets/img/signals/R-21.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-22',
    legend: 'R-22 - Uso obrigatório de corrente',
    svg: 'assets/img/signals/R-22.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-23',
    legend: 'R-23 - Conserve-se à direita',
    svg: 'assets/img/signals/R-23.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-24a',
    legend: 'R-24a - Sentido de circulação da via/pista',
    svg: 'assets/img/signals/R-24a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-24b',
    legend: 'R-24b - Passagem obrigatória',
    svg: 'assets/img/signals/R-24b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-25a',
    legend: 'R-25a - Vire à esquerda',
    svg: 'assets/img/signals/R-25a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-25b',
    legend: 'R-25b - Vire à direita',
    svg: 'assets/img/signals/R-25b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-25c',
    legend: 'R-25c - Siga em frente ou à esquerda',
    svg: 'assets/img/signals/R-25c.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-25d',
    legend: 'R-25d - Siga em frente ou à direita',
    svg: 'assets/img/signals/R-25d.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-26',
    legend: 'R-26 – Siga em frente',
    svg: 'assets/img/signals/R-26.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-27',
    legend: 'R-27 - Ônibus, caminhões e veículos de grande porte, mantenham-se à direita',
    svg: 'assets/img/signals/R-27.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-28',
    legend: 'R-28 – Duplo sentido de circulação',
    svg: 'assets/img/signals/R-28.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-29',
    legend: 'R-29 – Proibido trânsito de pedestres',
    svg: 'assets/img/signals/R-29.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-30',
    legend: 'R-30 – Pedestre, ande pela esquerda',
    svg: 'assets/img/signals/R-30.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-31',
    legend: 'R-31 – Pedestre, ande pela direita',
    svg: 'assets/img/signals/R-31.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-32',
    legend: 'R-32 – Circulação exclusiva de ônibus',
    svg: 'assets/img/signals/R-32.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-33',
    legend: 'R-33 – Sentido de circulação na rotatória',
    svg: 'assets/img/signals/R-33.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-34',
    legend: 'R-34 – Circulação exclusiva de bicicletas',
    svg: 'assets/img/signals/R-34.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-35a',
    legend: 'R-35a – Ciclista, transite à esquerda',
    svg: 'assets/img/signals/R-35a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-35b',
    legend: 'R-35b – Ciclista, transite à direita',
    svg: 'assets/img/signals/R-35b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-36a',
    legend: 'R-36a – Ciclistas à esquerda, pedestres à direita',
    svg: 'assets/img/signals/R-36a.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-36b',
    legend: 'R-36b – Pedestres à esquerda, ciclistas à direita',
    svg: 'assets/img/signals/R-36b.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-37',
    legend: 'R-37 – Proibido trânsito de motocicletas, motonetas e ciclomotores',
    svg: 'assets/img/signals/R-37.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-38',
    legend: 'R-38 – Proibido trânsito de ônibus',
    svg: 'assets/img/signals/R-38.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-39',
    legend: 'R-39 – Circulação exclusiva de caminhão',
    svg: 'assets/img/signals/R-39.svg'
  },
  {
    type: 'vertical',
    category: 'regulation',
    name: 'R-40',
    legend: 'R-40 – Trânsito proibido a carros de mão',
    svg: 'assets/img/signals/R-40.svg'
  },

  {
    type: 'vertical',
    category: 'warning',
    name: 'A-1a',
    legend: 'A-1a – Curva acentuada à esquerda',
    svg: 'assets/img/signals/A-1a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-1b',
    legend: 'A-1b – Curva acentuada à direita',
    svg: 'assets/img/signals/A-1b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-2a',
    legend: 'A-2a – Curva à esquerda',
    svg: 'assets/img/signals/A-2a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-2b',
    legend: 'A-2b – Curva à direita',
    svg: 'assets/img/signals/A-2b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-3a',
    legend: 'A-3a – Pista sinuosa à esquerda',
    svg: 'assets/img/signals/A-3a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-3b',
    legend: 'A-3b – Pista sinuosa à direita',
    svg: 'assets/img/signals/A-3b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-4a',
    legend: 'A-4a – Curva acentuada em “S” à esquerda',
    svg: 'assets/img/signals/A-4a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-4b',
    legend: 'A-4b – Curva acentuada em “S” à direita',
    svg: 'assets/img/signals/A-4b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-5a',
    legend: 'A-5a – Curva em “S” à esquerda',
    svg: 'assets/img/signals/A-5a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-5b',
    legend: 'A-5b – Curva em “S” à direita',
    svg: 'assets/img/signals/A-5b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-6',
    legend: 'A-6 – Cruzamento de vias',
    svg: 'assets/img/signals/A-6.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-7a',
    legend: 'A-7a – Via lateral à esquerda',
    svg: 'assets/img/signals/A-7a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-7b',
    legend: 'A-7b – Via lateral à direita',
    svg: 'assets/img/signals/A-7b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-8',
    legend: 'A-8 – Interseção em “T”',
    svg: 'assets/img/signals/A-8.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-9',
    legend: 'A-9 – Bifurcação em “Y”',
    svg: 'assets/img/signals/A-9.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-10a',
    legend: 'A-10a – Entroncamento oblíquo à esquerda',
    svg: 'assets/img/signals/A-10a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-10b',
    legend: 'A-10b – Entroncamento oblíquo à direita',
    svg: 'assets/img/signals/A-10b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-11a',
    legend: 'A-11a – Junções sucessivas contrárias, primeira à esquerda',
    svg: 'assets/img/signals/A-11a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-11b',
    legend: 'A-11b – Junções sucessivas contrárias, primeira à direita',
    svg: 'assets/img/signals/A-11b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-12',
    legend: 'A-12 – Interseção em círculo',
    svg: 'assets/img/signals/A-12.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-13a',
    legend: 'A-13a – Confluência à esquerda',
    svg: 'assets/img/signals/A-13a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-13b',
    legend: 'A-13b – Confluência à direita',
    svg: 'assets/img/signals/A-13b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-14',
    legend: 'A-14 – Semáforo à frente',
    svg: 'assets/img/signals/A-14.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-15',
    legend: 'A-15 – Parada obrigatória à frente',
    svg: 'assets/img/signals/A-15.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-16',
    legend: 'A-16 – Bonde',
    svg: 'assets/img/signals/A-16.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-17',
    legend: 'A-17 – Pista irregular',
    svg: 'assets/img/signals/A-17.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-18',
    legend: 'A-18 – Saliência ou lombada',
    svg: 'assets/img/signals/A-18.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-19',
    legend: 'A-19 – Depressão',
    svg: 'assets/img/signals/A-19.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-20a',
    legend: 'A-20a – Declive acentuado',
    svg: 'assets/img/signals/A-20a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-20b',
    legend: 'A-20b – Aclive acentuado',
    svg: 'assets/img/signals/A-20b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-21a',
    legend: 'A-21a – Estreitamento de pista ao centro',
    svg: 'assets/img/signals/A-21a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-21b',
    legend: 'A-21b – Estreitamento de pista à esquerda',
    svg: 'assets/img/signals/A-21b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-21c',
    legend: 'A-21c – Estreitamento de pista à direita',
    svg: 'assets/img/signals/A-21c.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-21d',
    legend: 'A-21d – Alargamento de pista à esquerda',
    svg: 'assets/img/signals/A-21d.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-21e',
    legend: 'A-21e – Alargamento de pista à direita',
    svg: 'assets/img/signals/A-21e.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-22',
    legend: 'A-22 – Ponte estreita',
    svg: 'assets/img/signals/A-22.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-23',
    legend: 'A-23 – Ponte móvel',
    svg: 'assets/img/signals/A-23.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-24',
    legend: 'A-24 – Obras',
    svg: 'assets/img/signals/A-24.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-25',
    legend: 'A-25 – Mão dupla adiante',
    svg: 'assets/img/signals/A-25.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-26a',
    legend: 'A-26a – Sentido único',
    svg: 'assets/img/signals/A-26a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-26b',
    legend: 'A-26b – Sentido duplo',
    svg: 'assets/img/signals/A-26b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-27',
    legend: 'A-27 – Área com desmoronamento',
    svg: 'assets/img/signals/A-27.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-28',
    legend: 'A-28 – Pista escorregadia',
    svg: 'assets/img/signals/A-28.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-29',
    legend: 'A-29 – Projeção de cascalho',
    svg: 'assets/img/signals/A-29.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-30a',
    legend: 'A-30a – Trânsito de ciclistas',
    svg: 'assets/img/signals/A-30a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-30b',
    legend: 'A-30b – Passagem sinalizada de ciclistas',
    svg: 'assets/img/signals/A-30b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-30c',
    legend: 'A-30c – Trânsito compartilhado por ciclistas e pedestres',
    svg: 'assets/img/signals/A-30c.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-31',
    legend: 'A-31 – Trânsito de tratores ou maquinaria agrícola',
    svg: 'assets/img/signals/A-31.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-32a',
    legend: 'A-32a – Trânsito de pedestres',
    svg: 'assets/img/signals/A-32a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-32b',
    legend: 'A-32b – Passagem sinalizada de pedestres',
    svg: 'assets/img/signals/A-32b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-33a',
    legend: 'A-33a – Área escolar',
    svg: 'assets/img/signals/A-33a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-33b',
    legend: 'A-33b – Passagem sinalizada de escolares',
    svg: 'assets/img/signals/A-33b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-34',
    legend: 'A-34 – Crianças',
    svg: 'assets/img/signals/A-34.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-35',
    legend: 'A-35 – Animais',
    svg: 'assets/img/signals/A-35.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-36',
    legend: 'A-36 – Animais selvagens',
    svg: 'assets/img/signals/A-36.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-37',
    legend: 'A-37 – Altura limitada',
    svg: 'assets/img/signals/A-37.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-38',
    legend: 'A-38 – Largura limitada',
    svg: 'assets/img/signals/A-38.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-39',
    legend: 'A-39 – Passagem de nível sem barreira',
    svg: 'assets/img/signals/A-39.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-40',
    legend: 'A-40 – Passagem de nível com barreira',
    svg: 'assets/img/signals/A-40.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-41',
    legend: 'A-41 – Cruz de Santo André',
    svg: 'assets/img/signals/A-41.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-42a',
    legend: 'A-42a – Início de pista dupla',
    svg: 'assets/img/signals/A-42a.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-42b',
    legend: 'A-42b – Fim de pista dupla',
    svg: 'assets/img/signals/A-42b.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-42c',
    legend: 'A-42c – Pista dividida',
    svg: 'assets/img/signals/A-42c.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-43',
    legend: 'A-43 – Aeroporto',
    svg: 'assets/img/signals/A-43.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-44',
    legend: 'A-44 – Vento lateral',
    svg: 'assets/img/signals/A-44.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-45',
    legend: 'A-45 – Rua sem saída',
    svg: 'assets/img/signals/A-45.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-46',
    legend: 'A-46 – Peso bruto total limitado',
    svg: 'assets/img/signals/A-46.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-47',
    legend: 'A-47 – Peso limitado por eixo',
    svg: 'assets/img/signals/A-47.svg'
  },
  {
    type: 'vertical',
    category: 'warning',
    name: 'A-48',
    legend: 'A-48 – Comprimento limitado',
    svg: 'assets/img/signals/A-48.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'ATRATIVOS_TURISTICOS',
    legend: 'Atrativos Turísticos',
    svg: 'assets/img/signals/ATRATIVO-TURISTICO.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'EDUCATIVAS',
    legend: 'Educativas',
    svg: 'assets/img/signals/EDUCATIVAS.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'IDENTIFICACAO',
    legend: 'Identificação',
    svg: 'assets/img/signals/IDENTIFICACAO.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'INFORMACAO_MISTA',
    legend: 'Informação mista',
    svg: 'assets/img/signals/INFO-MISTA.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'ORIENTACAO_DE_DESTINO',
    legend: 'Orientação de Destino',
    svg: 'assets/img/signals/ORIENTACAO-DESTINO.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'POSTOS_DE_FISCALIZACAO',
    legend: 'Postos de Fiscalização',
    svg: 'assets/img/signals/POSTO-FISCALIZACAO.svg'
  },
  {
    type: 'vertical',
    category: 'indication',
    name: 'SERVICOS_AUXILIARES',
    legend: 'Serviços Auxiliares',
    svg: 'assets/img/signals/SERVICOS-AUXILIARES.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LBO',
    legend: 'LBO - Linha de bordo',
    svg: 'assets/img/signals/LBO.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LCO',
    legend: 'LCO - Linha de continuidade',
    svg: 'assets/img/signals/LCO.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LFO-1',
    legend: 'LFO-1 - Linha simples contínua',
    svg: 'assets/img/signals/LFO-1.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LFO-2',
    legend: 'LFO-2 - Linha simples seccionada',
    svg: 'assets/img/signals/LFO-2.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LFO-3',
    legend: 'LFO-3 - Linha dupla contínua',
    svg: 'assets/img/signals/LFO-3.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LFO-4',
    legend: 'LFO-4 - Linha contínua/seccionada',
    svg: 'assets/img/signals/LFO-4.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LMS-1',
    legend: 'LMS-1 - Linha simples contínua',
    svg: 'assets/img/signals/LMS-1.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'LMS-2',
    legend: 'LMS-2 - Linha simples seccionada',
    svg: 'assets/img/signals/LMS-2.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'MCI',
    legend: 'MCI - Marcação de ciclofaixa ao longo da via',
    svg: 'assets/img/signals/MCI.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'MFE',
    legend: 'MFE - Marcação de faixa exclusiva',
    svg: 'assets/img/signals/MFE.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'MFP',
    legend: 'MFP - Marcação de faixa preferencial',
    svg: 'assets/img/signals/MFP.svg'
  },
  {
    type: 'horizontal',
    category: 'longitudinal',
    name: 'MFR',
    legend: 'MFR - Marcação de faixa reversível no contra-fluxo',
    svg: 'assets/img/signals/MFR.svg'
  },
  {
    type: 'horizontal',
    category: 'delimitation',
    name: 'LPP',
    legend: 'LPP - Linha de indicação de proibição de estacionamento e/ou parada',
    svg: 'assets/img/signals/LPP.svg'
  },
  {
    type: 'horizontal',
    category: 'delimitation',
    name: 'MER',
    legend: 'MER - Marca delimitadora de estacionamento regulamentado',
    svg: 'assets/img/signals/MER.svg'
  },
  {
    type: 'horizontal',
    category: 'delimitation',
    name: 'MVE',
    legend: 'MVE - Marca delimitadora de parada de veículos específicos',
    svg: 'assets/img/signals/MVE.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'LCA',
    legend: 'LCA - Linha de canalização',
    svg: 'assets/img/signals/LCA.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IPL-LVR',
    legend: 'IPL-LVR - Legenda velocidade regulamentada – “.....km/h”',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IPL-ESC',
    legend: 'IPL-ESC - Legenda “DEVAGAR”',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IPL-IND',
    legend: 'IPL-IND - Legenda de indicação de distância – “A...m”',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IPL-PAR',
    legend: 'IPL-PAR - Legenda “PARE”',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IPL-MCD',
    legend: 'IPL-MCD - Legendas “MOTO, AMBULÂNCIA, CARGA E DESCARGA, ...',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA',
    legend: 'ZPA - Zebrado de preenchimento da área de pavimento não utilizável',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MAC',
    legend: 'ZPA-MAC - Marcação de acostamento pavimentado e de canteiros centrais fictícios',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MAN',
    legend: 'ZPA-MAN - Marcação de áreas de pavimento não utilizáveis',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MIR',
    legend: 'ZPA-MIR - Marcação de interseção em rotatória',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MTL',
    legend: 'ZPA-MTL - Marcação de transição de largura de pista',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MAO',
    legend: 'ZPA-MAO - Marcação de aproximação de obstáculos permanentes',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'canalization',
    name: 'ZPA-MCB',
    legend: 'ZPA-MCB - Marcação de confluências, bifurcações e entroncamentos',
    svg: 'assets/img/signals/ZPA.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'FTP-1',
    legend: 'FTP-1 - Faixa de travessia de pedestres',
    svg: 'assets/img/signals/FTP-1.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'FTP-2',
    legend: 'FTP-2 - Faixa de travessia de pedestres',
    svg: 'assets/img/signals/FTP-2.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'LDP',
    legend: 'LDP - Linha de “Dê a preferência"',
    svg: 'assets/img/signals/LDP.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'LRE',
    legend: 'LRE - Linha de retenção',
    svg: 'assets/img/signals/LRE.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'LRV',
    legend: 'LRV - Linhas de estímulo a redução de velocidade',
    svg: 'assets/img/signals/LRV.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'MAC',
    legend: 'MAC - Marcação de área de conflito',
    svg: 'assets/img/signals/MAC.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'MAE',
    legend: 'MAE - Marcação de área de cruzamento com faixa exclusiva',
    svg: 'assets/img/signals/MAE.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'MCC',
    legend: 'MCC - Marcação de cruzamento rodocicloviário',
    svg: 'assets/img/signals/MCC.svg'
  },
  {
    type: 'horizontal',
    category: 'transversals',
    name: 'MCF',
    legend: 'MCF - Marcação de cruzamento rodoferroviário',
    svg: 'assets/img/signals/MCF.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'DEF',
    legend: 'DEF - “Deficiente Físico”',
    svg: 'assets/img/signals/DEF.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'IMC',
    legend: 'IMC - Seta indicativa de movimento em curva',
    svg: 'assets/img/signals/IMC.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'LEG',
    legend: 'Legenda',
    svg: 'assets/img/signals/LEG.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'MOF',
    legend: 'MOF - Seta indicativa de mudança obrigatória de faixa',
    svg: 'assets/img/signals/MOF.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'PEM',
    legend: 'PEM - Setas indicativas',
    svg: 'assets/img/signals/PEM.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'SAS',
    legend: 'SAS - “Serviços de Saúde”',
    svg: 'assets/img/signals/SAS.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'SIC',
    legend: 'SIC - “bicicleta”',
    svg: 'assets/img/signals/SIC.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'SIF',
    legend: 'SIF - “Cruz de Santo André”',
    svg: 'assets/img/signals/SIF.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'SIP',
    legend: 'SIP - “Dê a preferência”',
    svg: 'assets/img/signals/SIP.svg'
  },
  {
    type: 'horizontal',
    category: 'registration',
    name: 'SIP',
    legend: 'SIP - “Dê a preferência”',
    svg: 'assets/img/signals/SIP.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'TACHA',
    legend: 'Tacha',
    svg: 'assets/img/signals/TACHA.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'TACHAO',
    legend: 'Tachão',
    svg: 'assets/img/signals/TACHAO.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'DEFENSA_METALICA',
    legend: 'Defensa Metálica',
    svg: 'assets/img/signals/Defensa-Metalica.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'GRADIL_FIXO',
    legend: 'Gradil Fixo',
    svg: 'assets/img/signals/Gradil.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'GRADIL_MOVEL',
    legend: 'Gradil Móvel',
    svg: 'assets/img/signals/Gradil-Portatil.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'FAIXA_ELEVADA_PARA_TRAVESSEIAS_DE_PEDESTRES',
    legend: 'Faixa Elevada',
    svg: 'assets/img/signals/Faixa-Elevada.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'DISPOSITIVO_DE_CONCRETO',
    legend: 'Dispositivo de Concreto',
    svg: 'assets/img/signals/Dispositivo-de-Concreto.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'DISPOSITIVO_DE_CONTENCAO_E_BLOQUEIO',
    legend: 'Dispositivo de Contenção e Bloqueio',
    svg: 'assets/img/signals/Disp-Contensao-Bloqueio.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'CILINDRO_DELIMITADOR',
    legend: 'Cilíndro Delimitador',
    svg: 'assets/img/signals/Cilindro-Delimitador.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'CERCA_VIVA',
    legend: 'Cerca Viva',
    svg: 'assets/img/signals/Cerca-Viva.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BARREIRA_ACUSTICA',
    legend: 'Barreira Acústica',
    svg: 'assets/img/signals/Barreira-Acustica.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BARREIRA_ANTIOFUSCAMENTO_MANUFATURADA',
    legend: 'Barreira Antiofuscamento Manufaturada',
    svg: 'assets/img/signals/Barreira-Antiofuscamento.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BARREIRA_ANTIOFUSCAMENTO_NATURAL_VEGETACAO',
    legend: 'Barreira Antiofuscamento Natural',
    svg: 'assets/img/signals/Cerca-Viva.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BARREIRA_DE_CONCRETO',
    legend: 'Barreira de Concreto Texas',
    svg: 'assets/img/signals/Barreira-Concreto.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BARREIRA_METALICA_REMOVIVEL',
    legend: 'Barreira Metálica Removível',
    svg: 'assets/img/signals/Barreira-Metalica-Removivel.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BALIZADOR',
    legend: 'Balizador',
    svg: 'assets/img/signals/Balizador.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'BALIZADOR_RETRORREFLETIVO',
    legend: 'Balizador Retrorefletido',
    svg: 'assets/img/signals/Balizador-Retrorreflexivo.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'ATENUADOR_DE_IMPACTO_FIXO',
    legend: 'Atenuador de Impacto Fixo',
    svg: 'assets/img/signals/Atenuador-Impacto.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'ATENUADOR_DE_IMPACTO_MOVEL',
    legend: 'Atenuador de Impacto Móvel',
    svg: 'assets/img/signals/Atenuador-Impacto-Movel.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'PONTALETE',
    legend: 'Pontalete',
    svg: 'assets/img/signals/Pontalete.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'VASO_OU_FLOREIRA',
    legend: 'Vaso',
    svg: 'assets/img/signals/Vaso.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'PAINEL_ELETRONICO_FIXO',
    legend: 'Painel Eletrônico Fixo',
    svg: 'assets/img/signals/Painel-Eletronico-Fixo.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'MARCADOR_DE_ALINHAMENTO',
    legend: 'Marcador de Alinhamento',
    svg: 'assets/img/signals/Marcador-Alinhamento.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'MARCADOR_DE_OBSTACULOS',
    legend: 'Marcador de Obstáculos',
    svg: 'assets/img/signals/Marcador-Obstaculo.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'MARCADOR_DE_PERIGO',
    legend: 'Marcador de Perigo',
    svg: 'assets/img/signals/Marcador-Perigo.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'ONDULACAO_TRANSVERSAL',
    legend: 'Ondulação Transversal',
    svg: 'assets/img/signals/Ondulacao-Transversal.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'SONORIZADOR',
    legend: 'Sonorizador',
    svg: 'assets/img/signals/Sonorizador.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'REVESTIMENTO_COM_SONORIZADOR_LONGITUDINAL',
    legend: 'Revestimento com Sonorizador Longitudinal',
    svg: 'assets/img/signals/Rev-Sonorizador-Longitudinal.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'REVESTIMENTO_RUGOSO',
    legend: 'Revestimento Rugoso',
    svg: 'assets/img/signals/Revestimento-Rugoso.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'PAVIMENTO_MICROFRESADO',
    legend: 'Pavimento Microfresado',
    svg: 'assets/img/signals/Pavimento-Microfresado.svg'
  },
  {
    type: 'device',
    category: 'none',
    name: 'PAVIMENTO_COLORIDO',
    legend: 'Pavimento Colorido',
    svg: 'assets/img/signals/Pavimento-Colorido.svg'
  },
];
