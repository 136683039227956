<app-header></app-header>
<app-header-menu></app-header-menu>
<app-toggle-sidebar></app-toggle-sidebar>

<app-resume-project *ngIf="pageController == 'resume'" (newController)="pageController = $event; checkCounterBreak()"
  [projectBody]="project" [contract]="currentContract" [uploadedFiles]="uploadedFiles"></app-resume-project>

<div *ngIf="loading" style="position: fixed; top: 50%; left: 48%;">
  <app-loading-page></app-loading-page>
</div>

<div class="container-fluid main" *ngIf="pageController !== 'resume' && project && !loading">

  <!-- Título -->
  <div class="d-flex row justify-content-between action-bar px-3">
    <h1>{{editMode ? 'Projetos' : 'Novo Projeto'}}</h1>
  </div>
  <!-- Título -->

  <!-- Step e botões -->
  <div class="row" style="margin-top: 30px;">
    <div class="col">
      <div class="contentLinks mb-4">

        <!-- Steps -->
        <div class="row mx-0">

          <div class="col-auto pl-3 mr-3 d-flex align-items-center ">
            <i class="fas fa-arrow-left cursor-pointer fa-lg" (click)="goBack()"></i>
          </div>

          <div class="form-status">
            <h5 class="text-primary"><i class="fa-solid fa-circle-1"></i> Dados do Projeto</h5>
          </div>

          <div class="form-status" style="padding-left: 40px">
            <h5 class="text-muted"
              [ngClass]="{'text-muted': pageController == 'projectData', 'text-primary': pageController != 'projectData'}">
              <i class="fa-solid fa-circle-2"></i> Sinalizações do Projeto
            </h5>
          </div>

        </div>
        <!-- Steps -->


        <!-- Botões -->
        <div class="col-4 px-0 contentBtns">

          <div class="col-6">

            <button class="btn btn-outline-primary btn-block" (click)="openModal(closeWarning)">
              Cancelar
            </button>

          </div>

          <div class="col-6">
            <button class="btn btn-primary btn-block" (click)="goAhead(); submitRegionAreaPath()"
              [disabled]="!project?.name || !project.contractId || (pageController != 'projectData' && !formValidation) || (pageController != 'projectData' && !project?.projectTrafficSigns.length && !project?.projectSupports.length && !project?.projectHorizontalSignalings.length && !project?.projectDeviceSignalings.length)">
              Avançar <i class="far fa-angle-right fa-lg"></i>
            </button>

          </div>

        </div>
        <!-- Botões -->

      </div>
    </div>
  </div>
  <!-- Step e botões -->

  <div class="row">

    <!-- Step Um -->
    <ng-container *ngIf="pageController == 'projectData'">

      <div class="col-8 card-main scroll-project">
        <!-- Título Seção -->
        <div class="mb-3">
          <h3>Dados Gerais do Projeto</h3>
        </div>

        <div class="px-0">
          <hr>
        </div>
        <!-- Título Seção -->

        <!-- Formulário -->
        <form>
          <!-- Dados Gerais -->
          <div class="row">

            <!-- Nome do Projeto -->
            <div class="col">
              <div class="form-group">

                <label for="inputNameproject'">Nome do Projeto*</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="project.name"
                  placeholder="Informe o nome do projeto">

              </div>
            </div>
            <!-- Nome do Projeto -->


            <!-- Vínculo Contratual -->
            <div class="col">
              <div class="form-group">

                <label for="inputContract">Vínculo Contratual*</label>

                <select type="text" class="form-control" id="inputContract" name="contractId"
                  [(ngModel)]="project.contractId" (ngModelChange)="setContract($event)">
                  <option [ngValue]="''" disabled>Selecione o contrato</option>
                  <option *ngFor="let contract of contractsList" [ngValue]="contract?.id">{{contract?.name}}</option>
                </select>

              </div>
            </div>
            <!-- Vínculo Contratual -->

          </div>
          <!-- Dados Gerais -->


          <!-- Título Seção -->
          <div class="my-3">
            <h3>Descrição do Projeto</h3>
          </div>

          <div class="px-0" style="margin-right: -15px;">
            <hr>
          </div>
          <!-- Título Seção -->


          <!-- Descrição do Projeto -->
          <div class="row mt-3">

            <div class="col">

              <div class="form-group" id="region-container-row" #regionContainer>

                <label for="selectRegion">Descrição do Projeto</label>
                <textarea placeholder="Escreva aqui detalhes sobre o projeto." class="form-control" id="txtArea"
                  rows="3" style="max-height: 200px" [(ngModel)]="project.description" name="details"></textarea>

              </div>

            </div>
          </div>
          <!-- Descrição do Projeto -->


          <!-- Título Seção -->
          <div class="my-3">
            <h3>Localização</h3>
          </div>

          <div class="px-0" style="margin-right: -15px;">
            <hr>
          </div>
          <!-- Título Seção -->


          <!-- Localização -->
          <div class="row mt-3">

            <!-- Input Múltiplo Região -->
            <div class="col">
              <div class="form-group" id="region-container-row" #regionContainer>

                <label for="selectRegion">Região</label>

                <!-- Div de Regiões Selecionadas -->
                <div class="region-area-row" id="region-selection-row" #regionRow>
                  <div class="selected-region-area mr-2" *ngFor="let region of selectedRegions; index as indexOfRegion"
                    [id]="'region'+region?.id">
                    <p>{{region?.name}}</p>
                    <div class="cancel-selection" (click)="removeRegionAreaPath('region', region,indexOfRegion)"><i
                        class="fa-solid fa-close fa-lg"></i></div>
                  </div>
                </div>
                <!-- Div de Regiões Selecionadas -->


                <!-- Contador de Regiões Escondidas-->
                <div class="text-muted counter" *ngIf="overflowCounter.region > 0">+ {{overflowCounter.region}}</div>
                <!-- Contador de Regiões Escondidas-->


                <!--  Select das Regiões-->
                <select class="form-control region-area-select" name="regionId"
                  (change)="setRegionAreaPath($event.target.value, 'region')">
                  <option value="" class="select-placeholder" hidden>{{selectedRegions.length
                    ? '' : 'Selecione aqui'}}</option>
                  <option *ngFor="let region of regionsList;index as indexOfRegion" [value]="region?.id">
                    {{region?.name}}
                  </option>
                </select>
                <!--  Select das Regiões-->
              </div>
            </div>
            <!-- Input Múltiplo Região -->


            <!-- Input Múltiplo Área-->
            <div class="col">
              <div class="form-group" id="area-container-row" #areaContainer>

                <label for="selectArea">Área</label>

                <!-- Div de Áreas Selecionadas -->
                <div class="region-area-row" id="area-selection-row" #areaRow>
                  <div class="selected-region-area mr-2" *ngFor="let area of selectedAreas; index as indexOfArea"
                    [id]="'area'+area?.id">
                    <p>{{area?.name}}</p>
                    <div class="cancel-selection" (click)="removeRegionAreaPath('area', area, indexOfArea)"><i
                        class="fa-solid fa-close fa-lg"></i></div>
                  </div>
                </div>
                <!-- Div de Áreas Selecionadas -->


                <!-- Contador de Áreas Escondidas-->
                <div class="text-muted counter" *ngIf="overflowCounter.area > 0">+ {{overflowCounter.area}}</div>
                <!-- Contador de Áreas Escondidas-->


                <!--  Select das Áreas-->
                <select id="selectArea" class="form-control region-area-select" name="areaId"
                  (change)="setRegionAreaPath($event.target.value, 'area')">
                  <option value="" class="select-placeholder" hidden>{{selectedAreas.length
                    ? '' : 'Selecione aqui'}}</option>
                  <option *ngFor="let area of areasList; index as indexOfArea" [value]="area?.id">{{area?.name}}
                  </option>
                </select>
                <!--  Select das Áreas-->

              </div>
            </div>
            <!-- Input Múltiplo Área-->


            <!-- Input Múltiplo Trajeto-->
            <div class="col">
              <div class="form-group" id="path-container-row" #pathContainer>

                <label for="selectPath">Trajeto</label>

                <!-- Div de Trajetos Selecionadas -->
                <div class="region-area-row" id="path-selection-row" #pathRow>
                  <div class="selected-region-area mr-2" *ngFor="let path of selectedPaths; index as indexOfPath"
                    [id]="'path'+path?.id">
                    <p>{{path?.name}}</p>
                    <div class="cancel-selection" (click)="removeRegionAreaPath('path', path, indexOfPath)"><i
                        class="fa-solid fa-close fa-lg"></i></div>
                  </div>
                </div>

                <!-- Contador de Regiões Escondidas-->
                <div class="text-muted counter" *ngIf="overflowCounter.path > 0">+ {{overflowCounter.path}}</div>

                <!--  Select das Trajetos-->
                <select id="selectPath" class="form-control region-area-select" name="pathId"
                  (change)="setRegionAreaPath($event.target.value, 'path')">
                  <option value="" class="select-placeholder" hidden>{{selectedPaths.length
                    ? '' : 'Selecione aqui'}}</option>
                  <option *ngFor="let path of pathsList; index as indexOfPath" [value]="path?.id">{{path?.name}}
                  </option>
                </select>
              </div>
            </div>
            <!-- Input Múltiplo Trajeto-->

          </div>
          <!-- Localização -->


          <!-- Título Seção -->
          <div class="my-3">
            <h3>Observações</h3>
          </div>

          <div class="px-0" style="margin-right: -15px;">
            <hr>
          </div>
          <!-- Título Seção -->


          <!-- Observações -->
          <div class="row mt-3">

            <div class="col">
              <div class="form-group" id="region-container-row" #regionContainer>
                <label for="selectRegion">Se desejar, adicione observações adicionais</label>
                <input type="text" class="form-control" name="observation" [(ngModel)]="project.observation"
                  placeholder="Escreva suas observações aqui.">
              </div>
            </div>
          </div>
          <!-- Observações -->

        </form>
        <!-- Formulário -->
      </div>

    </ng-container>
    <!-- Step Um -->


    <!-- Step Dois -->
    <ng-container *ngIf="pageController != 'projectData'">
      <div *ngIf="loadingContract" class="h-50">
        <app-loading-page></app-loading-page>
      </div>
      <div class="wrapper col-8" *ngIf="!loadingContract">
        <!-- Título Seção -->
        <div>
          <h3>Sinalizações do Projeto</h3>
        </div>

        <div class="px-0 my-4" style="margin-right: -15px;">
          <hr>
        </div>
        <!-- Título Seção -->

        <div class="col-auto px-0 mb-4">
          <div class="select-hvd">
            <div class="col px-2">
              <button (click)="pageController = signTypeEnum.Vertical" class="btn select-btn"
                [class.active-link]="pageController == signTypeEnum.Vertical">
                Vertical
              </button>
            </div>
            <div class="col px-2">
              <button (click)="pageController = signTypeEnum.Horizontal" class="btn select-btn"
                [class.active-link]="pageController == signTypeEnum.Horizontal">
                Horizontal
              </button>
            </div>
            <div class="col px-2">
              <button (click)="pageController = signTypeEnum.Device" class="btn select-btn "
                [class.active-link]="pageController == signTypeEnum.Device">
                Dispositivos
              </button>
            </div>
          </div>
        </div>

        <!-- Chamando os componentes de formulário -->
        <app-vertical-project *ngIf="pageController == signTypeEnum.Vertical" class="w-100"
          [trafficSignTaskActivities]="project.projectTrafficSigns" [contract]="currentContract"
          (validationEvent)="handleFormValidation($event)"></app-vertical-project>

        <app-device-project *ngIf="pageController == signTypeEnum.Device" class="w-100"
          [deviceSignalingTaskActivities]="project.projectDeviceSignalings" [contract]="currentContract"
          (validationEvent)="handleFormValidation($event)"></app-device-project>

        <app-horizontal-project *ngIf="pageController == signTypeEnum.Horizontal" class="w-100"
          [horizontalSignalingTaskActivities]="project.projectHorizontalSignalings" [contract]="currentContract"
          (validationEvent)="handleFormValidation($event)">
        </app-horizontal-project>

        <div class="row pb-4 mx-2">
          <button class="btn btn-link" (click)="addProjectItem(pageController)">
            <i class="fas fa-plus-circle"></i>
            nova sinalização
          </button>
        </div>

        <ng-container *ngIf="pageController == signTypeEnum.Vertical">
          <app-support-project class="w-100" [supportTaskActivities]="project.projectSupports"
            [contract]="currentContract" (validationEvent)="handleFormValidation($event)"></app-support-project>
          <div class="row pb-4 mx-2">
            <button class="btn btn-link" (click)="addProjectItem(verticalGroupTypeEnum.Support)">
              <i class="fas fa-plus-circle"></i>
              novo suporte
            </button>
          </div>
        </ng-container>
        <!-- Chamando os componentes de formulário -->

      </div>
    </ng-container>
    <!-- Step Dois -->


    <!-- Componente de Anexos  -->
    <div class="col-4" style="padding: 0px 28px 0 16px;">
      <app-upload-file (shareFiles)="uploadedFiles = $event" [editFiles]="uploadedFiles" [editMode]="editMode"
        [type]="'project'" [isProject]="true">
      </app-upload-file>
    </div>
    <!-- Componente de Anexos  -->

  </div>


</div>

<!-- Modal para encerrar ação - Aviso de perda de progresso -->
<ng-template #closeWarning>
  <div class="modal-header">
    <h3 class="modal-title">Cancelar {{editMode ? 'Edição' : 'Criação'}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="h2">Tem certeza que deseja sair da {{editMode ? 'edição' : 'criação'}} deste Projeto?</div>
  </div>

  <div class="modal-footer">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">
          cancelar
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary" routerLink="/projects" (click)="modalRef.hide()">
          sair
        </button>
      </div>
    </div>
  </div>
</ng-template>
