import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { FaqComponent } from './faq/faq.component';
import { FaqModule } from './faq/faq.module';
import { HomePageComponent } from './home-page/home-page.component';
import { PipesModule } from './pipes/pipes.module';
import { NewProjectComponent } from './project-page/new-project/new-project.component';
import { ResumeProjectComponent } from './project-page/resume-project/resume-project.component';
import { PublicReportPageComponent } from './public-report-page/public-report-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { LicenseGuard } from './services/license.guard';
import { AuthGuardService } from './services/route-guard.service';
import { ValidationErrorInterceptor } from './services/validation-error.interceptor';

const maskConfig: Partial<IConfig> = {
  validation: false,
};


defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePt, 'pt');

const routes: Routes = [
  {
    path: 'contracts',
    loadChildren: () => import('./contract-page/contract-page.module').then((m) => m.ContractPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./login-page/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'master',
    loadChildren: () => import('./master-page/master-page.module').then((m) => m.MasterPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onBoarding/on-boardings.module').then((m) => m.OnBoardingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'region-area',
    loadChildren: () => import('./region-area/region-area.module').then((m) => m.RegionAreaModule),
    canActivate: [AuthGuardService],
  },
  { path: 'help', component: FaqComponent },
  {
    path: 'invitation-confirmation',
    pathMatch: 'prefix',
    redirectTo: 'login/from-email-link',
  },
  {
    path: 'password-recovery-token',
    pathMatch: 'prefix',
    redirectTo: 'login/recovery-password',
  },
  {
    path: 'organization',
    loadChildren: () => import('./org-page/org.module').then((m) => m.OrgModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then((m) => m.CatalogModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'region-area',
    loadChildren: () => import('./region-area/region-area.module').then((m) => m.RegionAreaModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'map',
    loadChildren: () => import('./map-page/map-page.module').then((m) => m.MapPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects',
    loadChildren: () => import('./project-page/project-page.module').then((m) => m.ProjectPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'stock',
    loadChildren: () => import('./stock-page/stock-page.module').then((m) => m.StockPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/new-project/:id',
    component: NewProjectComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/display/:id',
    component: ResumeProjectComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'report',
    loadChildren: () => import('./report-page/report-page.module').then((m) => m.ReportPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard-page/dashboard-page.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings-page/settings-page.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'services',
    loadChildren: () => import('./services-page/services-page.module').then((m) => m.ServicesPageModule),
    canActivate: [AuthGuardService, LicenseGuard],
  },
  {
    path: 'paths',
    loadChildren: () => import('./path-page/path-page.module').then((m) => m.PathPageModule),
    canActivate: [AuthGuardService, LicenseGuard],
  },
  { path: '', component: HomePageComponent, canActivate: [AuthGuardService] },
  { path: 'relatorio-fotografico/:id', component: PublicReportPageComponent },
];

@NgModule({
  declarations: [AppComponent, HomePageComponent, ServicesPageComponent, PublicReportPageComponent],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(routes),
    ComponentsModule,
    HttpClientModule,
    FaqModule,
    RouterModule.forRoot(routes, { useHash: true }),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PipesModule,
    AccordionModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    AuthGuardService,
    BsLocaleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ValidationErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    provideNgxMask(maskConfig)
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
