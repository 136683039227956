import { ServiceFunction } from 'src/app/utils/servicesStaticFunction';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import { svgElement } from 'src/dictionaries/svg-sign';
import {
  ActionEnum, ComplementInformationEnum,
  DeviceSignalingEnum, DimensionInformationEnum,
  DirectionEnum,
  HorizontalSignalingEnum, PaymentStatusEnum, SignalingHistoryActionMadeOnEnum,
  SignalingQualityEnum,
  SignalingStatusEnum, SignalingTypeEnum, SupportMaterialTypeEnum,
  VerticalSignalingEnum, VerticalSignalizationTypeEnum, WarrantyStatusEnum
} from 'src/enumerators';
import {
  Contract,
  ContractDeviceItem,
  ContractHorizontalItem,
  ContractVerticalItemSign,
  ContractVerticalItemSupport,
  RoadSignFilm,
  RoadSignMarkingPaint,
  RoadSignSubstrate
} from './contract';
import { Path } from './path';
import {
  Project, ProjectDeviceSignaling,
  ProjectHorizontalSignaling, ProjectSupport,
  ProjectTrafficSign
} from './project';
import { IRest } from './rest.interface';
import { Member } from './user';

export class Address implements IRest {
  className = 'Objeto para preenchimento do enderenço das sinalizações';

  city: string;
  house_number: string;
  road: string;
  suburb: string;
  postcode: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      city: this.city,
      house_number: this.house_number,
      road: this.road,
      suburb: this.suburb,
      postcode: this.postcode,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.city = data['city'] || '';
      this.house_number = data['house_number'] || '';
      this.road = data['road'] || '';
      this.suburb = data['suburb'] || '';
      this.postcode = data['postcode'] || '';
    }
    return this;
  }
}

export class Location implements IRest {
  className = 'Objeto para preenchimento das localizações de H e D';

  lat: number;
  lng: number;
  marker: number;
  distance: number;
  address: any

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      lat: this.lat,
      lng: this.lng,
      marker: this.marker,
      distance: this.distance,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.lat = data['lat'] || 0;
      this.lng = data['lng'] || 0;
      this.marker = data['marker'] || 0;
      this.distance = data['distance'] || 0;
    }
    return this;
  }
}

export class SignalingHistory implements IRest {
  className = 'Objeto para preenchimento do histórico da sinalização';

  id: string;
  action: ActionEnum;
  actionMadeOn: SignalingHistoryActionMadeOnEnum;
  createdAt: Date;
  data: Object;
  member: Member;
  memberId: string;
  updatedAt: Date;
  verticalSignalingId: string;
  horizontalSignalingId: string;
  deviceSignalingId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      action: this.action,
      actionMadeOn: this.actionMadeOn,
      createdAt: this.createdAt,
      data: this.data,
      member: this.member.Serialize(),
      memberId: this.memberId,
      updatedAt: this.updatedAt,
      verticalSignalingId: this.verticalSignalingId,
      horizontalSignalingId: this.horizontalSignalingId,
      deviceSignalingId: this.deviceSignalingId,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.action = data['action'] || ActionEnum;
      this.actionMadeOn = data['actionMadeOn'] || SignalingHistoryActionMadeOnEnum;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.data = data['data'] || undefined;
      this.member = new Member(data['member'] || {});
      this.memberId = data['memberId'] || undefined;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.verticalSignalingId = data['verticalSignalingId'];
      this.horizontalSignalingId = data['horizontalSignalingId'];
      this.deviceSignalingId = data['deviceSignalingId'];
    }
    return this;
  }
}

export class SignalingMasterFile implements IRest {
  className = 'Objeto para preenchimento do arquivo mestre de sinalizações';

  id: string;
  createdAt: Date;
  locations: Array<any>;
  projectFile: Object;
  projectFileId: string;
  updatedAt: Date;
  url: string;
  verticalSignalingId: string;
  horizontalSignalingId: string;
  deviceSignalingId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      createdAt: this.createdAt,
      locations: this.locations,
      projectFile: this.projectFile,
      projectFileId: this.projectFileId,
      updatedAt: this.updatedAt,
      url: this.url,
      verticalSignalingId: this.verticalSignalingId,
      horizontalSignalingId: this.horizontalSignalingId,
      deviceSignalingId: this.deviceSignalingId,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.locations = data['locations'] || undefined;
      this.projectFile = data['projectFile'] || undefined;
      this.projectFileId = data['projectFileId'] || undefined;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.url = data['url'] || '';
      this.verticalSignalingId = data['verticalSignalingId'];
      this.horizontalSignalingId = data['horizontalSignalingId'];
      this.deviceSignalingId = data['deviceSignalingId'];
    }
    return this;
  }
}

export class VerticalCombinedSign implements IRest {
  className = 'Objeto para preenchimento da lista de combinadas dentro de uma vertical';

  id: string;
  active: number;
  complementInformationType: ComplementInformationEnum;
  complementInformation: string;
  cost: number;
  createdAt: Date;
  groupId: string;
  internalCaption: number;
  order: number;
  progress: number;
  signName: string;
  svg: string;
  signCode: VerticalSignalingEnum;
  verticalType: VerticalSignalizationTypeEnum;
  warranty: Date;
  warrantyCurrent: Array<number>;
  warrantyEnd: Array<number>;
  quality: SignalingQualityEnum;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      active: this.active,
      complementInformationType: this.complementInformationType,
      complementInformation: this.complementInformation,
      cost: this.cost,
      createdAt: this.createdAt,
      groupId: this.groupId,
      internalCaption: this.internalCaption,
      order: this.order,
      progress: this.progress,
      signName: this.signName,
      svg: this.svg,
      signCode: this.signCode,
      verticalType: this.verticalType,
      warranty: this.warranty,
      warrantyCurrent: this.warrantyCurrent,
      warrantyEnd: this.warrantyEnd,
      quality: this.quality,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['conjugate']['id'] || undefined;
      this.complementInformation = data['conjugate']['complementInformation'] || '';
      this.complementInformationType = data['conjugate']['complementInformationType'] || undefined;
      this.createdAt = data['conjugate']['createdAt'] || new Date(2000, 0);
      this.cost = data['conjugate']['cost'] || 0;
      this.groupId = data['conjugate']['groupId'] || undefined;
      this.internalCaption = data['conjugate']['internalCaption'] || '';
      this.order = data['conjugate']['order'] || 1;
      this.signCode = data['conjugate']['signCode'] || data['conjugate']['verticalCode'] || undefined;
      this.signName = enumerators.Signaling[this.signCode] || undefined;
      this.svg = svgElement.Signaling[this.signCode] || 'assets/icons/vertical.svg';
      this.verticalType = data['conjugate']['verticalType'] || undefined;
      this.warranty = data['conjugate']['warranty'] || new Date(2000, 0);
      this.warrantyCurrent = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, new Date()) : undefined;
      this.warrantyEnd = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, this.createdAt) : undefined;
      this.quality = data['conjugate']['quality'] || undefined;
      this.progress = this.warrantyEnd
        ? this.warrantyEnd[0] || this.warrantyCurrent[0]
          ? this.warrantyCurrent[0] / this.warrantyEnd[0]
          : 0
        : 0;
      this.active = this.progress <= 0 ? 3 : ServiceFunction.lessThanAMonth(this.warrantyCurrent) ? 2 : 1;
    }
    return this;
  }
}

export class VerticalTrafficSignaling implements IRest {
  className = 'Objeto para preenchimento de sinalizações verticais';

  id: string;
  useTraffic: number; // Identificar qual dos três traffics vou utilizar
  active: number;
  address: Address;
  addressFormatted: string;
  check: boolean;
  combined: number;
  combinedSign: Array<VerticalCombinedSign>;
  complementInformationType: ComplementInformationEnum;
  complementInformation: string;
  contract: Contract;
  contractId: string;
  contractSupportItem: ContractVerticalItemSupport;
  contractSupportItemId: string;
  contractSignItem: ContractVerticalItemSign;
  contractSignItemId: string;
  cost: number;
  createdAt: Date;
  executionDate: Date;
  diameter: number;
  dimension: number;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  direction: DirectionEnum;
  distance: number;
  startsAt: number;
  endsAt: number;
  existingSupport: boolean;
  groupId: string;
  icon: string;
  iconSize: Array<number>;
  internalCaption: number;
  isEditable: boolean;
  lat: number;
  lng: number;
  observation: string;
  order: number;
  path: Path;
  pathId: string;
  paymentStatus: PaymentStatusEnum;
  progress: number;
  project: Project;
  projectId: string;
  projectSupport: ProjectSupport;
  projectSupportId: string;
  projectSignalingItem: ProjectTrafficSign;
  projectSignalingItemId: string;
  responsibleMemberId: string;
  retroreflection: number;
  maximumRetroreflectionValue: number;
  minimumRetroreflectionValue: number;
  roadSignFilm: RoadSignFilm;
  roadSignFilmId: string;
  roadSignSubstrate: RoadSignSubstrate;
  roadSignSubstrateId: string;
  roadSignSubstrateTranslate: string;
  sideLength: number;
  signName: string;
  status: SignalingStatusEnum;
  supportCost: number;
  supportMaterialType: SupportMaterialTypeEnum;
  supportQuantity: number;
  supportMaterialTranslate: string;
  svg: string;
  type: SignalingTypeEnum;
  updatedAt: Date;
  signCode: VerticalSignalingEnum;
  verticalSignalingFiles: Array<Object>;
  verticalSignalingHistory: Array<SignalingHistory>;
  verticalSignalingMasterFile: SignalingMasterFile;
  verticalSignalingId: string;
  verticalType: VerticalSignalizationTypeEnum;
  warranty: Date;
  warrantyCurrent: Array<number>;
  warrantyEnd: Array<number>;
  warrantyLastUpdate: Date;
  quality: SignalingQualityEnum;
  workspaceId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      useTraffic: this.useTraffic,
      active: this.active,
      address: this.address,
      addressFormatted: this.addressFormatted,
      check: this.check,
      combined: this.combined,
      combinedSign: this.combinedSign,
      complementInformationType: this.complementInformationType,
      complementInformation: this.complementInformation,
      contract: this.contract,
      contractId: this.contractId,
      contractSupportItem: this.contractSupportItem,
      contractSupportItemId: this.contractSupportItemId,
      contractSignItem: this.contractSignItem,
      contractSignItemId: this.contractSignItemId,
      cost: this.cost,
      createdAt: this.createdAt,
      executionDate: this.executionDate,
      diameter: this.diameter || 0,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      direction: this.direction,
      distance: this.distance,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      existingSupport: this.existingSupport,
      groupId: this.groupId,
      icon: this.icon,
      iconSize: this.iconSize,
      internalCaption: this.internalCaption,
      isEditable: this.isEditable,
      lat: this.lat,
      lng: this.lng,
      observation: this.observation,
      order: this.order,
      path: this.path,
      pathId: this.pathId,
      paymentStatus: this.paymentStatus,
      progress: this.progress,
      project: this.project,
      projectId: this.projectId,
      projectSupport: this.projectSupport,
      projectSupportId: this.projectSupportId,
      projectTrafficSign: this.projectSignalingItem,
      projectTrafficSignId: this.projectSignalingItemId,
      responsibleMemberId: this.responsibleMemberId,
      retroreflection: this.retroreflection,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      roadSignFilm: this.roadSignFilm,
      roadSignFilmId: this.roadSignFilmId,
      roadSignSubstrate: this.roadSignSubstrate,
      roadSignSubstrateId: this.roadSignSubstrateId,
      roadSignSubstrateTranslate: this.roadSignSubstrateTranslate,
      sideLength: this.sideLength || 0,
      signName: this.signName,
      status: this.status,
      supportCost: this.supportCost,
      supportMaterialType: this.supportMaterialType,
      supportMaterialTranslate: this.supportMaterialTranslate,
      svg: this.svg,
      type: this.type,
      updatedAt: this.updatedAt,
      signCode: this.signCode,
      verticalSignalingFiles: this.verticalSignalingFiles,
      verticalSignalingHistory: this.verticalSignalingHistory,
      verticalSignalingMasterFile: this.verticalSignalingMasterFile,
      verticalSignalingId: this.verticalSignalingId,
      verticalType: this.verticalType,
      warranty: this.warranty,
      warrantyCurrent: this.warrantyCurrent,
      warrantyEnd: this.warrantyEnd,
      warrantyLastUpdate: this.warrantyLastUpdate,
      quality: this.quality,
      workspaceId: this.workspaceId,
      supportQuantity: this.supportQuantity
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.useTraffic = data['useTraffic'] || 0;
      this.id = data['trafficSigns'][this.useTraffic]['id'] || undefined;
      this.groupId = data['id'] || undefined;
      this.address = new Address(data['address'] || {});
      this.addressFormatted =
        (data.address?.road ? data.address?.road + ', ' : '') +
        (data.address?.road
          ? data.address?.house_number
            ? data.address?.house_number + ' - '
            : 's/ nº - '
          : '') +
        (data.address?.suburb ? data.address?.suburb + ', ' : '') +
        (data.address?.city ? data.address?.city : '') +
        (data.address?.postcode ? ', ' + data.address?.postcode : '') || '';
      this.check = false;
      this.combined = data['trafficSigns'].length > 1 ? this.useTraffic + 1 : 0;
      this.combinedSign = ServiceFunction.setCombinedSigns(data['trafficSigns'], data['id'], this.useTraffic);
      this.complementInformationType = data['trafficSigns'][this.useTraffic]['complementInformationType'] || undefined;
      this.complementInformation = data['trafficSigns'][this.useTraffic]['complementInformation'] || undefined;
      this.contract = new Contract(data['contract'] || {});
      this.contractId = data['contractId'] || undefined;
      this.contractSupportItem = new ContractVerticalItemSupport(data['contractSupportItem'] || {});
      this.contractSupportItemId = data['contractSupportItemId'] || undefined;
      this.contractSignItem = new ContractVerticalItemSign(
        data['trafficSigns'][this.useTraffic]['contractVerticalItem'] ||
        data['trafficSigns'][this.useTraffic]['contractSignItem'] ||
        {},
      );
      this.contractSignItemId =
        data['trafficSigns'][this.useTraffic]['contractVerticalItemId'] ||
        data['trafficSigns'][this.useTraffic]['contractSignItemId'] ||
        undefined;
      this.cost = data['trafficSigns'][this.useTraffic]['cost'] || 0;
      this.createdAt = data['trafficSigns'][this.useTraffic]['createdAt'] || new Date(2000, 0);
      this.executionDate = data['trafficSigns'][this.useTraffic]['executionDate'] || new Date();
      this.diameter = data['trafficSigns'][this.useTraffic]['diameter'] || 0;
      this.dimension = data['trafficSigns'][this.useTraffic]['dimension'] || 0;
      this.dimensionType = data['trafficSigns'][this.useTraffic]['dimensionType'] || DimensionInformationEnum.Envolvente;
      this.dimensionX = data['trafficSigns'][this.useTraffic]['dimensionX'] || 0;
      this.dimensionY = data['trafficSigns'][this.useTraffic]['dimensionY'] || 0;
      this.direction = data['direction'] || undefined;
      this.distance = data['distance'] || 0;
      this.startsAt = data['startsAt'] >= 0 ? data['startsAt'] : undefined;
      this.endsAt = data['endsAt'] >= 0 ? data['endsAt'] : undefined;
      this.existingSupport = data['existingSupport'] || false;
      this.internalCaption = data['trafficSigns'][this.useTraffic]['internalCaption'] || undefined;
      this.isEditable = ServiceFunction.isEditable(this.id) || false;
      this.lat = data['lat'] || undefined;
      this.lng = data['lng'] || undefined;
      this.observation = data['observation'];
      this.order = data['trafficSigns'][this.useTraffic]['order'] || 0;
      this.path = new Path(data['path'] || {});
      this.pathId = data['pathId'] || undefined;
      this.paymentStatus = data['paymentStatus'] || undefined;
      this.project = new Project(data['project'] || {});
      this.projectId = data['projectId'] || undefined;
      this.projectSupport = new ProjectSupport(data['projectSupportApplied'] || {});
      this.projectSupportId = data['projectSupportAppliedId'] || undefined;
      this.projectSignalingItem = new ProjectTrafficSign(
        data['trafficSigns'][this.useTraffic]['projectTrafficSign'] ||
        data['trafficSigns'][this.useTraffic]['projectSignalingItem'] ||
        {},
      );
      this.projectSignalingItemId = data['trafficSigns'][this.useTraffic]['projectTrafficSignId'] || data['trafficSigns'][this.useTraffic]['projectSignalingItemId'] || undefined;
      this.responsibleMemberId = data['responsibleMemberId'] || undefined;
      this.retroreflection = data['trafficSigns'][this.useTraffic]['retroreflection'] || undefined;
      this.minimumRetroreflectionValue = data['trafficSigns'][this.useTraffic]['minimumRetroreflectionValue'] || 0;
      this.maximumRetroreflectionValue = data['trafficSigns'][this.useTraffic]['maximumRetroreflectionValue'] || 0;
      this.roadSignFilm = new RoadSignFilm(data['trafficSigns'][this.useTraffic]['roadSignFilm'] || {});
      this.roadSignFilmId = data['trafficSigns'][this.useTraffic]['roadSignFilmId'] || undefined;
      this.roadSignSubstrate = new RoadSignSubstrate(data['trafficSigns'][this.useTraffic]['roadSignSubstrate'] || {});
      this.roadSignSubstrateId = data['trafficSigns'][this.useTraffic]['roadSignSubstrateId'] || undefined;
      this.roadSignSubstrateTranslate = enumerators.RoadSignSubstrate[this.roadSignSubstrate.name] || undefined;
      this.sideLength = data['trafficSigns'][this.useTraffic]['sideLength'] || 0;
      this.supportCost = data['supportCost'] || 0;
      this.supportMaterialType = data['supportMaterialType'] || undefined;
      this.supportMaterialTranslate = enumerators.RoadSignSupport[this.supportMaterialType] || undefined;
      this.type = SignalingTypeEnum.Vertical;
      this.updatedAt = data['trafficSigns'][this.useTraffic]['updatedAt'] || new Date(2000, 0);
      this.signCode =
        data['trafficSigns'][this.useTraffic]['signCode'] ||
        data['trafficSigns'][this.useTraffic]['verticalCode'] ||
        undefined;
      this.signName = enumerators.Signaling[this.signCode] || undefined;
      this.status = data['trafficSigns']['status'] || undefined;
      this.svg = svgElement.Signaling[this.signCode] || 'assets/icons/map-vertical.svg';
      this.verticalSignalingFiles = data['verticalSignalingFiles'] || [];
      this.verticalSignalingHistory = (data['verticalSignalingHistory'] || []).map((v) => new SignalingHistory(v));
      this.verticalSignalingMasterFile =
        new SignalingMasterFile(data['verticalSignalingMasterFile'] || {}) || undefined;
      this.verticalType =
        data['trafficSigns'][this.useTraffic]['verticalType'] || VerticalSignalizationTypeEnum.Regulation;
      this.icon =
        this.verticalType == VerticalSignalizationTypeEnum.Indication
          ? 'assets/icons/minimum-indication.svg'
          : this.verticalType == VerticalSignalizationTypeEnum.Regulation
            ? 'assets/icons/minimum-regulation.svg'
            : 'assets/icons/minimum-warning.svg';
      this.iconSize = [10, 10];
      this.warranty = data['trafficSigns'][this.useTraffic]['warranty'] || undefined;
      this.warrantyLastUpdate = data['trafficSigns'][this.useTraffic]['warrantyLastUpdate'] || new Date();
      this.warrantyCurrent = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, new Date()) : undefined;
      this.warrantyEnd = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, this.createdAt) : undefined;
      this.quality = data['trafficSigns'][this.useTraffic]['quality'] || undefined;
      this.progress = this.warrantyEnd
        ? this.warrantyEnd[0] || this.warrantyCurrent[0]
          ? this.warrantyCurrent[0] / this.warrantyEnd[0]
          : 0
        : 0;
      this.active = this.progress <= 0 ? 3 : ServiceFunction.lessThanAMonth(this.warrantyCurrent) ? 2 : 1;
      this.workspaceId = data['workspaceId'] || undefined;
      this.supportQuantity = data['supportQuantity'] || 1;
      this.verticalSignalingId = data['id'] || undefined;
    }
    return this;
  }
}

export class TrafficSign implements IRest {
  className = 'Objeto para preenchimento do histórico da sinalização de vertical';

  id: string;
  active: number;
  address: Address;
  complementInformationType: ComplementInformationEnum;
  complementInformation: string;
  contractSignItem: ContractVerticalItemSign;
  contractSignItemId: string;
  contractSupportItem: ContractVerticalItemSupport;
  cost: number;
  createdAt: Date;
  executionDate: Date;
  diameter: number;
  dimension: number;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  internalCaption: number;
  lat: number;
  lng: number;
  order: number;
  progress: number;
  projectSignalingItem: ProjectTrafficSign;
  projectSignalingItemId: string;
  retroreflection: number;
  maximumRetroreflectionValue: number;
  minimumRetroreflectionValue: number;
  roadSignFilm: RoadSignFilm;
  roadSignFilmId: string;
  roadSignSubstrate: RoadSignSubstrate;
  roadSignSubstrateId: string;
  updatedAt: Date;
  sideLength: number;
  status: SignalingStatusEnum;
  signCode: VerticalSignalingEnum;
  verticalSignalingId: string;
  verticalSignaling: any;
  verticalType: VerticalSignalizationTypeEnum;
  warranty: Date;
  quality: SignalingQualityEnum;
  warrantyStatus: WarrantyStatusEnum;
  warrantyLastUpdate: Date;
  warrantyCurrent: Array<number>;
  warrantyEnd: Array<number>;
  stockedTrafficSignId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      active: this.active,
      address: this.address,
      complementInformationType: this.complementInformationType,
      complementInformation: this.complementInformation,
      contractVerticalItem: this.contractSignItem.Serialize(),
      contractVerticalItemId: this.contractSignItemId,
      contractSupportItem: this.contractSupportItem,
      cost: this.cost,
      createdAt: this.createdAt,
      executionDate: this.executionDate,
      diameter: this.diameter || 0,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      internalCaption: this.internalCaption,
      lat: this.lat,
      lng: this.lng,
      order: this.order,
      progress: this.progress,
      projectTrafficSign: this.projectSignalingItem.Serialize(),
      projectTrafficSignId: this.projectSignalingItemId,
      retroreflection: this.retroreflection,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      roadSignFilm: this.roadSignFilm.Serialize(),
      roadSignFilmId: this.roadSignFilmId,
      roadSignSubstrate: this.roadSignSubstrate.Serialize(),
      roadSignSubstrateId: this.roadSignSubstrateId,
      updatedAt: this.updatedAt,
      status: this.status,
      sideLength: this.sideLength || 0,
      verticalCode: this.signCode,
      verticalSignalingId: this.verticalSignalingId,
      verticalSignaling: this.verticalSignaling,
      verticalType: this.verticalType,
      warranty: this.warranty,
      quality: this.quality,
      warrantyStatus: this.warrantyStatus,
      warrantyCurrent: this.warrantyCurrent,
      warrantyEnd: this.warrantyEnd,
      warrantyLastUpdate: this.warrantyLastUpdate,
      stockedTrafficSignId: this.stockedTrafficSignId,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.address = data['address'] || undefined;
      this.complementInformationType = data['complementInformationType'] || undefined;
      this.complementInformation = data['complementInformation'] || undefined;
      this.contractSignItem = new ContractVerticalItemSign(data['contractVerticalItem'] || {});
      this.contractSignItemId = data['contractVerticalItemId'] || undefined;
      this.contractSupportItem = data['contractSupportItem'] || undefined;
      this.cost = data['cost'] || 0;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.executionDate = data['executionDate'] || new Date(2000, 0);
      this.diameter = data['diameter'] || 0;
      this.dimension = data['dimension'] || 0;
      this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
      this.dimensionX = data['dimensionX'] || 0;
      this.dimensionY = data['dimensionY'] || 0;
      this.internalCaption = data['internalCaption'] || '';
      this.lat = data['lat'] || 0;
      this.lng = data['lng'] || 0;
      this.order = data['order'] || 1;
      this.projectSignalingItem = new ProjectTrafficSign(data['projectTrafficSign'] || data['projectSignalingItem'] || {});
      this.projectSignalingItemId = data['projectTrafficSignId'] || data['projectSignalingItemId'] || undefined;
      this.retroreflection = data['retroreflection'] || undefined;
      this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0;
      this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0;
      this.roadSignFilm = new RoadSignFilm(data['roadSignFilm'] || {});
      this.roadSignFilmId = data['roadSignFilmId'] || undefined;
      this.roadSignSubstrate = new RoadSignSubstrate(data['roadSignSubstrate'] || {});
      this.roadSignSubstrateId = data['roadSignSubstrateId'] || undefined;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.sideLength = data['sideLength'] || 0;
      this.signCode = data['signCode'] || data['verticalCode'] || undefined;
      this.status = data['status'] || undefined;
      this.verticalSignalingId = data['verticalSignalingId'] || undefined;
      this.verticalSignaling = data['verticalSignaling'] || undefined;
      this.verticalType = data['verticalType'] || undefined;
      this.warranty = data['warranty'] || undefined;
      this.warrantyStatus = data['warrantyStatus'] || WarrantyStatusEnum.UnderWarranty;
      this.warrantyLastUpdate = data['warrantyLastUpdate'] || new Date();
      this.quality = data['quality'] || undefined;
      this.warrantyCurrent = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, new Date()) : undefined;
      this.warrantyEnd = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, this.createdAt) : undefined;
      this.progress = this.warrantyEnd
        ? this.warrantyEnd[0] || this.warrantyCurrent[0]
          ? this.warrantyCurrent[0] / this.warrantyEnd[0]
          : 0
        : 0;
      this.active = this.progress <= 0 ? 3 : ServiceFunction.lessThanAMonth(this.warrantyCurrent) ? 2 : 1;
      this.stockedTrafficSignId = data['stockedTrafficSignId'] || undefined;
    }
    return this;
  }
}

export class VerticalSignaling implements IRest {
  className = 'Objeto para preenchimento de sinalizações verticais';

  id: string;
  address: Address;
  contract: Contract;
  contractId: string;
  contractSupportItem: ContractVerticalItemSupport;
  contractSupportItemId: string;
  createdAt: Date;
  direction: DirectionEnum;
  distance: number;
  startsAt: number;
  endsAt: number;
  existingSupport: boolean;
  lat: number;
  lng: number;
  observation: string;
  path: Path;
  pathId: string;
  paymentStatus: PaymentStatusEnum;
  project: Project;
  projectId: string;
  projectSupport: ProjectSupport;
  projectSupportId: string;
  responsibleMemberId: string;
  svg: string;
  stockedSupportId: string
  supportCost: number;
  supportMaterialType: SupportMaterialTypeEnum;
  supportQuantity: number;
  trafficSigns: Array<TrafficSign>;
  type: SignalingTypeEnum;
  verticalSignalingFiles: Array<Object>;
  verticalSignalingHistory: Array<SignalingHistory>;
  verticalSignalingMasterFile: SignalingMasterFile;
  updatedAt: Date;
  workspaceId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      address: this.address.Serialize(),
      contract: this.contract.Serialize(),
      contractId: this.contractId,
      contractSupportItem: this.contractSupportItem?.Serialize(),
      contractSupportItemId: this.contractSupportItemId,
      direction: this.direction,
      distance: this.distance,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      existingSupport: this.existingSupport,
      lat: this.lat,
      lng: this.lng,
      observation: this.observation,
      path: this.path.Serialize(),
      pathId: this.pathId,
      paymentStatus: this.paymentStatus,
      project: this.project.Serialize(),
      projectId: this.projectId,
      projectSupport: this.projectSupport?.Serialize(),
      projectSupportId: this.projectSupportId,
      responsibleMemberId: this.responsibleMemberId,
      svg: this.svg,
      stockedSupportId: this.stockedSupportId,
      supportCost: this.supportCost,
      supportMaterialType: this.supportMaterialType,
      trafficSigns: this.trafficSigns.map((v) => v.Serialize()),
      type: this.type,
      verticalSignalingFiles: this.verticalSignalingFiles,
      verticalSignalingHistory: this.verticalSignalingHistory.map((v) => v.Serialize()),
      verticalSignalingMasterFile: this.verticalSignalingMasterFile.Serialize(),
      workspaceId: this.workspaceId,
      supportQuantity: this.supportQuantity
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.address = new Address(data['address'] || {});
      this.contract = new Contract(data['contract'] || {});
      this.contractId = data['contractId'] || undefined;
      this.contractSupportItem = new ContractVerticalItemSupport(data['contractSupportItem'] || {});
      this.contractSupportItemId = data['contractSupportItemId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.direction = data['direction'] || undefined;
      this.startsAt = data['startsAt'] >= 0 ? data['startsAt'] : undefined;
      this.endsAt = data['endsAt'] >= 0 ? data['endsAt'] : undefined;
      this.existingSupport = data['existingSupport'] || false;
      this.lat = data['lat'] || 0;
      this.lng = data['lng'] || 0;
      this.observation = data['observation'] || '';
      this.path = new Path(data['path'] || {});
      this.pathId = data['pathId'] || undefined;
      this.paymentStatus = data['paymentStatus'] || undefined;
      this.project = new Project(data['project'] || {});
      this.projectId = data['projectId'] || undefined;
      this.projectSupport = new ProjectSupport(data['projectSupport'] || {});
      this.projectSupportId = data['projectSupportId'] || undefined;
      this.responsibleMemberId = data['responsibleMemberId'] || undefined;
      this.stockedSupportId = data['stockedSupportId'] || undefined
      this.supportCost = data['supportCost'] || 0;
      this.supportMaterialType = data['supportMaterialType'] || undefined;
      this.trafficSigns = (data['trafficSigns'] || []).map((v) => new TrafficSign(v));
      this.type = SignalingTypeEnum.Vertical;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.verticalSignalingFiles = data['verticalSignalingFiles'] || [];
      this.verticalSignalingHistory = (data['verticalSignalingHistory'] || []).map((v) => new SignalingHistory(v));
      this.verticalSignalingMasterFile =
        new SignalingMasterFile(data['verticalSignalingMasterFile'] || {}) || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.svg = svgElement.Signaling[this.trafficSigns[0]?.signCode] || 'assets/icons/map-vertical.svg';
      this.supportQuantity = data['supportQuantity'] || 1;
    }
    return this;
  }
}

export class HorizontalSignaling implements IRest {
  className = 'Objeto para preenchimento de sinalizações horizontais';

  id: string;
  active: number;
  address: Address;
  addressFormatted: string;
  check: boolean;
  contract: Contract;
  contractSignItem: ContractHorizontalItem;
  contractSignItemId: string;
  contractId: string;
  cost: number;
  createdAt: Date;
  executionDate: Date;
  dimension: number;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  direction: DirectionEnum;
  distance: number;
  startsAt: number;
  endsAt: number;
  signCode: HorizontalSignalingEnum;
  horizontalCode: HorizontalSignalingEnum;
  horizontalSignalingFiles: Array<Object>;
  horizontalSignalingHistory: Array<SignalingHistory>;
  horizontalSignalingMasterFile: SignalingMasterFile;
  horizontalSignalingId: string;
  icon: string;
  iconSize: Array<number>;
  isEditable: boolean;
  locations: Array<Location>;
  observation: string;
  path: Path;
  pathId: string;
  progress: number;
  project: Project;
  projectSignalingItem: ProjectHorizontalSignaling;
  projectSignalingItemId: string;
  projectId: string;
  paymentStatus: PaymentStatusEnum;
  quantity: number;
  responsibleMemberId: string;
  retroreflection: number;
  maximumRetroreflectionValue: number;
  minimumRetroreflectionValue: number;
  roadSignMarkingPaint: RoadSignMarkingPaint;
  roadSignMarkingPaintId: string;
  signName: string;
  size: number;
  status: SignalingStatusEnum;
  svg: string;
  type: SignalingTypeEnum;
  units: number;
  updatedAt: Date;
  warranty: Date;
  warrantyStatus: WarrantyStatusEnum;
  warrantyLastUpdate: Date;
  warrantyCurrent: Array<number>;
  warrantyEnd: Array<number>;
  quality: SignalingQualityEnum;
  workspaceId: string;
  dash: number;
  spacing: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      active: this.active,
      address: this.address.Serialize(),
      addressFormatted: this.addressFormatted,
      check: this.check,
      contract: this.contract.Serialize(),
      contractHorizontalItem: this.contractSignItem.Serialize(),
      contractHorizontalItemId: this.contractSignItemId,
      contractId: this.contractId,
      cost: this.cost,
      createdAt: this.createdAt,
      executionDate: this.executionDate,
      dimension: this.dimension || 0,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX || 0,
      dimensionY: this.dimensionY || 0,
      direction: this.direction,
      distance: this.distance,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      horizontalCode: this.signCode,
      horizontalSignalingFiles: this.horizontalSignalingFiles,
      horizontalSignalingHistory: this.horizontalSignalingHistory.map((v) => v.Serialize()),
      horizontalSignalingMasterFile: this.horizontalSignalingMasterFile.Serialize() || undefined,
      horizontalSignalingId: this.horizontalSignalingId,
      icon: this.icon,
      iconSize: this.iconSize,
      isEditable: this.isEditable,
      locations: this.locations.map((v) => v.Serialize()),
      observation: this.observation,
      pathId: this.pathId,
      path: this.path.Serialize(),
      progress: this.progress,
      project: this.project.Serialize(),
      projectHorizontalItem: this.projectSignalingItem.Serialize(),
      projectHorizontalSignalingId: this.projectSignalingItemId,
      projectId: this.projectId,
      paymentStatus: this.paymentStatus,
      quantity: this.quantity || 0,
      responsibleMemberId: this.responsibleMemberId,
      retroreflection: this.retroreflection,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      roadSignMarkingPaint: this.roadSignMarkingPaint.Serialize(),
      roadSignMarkingPaintId: this.roadSignMarkingPaintId,
      signName: this.active,
      size: this.size,
      svg: this.svg,
      status: this.status,
      type: this.type,
      units: this.units || 0,
      updatedAt: this.updatedAt,
      warranty: this.warranty,
      warrantyStatus: this.warrantyStatus,
      warrantyCurrent: this.warrantyCurrent,
      warrantyEnd: this.warrantyEnd,
      warrantyLastUpdate: this.warrantyLastUpdate,
      quality: this.quality,
      workspaceId: this.workspaceId,
      dash: this.dash,
      spacing: this.spacing,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.address = new Address(data['address'] || {});
      this.addressFormatted =
        (data.address?.road ? data.address?.road + ', ' : '') +
        (data.address?.road
          ? data.address?.house_number
            ? data.address?.house_number + ' - '
            : 's/ nº - '
          : '') +
        (data.address?.suburb ? data.address?.suburb + ', ' : '') +
        (data.address?.city ? data.address?.city : '') +
        (data.address?.postcode ? ', ' + data.address?.postcode : '') || '';
      this.check = false;
      this.contract = new Contract(data['contract'] || {});
      this.contractSignItem = new ContractHorizontalItem(
        data['contractHorizontalItem'] || data['contractSignItem'] || {},
      );
      this.contractSignItemId = data['contractHorizontalItemId'] || data['contractSignItemId'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.cost = data['cost'] || 0;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.executionDate = data['executionDate'] || new Date();
      this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
      this.dimension = data['dimension'] || 0;
      this.dimensionX = data['dimensionX'] || 0;
      this.dimensionY = data['dimensionY'] || 0;
      this.direction = data['direction'] || undefined;
      this.distance = data['distance'] || 0;
      this.startsAt = data['startsAt'] >= 0 ? data['startsAt'] : undefined;
      this.endsAt = data['endsAt'] >= 0 ? data['endsAt'] : undefined;
      this.signCode = data['signCode'] || data['horizontalCode'] || undefined;
      this.horizontalCode = data['signCode'] || data['horizontalCode'] || undefined;
      this.horizontalSignalingFiles = data['horizontalSignalingFiles'] || [];
      this.horizontalSignalingHistory = (data['horizontalSignalingHistory'] || []).map((v) => new SignalingHistory(v));
      this.horizontalSignalingMasterFile =
        new SignalingMasterFile(data['horizontalSignalingMasterFile'] || {}) || undefined;
      this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
      this.icon = 'assets/icons/minimum-horizontal.svg';
      this.iconSize = [10, 10];
      this.isEditable = ServiceFunction.isEditable(this.id);
      this.locations = (data['locations'] || [{}, {}]).map((v) => new Location(v));
      this.observation = data['observation'] || '';
      this.project = new Project(data['project'] || {});
      this.projectSignalingItem = new ProjectHorizontalSignaling(data['projectHorizontalItem'] || data['projectSignalingItem'] || {});
      this.projectSignalingItemId = data['projectHorizontalItemId'] || data['projectSignalingItemId'] || undefined;
      this.projectId = data['projectId'] || undefined;
      this.quantity = data['quantity'] || 0;
      this.responsibleMemberId = data['responsibleMemberId'] || undefined;
      this.retroreflection = data['retroreflection'] || undefined;
      this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0;
      this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0;
      this.path = new Path(data['path'] || {});
      this.pathId = data['pathId'] || undefined;
      this.paymentStatus = data['paymentStatus'] || undefined;
      this.roadSignMarkingPaint = new RoadSignMarkingPaint(data['roadSignMarkingPaint'] || {});
      this.roadSignMarkingPaintId = data['roadSignMarkingPaintId'] || undefined;
      this.signName = enumerators.Signaling[this.signCode] || undefined;
      this.size = data['size'] || 0;
      this.svg = svgElement.Signaling[this.signCode] || 'assets/icons/map-horizontal.svg';
      this.status = data['status'] || undefined;
      this.type = SignalingTypeEnum.Horizontal;
      this.units = data['units'] || 0;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.warranty = data['warranty'] || undefined;
      this.warrantyStatus = data['warrantyStatus'] || WarrantyStatusEnum.UnderWarranty;
      this.warrantyLastUpdate = data['warrantyLastUpdate'] || new Date();
      this.warrantyCurrent = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, new Date()) : undefined;
      this.warrantyEnd = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, this.createdAt) : undefined;
      this.quality = data['quality'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.progress = this.warrantyEnd
        ? this.warrantyEnd[0] || this.warrantyCurrent[0]
          ? this.warrantyCurrent[0] / this.warrantyEnd[0]
          : 0
        : 0;
      this.active = this.progress <= 0 ? 3 : ServiceFunction.lessThanAMonth(this.warrantyCurrent) ? 2 : 1;
      this.dash = data['dash'] || 0;
      this.spacing = data['spacing'] || 0;
    }
    return this;
  }
}

export class DeviceSignaling implements IRest {
  className = 'Objeto para preenchimento de dispositivos';

  id: string;
  active: number;
  address: Address;
  addressFormatted: string;
  check: boolean;
  contract: Contract;
  contractSignItem: ContractDeviceItem;
  contractSignItemId: string;
  contractId: string;
  cost: number;
  createdAt: Date;
  executionDate: Date;
  distance: number;
  signCode: DeviceSignalingEnum;
  deviceCode: DeviceSignalingEnum;
  deviceSignalingFiles: Array<Object>;
  deviceSignalingHistory: Array<SignalingHistory>;
  deviceSignalingMasterFile: SignalingMasterFile;
  dimension: number;
  direction: DirectionEnum;
  startsAt: number;
  endsAt: number;
  horizontalSignalingId: string;
  icon: string;
  iconSize: Array<number>;
  isEditable: boolean;
  locations: Array<Location>;
  observation: string;
  path: Path;
  pathId: string;
  paymentStatus: PaymentStatusEnum;
  progress: number;
  project: Project;
  projectSignalingItem: ProjectDeviceSignaling;
  projectSignalingItemId: string;
  projectId: string;
  responsibleMemberId: string;
  signName: string;
  status: SignalingStatusEnum;
  stockedDeviceSignalingId: string;
  svg: string;
  type: SignalingTypeEnum;
  units: number;
  updatedAt: Date;
  warranty: Date;
  warrantyStatus: WarrantyStatusEnum;
  warrantyCurrent: Array<number>;
  warrantyEnd: Array<number>;
  warrantyLastUpdate: Date;
  quality: SignalingQualityEnum;
  workspaceId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      active: this.active,
      address: this.address.Serialize(),
      addressFormatted: this.addressFormatted,
      check: this.check,
      contract: this.contract.Serialize(),
      contractDeviceItem: this.contractSignItem.Serialize(),
      contractDeviceItemId: this.contractSignItemId,
      contractId: this.contractId,
      cost: this.cost,
      createdAt: this.createdAt,
      executionDate: this.executionDate,
      deviceCode: this.signCode,
      deviceSignalingFiles: this.deviceSignalingFiles,
      deviceSignalingHistory: this.deviceSignalingHistory.map((v) => v.Serialize()),
      deviceSignalingMasterFile: this.deviceSignalingMasterFile.Serialize() || undefined,
      dimension: this.dimension || 0,
      direction: this.direction,
      distance: this.distance,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      horizontalSignalingId: this.horizontalSignalingId,
      icon: this.icon,
      iconSize: this.iconSize,
      isEditable: this.isEditable,
      locations: this.locations.map((v) => v.Serialize()),
      observation: this.observation,
      path: this.path.Serialize(),
      pathId: this.pathId,
      paymentStatus: this.paymentStatus,
      progress: this.progress,
      project: this.project.Serialize(),
      projectDeviceItem: this.projectSignalingItem.Serialize(),
      projectDeviceSignalingId: this.projectSignalingItemId,
      projectId: this.projectId,
      responsibleMemberId: this.responsibleMemberId,
      signName: this.signName,
      status: this.status,
      stockedDeviceSignalingId: this.stockedDeviceSignalingId,
      svg: this.svg,
      type: this.type,
      units: this.units || 1,
      updatedAt: this.updatedAt,
      warranty: this.warranty,
      warrantyStatus: this.warrantyStatus,
      warrantyCurrent: this.warrantyCurrent,
      warrantyEnd: this.warrantyEnd,
      warrantyLastUpdate: this.warrantyLastUpdate,
      quality: this.quality,
      workspaceId: this.workspaceId,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.address = new Address(data['address'] || {});
      this.addressFormatted =
        (data.address?.road ? data.address?.road + ', ' : '') +
        (data.address?.road
          ? data.address?.house_number
            ? data.address?.house_number + ' - '
            : 's/ nº - '
          : '') +
        (data.address?.suburb ? data.address?.suburb + ', ' : '') +
        (data.address?.city ? data.address?.city : '') +
        (data.address?.postcode ? ', ' + data.address?.postcode : '') || '';
      this.check = false;
      this.contract = new Contract(data['contract'] || {});
      this.contractSignItem = new ContractDeviceItem(data['contractDeviceItem'] || data['contractSignItem'] || {});
      this.contractSignItemId = data['contractDeviceItemId'] || data['contractSignItemId'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.cost = data['cost'] || 0;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.executionDate = data['executionDate'] || new Date();
      this.signCode = data['signCode'] || data['deviceCode'] || undefined;
      this.deviceCode = data['signCode'] || data['deviceCode'] || undefined;
      this.deviceSignalingFiles = data['deviceSignalingFiles'] || [];
      this.deviceSignalingHistory = (data['deviceSignalingHistory'] || []).map((v) => new SignalingHistory(v));
      this.deviceSignalingMasterFile = new SignalingMasterFile(data['deviceSignalingMasterFile'] || {}) || undefined;
      this.dimension = data['dimension'] || 1;
      this.direction = data['direction'] || undefined;
      this.distance = data['distance'] || 0;
      this.startsAt = data['startsAt'] >= 0 ? data['startsAt'] : undefined;
      this.endsAt = data['endsAt'] >= 0 ? data['endsAt'] : undefined;
      this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
      this.icon = 'assets/icons/minimum-device.svg';
      this.iconSize = [10, 10];
      this.isEditable = ServiceFunction.isEditable(this.id);
      this.locations = (data['locations'] || [{}, {}]).map((v) => new Location(v));
      this.observation = data['observation'] || '';
      this.path = new Path(data['path'] || {});
      this.pathId = data['pathId'] || undefined;
      this.paymentStatus = data['paymentStatus'] || undefined;
      this.project = new Project(data['project'] || {});
      this.projectSignalingItem = new ProjectDeviceSignaling(data['projectDeviceItem'] || data['projectSignalingItem'] || {});
      this.projectSignalingItemId = data['projectDeviceSignalingId'] || data['projectSignalingItemId'] || undefined;
      this.projectId = data['projectId'] || undefined;
      this.responsibleMemberId = data['responsibleMemberId'] || undefined;
      this.signName = enumerators.Signaling[this.signCode] || undefined;
      this.status = data['status'] || undefined;
      this.svg = svgElement.Signaling[this.signCode] || 'assets/icons/map-devices.svg';
      this.stockedDeviceSignalingId = data['stockedDeviceSignalingId'] || undefined
      this.type = SignalingTypeEnum.Device;
      this.units = data['units'] || 1;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.warranty = data['warranty'] || undefined;
      this.warrantyStatus = data['warrantyStatus'] || WarrantyStatusEnum.UnderWarranty;
      this.warrantyLastUpdate = data['warrantyLastUpdate'] || new Date();
      this.warrantyCurrent = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, new Date()) : undefined;
      this.warrantyEnd = this.warranty ? ServiceFunction.getRelativeTime(this.warranty, this.createdAt) : undefined;
      this.quality = data['quality'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.progress = this.warrantyEnd
        ? this.warrantyEnd[0] || this.warrantyCurrent[0]
          ? this.warrantyCurrent[0] / this.warrantyEnd[0]
          : 0
        : 0;
      this.active = this.progress <= 0 ? 3 : ServiceFunction.lessThanAMonth(this.warrantyCurrent) ? 2 : 1;
    }
    return this;
  }
}

export class SignalingList implements IRest {
  className = 'Classe com as listas de sinalizações para o catalogo';

  verticalSignalings: VerticalSignaling[];
  horizontalSignalings: HorizontalSignaling[];
  deviceSignalings: DeviceSignaling[];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      verticalSignalings: this.verticalSignalings.map((v) => v.Serialize()),
      horizontalSignalings: this.horizontalSignalings.map((v) => v.Serialize()),
      deviceSignalings: this.deviceSignalings.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.verticalSignalings = (data['verticalSignalings'] || []).map((v) => new VerticalSignaling(v));
      this.horizontalSignalings = (data['horizontalSignalings'] || []).map((v) => new HorizontalSignaling(v));
      this.deviceSignalings = (data['deviceSignalings'] || []).map((v) => new DeviceSignaling(v));
    }
    return this;
  }
}

export class SignalingFilterResData implements IRest {
  className = 'Classe com os dados do retorno da requisição de filtro do catalogo';

  amount: number;
  quantity: number;
  underWarranty: number;
  endOfWarranty: number;
  expiredWarranty: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      amount: this.amount,
      quantity: this.quantity,
      underWarranty: this.underWarranty,
      endOfWarranty: this.endOfWarranty,
      expiredWarranty: this.expiredWarranty,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.amount = data['amount'] || 0;
      this.quantity = data['quantity'] || 0;
      this.underWarranty = data['underWarranty'] || 0;
      this.endOfWarranty = data['endOfWarranty'] || 0;
      this.expiredWarranty = data['expiredWarranty'] || 0;
    }
    return this;
  }
}

export class SignalingFilterRes implements IRest {
  className = 'Classe com os dados do retorno da requisição de filtro do catalogo';

  deviceSignalings: SignalingFilterResData;
  horizontalSignalings: SignalingFilterResData;
  verticalSignalings: SignalingFilterResData;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      deviceSignalings: this.deviceSignalings.Serialize(),
      horizontalSignalings: this.horizontalSignalings.Serialize(),
      verticalSignalings: this.verticalSignalings.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.deviceSignalings = new SignalingFilterResData(data['deviceSignalings'] || {});
      this.horizontalSignalings = new SignalingFilterResData(data['horizontalSignalings'] || {});
      this.verticalSignalings = new SignalingFilterResData(data['verticalSignalings'] || {});
    }
    return this;
  }
}

export class SignalingFilterCost implements IRest {
  className = 'Classe com os dados do retorno da requisição de filtro do catalogo';

  deviceSignalings: number;
  horizontalSignalings: number;
  verticalSignalings: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      deviceSignalings: this.deviceSignalings,
      horizontalSignalings: this.horizontalSignalings,
      verticalSignalings: this.verticalSignalings,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.deviceSignalings = data['deviceSignalings'] || 0;
      this.horizontalSignalings = data['horizontalSignalings'] || 0;
      this.verticalSignalings = data['verticalSignalings'] || 0;
    }
    return this;
  }
}
