import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { add } from 'date-fns';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DateService } from 'src/app/services/date.service';
import { SignalElement, SignalsElement } from 'src/app/utils/signalsElements';
import { svgElement } from 'src/dictionaries/svg-sign';
import { DeviceSignalingEnum, ItemUnitEnum, SignalingTypeEnum } from 'src/enumerators';
import { WarrantyTypeEnum } from 'src/enumerators/action.enum';
import { Contract, ContractDeviceItem, DeviceSignalingTaskActivities, ProjectDeviceSignaling } from 'src/models';

@Component({
  selector: 'app-device-project',
  templateUrl: './device-project.component.html',
  styleUrls: ['./device-project.component.css', '../form-style.css'],
})
export class DeviceProjectComponent implements OnInit {
  deviceItems: ContractDeviceItem[] = [];
  signalsElements: SignalElement[] = Object.values(SignalsElement);
  itemUnitEnum = ItemUnitEnum;
  radioState = [];
  stockEntries: any[] = [];
  signalingTypeEnum = SignalingTypeEnum;

  @Input() deviceSignalingTaskActivities: any[];
  @Input() contract: Contract;
  @Output() validationEvent = new EventEmitter<boolean>();
  @Input() isStock: boolean = false;


  today = new Date();

  constructor(
    private _localeService: BsLocaleService,
    public dateService: DateService,
  ) { }
  ngOnInit(): void {
    this._localeService.use('pt-br');

    this.contract?.deviceGroups.forEach((group) => {
      group.deviceItems.forEach((item: ContractDeviceItem) => {
        this.deviceItems.push(item);
      });
    });
    this.deviceSignalingTaskActivities.forEach((sign) => {
      this.radioState.push(sign.stockedDeviceSignalingId ? { stock: true, contract: false } : { stock: false, contract: true });
      if (sign.contractDeviceItemId) {
        this.setContractItem(sign, undefined, undefined, false);
        this.setWarranty(sign.warranty, sign);
      }
      sign['isAccOpen'] = false;
    });
  }

  // Defini o item do contrato para autocomplete e calculo
  setContractItem(sign: DeviceSignalingTaskActivities, stockedId?: string, index?: number, boolChange = true) {
    var item: any

    // Serve para reiniciar a dimension e as unidades quando a troca acontece entre os itens
    if (boolChange) {
      sign.dimension = 0;
      sign.units = 1;
    }

    if (!stockedId) {
      item = this.deviceItems.find((item) => item.id == sign.contractDeviceItemId);
      sign.contractDeviceItem = new ContractDeviceItem(item);
      if (boolChange) {
        if (sign.contractDeviceItemId) {
          if (sign.contractDeviceItem.warrantyType == WarrantyTypeEnum.TimeInterval) {
            sign.warranty = add(new Date(), { years: sign.contractDeviceItem.warrantyYear, months: sign.contractDeviceItem.warrantyMonth })
          }
          else if(sign.contractDeviceItem.warranty) sign.warranty = new Date(sign.contractDeviceItem.warranty);
        }
      }
      sign.deviceCode = item.signaling as DeviceSignalingEnum;
      sign['svg'] = svgElement.Signaling[item.signaling];
      if (sign.units || sign.dimension) this.setDeviceCost(sign);
    } else {
      let signItem = this.deviceSignalingTaskActivities[index]
      item = this.stockEntries.find((item) => item.id == stockedId).contractDeviceItem;
      signItem.warranty = this.stockEntries.find((item) => item.id == stockedId).warranty
      signItem.contractDeviceItem = new ContractDeviceItem(item);
      signItem.contractDeviceItemId = item.id;
      signItem['progress'] = 0;
      signItem['active'] = 1;
      signItem.deviceCode = item.signaling as DeviceSignalingEnum;
      signItem['svg'] = svgElement.Signaling[item.signaling];
      if (signItem.units || signItem.dimension) this.setDeviceCost(signItem);
    }
  }

  // Calcula e atribui valor à atividade
  setDeviceCost(sign: any) {
    sign.cost = (sign.units * sign.dimension * sign?.contractDeviceItem?.unitValue) * (this.isStock ? sign.quantity : 1) || 0;
  }

  // Remove a atividade
  removeActivity(index: number) {
    this.deviceSignalingTaskActivities.splice(index, 1);
  }

  // Seta a garantia da sinalização e barra de progresso
  setWarranty(event: Date, sign: DeviceSignalingTaskActivities) {
    sign['warrantyTextCurrent'] = null;
    sign['warrantyCurrent'] = null;
    sign['warrantyEnd'] = null;
    sign['progress'] = 0;
    sign['active'] = 1;
    sign.warranty = event;
    const relativeTime: any[] = this.dateService.getRelativeTime(event, new Date());
    sign['warrantyTextCurrent'] = this.dateService.getTextDate(relativeTime);
    this.progressWarranty(event, sign);
  }

  //Função par auxiliar na criação da barra de progresso de garantia
  progressWarranty(warranty, sign: DeviceSignalingTaskActivities) {
    let now = new Date();
    sign['warrantyEnd'] = this.dateService.getRelativeTime(warranty, now);
    sign['warrantyCurrent'] = this.dateService.getRelativeTime(warranty, now);

    if (sign['warrantyCurrent'][0] || sign['warrantyEnd'][0]) {
      sign['progress'] = sign['warrantyCurrent'][0] / sign['warrantyEnd'][0];
    } else {
      sign['progress'] = 0;
    }

    if (sign['progress'] <= 0) {
      sign['active'] = 3;
    } else {
      if (
        sign['warrantyCurrent'][1] <= 0 &&
        ((sign['warrantyCurrent'][2] <= 0 && sign['warrantyCurrent'][3] <= 31) ||
          (sign['warrantyCurrent'][2] == 1 && sign['warrantyCurrent'][3] == 0))
      ) {
        sign['active'] = 2;
      } else {
        sign['active'] = 1;
      }
    }
  }

  // Emite validação do form
  emitValidation() {
    let canPress = true;
    this.deviceSignalingTaskActivities.forEach((item: ProjectDeviceSignaling) => {
      if (!item.contractDeviceItemId || !item.warranty || !item.deviceCode
        || ((item.units == 0) && (item.dimension == 0))
      ) {
        canPress = false;
        return false;
      }
    });
    this.validationEvent.emit(canPress);
  }

  setBond(type, index) {
    if (type == 'stock') {
      this.radioState[index] = { stock: true, contract: false };
    } else {
      this.radioState[index] = { stock: false, contract: true };
    }
  }

  openAccordionGroup(item) {
    item.isAccOpen = !item.isAccOpen;
  }
}
