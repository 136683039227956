<!-- Formulario Dispositivos -->
<ng-container *ngFor="let sign of deviceSignalingTaskActivities; index as indexOfSign">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [isOpen]="sign?.isAccOpen">
      <div class="row mx-0 w-100" style="cursor: auto;" accordion-heading>
        <div class="col sign-container">

          <!-- SVG da Sinalização -->
          <div class="svg-collapse" (click)="openAccordionGroup(sign)">
            <img
              [src]="sign.deviceCode ? (sign?.deviceCode| enumSvg: 'Signaling': signalingTypeEnum.Device) : 'assets/icons/d-task-icon.svg'"
              alt="" height="48px" width="48px">
          </div>
          <!-- SVG da Sinalização -->


          <!-- Nome da Sinalização -->
          <div class="col mt-2 cursor-pointer" *ngIf="!sign?.isAccOpen" (click)="openAccordionGroup(sign)">
            <div class="ml-2">
              <h4 class="item-title">Sinalização selecionada</h4>
              <p>{{sign.deviceCode ? (sign?.deviceCode|enumText: 'Signaling') : 'Selecione a Sinalização'}}</p>
            </div>
          </div>
          <!-- Nome da Sinalização -->


          <!-- Formulário da Sinalização -->
          <div class="col mt-2" *ngIf="sign?.isAccOpen">

            <div class="content-contractual">
              <form #deviceForm="ngForm">
                <div class="snow-light-container mb-3" *ngIf="isStock">
                  <div class="row mx-0 d-flex align-items-center justify-content-between" *ngIf="isStock">
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="contractOrStockRadio{{indexOfSign+1}}"
                        id="contractOrStockRadio{{indexOfSign+1}}1" class="custom-control-input cursor-pointer"
                        (change)="setBond('contract', indexOfSign)" [checked]="radioState[indexOfSign]?.contract">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="contractOrStockRadio{{indexOfSign+1}}1">Item do
                        Contrato</label>
                    </div>
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="contractOrStockRadio{{indexOfSign+1}}"
                        id="contractOrStockRadio{{indexOfSign+1}}2" class="custom-control-input cursor-pointer"
                        (change)="setBond('stock', indexOfSign)" [checked]="radioState[indexOfSign]?.stock">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="contractOrStockRadio{{indexOfSign+1}}2">Vínculo
                        com Estoque</label>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="isStock && radioState[indexOfSign]?.contract">
                    <label for="contractBond">Selecione o item do Contrato</label>
                    <select name="contractBond" class="form-control" [(ngModel)]="sign.contractDeviceItemId" required
                      autocomplete="off" list="contractItems"
                      (change)="sign.contractDeviceItemId = $event.target.value;setContractItem(sign); emitValidation()">
                      <option value="" hidden>Selecione...</option>
                      <option *ngFor="let item of deviceItems" [value]="item.id">{{item?.name}}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="radioState[indexOfSign]?.stock">
                    <label for="stockedTrafficSignId">Selecione o item do Estoque</label>
                    <select name="stockedTrafficSignId" class="form-control" [(ngModel)]="sign.stockedDeviceSignalingId"
                      list="stockList" required
                      (change)="setContractItem(sign.target.value, indexOfSign); emitValidation()">
                      <option [value]="undefined" hidden>Selecione...</option>
                      <option *ngFor="let item of stockEntries" [value]="item.id">
                        {{item.contractDeviceItem.name+ ' - '+(item.quantity - item.quantityRegistered)+' disponíveis'}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mx-0 d-flex align-items-center" *ngIf="!isStock">
                  <div class="col px-0">
                    <div class="form-group">
                      <label for="contractDeviceItemId">Selecione o item do Contrato</label>
                      <select name="contractDeviceItemId" id="contractDeviceItemId" class="form-control"
                        placeholder="Selecione o item do contrato" required
                        (change)="sign.contractDeviceItemId = $event.target.value;setContractItem(sign); emitValidation();"
                        [(ngModel)]="sign.contractDeviceItemId">
                        <option value="" hidden>Selecione...</option>
                        <option *ngFor="let option of deviceItems" [value]="option.id">{{option?.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col d-flex flex-column px-4 dimension-container">
                  <p class="text-uppercase text-muted mb-2">APLICAÇÃO</p>
                  <div class="row mx-0 d-flex align-items-center">
                    <div class="col pl-0 pr-1 pl-0 w-100">
                      <label for="units">Unidades</label>
                      <input name="units" id="units" required type="number" class="form-control" placeholder="0"
                        [disabled]="sign.contractDeviceItem.unit == itemUnitEnum.m" [(ngModel)]="sign.units"
                        (change)="setDeviceCost(sign);emitValidation()" min="0"
                        oninput="if(this.value < 0) this.value = 0" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col pl-1 pr-0 w-100">
                      <label for="dimension">Extensão Aplicada (m)</label>
                      <input name="dimension" required type="number" class="form-control" [(ngModel)]="sign.dimension"
                        [disabled]="sign.contractDeviceItem.unit == itemUnitEnum.und" placeholder="0"
                        (change)="setDeviceCost(sign); emitValidation()" min="0"
                        oninput="if(this.value < 0) this.value = 0" [dropSpecialCharacters]="false">
                    </div>
                  </div>
                </div>
              </form>
              <div class="row mx-0 my-2">
                <div class="col-4 px-0">
                  <div class="px-0 form-group">
                    <label for="warranty">Determine o tempo de garantia*</label>
                    <div class="row d-flex align-items-center justify-content-between mx-0">
                      <div class="position-relative w-100">
                        <input name="warranty" id="warranty" bsDatepicker class="form-control" #wDate
                          [ngModel]="dateService.TransformDate(sign,'get')"
                          [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                          (bsValueChange)="dateService.TransformDate(sign,'set',wDate.value);setWarranty($event, sign); emitValidation()">
                        <i class="fa-solid fa-calendar position-absolute" (click)="dp.show()"
                          style="right: 5%; top: 35%; cursor:text"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="align-items-center d-flex col px-0">
                  <div *ngIf="true" class="d-flex justify-content-between flex-column col-4">
                    <a style="font-size: 20px; color:var(--greenmix-light); pointer-events: none;"
                      [ngClass]="{'text-danger': sign['active'] > 1}">
                      {{!sign['warrantyTextCurrent'] || sign['warrantyTextCurrent'] == 0 ? 'Sem período':
                      sign['warrantyTextCurrent']}}</a>
                    <a [ngClass]="{'text-danger': sign['active'] > 1}" class="warranty-due-text">
                      {{sign.warranty ? (sign.warranty | date:'dd/MM/yyyy') : 'Escolha a data de expiração'}}
                    </a>
                  </div>
                  <div class="col px-0">
                    <progressbar *ngIf="sign['active'] == 1" class="mx-4"
                      style="border-radius: 8px; height: 8px; width: 100%;" [value]="(sign['progress'])*100"
                      type="success">
                    </progressbar>
                    <progressbar *ngIf="sign['active'] > 1" class="mx-4"
                      style="border-radius: 8px; height: 8px; width: 100%;" [value]="(sign['progress'])*100"
                      type="danger">
                    </progressbar>
                  </div>
                  <div class="d-flex justify-content-between flex-column align-items-end col px-0">
                    <a *ngIf="sign['warrantyTextCurrent'] !== 0"
                      style="font-size: 20px; color:var(--gray-light); pointer-events: none;">{{sign.warranty
                      ? (sign.warranty | date:'dd/MM/yyyy') : 'Sem Período'}}</a>
                    <a *ngIf="sign['warrantyTextCurrent'] == 0"
                      style="color:var(--red-base); font-weight: 500; font-size: 16px; pointer-events: none;"><i
                        class="fa-solid fa-triangle-exclamation"
                        style="margin-right: 8px; font-size: 20px;"></i>Garantia
                      Expirada</a>
                    <h4 class="mt-2" style="color:var(--gray-light)" *ngIf="sign['warrantyTextCurrent'] !== 0">Sem
                      última edição</h4>
                  </div>
                </div>
              </div>
              <div class="row  px-4 justify-content-between align-items-center  my-2 py-2">
                <div class="col px-0">
                  <label for="signQuant" class="text-muted">Quantidade</label>
                  <p>{{sign?.contractDeviceItem?.quantity}}</p>
                </div>
                <div class="col px-0">
                  <div>
                    <label for="signDevice" class="text-muted">Sinalização</label>
                    <p>{{sign.contractDeviceItem.signaling ? (sign.contractDeviceItem.signaling | enumText:
                      'Signaling') : 'Não informado'}}</p>
                  </div>
                </div>
                <div class="col px-0">
                  <label for="unity" class="text-muted">Unidades</label>
                  <p>{{sign?.units}}</p>
                </div>
                <div class="col px-0">
                  <label for="signValueUnity" class="text-muted">Vr. Unt. da Sinalização</label>
                  <p class="text-success">{{ sign.contractDeviceItem ? ((sign.units > 0 ? (sign.units) :
                    (sign.dimension)) *
                    (isStock ? sign.quantity : 1) * sign.contractDeviceItem.unitValue | currency:'BRL':'R$') :
                    ('R$0,00')}}
                  </p>
                </div>
                <div class="col px-0">
                  <label for="signValue" class="text-muted">Valor do Item</label>
                  <p>{{ sign.contractDeviceItem ? ((sign.contractDeviceItem.unitValue |
                    currency:'BRL':'R$')+'/'+(sign.contractDeviceItem.unit)) : ('R$0,00/--')}}</p>
                </div>
                <div class="col px-0">
                  <label for="totalValue" class="text-muted">Valor Total</label>
                  <p class="text-success">{{ sign.contractDeviceItem ? ((sign.units > 1 ? (sign.units) :
                    (sign.dimension)) *
                    (isStock ? sign.quantity : 1) * sign.contractDeviceItem.unitValue | currency:'BRL':'R$') :
                    ('R$0,00')}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Formulário da Sinalização -->


          <!-- Collapse -->
          <div class="collapse-button" (click)="openAccordionGroup(sign)">
            <i class="fa-regular fa-lg" [ngClass]="sign?.isAccOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
          </div>
          <!-- Collapse -->

        </div>

        <div class="col-auto px-0 pl-4" *ngIf="!isStock">
          <button class="btn btn-icon btn-icon-alt" (click)="removeActivity(devices, indexOfSign)">
            <i class="fa-regular fa-xmark fa-lg text-danger"></i>
          </button>
        </div>

      </div>
    </accordion-group>
  </accordion>
</ng-container>
