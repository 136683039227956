import { Injectable } from '@angular/core';
import { intervalToDuration } from 'date-fns';
import {
  DeviceSignalingTaskActivities, HorizontalSignalingTaskActivities, TrafficSignTaskActivities
} from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() { }

  // Retorna uma lista com os tempos em anos, meses, dias, horas e minutos, e a diferença em milissegundos
  getRelativeTime(warranty, date): any {
    // Cálculo para pegar as horas, dias, meses e ano em number;
    let diffTime = this.calcDate(warranty, date);
    let intervalWarranty: Duration = { years: 0, months: 0, days: 0, hours: 0, minutes: 0 };

    if (diffTime > 0) {
      intervalWarranty = intervalToDuration({
        start: new Date(warranty),
        end: new Date(date),
      });
    }
    return [
      diffTime,
      intervalWarranty.years,
      intervalWarranty.months,
      intervalWarranty.days,
      intervalWarranty.hours,
      intervalWarranty.minutes,
    ];
  }

  // Retorna a diferença entre duas datas em milissegundos (getTimes)
  calcDate(date1: any, date2: any): any {

    let newDate1;
    let newDate2;

    if (typeof date1 == 'string') {
      const string1 = date1.substring(0, 10);
      const string2 = date1.substring(11, 19);
      newDate1 = new Date(string1 + ' ' + string2);
    } else {
      newDate1 = date1;
    }
    if (typeof date2 == 'string') {
      const string3 = date2.substring(0, 10);
      const string4 = date2.substring(11, 19);
      newDate2 = new Date(string3 + ' ' + string4);
    } else {
      newDate2 = date2;
    }
    if (newDate2 > newDate1) {
      return 0;
    }
    const time = Math.abs(newDate1.getTime() - newDate2.getTime());
    return time;
  }

  getTextDate(listDate: Array<any>) {
    let year;
    let month;
    let day;
    let text;
    if (listDate) {
      year = listDate[1] == 1 ? 'ano' : listDate[1] > 1 ? 'anos' : '';
      month = listDate[2] == 1 ? 'mês' : listDate[2] > 1 ? 'meses' : '';
      day = listDate[3] == 1 ? 'dia' : listDate[3] > 1 ? 'dias' : '';
      if (year && month) {
        text = listDate[1] + ' ' + year + ' e ' + listDate[2] + ' ' + month;
      } else if (!month && year) {
        text = listDate[1] + ' ' + year;
      } else if (!year && month) {
        text = listDate[2] + ' ' + month;
        if (day) {
          text = text + ' e ' + listDate[3] + ' ' + day;
        }
      } else if (!year && !month) {
        text = listDate[3] + ' ' + day;
      }
    }
    return text;
  }

  // Nome explicito, verifica se falta menos de um mes e retorna true se sim
  lessThanAMonth(listDate: Array<any>): boolean {
    if(listDate && listDate?.length > 1)
    return (listDate[1] <= 0 && ((listDate[2] <= 0 && listDate[3] <= 31) || (listDate[2] == 1 && listDate[3] == 0)));
  }

  // Pipe para tratar dates do DatePicker
  public TransformDate(
    sign: TrafficSignTaskActivities | DeviceSignalingTaskActivities | HorizontalSignalingTaskActivities,
    crud: 'get' | 'set',
    value?,
  ) {
    if (crud == 'set' && value) {
      sign.warranty = new Date();
      sign.warranty.setHours(0);
      sign.warranty.setMinutes(0);
      sign.warranty.setSeconds(0);
      //Definir a Data no Atributo
      sign.warranty.setFullYear(value.split('/')[2] || 2000);
      sign.warranty.setMonth(value.split('/')[1] - 1 || 0);
      sign.warranty.setDate(value.split('/')[0] || 1);
    } else if (crud == 'get') {
      sign.warranty = sign.warranty ? new Date(sign.warranty) : new Date();
      //Escrever a Data em Texto Seguindo o Padrão Esperado
      let dateString = '';
      dateString += (sign.warranty.getDate() < 10 ? '0' + sign.warranty.getDate() : sign.warranty.getDate()) + '/';
      dateString +=
        (sign.warranty.getMonth() < 9 ? '0' + (sign.warranty.getMonth() + 1) : sign.warranty.getMonth() + 1) + '/';
      dateString += sign.warranty.getFullYear();
      return dateString;
    }
  }
}
