export const svgElement = {
  'Signaling': {
    // VERTICAL
    'R-1': 'assets/signalings/vertical/regulation/R-1.svg',
    'R-2': 'assets/signalings/vertical/regulation/R-2.svg',
    'R-3': 'assets/signalings/vertical/regulation/R-3.svg',
    'R-4a': 'assets/signalings/vertical/regulation/R-4a.svg',
    'R-4b': 'assets/signalings/vertical/regulation/R-4b.svg',
    'R-5a': 'assets/signalings/vertical/regulation/R-5a.svg',
    'R-5b': 'assets/signalings/vertical/regulation/R-5b.svg',
    'R-6a': 'assets/signalings/vertical/regulation/R-6a.svg',
    'R-6b': 'assets/signalings/vertical/regulation/R-6b.svg',
    'R-6c': 'assets/signalings/vertical/regulation/R-6c.svg',
    'R-7': 'assets/signalings/vertical/regulation/R-7.svg',
    'R-8a': 'assets/signalings/vertical/regulation/R-8a.svg',
    'R-8b': 'assets/signalings/vertical/regulation/R-8b.svg',
    'R-9': 'assets/signalings/vertical/regulation/R-9.svg',
    'R-10': 'assets/signalings/vertical/regulation/R-10.svg',
    'R-11': 'assets/signalings/vertical/regulation/R-11.svg',
    'R-12': 'assets/signalings/vertical/regulation/R-12.svg',
    'R-13': 'assets/signalings/vertical/regulation/R-13.svg',
    'R-14': 'assets/signalings/vertical/regulation/R-14.svg',
    'R-15': 'assets/signalings/vertical/regulation/R-15.svg',
    'R-16': 'assets/signalings/vertical/regulation/R-16.svg',
    'R-17': 'assets/signalings/vertical/regulation/R-17.svg',
    'R-18': 'assets/signalings/vertical/regulation/R-18.svg',
    'R-19': 'assets/signalings/vertical/regulation/R-19.svg',
    'R-20': 'assets/signalings/vertical/regulation/R-20.svg',
    'R-21': 'assets/signalings/vertical/regulation/R-21.svg',
    'R-22': 'assets/signalings/vertical/regulation/R-22.svg',
    'R-23': 'assets/signalings/vertical/regulation/R-23.svg',
    'R-24a': 'assets/signalings/vertical/regulation/R-24a.svg',
    'R-24b': 'assets/signalings/vertical/regulation/R-24b.svg',
    'R-25a': 'assets/signalings/vertical/regulation/R-25a.svg',
    'R-25b': 'assets/signalings/vertical/regulation/R-25b.svg',
    'R-25c': 'assets/signalings/vertical/regulation/R-25c.svg',
    'R-25d': 'assets/signalings/vertical/regulation/R-25d.svg',
    'R-26': 'assets/signalings/vertical/regulation/R-26.svg',
    'R-27': 'assets/signalings/vertical/regulation/R-27.svg',
    'R-28': 'assets/signalings/vertical/regulation/R-28.svg',
    'R-29': 'assets/signalings/vertical/regulation/R-29.svg',
    'R-30': 'assets/signalings/vertical/regulation/R-30.svg',
    'R-31': 'assets/signalings/vertical/regulation/R-31.svg',
    'R-32': 'assets/signalings/vertical/regulation/R-32.svg',
    'R-33': 'assets/signalings/vertical/regulation/R-33.svg',
    'R-34': 'assets/signalings/vertical/regulation/R-34.svg',
    'R-35a': 'assets/signalings/vertical/regulation/R-35a.svg',
    'R-35b': 'assets/signalings/vertical/regulation/R-35b.svg',
    'R-36a': 'assets/signalings/vertical/regulation/R-36a.svg',
    'R-36b': 'assets/signalings/vertical/regulation/R-36b.svg',
    'R-37': 'assets/signalings/vertical/regulation/R-37.svg',
    'R-38': 'assets/signalings/vertical/regulation/R-38.svg',
    'R-39': 'assets/signalings/vertical/regulation/R-39.svg',
    'R-40': 'assets/signalings/vertical/regulation/R-40.svg',
    'ESPECIAL_REGULAMENTACAO': 'assets/signalings/vertical/regulation/ESPECIAL_REGULAMENTACAO.svg',
    'A-1a': 'assets/signalings/vertical/warning/A-1a.svg',
    'A-1b': 'assets/signalings/vertical/warning/A-1b.svg',
    'A-2a': 'assets/signalings/vertical/warning/A-2a.svg',
    'A-2b': 'assets/signalings/vertical/warning/A-2b.svg',
    'A-3a': 'assets/signalings/vertical/warning/A-3a.svg',
    'A-3b': 'assets/signalings/vertical/warning/A-3b.svg',
    'A-4a': 'assets/signalings/vertical/warning/A-4a.svg',
    'A-4b': 'assets/signalings/vertical/warning/A-4b.svg',
    'A-5a': 'assets/signalings/vertical/warning/A-5a.svg',
    'A-5b': 'assets/signalings/vertical/warning/A-5b.svg',
    'A-6': 'assets/signalings/vertical/warning/A-6.svg',
    'A-7a': 'assets/signalings/vertical/warning/A-7a.svg',
    'A-7b': 'assets/signalings/vertical/warning/A-7b.svg',
    'A-8': 'assets/signalings/vertical/warning/A-8.svg',
    'A-9': 'assets/signalings/vertical/warning/A-9.svg',
    'A-10a': 'assets/signalings/vertical/warning/A-10a.svg',
    'A-10b': 'assets/signalings/vertical/warning/A-10b.svg',
    'A-11a': 'assets/signalings/vertical/warning/A-11a.svg',
    'A-11b': 'assets/signalings/vertical/warning/A-11b.svg',
    'A-12': 'assets/signalings/vertical/warning/A-12.svg',
    'A-13a': 'assets/signalings/vertical/warning/A-13a.svg',
    'A-13b': 'assets/signalings/vertical/warning/A-13b.svg',
    'A-14': 'assets/signalings/vertical/warning/A-14.svg',
    'A-15': 'assets/signalings/vertical/warning/A-15.svg',
    'A-16': 'assets/signalings/vertical/warning/A-16.svg',
    'A-17': 'assets/signalings/vertical/warning/A-17.svg',
    'A-18': 'assets/signalings/vertical/warning/A-18.svg',
    'A-19': 'assets/signalings/vertical/warning/A-19.svg',
    'A-20a': 'assets/signalings/vertical/warning/A-20a.svg',
    'A-20b': 'assets/signalings/vertical/warning/A-20b.svg',
    'A-21a': 'assets/signalings/vertical/warning/A-21a.svg',
    'A-21b': 'assets/signalings/vertical/warning/A-21b.svg',
    'A-21c': 'assets/signalings/vertical/warning/A-21c.svg',
    'A-21d': 'assets/signalings/vertical/warning/A-21d.svg',
    'A-21e': 'assets/signalings/vertical/warning/A-21e.svg',
    'A-22': 'assets/signalings/vertical/warning/A-22.svg',
    'A-23': 'assets/signalings/vertical/warning/A-23.svg',
    'A-24': 'assets/signalings/vertical/warning/A-24.svg',
    'A-25': 'assets/signalings/vertical/warning/A-25.svg',
    'A-26a': 'assets/signalings/vertical/warning/A-26a.svg',
    'A-26b': 'assets/signalings/vertical/warning/A-26b.svg',
    'A-27': 'assets/signalings/vertical/warning/A-27.svg',
    'A-28': 'assets/signalings/vertical/warning/A-28.svg',
    'A-29': 'assets/signalings/vertical/warning/A-29.svg',
    'A-30a': 'assets/signalings/vertical/warning/A-30a.svg',
    'A-30b': 'assets/signalings/vertical/warning/A-30b.svg',
    'A-30c': 'assets/signalings/vertical/warning/A-30c.svg',
    'A-31': 'assets/signalings/vertical/warning/A-31.svg',
    'A-32a': 'assets/signalings/vertical/warning/A-32a.svg',
    'A-32b': 'assets/signalings/vertical/warning/A-32b.svg',
    'A-33a': 'assets/signalings/vertical/warning/A-33a.svg',
    'A-33b': 'assets/signalings/vertical/warning/A-33b.svg',
    'A-34': 'assets/signalings/vertical/warning/A-34.svg',
    'A-35': 'assets/signalings/vertical/warning/A-35.svg',
    'A-36': 'assets/signalings/vertical/warning/A-36.svg',
    'A-37': 'assets/signalings/vertical/warning/A-37.svg',
    'A-38': 'assets/signalings/vertical/warning/A-38.svg',
    'A-39': 'assets/signalings/vertical/warning/A-39.svg',
    'A-40': 'assets/signalings/vertical/warning/A-40.svg',
    'A-41': 'assets/signalings/vertical/warning/A-41.svg',
    'A-42a': 'assets/signalings/vertical/warning/A-42a.svg',
    'A-42b': 'assets/signalings/vertical/warning/A-42b.svg',
    'A-42c': 'assets/signalings/vertical/warning/A-42c.svg',
    'A-43': 'assets/signalings/vertical/warning/A-43.svg',
    'A-44': 'assets/signalings/vertical/warning/A-44.svg',
    'A-45': 'assets/signalings/vertical/warning/A-45.svg',
    'A-46': 'assets/signalings/vertical/warning/A-46.svg',
    'A-47': 'assets/signalings/vertical/warning/A-47.svg',
    'A-48': 'assets/signalings/vertical/warning/A-48.svg',
    'ESPECIAL_ADVERTENCIA': 'assets/signalings/vertical/warning/ESPECIAL_ADVERTENCIA.svg',
    'ATRATIVOS_TURISTICOS': 'assets/signalings/vertical/indication/ATRATIVO_TURISTICO.svg',
    'EDUCATIVAS': 'assets/signalings/vertical/indication/EDUCATIVAS.svg',
    'IDENTIFICACAO': 'assets/signalings/vertical/indication/IDENTIFICACAO.svg',
    'INFORMACAO_MISTA': 'assets/signalings/vertical/indication/INFORMACAO_MISTA.svg',
    'ORIENTACAO_DE_DESTINO': 'assets/signalings/vertical/indication/ORIENTACAO_DESTINO.svg',
    'POSTOS_DE_FISCALIZACAO': 'assets/signalings/vertical/indication/POSTOS_FISCALIZACAO.svg',
    'SERVICOS_AUXILIARES': 'assets/signalings/vertical/indication/SERVICOS_AUXILIARES.svg',
    'VERTICAL_PERSONALIZADA': 'assets/signalings/vertical/indication/VERTICAL_PERSONALIZADA.svg',
    'VIA_FISCALIZADA_POR_CAMERAS': 'assets/signalings/vertical/indication/VIA_FISCALIZADA_POR_CAMERAS.svg',
    'BUS_STOP': 'assets/signalings/vertical/indication/BUS_STOP.svg',
    'QR_CODE': 'assets/signalings/vertical/indication/QR_CODE.svg',

    // HORIZONTAL
    'LBO': 'assets/signalings/horizontal/LBO.svg',
    'LCO': 'assets/signalings/horizontal/LCO.svg',
    'LFO-1': 'assets/signalings/horizontal/LFO-1.svg',
    'LFO-2': 'assets/signalings/horizontal/LFO-2.svg',
    'LFO-3': 'assets/signalings/horizontal/LFO-3.svg',
    'LFO-4': 'assets/signalings/horizontal/LFO-4.svg',
    'LMS-1': 'assets/signalings/horizontal/LMS-1.svg',
    'LMS-2': 'assets/signalings/horizontal/LMS-2.svg',
    'MCI': 'assets/signalings/horizontal/MCI.svg',
    'MFE': 'assets/signalings/horizontal/MFE.svg',
    'MFP': 'assets/signalings/horizontal/MFP.svg',
    'MFR': 'assets/signalings/horizontal/MFR.svg',

    'FTP-1': 'assets/signalings/horizontal/FTP-1.svg',
    'FTP-2': 'assets/signalings/horizontal/FTP-2.svg',
    'LDP': 'assets/signalings/horizontal/LDP.svg',
    'LRE': 'assets/signalings/horizontal/LRE.svg',
    'LRV': 'assets/signalings/horizontal/LRV.svg',
    'MAC': 'assets/signalings/horizontal/MAC.svg',
    'MAE': 'assets/signalings/horizontal/MAE.svg',
    'MCC': 'assets/signalings/horizontal/MCC.svg',
    'MCF': 'assets/signalings/horizontal/MCF.svg',

    'LCA': 'assets/signalings/horizontal/LCA.svg',
    'ZPA-MAC': 'assets/signalings/horizontal/ZPA-MAC.svg',
    'ZPA-MAN': 'assets/signalings/horizontal/ZPA-MAN.svg',
    'ZPA-MAO': 'assets/signalings/horizontal/ZPA-MAO.svg',
    'ZPA-MCB': 'assets/signalings/horizontal/ZPA-MCB.svg',
    'ZPA-MIR': 'assets/signalings/horizontal/ZPA-MIR.svg',
    'ZPA-MTL': 'assets/signalings/horizontal/ZPA-MTL.svg',

    'LPP': 'assets/signalings/horizontal/LPP.svg',
    'MER': 'assets/signalings/horizontal/MER.svg',
    'MVE': 'assets/signalings/horizontal/MVE.svg',

    'DEF': 'assets/signalings/horizontal/DEF.svg',
    'IMC': 'assets/signalings/horizontal/IMC.svg',
    'MOF': 'assets/signalings/horizontal/MOF.svg',
    'PEM': 'assets/signalings/horizontal/PEM.svg',
    'SAS': 'assets/signalings/horizontal/SAS.svg',
    'SIC': 'assets/signalings/horizontal/SIC.svg',
    'SIF': 'assets/signalings/horizontal/SIF.svg',
    'SIP': 'assets/signalings/horizontal/SIP.svg',
    'IPL-LVR': 'assets/signalings/horizontal/LEG.svg',
    'IPL-ESC': 'assets/signalings/horizontal/LEG.svg',
    'IPL-IND': 'assets/signalings/horizontal/LEG.svg',
    'IPL-PAR': 'assets/signalings/horizontal/LEG.svg',
    'IPL-MCD': 'assets/signalings/horizontal/LEG.svg',
    'PEM_DIREITA': 'assets/signalings/horizontal/PEM_DIREITA.svg',
    'PEM_ESQUERDA': 'assets/signalings/horizontal/PEM_ESQUERDA.svg',
    'PEM_RETORNO_DIREITA': 'assets/signalings/horizontal/PEM_RETORNO_DIREITA.svg',
    'PEM_RETORNO_ESQUERDA': 'assets/signalings/horizontal/PEM_RETORNO_ESQUERDA.svg',
    'PEM_SIGA': 'assets/signalings/horizontal/PEM_SIGA.svg',
    'PEM_SIGA_DIREITA': 'assets/signalings/horizontal/PEM_SIGA_DIREITA.svg',
    'PEM_SIGA_ESQUERDA': 'assets/signalings/horizontal/PEM_SIGA_ESQUERDA.svg',
    'PEM_SIGA_PEDESTRE': 'assets/signalings/horizontal/PEM_SIGA_PEDESTRE.svg',
    'ROTATORIA': 'assets/signalings/horizontal/ROTATORIA.svg',
    'ONDULACAO_TRANSVERSAL_PINTURA': 'assets/signalings/horizontal/ONDULACAO_TRANSVERSAL_PINTURA.svg',

    'HORIZONTAL_PERSONALIZADA': 'assets/signalings/horizontal/HORIZONTAL_PERSONALIZADA.svg',


    // DISPOSITIVO
    'ATENUADOR_IMPACTO_FIXO': 'assets/signalings/device/ATENUADOR_IMPACTO_FIXO.svg',
    'ATENUADOR_IMPACTO_MOVEL': 'assets/signalings/device/ATENUADOR_IMPACTO_MOVEL.svg',
    'BALIZADOR': 'assets/signalings/device/BALIZADOR.svg',
    'BALIZADOR_RETRORREFLETIVO': 'assets/signalings/device/BALIZADOR_RETRORREFLETIVO.svg',
    'BARREIRA_ACUSTICA': 'assets/signalings/device/BARREIRA_ACUSTICA.svg',
    'BARREIRA_ANTIOFUSCAMENTO': 'assets/signalings/device/BARREIRA_ANTIOFUSCAMENTO.svg',
    'BARREIRA_CONCRETO': 'assets/signalings/device/BARREIRA_CONCRETO.svg',
    'BARREIRA_METALICA_REMOVIVEL': 'assets/signalings/device/BARREIRA_METALICA_REMOVIVEL.svg',
    'CERCA_VIVA': 'assets/signalings/device/CERCA_VIVA.svg',
    'CILINDRO_DELIMITADOR': 'assets/signalings/device/CILINDRO_DELIMITADOR.svg',
    'DEFENSA_METALICA': 'assets/signalings/device/DEFENSA_METALICA.svg',
    'DISPOSITIVO_CONCRETO': 'assets/signalings/device/DISPOSITIVO_CONCRETO.svg',
    'DISPOSITIVO_CONTENCAO': 'assets/signalings/device/DISPOSITIVO_CONTENCAO.svg',
    'FAIXA_ELEVADA_PEDESTRES': 'assets/signalings/device/FAIXA_ELEVADA_PEDESTRES.svg',
    'GRADIL_FIXO': 'assets/signalings/device/GRADIL_FIXO.svg',
    'GRADIL_MOVEL': 'assets/signalings/device/GRADIL_MOVEL.svg',
    'MARCADOR_ALINHAMENTO': 'assets/signalings/device/MARCADOR_ALINHAMENTO.svg',
    'MARCADOR_OBSTACULOS': 'assets/signalings/device/MARCADOR_OBSTACULOS.svg',
    'MARCADOR_PERIGO': 'assets/signalings/device/MARCADOR_PERIGO.svg',
    'ONDULACAO_TRANSVERSAL': 'assets/signalings/device/ONDULACAO_TRANSVERSAL.svg',
    'PAINEL_ELETRONICO_FIXO': 'assets/signalings/device/PAINEL_ELETRONICO_FIXO.svg',
    'PAVIMENTO_COLORIDO': 'assets/signalings/device/PAVIMENTO_COLORIDO.svg',
    'PAVIMENTO_MICROFRESADO': 'assets/signalings/device/PAVIMENTO_MICROFRESADO.svg',
    'PONTALETE': 'assets/signalings/device/PONTALETE.svg',
    'REVESTIMENTO_RUGOSO': 'assets/signalings/device/REVESTIMENTO_RUGOSO.svg',
    'REVESTIMENTO_SONORIZADOR_LONGITUDINAL': 'assets/signalings/device/REVESTIMENTO_SONORIZADOR_LONGITUDINAL.svg',
    'SEMI_PORTICO_TRELICADO_PROJETADO': 'assets/signalings/device/SEMI_PORTICO_TRELICADO_PROJETADO.svg',
    'SONORIZADOR': 'assets/signalings/device/SONORIZADOR.svg',
    'TACHA': 'assets/signalings/device/TACHA.svg',
    'TACHAO': 'assets/signalings/device/TACHAO.svg',
    'TERMINAL_ABSORVEDOR': 'assets/signalings/device/TERMINAL_ABSORVEDOR.svg',
    'TERMINAL_AEREO': 'assets/signalings/device/TERMINAL_AEREO.svg',
    'TERMINAL_ENTERRADO': 'assets/signalings/device/TERMINAL_ENTERRADO.svg',
    'VASO': 'assets/signalings/device/VASO.svg',
    'SEGREGADOR': 'assets/signalings/device/SEGREGADOR.svg',
    'DISPOSITIVO_PERSONALIZADO': 'assets/signalings/device/DISPOSITIVO_PERSONALIZADO.svg',

    // Suporte (para estoque)
    'GALVANIZED_STEEL': 'assets/signalings/support/steel-support.svg',
    'STEEL': 'assets/signalings/support/steel-support.svg',
    'IMMUNIZED_WOOD': 'assets/signalings/support/wood-support.svg',
    'ECOLOGICAL_SUPPORT': 'assets/signalings/support/wood-support.svg',
    'REFORESTED_WOOD': 'assets/signalings/support/wood-support.svg',
    'RECYCLED_MATERIALS': 'assets/signalings/support/wood-support.svg',
    'SEMI_PORTAL': 'assets/signalings/support/steel-support.svg',
    'SEMI_PORTAL_B2': 'assets/signalings/support/steel-support.svg',
    'SEMI_PORTAL_B4': 'assets/signalings/support/steel-support.svg',
    'SEMI_PORTAL_B5': 'assets/signalings/support/steel-support.svg',
    'SEMI_PORTAL_B6': 'assets/signalings/support/steel-support.svg',
    'LATTICE_PORTAL': 'assets/signalings/support/steel-support.svg'
  },

  'ComplementInformation': {
    'ADDITIONAL_REGULATION': 'assets/signalings/vertical/ADDITIONAL_REGULATION.svg',
    'ADDITIONAL_WARNING': 'assets/signalings/vertical/ADDITIONAL_WARNING.svg',
    'INCORPORATED_REGULATION': 'assets/signalings/vertical/INCORPORATED_REGULATION.svg',
    'INCORPORATED_WARNING': 'assets/signalings/vertical/INCORPORATED_WARNING.svg',
  },


  'TypeSignDimension': {
    'CIRCULAR': 'assets/signalings/vertical-circular.svg',
    'TRIANGULAR': 'assets/signalings/vertical/regulation/R-2.svg',
    'LOZENGE': 'assets/signalings/vertical-losangular.svg',
    'RECTANGULAR': 'assets/signalings/vertical-retangular.svg',
    'OCTAGONAL': 'assets/signalings/vertical/regulation/R-1.svg',
  }
};
