import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { add } from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateService } from 'src/app/services/date.service';
import { SignalElement, SignalsElement } from 'src/app/utils/signalsElements';
import {
  DelimitationHorizontalSignalingEnum, DimensionInformationEnum,
  LongitudinalHorizontalSignalingEnum, MarkingTypeEnum,
  PavementHorizontalSignalingEnum, PlumbingHorizontalSignalingEnum,
  SignalingTypeEnum, TransversalsHorizontalSignalingEnum
} from 'src/enumerators';
import { WarrantyTypeEnum } from 'src/enumerators/action.enum';
import { Contract, ContractHorizontalItem, HorizontalSignalingTaskActivities, ProjectHorizontalSignaling } from 'src/models';

@Component({
  selector: 'app-horizontal-project',
  templateUrl: './horizontal-project.component.html',
  styleUrls: ['../form-style.css'],
})
export class HorizontalProjectComponent implements OnInit {
  markingTypes = Object.values(MarkingTypeEnum);
  markingTypeEnum = MarkingTypeEnum;
  horizontalSigns = {
    longitudinal: Object.values(LongitudinalHorizontalSignalingEnum),
    transversal: Object.values(TransversalsHorizontalSignalingEnum),
    channeling: Object.values(PlumbingHorizontalSignalingEnum),
    delimitation: Object.values(DelimitationHorizontalSignalingEnum),
    inscriptions: Object.values(PavementHorizontalSignalingEnum),
  };
  horizontalItems: ContractHorizontalItem[] = [];
  blockDimension: { dimensionXY: boolean; dimension: boolean } = { dimensionXY: false, dimension: false };
  signalsElements: SignalElement[] = Object.values(SignalsElement);
  today = new Date();
  signalingTypeEnum = SignalingTypeEnum; // Enum do tipo HVD
  modalsRef?: Array<BsModalRef> = [];
  currentItem;

  @Output() validationEvent = new EventEmitter<boolean>();
  @Input() horizontalSignalingTaskActivities: any[];
  @Input() contract: Contract;

  constructor(public dateService: DateService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit(): void {

    this.contract?.horizontalGroups.forEach((group) => {
      group.horizontalItems.forEach((item: ContractHorizontalItem) => {
        this.horizontalItems.push(item);
      });
    });
    this.horizontalSignalingTaskActivities.forEach((sign) => {
      if (sign.contractHorizontalItemId) {
        this.setWarranty(sign?.warranty, sign);
        this.setContractItem(sign, true);
        this.setSvg(sign);
      }
      sign['isAccOpen'] = false;
    });
  }

  // Remove a atividade
  removeActivity(index: number) {
    this.horizontalSignalingTaskActivities.splice(index, 1);
  }

  // Defini o item do contrato para autocomplete e calculo
  setContractItem(sign: HorizontalSignalingTaskActivities, edit?: boolean) {
    const item = this.horizontalItems.find((item) => item.id == sign.contractHorizontalItemId);
    sign.contractHorizontalItem = new ContractHorizontalItem(item);
    if (!edit) {
      if (sign.contractHorizontalItemId) {
        if (sign.contractHorizontalItem.warrantyType == WarrantyTypeEnum.TimeInterval) {
          sign.warranty = add(new Date(), { years: sign.contractHorizontalItem.warrantyYear, months: sign.contractHorizontalItem.warrantyMonth })
        }
        else if(sign.contractHorizontalItem.warranty) sign.warranty = new Date(sign.contractHorizontalItem.warranty);
      }
    }
    sign['progress'] = 0;
    sign['active'] = 1;
    if (sign.dimension) this.calculateDimension(sign);
  }

  // Defini calculo de dimensao e bloqueio de inputs
  setDimension(type: 'xy' | 'units' | 'total', sign: HorizontalSignalingTaskActivities, value: number) {
    this.calculateDimension(sign);
    if (type == 'xy') {
      this.blockDimension.dimension = ((sign.dimensionX + sign.dimensionY) !== 0) ? true : false;
    } else {
      this.blockDimension.dimensionXY = (value !== 0) ? true : false;
    }
  }

  // Calcula a dimensao e atribui valor à sinalização
  calculateDimension(sign: HorizontalSignalingTaskActivities) {
    sign.cost = (sign.dimension * sign?.contractHorizontalItem?.unitValue) || 0;
  }

  // Seta a garantia da sinalização e barra de progresso
  setWarranty(event: Date, sign: HorizontalSignalingTaskActivities) {
    sign['warrantyTextCurrent'] = null;
    sign['warrantyCurrent'] = null;
    sign['warrantyEnd'] = null;
    sign['progress'] = 0;
    sign['active'] = 1;
    sign.warranty = event;
    const relativeTime: any[] = this.dateService.getRelativeTime(event, new Date());
    sign['warrantyTextCurrent'] = this.dateService.getTextDate(relativeTime);
    this.progressWarranty(event, sign);
  }

  //Função par auxiliar na criação da barra de progresso de garantia
  progressWarranty(warranty, sign: HorizontalSignalingTaskActivities) {
    let now = new Date();
    sign['warrantyEnd'] = this.dateService.getRelativeTime(warranty, now);
    sign['warrantyCurrent'] = this.dateService.getRelativeTime(warranty, now);

    if (sign['warrantyCurrent'][0] || sign['warrantyEnd'][0]) {
      sign['progress'] = sign['warrantyCurrent'][0] / sign['warrantyEnd'][0];
    } else {
      sign['progress'] = 0;
    }

    if (sign['progress'] <= 0) {
      sign['active'] = 3;
    } else {
      if (
        sign['warrantyCurrent'][1] <= 0 &&
        ((sign['warrantyCurrent'][2] <= 0 && sign['warrantyCurrent'][3] <= 31) ||
          (sign['warrantyCurrent'][2] == 1 && sign['warrantyCurrent'][3] == 0))
      ) {
        sign['active'] = 2;
      } else {
        sign['active'] = 1;
      }
    }
  }

  // Defini o SVG da sinalização selecionada
  setSvg(task: HorizontalSignalingTaskActivities) {
    task['svg'] = this.signalsElements.find((sign) => sign.name.toUpperCase() == task.horizontalCode.toUpperCase());
  }

  // Emite validação do form
  emitValidation() {
    let canPress = true;
    this.horizontalSignalingTaskActivities.forEach((item: ProjectHorizontalSignaling) => {
      if (!item.contractHorizontalItemId || !item.warranty || !item.horizontalCode
        || (item.quantity == 0) || (item.dimension == 0)
        || !((item['retroSwitch'] && (item.maximumRetroreflectionValue || item.minimumRetroreflectionValue)) || !item['retroSwitch'])
      ) {
        canPress = false;
        return false;
      }
    });
    this.validationEvent.emit(canPress);
  }

  // Fecha o último modal aberto
  closeModal() {
    const last: any = this.modalsRef.length - 1;
    this.modalsRef[last].hide();
    this.modalsRef.pop();
  }

  // Abre MODAL
  openModalXL(template: TemplateRef<any>) {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  setHorizontalClass() {
    if (this.horizontalSigns.delimitation.find(element => element == this.currentItem.signaling)) {
      this.currentItem.markingType = MarkingTypeEnum.delimitation;
    } else if (this.horizontalSigns.longitudinal.find(element => element == this.currentItem.signaling)) {
      this.currentItem.markingType = MarkingTypeEnum.longitudinal;
    } else if (this.horizontalSigns.inscriptions.find(element => element == this.currentItem.signaling)) {
      this.currentItem.markingType = MarkingTypeEnum.inscriptions;
    } else if (this.horizontalSigns.channeling.find(element => element == this.currentItem.signaling)) {
      this.currentItem.markingType = MarkingTypeEnum.channeling;
    } else if (this.horizontalSigns.transversal.find(element => element == this.currentItem.signaling)) {
      this.currentItem.markingType = MarkingTypeEnum.transversal;
    }
  }

  // Método chamado após selecionar a sinalização dentro do modal
  selectSignModal(event) {
    this.currentItem.horizontalCode = event[0];
    this.currentItem.signCode = event[0];
    this.setHorizontalClass();
    this.emitValidation();
  }

  // Atribui valor do componente de dimensao para a sinalização
  handleDimensionInformation(event: {
    dimensionType: DimensionInformationEnum, dimension: number,
    dimensionX: number, dimensionY: number, units: number,
    diameter: number, sideLength: number, quantity: number,
    dash: number, spacing: number
  }, sign) {
    sign.units = event.quantity;
    sign.dimension = event.dimension;
    sign.dimensionX = event.dimensionX;
    sign.dimensionY = event.dimensionY;
    sign.dimensionType = event.dimensionType;
    sign.dash = event.dash;
    sign.spacing = event.spacing;
    this.calculateDimension(sign);
    this.emitValidation();
  }

  clickButton(event, sign, template: TemplateRef<any>) {
    if (event.pointerId == 1) {
      this.currentItem = sign;
      this.openModalXL(template);
    }
  }

  openAccordionGroup(item) {
    item.isAccOpen = !item.isAccOpen;
  }

}
