import { IRest } from "./rest.interface";

export class AppVersion implements IRest {
  className = 'Notificação da versão do aplicativo';

  id: string;
  version: string;
  required: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      version: this.version,
      required: this.required,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.version = data['version'] || '';
      this.required = data['required'] || false;
      this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] || new Date(2000, 1, 1);
    }
    return this;
  }
}

export class GlobalNotification implements IRest {
  className = 'Notificação global';

  title: string;
  body: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      title: this.title,
      body: this.body,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.title = data['title'] || '';
      this.body = data['body'] || '';
    }
    return this;
  }
}
