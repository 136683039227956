import { ServiceOrderStatusEnum } from 'src/enumerators';
import { IRest } from './rest.interface';

export class ServiceOrder implements IRest {
  className: 'Ordem de Serviço';

  id: string;
  name: string;
  createdBy: any;
  createdAt: Date;
  contractId: string;
  projectId: string;
  status: ServiceOrderStatusEnum;
  executionDate: Date;
  serviceOrderFiles: any[];
  createdByMemberId: string;
  requester: string;
  contactChannel: string;
  progress: ServiceOrderSignalingsProgress

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }
  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      contractId: this.contractId,
      projectId: this.projectId,
      createdAt: this.createdAt,
      serviceOrderFiles: this.serviceOrderFiles,
      requester: this.requester,
      contactChannel: this.contactChannel,
      status: this.status,
      executionDate: this.executionDate,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || '';
    this.name = data['name'] || '';
    this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
    this.contractId = data['contractId'] || '';
    this.projectId = data['projectId'] || '';
    this.status = data['status'] || ServiceOrderStatusEnum.Open;
    this.executionDate = data['executionDate'] || null;
    this.createdByMemberId = data['createdByMemberId'] || '';
    this.requester = data['requester'] || '';
    this.contactChannel = data['contactChannel'] || '';
    this.serviceOrderFiles = data['serviceOrderFiles'] || []
    return this;
  }
}

export class ServiceOrderSignalingsProgress implements IRest {
  className: 'Progresso da Ordem de Serviço';

  serviceOrderId: string;
  activitiesSignalingsQuantity: number;
  signalingsQuantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }
  Serialize() {
    const out = {
      serviceOrderId: this.serviceOrderId || undefined,
      activitiesSignalingsQuantity: this.activitiesSignalingsQuantity,
      signalingsQuantity: this.signalingsQuantity,
    };
    return out;
  }
  Deserialize(data: any) {
    this.serviceOrderId = data['serviceOrderId'] || undefined;
    this.activitiesSignalingsQuantity = data['activitiesSignalingsQuantity'] || 0;
    this.signalingsQuantity = data['signalingsQuantity'] || 0;
    return this;
  }
}
