import { DimensionInformationEnum, MemberRemovalRequestJustificationEnum, TypeSignDimensionEnum } from 'src/enumerators';
import { IRest } from './rest.interface';
import { HorizontalSignalingDimensionSettingTypeEnum } from 'src/enumerators/signaling-type.enum';

export class SettingsReportData implements IRest {
  className = 'Notificação da sinalização';

  signalings: {
    vertical: boolean,
    horizontal: boolean,
    device: boolean,
  };
  tasks: {
    removal: boolean,
    implantation: boolean,
    replacement: boolean,
    maintenance: boolean,
    cataloging: boolean,
    revitalizing: boolean,
  };
  active: boolean;


  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      signalings: this.signalings,
      tasks: this.tasks,
      active: this.active,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.signalings = data['signalings'] || {
        vertical: false,
        horizontal: false,
        device: false,
      };
      this.tasks = data['tasks'] || {
        removal: false,
        implantation: false,
        replacement: false,
        maintenance: false,
        cataloging: false,
      };
      this.active = data['active'] || false;
    }
    return this;
  }
}

export class SettingsDimesionTrafficData implements IRest {
  className = 'Dimensão da sinalização vertical';

  shape: TypeSignDimensionEnum;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  diameter: number;
  sideLength: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      shape: this.shape,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX,
      dimensionY: this.dimensionY,
      dimension: this.dimension,
      diameter: this.diameter,
      sideLength: this.sideLength,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.shape = data['shape'] || undefined
      this.dimensionType = data['dimensionType'] || undefined
      this.dimensionX = data['dimensionX'] || 0
      this.dimensionY = data['dimensionY'] || 0
      this.dimension = data['dimension'] || 0
      this.diameter = data['diameter'] || 0
      this.sideLength = data['sideLength'] || 0
    }
    return this;
  }
}

export class SettingsDimesionHorizontalData implements IRest {
  className = 'Dimensão da sinalização horizontal';

  type: HorizontalSignalingDimensionSettingTypeEnum;
  dimensionX: number;
  dimensionY: number;
  dimension: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      type: this.type,
      dimensionX: this.dimensionX,
      dimensionY: this.dimensionY,
      dimension: this.dimension,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.type = data['type'] || undefined
      this.dimensionX = data['dimensionX'] || 0
      this.dimensionY = data['dimensionY'] || 0
      this.dimension = data['dimension'] || 0
    }
    return this;
  }
}

export class MemberRemovalRequestAttributes implements IRest {
  className = 'Remoção de membro pela configuração';

  id: string;
  workspaceId: string;
  userId: string;
  memberId: string;
  justification: MemberRemovalRequestJustificationEnum;
  justificationText: string;
  password: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      workspaceId: this.workspaceId,
      userId: this.userId,
      memberId: this.memberId,
      justification: this.justification,
      justificationText: this.justificationText,
      password: this.password,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,

    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.userId = data['userId'] || undefined;
      this.memberId = data['memberId'] || undefined;
      this.justification = data['justification'] || undefined;
      this.justificationText = data['justificationText'] || '';
      this.password = data['password'] || '';
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);

    }
    return this;
  }

};
