import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContractService } from 'src/app/services/contract.service';
import { PathService } from 'src/app/services/path.service';
import { ProjectService } from 'src/app/services/project.service';
import { RegionAreaService } from 'src/app/services/region-area.service';
import { SignalingTypeEnum, VerticalGroupTypeEnum } from 'src/enumerators';
import {
  Area,
  Contract,
  Path,
  Project,
  ProjectDeviceSignaling,
  ProjectHorizontalSignaling,
  ProjectSupport,
  ProjectTrafficSign,
  Region,
} from 'src/models';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css'],
})
export class NewProjectComponent implements OnInit {
  currentContract: Contract; // Contrato escolhido
  project: Project = new Project({ createdAt: new Date() }); // Projeto sendo Criado/Editado
  regionsList: Array<Region> = []; // Lista de região
  areasList: Array<Area> = []; // Lista de áreas
  pathsList: Array<Path> = []; // Lista de trajetos
  contractsList: Array<Contract> = []; // Lista de contratos
  uploadedFiles: Array<any> = []; // Lista de arquivos
  pageController = 'projectData'; // Controlador da página
  signTypeEnum = SignalingTypeEnum; // Enum da sinalização HVD
  verticalGroupTypeEnum = VerticalGroupTypeEnum;
  formValidation = false; // Validação dos formulários de HVD
  editMode = false; // Saber se é edição
  loading = true;
  loadingContract = false;
  selectedRegions: Array<Region> = []; // Regiões selecionadas do input de select
  selectedAreas: Array<Area> = []; // Áreas selecionadas do input de select
  selectedPaths: Array<Path> = []; // Trajetos selecionadas do input de select
  overflowCounter: {
    region: number;
    area: number;
    path: number;
  } = { region: 0, area: 0, path: 0 }; // Contabiliza o numero de RAP adicionais no select, contatador de cada caso
  hiddenRegionIds: Array<string> = []; // Lista de regiões escondias no select
  hiddenAreaIds: Array<string> = []; // Lista de áreas escondias no select
  hiddenPathIds: Array<string> = []; // Lista de trajetos escondias no select

  // Referencia do modal
  modalRef: BsModalRef;

  constructor(
    private _regionArea: RegionAreaService,
    private _contractService: ContractService,
    private _activedRoute: ActivatedRoute,
    private _project: ProjectService,
    private _modalService: BsModalService,
    private _pathService: PathService,
    private _route: Router,
  ) { }

  // Referencia da barra de regiao e area
  @ViewChild('areaRow') areaRow: ElementRef<HTMLElement>;
  @ViewChild('regionRow') regionRow: ElementRef<HTMLElement>;
  @ViewChild('pathRow') pathRow: ElementRef<HTMLElement>;
  @ViewChild('areaContainer') areaContainer: ElementRef<HTMLElement>;
  @ViewChild('regionContainer') regionContainer: ElementRef<HTMLElement>;
  @ViewChild('pathContainer') pathContainer: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.project.projectDeviceSignalings.push(new ProjectDeviceSignaling({ warranty: new Date() }));
    this.project.projectHorizontalSignalings.push(new ProjectHorizontalSignaling({ warranty: new Date() }));
    this.project.projectTrafficSigns.push(new ProjectTrafficSign({ warranty: new Date() }));
    this._activedRoute.params.subscribe((res) => {
      if (res.id) {
        this.editMode = true;
        this._project.getProject(res.id).subscribe((next: Project) => {
          this.project = next;
          this.checkForAdjustments(this.project)
          this.populateRegionAreaPathList();
          this.uploadedFiles = this.project.projectFiles;
          this._contractService.getContract(this.project?.contractId).subscribe((res: any) => {
            this.currentContract = res;
            this._contractService.checkForContractAdjustments(this.currentContract)
          });
          this.loading = false;
        });
      } else {
        this.project = new Project({ createdAt: new Date() });
        this.populateRegionAreaPathList();
        this.loading = false;
      }
    });

    // Populando lista de contratos
    this._contractService.listContracts().subscribe((res: any) => (this.contractsList = res));
  }

  checkForAdjustments(project: Project) {
    project.projectTrafficSigns.forEach((trafficSign) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(trafficSign.contractVerticalItem.adjustments)
      if (contractAdjustmentItem) trafficSign.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue
    })

    project.projectSupports.forEach((projectSupport) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(projectSupport.contractVerticalItem.adjustments)
      if (contractAdjustmentItem) projectSupport.contractVerticalItem.unitValue = contractAdjustmentItem.unitValue
    })

    project.projectHorizontalSignalings.forEach((horizontalSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(horizontalSignaling.contractHorizontalItem.adjustments)
      if (contractAdjustmentItem) horizontalSignaling.contractHorizontalItem.unitValue = contractAdjustmentItem.unitValue
    })

    project.projectDeviceSignalings.forEach((deviceSignaling) => {
      const contractAdjustmentItem = this._contractService.getItemWithClosestDate(deviceSignaling.contractDeviceItem.adjustments)
      if (contractAdjustmentItem) deviceSignaling.contractDeviceItem.unitValue = contractAdjustmentItem.unitValue
    })
  }

  // Verifica quebra de width no select de regiao e area
  checkCounterBreak() {
    setTimeout(() => {
      this.overflowCounter = { region: 0, area: 0, path: 0 };
      this.selectedRegions.forEach((region, index) =>
        this.checkRegionLength(this.selectedRegions[this.selectedRegions.length - (index + 1)]),
      );
      this.selectedAreas.forEach((area, index) =>
        this.checkAreaLength(this.selectedAreas[this.selectedAreas.length - (index + 1)]),
      );
    }, 200);
  }

  goAhead() {
    this.pageController = this.pageController == 'projectData' ? SignalingTypeEnum.Vertical : 'resume';
  }

  goBack() {
    if (this.pageController == 'projectData') this._route.navigate(['/projects']);
    else this.pageController = 'projectData';
  }

  handleFormValidation(event) {
    this.formValidation = event;
  }

  // Método para popular lista de Regiao e Area
  populateRegionAreaPathList() {
    // Populando lista de regiões
    this._regionArea.getRegions().subscribe((res: Array<any>) => {
      res.forEach((region) => {
        const sameRegion = this.selectedRegions.find((selectedRegion) => {
          return region.id == selectedRegion.id;
        });
        if (!sameRegion) {
          this.regionsList.push(region);
        }
      });
      if (this.editMode) {
        this.project.regions.forEach((region) => {
          this.setRegionAreaPath(region.id, 'region');
        });
      }
    });
    // Populando lista de áreas
    this._regionArea.getAreas().subscribe((res: Array<any>) => {
      res.forEach((area) => {
        const sameArea = this.selectedAreas.find((selectedArea) => {
          return area.id == selectedArea.id;
        });
        if (!sameArea) {
          this.areasList.push(area);
        }
      });
      if (this.editMode) {
        this.project.areas.forEach((area) => {
          this.setRegionAreaPath(area.id, 'area');
        });
      }
    });
    // Populando lista de Trajetos
    this._pathService.getPaths(['id', 'name']).subscribe((res: Array<any>) => {
      res.forEach((path) => {
        const samePath = this.selectedPaths.find((selectedPath) => {
          return path.id == selectedPath.id;
        });
        if (!samePath) {
          this.pathsList.push(path);
        }
      });
      if (this.editMode) {
        this.project.paths.forEach((path) => {
          this.setRegionAreaPath(path.id, 'path');
        });
      }
    });
  }

  // seta o contrato na classe de projetos
  setContract(contractId) {
    this._contractService.getContract(contractId).subscribe((res: any) => {
      this.currentContract = res;
      this._contractService.checkForContractAdjustments(this.currentContract)
    });
  }

  // Manipula as regioes e areas selecionadas
  setRegionAreaPath(id: string, type: string) {
    if (type == 'region') {
      let index = 0;
      const region = this.regionsList.find((region, indexOfRegion) => {
        index = indexOfRegion;
        return region.id == id;
      });
      this.regionsList.splice(index, 1);
      this.selectedRegions.push(region);
      setTimeout(() => {
        this.checkRegionLength(region);
      }, 200);
    } else if (type == 'area') {
      let index = 0;
      const area = this.areasList.find((area, indexOfArea) => {
        index = indexOfArea;
        return area.id == id;
      });
      this.areasList.splice(index, 1);
      this.selectedAreas.push(area);
      setTimeout(() => {
        this.checkAreaLength(area);
      }, 200);
    } else {
      let index = 0;
      const path = this.pathsList.find((path, indexOfPath) => {
        index = indexOfPath;
        return path.id == id;
      });

      this.pathsList.splice(index, 1);
      this.selectedPaths.push(path);
      setTimeout(() => {
        this.checkPathLength(path);
      }, 200);
    }
  }

  // Analisa o tamanho da div para truncar* o display das regioes/areas
  checkRegionLength(region: Region) {
    const container = this.regionContainer.nativeElement.clientWidth;
    const row = this.regionRow.nativeElement.clientWidth;

    if (row > container / 3 - 100) {
      const regionElement = document.getElementById(`region${region.id}`) as HTMLElement;
      this.hiddenRegionIds.push(region.id);
      this.overflowCounter.region += 1;
      regionElement.hidden = true;
    }
  }

  checkAreaLength(area: Area) {
    const container = this.areaContainer.nativeElement.clientWidth;
    const row = this.areaRow.nativeElement.clientWidth;

    if (row > container - 100) {
      const areaElement = document.getElementById(`area${area.id}`) as HTMLElement;
      this.hiddenAreaIds.push(area.id);
      this.overflowCounter.area += 1;
      areaElement.hidden = true;
    }
  }

  checkPathLength(path: Path) {
    const container = this.pathContainer.nativeElement.clientWidth;
    const row = this.pathRow.nativeElement.clientWidth;

    if (row > container - 100) {
      const pathElement = document.getElementById(`path${path.id}`) as HTMLElement;
      this.hiddenPathIds.push(path.id);
      this.overflowCounter.path += 1;
      pathElement.hidden = true;
    }
  }

  // Seta as Regioes e Areas na classe de Projetos
  submitRegionAreaPath() {
    this.project.regions = this.selectedRegions.map((region) => {
      return region;
    });
    this.project.regionIds = this.selectedRegions.map((region) => {
      return region.id;
    });

    this.project.areas = this.selectedAreas.map((area) => {
      return area;
    });
    this.project.areaIds = this.selectedAreas.map((area) => {
      return area.id;
    });

    this.project.paths = this.selectedPaths.map((path) => {
      return path;
    });
    this.project.pathIds = this.selectedPaths.map((path) => {
      return path.id;
    });
  }

  // Remove Região ou Área, repopula lista de regiões
  removeRegionAreaPath(type: 'region' | 'area' | 'path', item: any, index: number) {
    if (type == 'region') {
      this.regionsList.push(item);
      this.selectedRegions.splice(index, 1);
      const container = this.regionContainer.nativeElement.clientWidth;
      const row = this.regionRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenRegionIds.length) {
          const element = document.getElementById(`region${this.hiddenRegionIds[0]}`) as HTMLElement;
          this.hiddenRegionIds.splice(0, 1);
          element.hidden = false;
        }
        if (this.overflowCounter.region > 0) {
          this.overflowCounter.region -= 1;
        }
      }
    } else if (type == 'area') {
      this.areasList.push(item);
      this.selectedAreas.splice(index, 1);
      const container = this.areaContainer.nativeElement.clientWidth;
      const row = this.areaRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenAreaIds.length) {
          const element = document.getElementById(`area${this.hiddenAreaIds[0]}`) as HTMLElement;
          this.hiddenAreaIds.splice(0, 1);
          element.hidden = false;
          if (this.overflowCounter.area > 0) {
            this.overflowCounter.area -= 1;
          }
        }
      }
    } else {
      this.pathsList.push(item);
      this.selectedPaths.splice(index, 1);
      const container = this.pathContainer.nativeElement.clientWidth;
      const row = this.pathRow.nativeElement.clientWidth;
      if (row < container - 150) {
        if (this.hiddenPathIds.length) {
          const element = document.getElementById(`path${this.hiddenPathIds[0]}`) as HTMLElement;
          this.hiddenPathIds.splice(0, 1);
          element.hidden = false;
          if (this.overflowCounter.path > 0) {
            this.overflowCounter.path -= 1;
          }
        }
      }
    }
  }

  addProjectItem(type) {
    if (type == SignalingTypeEnum.Vertical) {
      this.project.projectTrafficSigns.push(new ProjectTrafficSign({ warranty: new Date() }));
    } else if (type == SignalingTypeEnum.Device) {
      this.project.projectDeviceSignalings.push(new ProjectDeviceSignaling({ warranty: new Date() }));
    } else if (type == SignalingTypeEnum.Horizontal) {
      this.project.projectHorizontalSignalings.push(new ProjectHorizontalSignaling({ warranty: new Date() }));
    } else {
      this.project.projectSupports.push(new ProjectSupport({ warranty: new Date() }));
    }
  }

  // abre modal
  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
    });
  }
}
