import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Project } from 'src/models';

import { AuthService } from '.';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends RestApiService {
  // Variáveis

  private _urlProjects = '/projects';
  private _urlProject = '/projects/:projectId';
  private _urlProjectFiles = '/projects/:projectId/files';
  private _urlHorizontalMasterFiles = '/horizontal-signalings/:signId/master-file';
  private _urlHorizontalMasterFile = '/horizontal-signalings/:signId/master-file/:masterId';
  private _urlDeviceMasterFiles = '/device-signalings/:signId/master-file';
  private _urlDeviceMasterFile = '/device-signalings/:signId/master-file/:masterId';
  private _urlVerticalMasterFiles = '/vertical-signalings/:signId/master-file';
  private _urlVerticalMasterFile = '/vertical-signalings/:signId/master-file/:masterId';
  private _urlUploadFiles = '/projects/:id/files';
  private _urlFilesId = '/projects/files/:id';
  private _urlData = '/projects/data';
  private _urlMasterFileId = '/projects/:id/master-file';
  private _urlProjectHistory = '/projects/:id/history';
  _urlUploadProjectFiles = '/projects/:id/files';


  private environmentEndpoint = environment.endpoint();

  constructor(public http: HttpClient, private _auth: AuthService) {
    super(http);
  }

  // Obter Projetos
  getProjects(
    filter?: { ids?: string[]; withoutContract?: boolean },
    pagination?: { limit?: number; offset?: number }
  ) {
    const params = new URLSearchParams();
  
    if (filter?.ids) {
      params.append('contractIds', JSON.stringify(filter.ids));
    }
  
    if (filter?.withoutContract !== undefined) {
      params.append('withoutContract', filter.withoutContract.toString());
    }
  
    if (pagination?.limit !== undefined) {
      params.append('limit', pagination.limit.toString());
    }
  
    if (pagination?.offset !== undefined) {
      params.append('offset', pagination.offset.toString());
    }
  
    const queryString = params.toString();
    const url = `${this._urlProjects}${queryString ? `?${queryString}` : ''}`;
  
    return this.get(url, Project);
  }
  
  // Criar Projeto
  createProject(project) {
    const url = this._urlProjects;
    return this.post(url, project, Project);
  }

  // Obter Projeto
  getProject(projectId: string) {
    const url = this._urlProject.replace(':projectId', projectId);
    return this.get(url, Project);
  }

  getNewProject(projectId: string) {
    const url = this._urlProject.replace(':projectId', projectId);
    return this.get(url, Project);
  }

  // Atualizar Projeto
  updateProject(project) {
    const url = this._urlProject.replace(':projectId', project.id);
    return this.put(url, project, Project);
  }

  // Remover Projeto
  deleteProject(projectId) {
    const url = this._urlProject.replace(':projectId', projectId);
    return this.del(url, Project);
  }

  // Obter Arquivos de Imagem do Projeto
  getImagesFiles(projectId) {
    const imgsJson = JSON.stringify(['image/jpg', 'image/png', 'image/jpeg']);
    let url = this.environmentEndpoint + this._urlProjectFiles.replace(':projectId', projectId);
    url += `?mimetypes=${imgsJson}`;
    return this.http.get(url);
  }

  // Criar Arquivo Mestre de Horizontal
  createHorizontalMasterFile(horizontalId, data) {
    const url = this.environmentEndpoint + this._urlHorizontalMasterFiles.replace(':signId', horizontalId);
    return this.http.post(url, data);
  }

  // Atualizar Arquivo Mestre de Horizontal
  updateHorizontalMasterFile(horizontalId, data) {
    const url = this.environmentEndpoint + this._urlHorizontalMasterFile.replace(':signId', horizontalId).replace(':masterId', data.id);
    return this.http.put(url, data);
  }

  // Criar Arquivo Mestre de Device
  createDeviceMasterFile(deviceId, data) {
    const url = this.environmentEndpoint + this._urlDeviceMasterFiles.replace(':signId', deviceId);
    return this.http.post(url, data);
  }

  // Atualizar Arquivo Mestre de Device
  updateDeviceMasterFile(deviceId, data) {
    const url = this.environmentEndpoint + this._urlDeviceMasterFile.replace(':signId', deviceId).replace(':masterId', data.id);
    return this.http.put(url, data);
  }

  // Criar Arquivo Mestre de Device
  createVerticalMasterFile(verticalId, data) {
    const url = this.environmentEndpoint + this._urlVerticalMasterFiles.replace(':signId', verticalId);
    return this.http.post(url, data);
  }

  // Atualizar Arquivo Mestre de Device
  updateVerticalMasterFile(verticalId, data) {
    const url = this.environmentEndpoint + this._urlVerticalMasterFile.replace(':signId', verticalId).replace(':masterId', data.id);
    return this.http.put(url, data);
  }

  // Upload nos arquivos de projeto
  uploadProjectFiles(files: [{ file: File, url: SafeResourceUrl }], projectId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file.file);
    }

    const url = this.environmentEndpoint + this._urlUploadFiles.replace(':id', projectId);
    return this.http.post(url, formData, { headers: reqHeaders });
  }

  deleteProjectFile(fileId: string){
    const url = this.environmentEndpoint + this._urlFilesId.replace(':id', fileId);
    return this.http.delete(url);
  }

  getProjectsData(){
    const url = this.environmentEndpoint + this._urlData;
    return this.http.get(url);
  }


  getProjectHistory(projectBody: Project){
    const url = this.environmentEndpoint + this._urlProjectHistory.replace(':id', projectBody.id);
    return this.http.get(url);
  }

  updateMasterFile(projectId: string, projectFileId: string){
    const url = this.environmentEndpoint + this._urlMasterFileId.replace(':id', projectId)
    return this.http.put(url, {projectFileId: projectFileId})
  }


  downloadProjectFile(fileId: string) {
    const url = this.environmentEndpoint + this._urlFilesId.replace(':id', fileId);
    return this.http.get<{ url: string }>(url);
  }

}
