<ng-container *ngFor="let item of list">
  <accordion [isAnimated]="true" [closeOthers]="true" class="card-box">
    <accordion-group [isOpen]="item.isAccOpen" (isOpenChange)="openAccordionGroup(item, $event)">
      <div accordion-heading>
        <!-- SVG|Nome|Quantidade -->
        <div class="line-collapse">

          <!-- SVG e Nome -->
          <div class="svg-name">

            <!-- SVG -->
            <div class="position-relative">

              <!-- Sem Informação Complementar -->
              <div *ngIf="!item?.complementInformationType" [ngClass]="item?.signCode| enumCaption: 'Caption' : 40"
                style="bottom: 6px;" class="position-relative internal-caption">
                <!-- Legenda Interna -->
                <span>{{item?.verticalType != verticalSignTypeEnum.Indication ? item.internalCaption : ''}}</span>
                <!-- Legenda Interna -->

                <img *ngIf="type == verticalGroupTypeEnum.Support"
                  [src]="item?.contractSignItem?.supportMaterial | enumSvg: 'Signaling': type"
                  style="width: 40px; height: 40px; object-fit: contain;">
                <img *ngIf="type != verticalGroupTypeEnum.Support" [src]="item?.signCode| enumSvg: 'Signaling': type"
                  style="width: 40px; height: 40px; object-fit: contain;">
              </div>
              <!-- Sem Informação Complementar -->


              <!-- Com Informação Complementar -->
              <div *ngIf="item?.type == signTypeEnum.Vertical && item?.complementInformationType"
                class="d-flex justify-content-center" style="height: 60px; width: 40px;">

                <!-- Adicional -->
                <img *ngIf="item?.complementInformationType == complementInformationTypeEnum.Additional"
                  [src]="complementType(item)| enumSvg: 'ComplementInformation'"
                  style="width: 40px; height: 20px; object-fit: contain; position: absolute; bottom: 8px;">
                <!-- Adicional -->


                <!-- Incorporada -->
                <img *ngIf="item?.complementInformationType == complementInformationTypeEnum.Incorporated"
                  [src]="complementType(item)| enumSvg: 'ComplementInformation'"
                  style="width: 40px; height: 60px; object-fit: contain; position: absolute; bottom: 8px;">
                <!-- Incorporada -->


                <!-- Legenda Interna -->
                <div [ngClass]="item?.signCode| enumCaption: 'Caption' : 32" class="position-relative internal-caption"
                  style="height: 32px; width: 32px;">
                  <span class="position-absolute" style="z-index: 1;">{{item?.verticalType != verticalSignTypeEnum.Indication ? item.internalCaption : ''}}</span>
                  <img [src]="item?.signCode| enumSvg: 'Signaling': signType"
                    style="width: 32px; height: 32px; object-fit: contain; position: absolute;">
                </div>
                <!-- Legenda Interna -->

              </div>
              <!-- Com Informação Complementar -->

            </div>
            <!-- SVG -->


            <!-- Nome -->
            <div class="d-flex flex-column">
              <h4 class="item-title">{{type == verticalGroupTypeEnum.Support
                ? 'Suporte Selecionado' : 'Sinalização Selecionada'}}</h4>
              <span *ngIf="type != verticalGroupTypeEnum.Support" class="mb-3">{{item.signCode|enumText: 'Signaling' ||
                '-'}}</span>
              <span *ngIf="type == verticalGroupTypeEnum.Support"
                class="mb-3">{{item?.contractSignItem?.supportMaterial|enumText: 'RoadSignSupport' || '-'}}</span>
            </div>
            <!-- Nome -->

          </div>
          <!-- SVG e Nome -->


          <!-- Quantidade e Botão Collapse -->
          <div class="d-flex align-items-center">

            <!-- Quantidade -->
            <div class="item-quantity" *ngIf="item.isAccOpen">
              <h3 class="text-white">{{(item.quantityRegistered||0 ) + '/' + (item.quantity||1)}}</h3>
            </div>
            <!-- Quantidade -->


            <!-- Collapse -->
            <div class="collapse-button">
              <i class="fa-regular fa-lg" [ngClass]="item.isAccOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
            </div>
            <!-- Collapse -->

          </div>
          <!-- Quantidade e Botão Collapse -->

        </div>
        <!-- SVG|Nome|Quantidade -->
      </div>

      <div>

        <div class="d-flex">

          <!-- Número do Item do Contrato -->
          <div *ngIf="item?.contractSignItem?.id" class="item-number">
            <span class="text-primary">{{item?.itemContractNumber}}</span>
          </div>
          <!-- Número do Item do Contrato -->


          <!-- Coluna de Informações -->
          <div class="info-column">

            <!-- Nome do Item -->
            <ng-container *ngIf="item?.contractSignItem?.id">
              <div class="ml-2">
                <h4 class="item-title">Nome do Item</h4>
                <span>{{item?.contractSignItem?.name || '-'}}</span>
              </div>
            </ng-container>
            <!-- Nome do Item -->


            <!-- Informações Vertical -->
            <ng-container *ngIf="type == signTypeEnum.Vertical">
              <div class="ml-2">
                <h4 class="item-title">Tipo do Substrato</h4>
                <span>{{item?.contractSignItem?.roadSignSubstrate?.name |enumText: 'RoadSignSubstrate' || '-'}}</span>
              </div>

              <div class="ml-2">
                <h4 class="item-title">Tipo da Película</h4>
                <span>{{item?.contractSignItem?.roadSignFilmItems.length ?
                  item?.contractSignItem?.roadSignFilmItems[0].roadSignFilm?.name : '-'}}</span>
              </div>

              <div class="ml-2">
                <h4 class="item-title">Tipo da Informação Complementar</h4>
                <span>{{item.complementInformationType ?
                  (item?.complementInformationType|enumText: 'ComplementInformation') : '-'}}</span>
              </div>

              <div class="ml-2">
                <h4 class="item-title">Informação Complementar</h4>
                <span>{{item?.complementInformation || '-'}}</span>
              </div>

            </ng-container>
            <!-- Informações Vertical -->


            <!-- Informações Horizontal -->
            <ng-container *ngIf="type == signTypeEnum.Horizontal">
              <div class="ml-2">
                <h4 class="item-title">Material Aplicado</h4>
                <span>{{item?.contractSignItem?.roadSignMarkingPaint?.name || '-'}}</span>
              </div>
            </ng-container>
            <!-- Informações Horizontal -->


            <!-- Informações Dimensão H/V -->
            <ng-container *ngIf="type != signTypeEnum.Device && type != verticalGroupTypeEnum.Support">
              <div class="ml-2">
                <h4 class="item-title">Dimensões</h4>

                <div class="d-flex mb-2">

                  <!-- Tipo da Dimensão -->
                  <div class="type-box">
                    <span class="type-text">{{item?.dimensionType}}</span>
                  </div>
                  <!-- Tipo da Dimensão -->


                  <!-- Diâmetro -->
                  <ng-container *ngIf="type == signTypeEnum.Vertical
                  && !(item.signCode == verticalSignEnum.R1 || item.signCode == verticalSignEnum.R2)
                  && item?.dimensionType == dimensionTypeEnum.Efetiva">
                    <ng-container *ngIf="item.verticalType == verticalSignTypeEnum.Regulation; else elseDiameter">
                      <span class="mr-2 text-primary">Ø</span>
                      <span class="mr-3">
                        {{item.diameter ? stringToFloatFixed(item?.diameter) + ' m' : '-'}}</span>
                    </ng-container>
                    <!-- Diâmetro -->


                    <!-- Lado -->
                    <ng-template #elseDiameter>
                      <i class="mr-2 fa-regular text-primary fa-arrows-v"></i>
                      <span class="mr-3">{{item.sideLength ? stringToFloatFixed(item?.sideLength) + ' m' :
                        '-'}}</span>
                    </ng-template>
                    <!-- Lado -->

                  </ng-container>

                  <!-- Quantidade -->
                  <ng-container
                    *ngIf="type == signTypeEnum.Horizontal && item?.dimensionType == dimensionTypeEnum.Efetiva">
                    <i class="mr-2 fa-regular fa-bring-forward text-primary"></i>
                    <span class="mr-3">{{stringToFloatFixed(item?.quantity)}}</span>
                  </ng-container>
                  <!-- Quantidade -->


                  <!-- Dimensão Y -->
                  <ng-container *ngIf="item?.dimensionType == dimensionTypeEnum.Envolvente
                    || type == signTypeEnum.Horizontal
                    || (item?.signCode == verticalSignEnum.R1
                    || item?.signCode == verticalSignEnum.R2)">
                    <i class="mr-2 fa-regular text-primary fa-arrows-v"></i>
                    <span class="mr-3">
                      {{item.dimensionY ? stringToFloatFixed(item?.dimensionY) + ' m': '-'}}</span>
                    <!-- Dimensão Y -->


                    <!-- Dimensão X -->
                    <ng-container
                      *ngIf="!(item?.signCode == verticalSignEnum.R1 || item?.signCode == verticalSignEnum.R2)">
                      <i class="mr-2 fa-regular text-primary fa-arrows-h"></i>
                      <span class="mr-3">{{item.dimensionX ?
                        stringToFloatFixed(item?.dimensionX) + ' m': '-'}}</span>
                    </ng-container>
                    <!-- Dimensão X -->

                  </ng-container>

                  <!-- Dimensão -->
                  <i class="mr-2 fa-regular text-primary fa-arrows"></i>
                  <span>{{item.dimension ? stringToFloatFixed(item?.dimension) + ' m²' : '-'}}</span>
                  <!-- Dimensão -->


                </div>
              </div>
            </ng-container>
            <!-- Informações Dimensão H/V -->


            <!-- Informações Dispositivos -->
            <ng-container *ngIf="type == signTypeEnum.Device">
              <div class="info-column pl-2">
                <div>
                  <h4 class="item-title">Nomenclatura do Dispositivo</h4>
                  <span>{{item?.signCode|enumText:'Signaling'}}</span>
                </div>
                <div>
                  <h4 class="item-title">Aplicação</h4>
                  <div class="d-flex align-items-center">
                    <i class="mr-2 fa-regular fa-bring-forward text-primary"></i>
                    <span>{{item.units ? stringToFloatFixed(item?.units) + ' und.' : '-'}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Informações Dispositivos -->


            <!-- Informações Suporte -->
            <div *ngIf="type == verticalGroupTypeEnum.Support" class="d-flex ml-2">
              <div *ngIf="item?.contractSupportItemId" class="item-number">
                <span class="text-primary">{{signSupportContractNumber}}</span>
              </div>
              <div>
                <ng-container *ngIf="item?.contractSupportItemId">
                  <h4 class="item-title">Nome do item</h4>
                  <span class="mb-3">{{item?.contractSignItem?.supportMaterial}}</span>
                </ng-container>
                <h4 class="item-title">Tipo do Suporte</h4>
                <span class="mb-3">{{item?.contractSignItem?.supportMaterial|enumText: 'RoadSignSupport' || '-'}}</span>
              </div>
            </div>
            <!-- Informações Suporte -->


            <!-- Informações Retrorrefletorização -->
            <ng-container *ngIf="type == signTypeEnum.Vertical || type == signTypeEnum.Horizontal">
              <div class="d-flex mt-3">
                <div class="col-6 px-2">
                  <h4 class="item-title">Valor Minímo</h4>
                  <span>{{item?.minimumRetroreflectionValue ? item?.minimumRetroreflectionValue + ' mcd Lux/m²' :
                    '-'}}</span>
                </div>
                <div>
                  <h4 class="item-title">Valor Desejado</h4>
                  <span>{{item?.maximumRetroreflectionValue ? item?.maximumRetroreflectionValue + ' mcd Lux/m²' :
                    '-'}}</span>
                </div>
              </div>
            </ng-container>
            <!-- Informações Retrorrefletorização -->


            <!-- Informações Garantia -->
            <div class="d-flex flex-column" *ngIf="type != verticalGroupTypeEnum.Support">
              <div class="col mb-2 px-2">
                <progressbar *ngIf="item['active'] == 1" class="line-progressbar" [value]="(item['progress'])*100"
                  type="success">
                </progressbar>
                <progressbar *ngIf="item['active'] > 1" class="line-progressbar" [value]="(item['progress'])*100"
                  type="danger">
                </progressbar>
              </div>
              <div class="d-flex justify-content-between px-2">
                <div class="d-flex justify-content-between flex-column">
                  <span *ngIf="item?.active != 3"
                    [ngStyle]="{color: item?.active == 1  ? 'var(--green-base)' : 'var(--red-base)'}"
                    style="font-size: 20px;">{{dateService.getTextDate(item?.warrantyCurrent) == 0
                    ? 'Fim da Garantia'
                    : dateService.getTextDate(item?.warrantyCurrent)}}</span>
                  <span *ngIf="item?.active==3" style="color:var(--redmix-light); font-weight: 400; font-size: 20px">
                    Tempo Encerrado
                  </span>
                  <h4 class="mt-2" [ngStyle]="{color: item?.active == 1 ? 'var(--greenmix-light)' : 'var(--red-base)'}">
                    {{item?.active == 3 ? 'expirou em' : 'expira em'}} {{item.warranty
                    |date:'dd/MM/yyyy'}}</h4>
                </div>

                <div class="d-flex justify-content-between flex-column align-items-end">
                  <span *ngIf="item?.active!=3" style="font-size: 20px; color:var(--gray-light)">de
                    {{dateService.getTextDate(item.warrantyEnd)}}</span>
                  <span *ngIf="item?.active==3" style="color:var(--red-base); font-weight: 500; font-size: 16px">
                    <i class="fa-solid fa-lg fa-triangle-exclamation" style="margin-right: 8px;"></i>
                    Garantia Expirada
                  </span>
                  <h4 class="mt-2" style="color:var(--gray-light)">Última edição em {{item.warrantyLastUpdate
                    |date:'dd/MM/yyyy'}}</h4>
                </div>
              </div>
            </div>
            <!-- Informações Garantia -->


            <!-- Informações Monetária -->
            <ng-container>
              <div class="d-flex">
                <div class="col-6 px-2">
                  <h4 class="item-title">Valor Unitário</h4>
                  <span class="text-money">{{ (item?.cost/item?.quantity)|currency: 'BRL' }}</span>
                </div>
                <div>
                  <h4 class="item-title">Valor Total do Item</h4>
                  <span class="text-money">{{ (item?.cost)|currency: 'BRL' }}</span>
                </div>
              </div>
            </ng-container>
            <!-- Informações Monetária -->
          </div>
        </div>
      </div>

    </accordion-group>
  </accordion>
</ng-container>
