import { Injectable } from '@angular/core';
import { intervalToDuration } from 'date-fns';
import { MemberRoleEnum, UserDutyEnum } from 'src/enumerators';
import { TrafficSign, User, VerticalCombinedSign } from 'src/models';
import { AuthService } from '../services';
import { CatalogService } from '../services/catalog.service';

@Injectable({
  providedIn: 'root'
})

export class ServiceFunction {

  loggedUser: User;
  static catalog: CatalogService;
  static auth: AuthService;

  constructor() { }

  // Retorna uma lista com os tempos em anos, meses, dias, horas e minutos, e a diferença em milissegundos
  static getRelativeTime(warranty, date): any {
    // Cálculo para pegar as horas, dias, meses e ano em number;
    let diffTime = this.calcDate(warranty, date);
    let intervalWarranty: Duration = { years: 0, months: 0, days: 0, hours: 0, minutes: 0 };

    if (diffTime > 0) {
      intervalWarranty = intervalToDuration({
        start: new Date(warranty),
        end: new Date(date),
      });
    }
    return [
      diffTime,
      intervalWarranty.years,
      intervalWarranty.months,
      intervalWarranty.days,
      intervalWarranty.hours,
      intervalWarranty.minutes,
    ];
  }

  // Retorna a diferença entre duas datas em milissegundos (getTimes)
  static calcDate(date1: any, date2: any): any {
    let newDate1;
    let newDate2;

    if (typeof date1 == 'string') {
      const string1 = date1.substring(0, 10);
      const string2 = date1.substring(11, 19);
      newDate1 = new Date(string1 + ' ' + string2);
    } else {
      newDate1 = date1;
    }
    if (typeof date2 == 'string') {
      const string3 = date2.substring(0, 10);
      const string4 = date2.substring(11, 19);
      newDate2 = new Date(string3 + ' ' + string4);
    } else {
      newDate2 = date2;
    }
    if (newDate2 > newDate1) {
      return 0;
    }
    const time = Math.abs(newDate1.getTime() - newDate2.getTime());
    return time;
  }

  // Nome explicito, verifica se falta menos de um mes e retorna true se sim
  static lessThanAMonth(listDate: Array<any>): boolean {
    return (listDate[1] <= 0 && ((listDate[2] <= 0 && listDate[3] <= 31) || (listDate[2] == 1 && listDate[3] == 0)));
  }

  // Verifica permissao para editar a sinalização
  static isEditable(itemId): boolean {
    if (this.auth?.currentMember?.role == MemberRoleEnum.TeamLeader) {
      let found = this.catalog?.editableSigns.find((signId) => {
        return signId == itemId;
      });
      if (found) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static setCombinedSigns(trafficSigns: Array<TrafficSign>, groupId, index): Array<VerticalCombinedSign> {
    let combinedSigns: Array<VerticalCombinedSign> = [];
    if (trafficSigns.length > 1) {
      trafficSigns.forEach((conjugate, i) => {
        if (index != i) {
          conjugate['groupId'] = groupId;
          let conjugateAux: VerticalCombinedSign = new VerticalCombinedSign({ conjugate });
          combinedSigns.push(conjugateAux);
        }
      });
    }
    return combinedSigns;
  }

}
