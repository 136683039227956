import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/models';
import { OrganizationService } from 'src/app/services';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { MemberRoleEnum } from 'src/enumerators';

@Component({
  selector: 'app-toggle-sidebar',
  templateUrl: './toggle-sidebar.component.html',
  styleUrls: ['./toggle-sidebar.component.css']
})
export class ToggleSidebarComponent implements OnInit {

  toggleOpen: boolean = false
  memberRoleEnum = MemberRoleEnum;

  org: Organization

  constructor(
    private _organizationService: OrganizationService,
    public authorizationService: AuthorizationService,

    ) { }

  ngOnInit(): void {
    this._organizationService.workSub.subscribe(
      (res: Organization) => {
        if(res) {
          this.org = res
        }
      }
    )
  }

  checkMemberRole(memberRoleList) {
    return this.authorizationService.checkMemberRole(memberRoleList);
  }

}
