import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FilterReportState, Report } from 'src/models';
import { AuthService } from '.';
import { RestApiService } from './rest-api.service';
import { startOfDay, endOfDay, addMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends RestApiService {
  // Variáveis

  private _urlReports = '/reports';
  private _urlReportFile = '/reports/:fileId/file';
  private _urlContractReports = '/contracts/report';
  private _urlProjectReports = '/projects/report';
  private _urlRegionReports = '/regions/report';
  private _urlAreaReports = '/areas/report';
  private _urlTeamReports = '/teams/report';
  private _urlVehicleReports = '/vehicles/report';
  private _urlTaskReports = '/tasks/report';
  private _urlServiceOrderReports = '/service-orders/report';
  private _urlSignalingsReports = '/signalings/report';
  private _urlHorizontalMeasurements = '/horizontal-signaling-measurements';
  private _urlProductivityReports = '/reports/productivity';
  private _urlRoutesReports = '/routes/report';
  private _urlDERESMeasurementReport = '/reports/DER-ES';
  private _urlDetranBAMeasurementReport = '/reports/DETRAN-BA';
  private _urlDERESMeasurementReportPhotos = '/reports/DER-ES/images';

  localhost = environment.endpoint();

  constructor(public http: HttpClient, private _auth: AuthService) {
    super(http);
  }

  // Obter Todos os Reports
  getReports({ limit, offset }: { limit?: number, offset?: number }) {
    let url = this._urlReports;

    if (offset !== undefined && limit !== undefined) {
      url += `?offset=${offset}&limit=${limit}`;
    }

    return this.get(url, Report);
  }

  getReportFile(fileId) {
    const url = this.localhost + this._urlReportFile.replace(':fileId', fileId);
    return this.http.get(url);
  }

  // Criar Report Contrato
  createReportContract(filterReportState: FilterReportState) {
    const report = new Report({});
    const url = this._urlContractReports + `?contractId=${filterReportState.contractId}`;
    return this.post(url, report, Report);
  }

  // Criar Report Projeto
  createReportProject(filterReportState: FilterReportState) {
    const report = new Report({});
    const projectListId = [...filterReportState.listSelected];
    const projectJson = JSON.stringify(projectListId);
    const url = this._urlProjectReports + `?ids=${projectJson}&financial=${filterReportState.financeCheck}`;
    return this.post(url, report, Report);
  }

  // Criar Report Região
  createReportRegion(filterReportState: FilterReportState) {
    const report = new Report({});
    const regionListId = [...filterReportState.listSelected];
    const regionJson = JSON.stringify(regionListId);
    const signsString = `&verticalSignaling=${filterReportState.verticalCheck}&deviceSignaling=${filterReportState.deviceCheck}&horizontalSignaling=${filterReportState.horizontalCheck}`;
    const url = this._urlRegionReports + `?ids=${regionJson}${signsString}&financial=${filterReportState.financeCheck}`;
    return this.post(url, report, Report);
  }

  // Criar Report Área
  createReportArea(filterReportState: FilterReportState) {
    const report = new Report({});
    const areaListId = [...filterReportState.listSelected];
    const areaJson = JSON.stringify(areaListId);

    const signsString = `&verticalSignaling=${filterReportState.verticalCheck}&deviceSignaling=${filterReportState.deviceCheck}&horizontalSignaling=${filterReportState.horizontalCheck}`;
    const url = this._urlAreaReports + `?ids=${areaJson}${signsString}&financial=${filterReportState.financeCheck}`;
    return this.post(url, report, Report);
  }


  createReportSignaling(filterReportState: FilterReportState) {
    const queryParams = new URLSearchParams();

    if (filterReportState.contractId) {
      queryParams.append('contractId', filterReportState.contractId);
    }

    if (filterReportState.startDate) {
      const startDate = startOfDay(new Date(filterReportState.startDate)).toISOString();
      queryParams.append('startDate', startDate);
    }

    if (filterReportState.endDate) {
      const endDate = endOfDay(filterReportState.endDate).toISOString();
      queryParams.append('endDate', endDate);
    }

    let regionListId = [...filterReportState.listSelected];
    if (regionListId[0] === '0') {
      queryParams.append('noRegions', 'true');
    } else {
      queryParams.append('regionIds', JSON.stringify(regionListId));
    }

    if (filterReportState.verticalCheck) {
      queryParams.append('verticalSignaling', 'true');
    }
    if (filterReportState.deviceCheck) {
        queryParams.append('deviceSignaling', 'true');
    }
    if (filterReportState.horizontalCheck) {
        queryParams.append('horizontalSignaling', 'true');
    }
    if (filterReportState.activeCheck) {
        queryParams.append('active', 'true');
    }
    if (filterReportState.warrantyCheck) {
        queryParams.append('warranty', 'true');
    }
    if (filterReportState.financeCheck) {
        queryParams.append('financial', 'true');
    };

    if (filterReportState.pathIds.size) {
      if (filterReportState.pathIds.has('0')) {
        queryParams.append('noPaths', 'true');
      } else {
        const pathIds = [...filterReportState.pathIds];
        queryParams.append('pathIds', JSON.stringify(pathIds));
      }
    }

    const url = `${this._urlSignalingsReports}?${queryParams.toString()}`;
    let report = new Report({});

    return this.post(url, report, Report);
  }

  // Criar Report Equipe
  createReportTeam(filterReportState: FilterReportState) {
    const report = new Report({});
    let listTypes = [];
    filterReportState.verticalCheck ? listTypes.push('VERTICAL') : undefined;
    filterReportState.horizontalCheck ? listTypes.push('HORIZONTAL') : undefined;
    filterReportState.deviceCheck ? listTypes.push('DEVICE') : undefined;
    const typesJson = JSON.stringify(listTypes);
    const url = this._urlTeamReports + `?ids=${typesJson}`;
    return this.post(url, report, Report);
  }

  // Criar Report Veículos
  createReportVehicle(filterReportState: FilterReportState) {
    const report = new Report({});
    const vehicleListId = [...filterReportState.listSelected];
    const vehicleJson = JSON.stringify(vehicleListId);
    const url = this._urlVehicleReports + `?ids=${vehicleJson}`;
    return this.post(url, report, Report);
  }

  // Criar Report Ordem de Serviços
  createReportServiceOrder(filterReportState: FilterReportState) {
    const report = new Report({});
    const url = this._urlServiceOrderReports + `?financial=${filterReportState.financeCheck}`;
    return this.post(url, report, Report);
  }

  // Criar Report Tarefas
  createReportTask(filterReportState: FilterReportState) {
    const report = new Report({});
    const f = `&financial=${filterReportState.financeCheck}`;
    const l = `&location=${filterReportState.taskLocationCheck}`;
    const s = `&systemData=${filterReportState.taskInfoCheck}`;
    const url = this._urlTaskReports + `?` + f + l + s;
    return this.post(url, report, Report);
  }

  // Criar Report Produtividade
  createReportProductivity(filterReportState: FilterReportState) {
    const report = new Report({});
    const sD = filterReportState.startDate ? `startDate=${new Date(filterReportState.startDate.setHours(0, 0, 0, 0)).toISOString()}&` : '';
    const eD = filterReportState.endDate ? `endDate=${new Date(filterReportState.endDate).toISOString()}&` : '';
    const url = this._urlProductivityReports + '?' + sD + eD;

    return this.post(url, report, Report);
  }

  // Criar Report Rotas
  createReportRoutes(reportPayload: any, taskIdList: Array<string>) {
    const report = new Report({});
    const rId = `routeId=${reportPayload.routeId}&`;
    const f = `financial=${reportPayload?.financial}&`;
    const sD = reportPayload?.startsAt ? `startDate=${new Date(reportPayload?.startsAt.setHours(0, 0, 0, 0)).toISOString()}&` : '';
    const eD = reportPayload?.endsAt ? `endDate=${new Date(reportPayload?.endsAt).toISOString()}&` : '';
    const t = `taskIds=${JSON.stringify(Array.from(taskIdList))}&`;
    const url = (this._urlRoutesReports + '?' + rId + sD + eD + f + t);

    return this.post(url, report, Report);
  }

  // Criar Report Medição do DER-ES
  createReportDERESMeasurement(filterReportState: FilterReportState) {
    const report = new Report({});
    const c = `?contractId=${filterReportState.contractId}`;
    const s = `&startDate=${filterReportState.startDate.toISOString()}`;
    const e = `&endDate=${filterReportState.endDate.toISOString()}`;
    let p = '';
    if (filterReportState.pathIds.size > 0) {
      const pathList = [...filterReportState.pathIds]
      p = `&pathIds=${JSON.stringify(pathList)}`
    }
    const url = this._urlDERESMeasurementReport + c + s + e + p;
    return this.post(url, report, Report);
  }

  // Criar Report Medição do Detran-BA
  createReportDetranBAMeasurement(filterReportState: FilterReportState) {
    const report = new Report({});
    const c = `?contractId=${filterReportState.contractId}`;
    const s = `&startDate=${filterReportState.startDate.toISOString()}`;
    const e = `&endDate=${filterReportState.endDate.toISOString()}`;
    const url = this._urlDetranBAMeasurementReport + c + s + e;
    return this.post(url, report, Report);
  }

  getPhotosForReportDERESMeasurement(filterReportState: FilterReportState) {
    const report = new Report({});
    const c = `?contractId=${filterReportState.contractId}`;
    const s = `&startDate=${filterReportState.startDate.toISOString()}`;
    const e = `&endDate=${filterReportState.endDate.toISOString()}`;
    let p = '';
    if (filterReportState.pathIds.size > 0) {
      const pathList = [...filterReportState.pathIds]
      p = `&pathIds=${JSON.stringify(pathList)}`
    }
    const header = new HttpHeaders({
      'FormData': 'true',
      'Content-Type': 'application/zip',
      'Accept': 'application/zip',
      'Access-Control-Allow-Headers': 'Content-Type'
    });
    const url = this.localhost + this._urlDERESMeasurementReportPhotos + c + s + e + p;
    return this.http.post(url, {}, { headers: header, responseType: 'blob' });
  }

  deleteReport(reportId) {
    const url = this._urlReports + `/${reportId}`;
    return this.del(url, Report);
  }

  // Retorna medições de horizontal
  getHorizontalSignalingMeasurements(horizontalSignalingId: string) {
    const url = this.localhost + this._urlHorizontalMeasurements
    const params = new HttpParams().append('horizontalSignalingId', horizontalSignalingId)

    return this.http.get(url, { params: params })
  }

}
