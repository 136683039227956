export enum SupportMaterialTypeEnum {
  GalvanizedSteel = 'GALVANIZED_STEEL',
  Steel = 'STEEL',
  ImmunizedWood = 'IMMUNIZED_WOOD',
  EcologicalSupport = 'ECOLOGICAL_SUPPORT',
  ReforestedWood = 'REFORESTED_WOOD',
  RecycledMaterials = 'RECYCLED_MATERIALS',
  SemiPortal = 'SEMI_PORTAL',
  SemiPortalB2 = 'SEMI_PORTAL_B2',
  SemiPortalB4 = 'SEMI_PORTAL_B4',
  SemiPortalB5 = 'SEMI_PORTAL_B5',
  SemiPortalB6 = 'SEMI_PORTAL_B6',
  Lattice_portal = 'LATTICE_PORTAL',
};
