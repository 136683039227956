import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-project-card-modal',
  templateUrl: './project-card-modal.component.html',
  styleUrls: ['./project-card-modal.component.css']
})
export class ProjectCardModalComponent implements OnInit {
  
  @Input() type;
  @Input() sign;
  @Input() signCode;
  @Input() selectedSign: string;
  @Output() signCodeEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  itemQuantity(sign): string {
    const text = `${sign.quantityRegistered || 0}/${sign.quantity || 1}`
    return text;
  }

  emitSignCode(signCode){
    this.signCodeEmitter.emit(signCode)
  }
}
