<div class="col" class="file-upload-container">
  <div *ngIf="isFileOpen.state">
    <i class="fas fa-times-circle" (click)="closeFile()" style="cursor:pointer"></i>
  </div>
  <div uploadFile (onFileDropped)="uploadFile($event); saveFiles = false" class="file-box">
    <p *ngIf="!isFileOpen.state" class="d-flex m-3"><i class="fa-solid fa-paperclip-vertical"></i>
      Anexos
    </p>
    <div *ngIf="isFileOpen.state">
      <iframe [src]="isFileOpen.url" class="iframe-content"></iframe>
    </div>
    <div *ngIf="!isFileOpen.state">
      <div *ngIf="uploadedFiles.length == 0" class="col p-5 my-5 text-center">
        <i class="fa-light fa-cloud-arrow-up fa-6x" style="color:var(--blue-base)"></i>
        <p class="my-3">Arraste e solte os arquivos aqui</p>
        <p class="my-3" style="color:var(--gray-base);">ou</p>
        <label class="my-3" for="select-file"
          style="text-decoration:underline; cursor:pointer; color:var(--blue-base)">Selecione um arquivo</label>
        <input type="file" id="select-file" style="display:none;"
          (change)="handleFileSelection($event.target.files); saveFiles = false">
      </div>
      <div #elseBlock class="col p-3">
        <div *ngFor="let file of uploadedFiles; index as indexOfFile">
          <div class="file-card mb-4 cursor-pointer" (click)="edit ? downloadFile(file.id) : openFile(file)">
            <div class="col text-start d-flex flex-column justify-content-between py-2 px-4"
              style="overflow: hidden; text-overflow: ellipsis;">
              <div class="row mx-0 d-flex align-items-center mt-2">
                {{file.file.name ?? file?.file.originalName}}
              </div>
              <div class="col-auto px-0">
                <small style="font-size: 14px;" class="row mx-0 text-muted my-1">
                  {{(file.file.type ?? file?.file.mimeType).split('/')[1].toUpperCase()}}
                </small>
                <small style="font-size: 14px;" class="row mx-0 text-muted">
                  {{file.file.size ?? file?.file.size | fileSize}}
                </small>
              </div>
            </div>
            <div class="col-auto d-flex py-3 flex-column align-items-center justify-content-between"
              (click)="$event.stopPropagation()">
              <div class="col text-right">
                <i *ngIf="!saveFiles" class="fa-solid fa-x text-muted mt-2 action-btn"
                  (click)="removeFile(file.file.name ?? file?.file.originalName)" style="cursor: pointer;"></i>
              </div>
              <div class="custom-control custom-radio mr-2" *ngIf="isProject">
                <input type="radio" id="customRadio{{indexOfFile+1}}" name="customRadio" class="custom-control-input"
                  [checked]="file?.masterFile" (change)="setMasterFile(indexOfFile)">
                <label class="custom-control-label" style="font-weight: 400; font-size: 16px"
                  for="customRadio{{indexOfFile+1}}">Arquivo Mestre</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="uploadedFiles.length > 0">
        <label class="my-3" for="select-file"
          style="display:flex; justify-content: center;cursor:pointer; color:var(--blue-base); letter-spacing:2px; font-size: 12px"><i
            class="fas fa-plus-circle"></i> ADICIONAR ARQUIVO</label>
        <input type="file" id="select-file" style="display:none;"
          (change)="handleFileSelection($event.target.files); saveFiles = false">
      </div>
    </div>
  </div>
</div>