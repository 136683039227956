export enum TaskStatusEnum {
  ToStart = 'TO_START',
  Started = 'STARTED',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export enum TaskActivityStatusEnum {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
};

export enum MapTaskEnum {
  ToDo = 'TO_DO',
  Completed = 'COMPLETED',
  Wait = 'WAIT',
}

export enum TodayTaskEnum {
  OfToday = 'OF_TODAY',
  LateToStart = 'LATE_TO_START',
  LateToFinish = 'LATE_TO_FINISH',
  FinishedToday = 'FINISHED_TODAY',
}

export enum PriorityEnum {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}
