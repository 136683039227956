import { RetroreflectionTypeEnum } from 'src/enumerators';
import { IRest } from './rest.interface';

export class HorizontalSignalingRetroreflection implements IRest {
  className = 'Classe de Retrorrefletorização de Horizontal';

  id: string;
  horizontalSignalingId: string;
  type: RetroreflectionTypeEnum;
  createdAt: Date;
  updatedAt: Date;
  newMeasure: boolean;

  measurements: HorizontalSignalingRetroreflectionMeasurement[];
  average: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      horizontalSignalingId: this.horizontalSignalingId || undefined,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      measurements: this.measurements.map((v) => v.Serialize()),
      average: this.average,
      newMeasure: this.newMeasure,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
      this.type = data['type'] || RetroreflectionTypeEnum.Unico;
      this.createdAt = data['createdAt'] ?  data['createdAt'] : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ?  data['updatedAt'] : new Date(2000, 1, 1);
      this.measurements = (data['measurements'] || []).map((v) => new HorizontalSignalingRetroreflectionMeasurement(v));
      this.average = data['average'] || 0;
      this.newMeasure = data['newMeasure'] || false;
    }
    return this;
  }
}

export class HorizontalSignalingRetroreflectionMeasurement implements IRest {
  className = 'Classe de Medida da Retrorrefletorização de Horizontal';

  id: string;
  horizontalSignalingRetroreflectionId: string;
  horizontalSignalingId: string;
  value: number;
  createdAt: Date;
  updatedAt: Date;
  startsAt: number;
  endsAt: number;
  distance: number;
  latitude: number;
  longitude: number;
  valueBelowMinimum: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      horizontalSignalingRetroreflectionId: this.horizontalSignalingRetroreflectionId || undefined,
      value: this.value,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      distance: this.distance,
      latitude: this.latitude,
      longitude: this.longitude,
      valueBelowMinimum: this.valueBelowMinimum,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.horizontalSignalingRetroreflectionId = data['horizontalSignalingRetroreflectionId'] || undefined;
      this.horizontalSignalingId = data['horizontalSignalingId'] || undefined;
      this.value = data['value'] || 0;
      this.createdAt = data['createdAt'] ?  data['createdAt'] : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ?  data['updatedAt'] : new Date(2000, 1, 1);
      this.startsAt = data['startsAt'] || 0;
      this.endsAt = data['endsAt'] || 0;
      this.distance = data['distance'] || 0;
      this.latitude = data['latitude'] || 0;
      this.longitude = data['longitude'] || 0;
      this.valueBelowMinimum = data['valueBelowMinimum'] || false;
    }
    return this;
  }
}

export class TrafficSignRetroreflection implements IRest {
  className = 'Classe da Retrorrefletorização de Vertical';

  id: string;
  trafficSignId: string;
  value: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      trafficSignId: this.trafficSignId || undefined,
      value: this.value,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.trafficSignId = data['trafficSignId'] || undefined;
      this.value = data['value'] || 0;
      this.createdAt = data['createdAt'] ?  data['createdAt'] : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ?  data['updatedAt'] : new Date(2000, 1, 1);
    }
    return this;
  }
}
