<div
  class="container-fluid d-flex align-items-center justify-content-center p-0"
  style="background-color: var(--snow-light); min-height: 100vh"
>
  <div class="col-sm col-md-6 px-4 pt-2 pb-0" *ngIf="!loading">
    <div class="d-flex flex-column align-items-center" *ngIf="environment?.atmanLogo && organization?.sinalesReport">
      <img src="assets/icons/logo-sinales.png" alt="" width="80px" />
      <small class="sinales-text">Sinales - Sinalização Espírito Santo Ltda. CNPJ: 36.377.091/0001-26</small>
      <div class="separate-line"></div>
    </div>
    <div class="row d-flex mx-0 justify-content-between align-items-center">
      <img [src]="'assets/icons/logo-hellius.svg'" alt="" [ngStyle]="{width: environment?.atmanLogo ? '103px' : '250px'}"/>
    </div>
    <h3 class="my-4">
      Relatório Fotográfico de {{ reportData?.systemModule == systemModuleEnum.Task ? 'Tarefas' : 'Ordem de Serviço' }}
    </h3>
    <!-- Dados da organização  -->
    <accordion [isAnimated]="true" [closeOthers]="true">
      <accordion-group
        panelClass="public-acc-header"
        [isOpen]="true"
        (isOpenChange)="!!orgOpen ? (orgOpen = !orgOpen) : (orgOpen = true)"
      >
        <div
          accordion-heading
          class="d-flex align-items-center justify-content-between cursor-pointer mt-3 mx-3 pb-3 mb-0"
          style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
        >
          <div class="d-flex align-items-center">
            <i class="fa-regular fa-file-chart-column mr-2"></i>
            <h4 class="weight-500">ORGANIZAÇÃO</h4>
          </div>
          <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
            <i class="" [ngClass]="orgOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
          </div>
        </div>

        <div class="acc-body p-3">
          <div class="text-group">
            <div class="body-label">Nome da Organização</div>
            <div class="simple-text">
              {{ organization?.name }}
            </div>
          </div>

          <div class="text-group">
            <div class="body-label">CNPJ da Organização</div>
            <div class="simple-text">
              {{ organization?.cnpj | mask: 'XX. XXX.XXX/XXXX-XX' }}
            </div>
          </div>

          <div class="text-group">
            <div class="body-label">Emissor do Relatório</div>
            <div class="simple-text">
              {{
                reportData?.user
                  ? reportData?.user?.firstName + ' ' + reportData?.user?.lastName
                  : 'Emitido pelo sistema'
              }}
            </div>
          </div>

          <div class="text-group">
            <div class="body-label">Data de Emissão</div>
            <div class="simple-text">
              {{ reportData?.issueDate | date: 'dd/MM/yyyy' }}
            </div>
          </div>

          <div class="text-group">
            <div class="body-label">Período de Execução</div>
            <div class="simple-text">
              {{ reportData?.executionDate | date: 'dd/MM/yyyy' }} - {{ reportData?.endDate | date: 'dd/MM/yyyy' }}
            </div>
          </div>

          <div class="text-group">
            <div class="body-label">Tarefas Selecionadas</div>
            <div class="simple-text" *ngFor="let task of tasks">
              {{ task?.name }}
            </div>
            <small class="text-muted" *ngIf="!tasks.length">Não possui tarefas</small>
          </div>
        </div>
      </accordion-group>
    </accordion>
    <div class="separate-line"></div>
    <ng-container *ngFor="let task of tasks; index as indexOfTask">
      <!-- Dados da tarefa -->
      <accordion [isAnimated]="true" [closeOthers]="true">
        <accordion-group
          panelClass="public-acc-header"
          [isOpen]="true"
          (isOpenChange)="!!task['isOpen'] ? (task['isOpen'] = !task['isOpen']) : (task['isOpen'] = true)"
        >
          <div
            accordion-heading
            class="d-flex align-items-center justify-content-between cursor-pointer mt-3 mx-3 pb-3 mb-0"
            style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
          >
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-list-check mr-2"></i>
              <h4 class="weight-500">{{ task?.name }}</h4>
            </div>
            <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
              <i class="" [ngClass]="task['isOpen'] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
            </div>
          </div>

          <div class="acc-body p-3">
            <div class="text-group">
              <div class="body-label">Nome da Tarefa</div>
              <div class="simple-text">
                {{ task?.name }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Tipo de Tarefa</div>
              <div class="simple-text">
                {{ task?.taskType | enumText: 'TaskType' }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Equipe Vinculada</div>
              <div class="simple-text">
                {{ task?.team?.name || 'Sem equipe' }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Veículo Vinculado</div>
              <div class="simple-text">
                {{ task?.vehicle?.licensePlate || 'Sem equipe' }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Período de Execução</div>
              <div class="simple-text">
                {{ task?.executionDate ? (task?.executionDate | date: 'dd/MM/yyyy') : 'Sem Data' }} -
                {{ task?.endDate ? (task?.endDate | date: 'dd/MM/yyyy') : 'Sem Data' }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Status da Tarefa</div>
              <div class="simple-text">
                {{ task?.status | enumText: 'TaskStatus' }}
              </div>
            </div>

            <div class="text-group">
              <div class="body-label">Prioridade da Tarefa</div>
              <div class="simple-text">
                {{ task?.priority | enumText: 'TaskPriority' }}
              </div>
            </div>
          </div>
        </accordion-group>
      </accordion>
      <div class="separate-line"></div>
      <!-- Dados da sinalização -->
      <div class="sign-container mb-4" *ngFor="let sign of task?.reportSigns">
        <div class="d-flex align-items-center">
          <div class="col-auto pr-3 pl-0">
            <img [src]="sign?.signCode | enumSvg: 'Signaling'" alt="sign-img" height="32px" width="32px" style="object-fit: contain;"/>
          </div>
          <div class="col-auto px-0" style="width: 90%">
            <p style="margin: 0; font-weight: 500">{{ sign?.signCode | enumText: 'Signaling' }}</p>
          </div>
        </div>
        <div class="separate-line"></div>
        <accordion [isAnimated]="true">
          <accordion-group
            [isOpen]="true"
            (isOpenChange)="!!sign['isOpen'] ? (sign['isOpen'] = !sign['isOpen']) : (sign['isOpen'] = true)"
          >
            <div
              accordion-heading
              class="d-flex align-items-center justify-content-between cursor-pointer pb-3 mb-0"
              style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
            >
              <h4 class="weight-500">INFORMAÇÕES DA SINALIZAÇÃO</h4>
              <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
                <i class="" [ngClass]="sign['isOpen'] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
              </div>
            </div>

            <div class="acc-body pt-3">
              <div class="text-group">
                <div class="body-label">Data de Cadastramento</div>
                <div class="simple-text">
                  {{ sign?.createdAt | date: 'dd/MM/yyyy' }}
                </div>
              </div>

              <div class="text-group">
                <div class="body-label">Localização da Sinalização</div>
                <div class="simple-text">
                  {{
                    (sign?.address?.road ? sign?.address?.road + ', ' : '') +
                      (sign?.address?.road
                        ? sign?.address?.house_number
                          ? sign?.address?.house_number + '
                                    - '
                          : 's/ nº - '
                        : '') +
                      (sign?.address?.suburb ? sign?.address?.suburb + ', ' : '') +
                      (sign?.address?.city ? sign?.address?.city : '') +
                      (sign?.address?.postcode ? ', ' + sign?.address?.postcode : '') || 'Não localizado'
                  }}
                </div>
              </div>

              <div class="text-group">
                <div class="body-label">Item do Contrato</div>
                <div class="simple-text">
                  {{ sign?.contractSignItem?.name }}
                </div>
              </div>

              <ng-container *ngIf="sign?.supportItem">
                <div class="text-group">
                  <div class="body-label">Item do Suporte</div>
                  <div class="simple-text">
                    {{ sign?.supportItem?.name }}
                  </div>
                </div>

                <div class="text-group">
                  <div class="body-label">Valor do Suporte</div>
                  <div class="simple-text">
                    {{ sign?.supportItem?.unitValue | currency: 'BRL' }}
                  </div>
                </div>
              </ng-container>

              <div class="text-group">
                <div class="body-label">Tipo de Medição</div>
                <div class="simple-text">
                  {{ sign?.dimensionType || '-' }}
                </div>
              </div>
            </div>
          </accordion-group>
          <!-- Memorial de calculo -->
          <accordion-group
            class="mt-3"
            [isOpen]="true"
            (isOpenChange)="
              !!sign['isMemoOpen'] ? (sign['isMemoOpen'] = !sign['isMemoOpen']) : (sign['isMemoOpen'] = true)
            "
          >
            <div
              accordion-heading
              class="d-flex align-items-center justify-content-between cursor-pointer pb-3 mb-0"
              style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
            >
              <h4 class="weight-500">MEMORIAL DE CÁLCULO</h4>
              <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
                <i class="" [ngClass]="sign['isMemoOpen'] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
              </div>
            </div>

            <div class="acc-body" style="max-width: 100%; overflow-x: scroll">
              <table class="table table-striped" style="width: 1000px">
                <thead>
                  <tr>
                    <th class="table-heading">Nº</th>
                    <th class="table-heading">Data de Execução</th>
                    <th class="table-heading">Unidades (und)</th>
                    <th class="table-heading">Altura (m)</th>
                    <th class="table-heading">Largura (m)</th>
                    <th class="table-heading">Área Total (m²)</th>
                    <th class="table-heading">Valor Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!sign?.measurements?.length; else elseMeasurements">
                    <td>1</td>
                    <td>{{ sign?.createdAt | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ sign?.quantity || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimensionY) || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimensionX) || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimension) || '-' }}</td>
                    <td>{{ sign?.cost || 0 | currency: 'BRL' }}</td>
                  </tr>
                  <ng-template #elseMeasurements>
                    <tr *ngFor="let measure of sign?.measurements; index as indexOfMeasure">
                      <td>{{ indexOfMeasure + 1 }}</td>
                      <td>{{ measure?.createdAt | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ measure?.units || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimensionY) || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimensionX) || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimension) || '-' }}</td>
                      <td>{{ (measure?.dimension || 0) * sign?.contractSignItem.unitValue | currency: 'BRL' }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="weight-500 text-end">Total Executado:</td>
                      <td class="weight-500">{{ sign?.totalDimension || '-' }}</td>
                      <td class="weight-500">{{ sign?.totalCost || 0 | currency: 'BRL' }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </accordion-group>
        </accordion>
        <div class="separate-line"></div>
        <!-- Foto do local -->
        <h4 class="text-muted my-3">FOTO DO LOCAL</h4>
        <div class="row mx-0">
          <ng-container
            *ngFor="
              let image of sign?.verticalSignalingFiles || sign?.horizontalSignalingFiles || sign?.deviceSignalingFiles
            "
          >
            <div class="col-sm col-md-6" *ngIf="image?.situation == 'AFTER'">
              <img [src]="image.url" alt="sign-img" class="mb-2" width="100%" />
            </div>
          </ng-container>
        </div>
        <h4 class="text-muted my-3">FOTO DA SINALIZAÇÃO</h4>
        <div class="row mx-0">
          <ng-container
            *ngFor="
              let image of sign?.verticalSignalingFiles || sign?.horizontalSignalingFiles || sign?.deviceSignalingFiles
            "
          >
            <div class="col-sm col-md-6" *ngIf="image?.situation == 'BEFORE'">
              <img [src]="image.url" alt="sign-img" class="mb-2" width="100%" />
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <h3 *ngIf="noTaskSigns.vertical.length || noTaskSigns.horizontal.length || noTaskSigns.device.length" class="my-4">
      Sinalizações Sem Tarefa Vinculada
    </h3>
    <!-- Dados da sinalização -->
    <ng-container *ngFor="let signKey of noTaskSigns | keyvalue: originalOrder">
      <div class="sign-container mb-4" *ngFor="let sign of signKey?.value">
        <div class="d-flex align-items-center">
          <div class="col-auto pr-3 pl-0">
            <img [src]="sign?.signCode | enumSvg: 'Signaling'" alt="sign-img" height="32px" width="32px" style="object-fit: contain;" />
          </div>
          <div class="col-auto px-0" style="width: 90%">
            <p style="margin: 0; font-weight: 500">{{ sign?.signCode | enumText: 'Signaling' }}</p>
          </div>
        </div>
        <div class="separate-line"></div>
        <accordion [isAnimated]="true">
          <accordion-group
            [isOpen]="true"
            (isOpenChange)="!!sign['isOpen'] ? (sign['isOpen'] = !sign['isOpen']) : (sign['isOpen'] = true)"
          >
            <div
              accordion-heading
              class="d-flex align-items-center justify-content-between cursor-pointer pb-3 mb-0"
              style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
            >
              <h4 class="weight-500">INFORMAÇÕES DA SINALIZAÇÃO</h4>
              <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
                <i class="" [ngClass]="sign['isOpen'] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
              </div>
            </div>

            <div class="acc-body pt-3">
              <div class="text-group">
                <div class="body-label">Data de Cadastramento</div>
                <div class="simple-text">
                  {{ sign?.createdAt | date: 'dd/MM/yyyy' }}
                </div>
              </div>

              <div class="text-group">
                <div class="body-label">Localização da Sinalização</div>
                <div class="simple-text">
                  {{
                    (sign?.address?.road ? sign?.address?.road + ', ' : '') +
                      (sign?.address?.road
                        ? sign?.address?.house_number
                          ? sign?.address?.house_number + '
                                    - '
                          : 's/ nº - '
                        : '') +
                      (sign?.address?.suburb ? sign?.address?.suburb + ', ' : '') +
                      (sign?.address?.city ? sign?.address?.city : '') +
                      (sign?.address?.postcode ? ', ' + sign?.address?.postcode : '') || 'Não localizado'
                  }}
                </div>
              </div>

              <div class="text-group">
                <div class="body-label">Item do Contrato</div>
                <div class="simple-text">
                  {{ sign?.contractSignItem?.name }}
                </div>
              </div>

              <ng-container *ngIf="sign?.supportItem">
                <div class="text-group">
                  <div class="body-label">Item do Suporte</div>
                  <div class="simple-text">
                    {{ sign?.supportItem?.name }}
                  </div>
                </div>

                <div class="text-group">
                  <div class="body-label">Valor do Suporte</div>
                  <div class="simple-text">
                    {{ sign?.supportItem?.unitValue | currency: 'BRL' }}
                  </div>
                </div>
              </ng-container>

              <div class="text-group">
                <div class="body-label">Tipo de Medição</div>
                <div class="simple-text">
                  {{ sign?.dimensionType || '-' }}
                </div>
              </div>
            </div>
          </accordion-group>
          <!-- Memorial de calculo -->
          <accordion-group
            class="mt-3"
            [isOpen]="true"
            (isOpenChange)="
              !!sign['isMemoOpen'] ? (sign['isMemoOpen'] = !sign['isMemoOpen']) : (sign['isMemoOpen'] = true)
            "
          >
            <div
              accordion-heading
              class="d-flex align-items-center justify-content-between cursor-pointer pb-3 mb-0"
              style="border-bottom: 1px solid rgba(206, 206, 206, 0.329)"
            >
              <h4 class="weight-500">MEMORIAL DE CÁLCULO</h4>
              <div class="col-auto px-0" style="display: flex; justify-content: flex-end">
                <i class="" [ngClass]="sign['isMemoOpen'] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
              </div>
            </div>

            <div class="acc-body" style="max-width: 100%; overflow-x: scroll">
              <table class="table table-striped" style="width: 1000px">
                <thead>
                  <tr>
                    <th class="table-heading">Nº</th>
                    <th class="table-heading">Data de Execução</th>
                    <th class="table-heading">Unidades (und)</th>
                    <th class="table-heading">Altura (m)</th>
                    <th class="table-heading">Largura (m)</th>
                    <th class="table-heading">Área Total (m²)</th>
                    <th class="table-heading">Valor Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!sign?.measurements?.length; else elseMeasurements">
                    <td>1</td>
                    <td>{{ sign?.createdAt | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ sign?.quantity || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimensionY) || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimensionX) || '-' }}</td>
                    <td>{{ formatNumber(sign?.dimension) || '-' }}</td>
                    <td>{{ sign?.cost || 0 | currency: 'BRL' }}</td>
                  </tr>
                  <ng-template #elseMeasurements>
                    <tr *ngFor="let measure of sign?.measurements; index as indexOfMeasure">
                      <td>{{ indexOfMeasure + 1 }}</td>
                      <td>{{ measure?.createdAt | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ measure?.units || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimensionY) || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimensionX) || '-' }}</td>
                      <td>{{ formatNumber(measure?.dimension) || '-' }}</td>
                      <td>{{ (measure?.dimension || 0) * sign?.contractSignItem.unitValue | currency: 'BRL' }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="weight-500 text-end">Total Executado:</td>
                      <td class="weight-500">{{ sign?.totalDimension || '-' }}</td>
                      <td class="weight-500">{{ sign?.totalCost || 0 | currency: 'BRL' }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </accordion-group>
        </accordion>
        <div class="separate-line"></div>
        <!-- Foto do local -->
        <h4 class="text-muted my-3">FOTO DO LOCAL</h4>
        <div class="row mx-0">
          <ng-container
            *ngFor="
              let image of sign?.verticalSignalingFiles || sign?.horizontalSignalingFiles || sign?.deviceSignalingFiles
            "
          >
            <div class="col-sm col-md-6" *ngIf="image?.situation == 'AFTER'">
              <img [src]="image.url" alt="sign-img" class="mb-2" width="100%" />
            </div>
          </ng-container>
        </div>
        <h4 class="text-muted my-3">FOTO DA SINALIZAÇÃO</h4>
        <div class="row mx-0">
          <ng-container
            *ngFor="
              let image of sign?.verticalSignalingFiles || sign?.horizontalSignalingFiles || sign?.deviceSignalingFiles
            "
          >
            <div class="col-sm col-md-6" *ngIf="image?.situation == 'BEFORE'">
              <img [src]="image.url" alt="sign-img" class="mb-2" width="100%" />
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- Footer -->
    <div class="row d-flex mx-0 my-3 mt-5 justify-content-between align-items-center">
      <img [src]="'assets/icons/logo-hellius.svg'" alt="" [ngStyle]="{width: environment.atmanLogo ? '103px' : '250px'}" />
      <img *ngIf="environment.atmanLogo" [src]="'assets/icons/logo-atman.svg'" alt="" width="90px" />
    </div>
    <div class="text-group" *ngIf="environment.atmanLogo">
      <div class="body-label">Desenvolvido por</div>
      <div class="simple-text">Atman Systems®</div>
    </div>
    <div class="text-group">
      <div class="body-label">Código de Identificação</div>
      <div class="simple-text">
        {{ reportId }}
      </div>
    </div>
    <div class="text-group">
      <div class="body-label">Origem do Relatório</div>
      <div class="simple-text text-uppercase">{{ environment.softwareName }} FULL</div>
      <div class="simple-text">
        Este relatório foi gerado no {{ environment.softwareName }} e pode,
        ou não, corresponder a valores ou números que condizem com a realidade.
      </div>
    </div>
  </div>
  <app-loading-page *ngIf="loading"></app-loading-page>
</div>
