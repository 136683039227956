export enum HorizontalSignalingEnum {
  LFO1 = 'LFO-1',
  LFO2 = 'LFO-2',
  LFO3 = 'LFO-3',
  LFO4 = 'LFO-4',
  LMS1 = 'LMS-1',
  LMS2 = 'LMS-2',
  LBO = 'LBO',
  LCO = 'LCO',
  MFE = 'MFE',
  MFP = 'MFP',
  MFR = 'MFR',
  MCI = 'MCI',
  LRE = 'LRE',
  LRV = 'LRV',
  LDP = 'LDP',
  FTP1 = 'FTP-1',
  FTP2 = 'FTP-2',
  MCC = 'MCC',
  MAC = 'MAC',
  MAE = 'MAE',
  MCF = 'MCF',
  LCA = 'LCA',
  ZPA_MAN = 'ZPA-MAN',
  ZPA_MCB = 'ZPA-MCB',
  ZPA_MAO = 'ZPA-MAO',
  ZPA_MTL = 'ZPA-MTL',
  ZPA_MAC = 'ZPA-MAC',
  ZPA_MIR = 'ZPA-MIR',
  LPP = 'LPP',
  MVE = 'MVE',
  MER = 'MER',
  PEM = 'PEM',
  MOF = 'MOF',
  IMC = 'IMC',
  SIP = 'SIP',
  SIF = 'SIF',
  SIC = 'SIC',
  SAS = 'SAS',
  DEF = 'DEF',
  IPL_LVR = 'IPL-LVR',
  IPL_ESC = 'IPL-ESC',
  IPL_IND = 'IPL-IND',
  IPL_PAR = 'IPL-PAR',
  IPL_MCD = 'IPL-MCD',
  HorizontalPersonalizada = 'HORIZONTAL_PERSONALIZADA'
}

export enum LongitudinalHorizontalSignalingEnum {
  LFO1 = 'LFO-1',
  LFO2 = 'LFO-2',
  LFO3 = 'LFO-3',
  LFO4 = 'LFO-4',
  LMS1 = 'LMS-1',
  LMS2 = 'LMS-2',
  LBO = 'LBO',
  LCO = 'LCO',
  MFE = 'MFE',
  MFP = 'MFP',
  MFR = 'MFR',
  MCI = 'MCI',
}

export enum TransversalsHorizontalSignalingEnum {
  LRE = 'LRE',
  LRV = 'LRV',
  LDP = 'LDP',
  FTP1 = 'FTP-1',
  FTP2 = 'FTP-2',
  MCC = 'MCC',
  MAC = 'MAC',
  MAE = 'MAE',
  MCF = 'MCF',
  ONDULACAO_TRANSVERSAL_PINTURA = 'ONDULACAO_TRANSVERSAL_PINTURA'
}

export enum PlumbingHorizontalSignalingEnum {
  LCA = 'LCA',
  ROTATORIA = 'ROTATORIA',
  ZPA_MAN = 'ZPA-MAN',
  ZPA_MCB = 'ZPA-MCB',
  ZPA_MAO = 'ZPA-MAO',
  ZPA_MTL = 'ZPA-MTL',
  ZPA_MAC = 'ZPA-MAC',
  ZPA_MIR = 'ZPA-MIR',
}

export enum DelimitationHorizontalSignalingEnum {
  LPP = 'LPP',
  MVE = 'MVE',
  MER = 'MER',
}

export enum PavementHorizontalSignalingEnum {
  PEM = 'PEM',
  MOF = 'MOF',
  IMC = 'IMC',
  SIP = 'SIP',
  SIF = 'SIF',
  SIC = 'SIC',
  SAS = 'SAS',
  DEF = 'DEF',
  IPL_LVR = 'IPL-LVR',
  IPL_ESC = 'IPL-ESC',
  IPL_IND = 'IPL-IND',
  IPL_PAR = 'IPL-PAR',
  IPL_MCD = 'IPL-MCD',
}

export enum PositioningArrowsEnum {
  PEM_RIGHT = 'PEM_DIREITA',
  PEM_LEFT = 'PEM_ESQUERDA',
  PEM_RETURN_RIGHT = 'PEM_RETORNO_DIREITA',
  PEM_RETURN_LEFT = 'PEM_RETORNO_ESQUERDA',
  PEM_KEEP = 'PEM_SIGA',
  PEM_SIGA_DIREITA = 'PEM_SIGA_DIREITA',
  PEM_SIGA_ESQUERDA = 'PEM_SIGA_ESQUERDA',
  PEM_SIGA_PEDESTRE = 'PEM_SIGA_PEDESTRE',
}

export enum SpecialHorizontalEnum {
  HorizontalPersonalizada = 'HORIZONTAL_PERSONALIZADA'
}

export enum MarkingTypeEnum {
  longitudinal = 'LONGITUDINAL_LINES',
  transversal = 'TRANSVERSAL_LINES',
  channeling = 'CHANNELING_MARKS',
  delimitation = 'DELIMITATION_MARKS',
  inscriptions = 'INSCRIPTIONS_ON_THE_FLOOR',
  positioningArrows = 'POSITIONING_ARROWS',
  special = 'SPECIAL',
}

export enum CadenceHorizontalSignaling {
  LFO2 = 'LFO-2',
  LFO4 = 'LFO-4',
  LMS2 = 'LMS-2',
  LCO = 'LCO',
  MFR = 'MFR'
}

export enum ContinuosHorizontalSignaling {
  LFO1 = 'LFO-1',
  LFO3 = 'LFO-3',
  LMS1 = 'LMS-1',
  LBO = 'LBO',
  MFE = 'MFE'
}
