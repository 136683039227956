import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Vehicle } from 'src/models';
import { AuthService } from '.';
import { RestApiService } from './rest-api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends RestApiService {
  // Variáveis

  host = environment.endpoint();

  private _urlVehicles = '/vehicles';
  private _urlRemoveCollectionVehicles = '/vehicles/remove-collection';
  private _urlVehicle = '/vehicles/:vehicleId';
  private _urlVehicleImage = '/vehicles/:vehicleId/image';
  private _urlVehicleImages = '/vehicles/:vehicleId/files';
  private _urlRemoveImageFile = '/vehicles/files/:fileId';

  listVehicles$: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]); // Lista de veículos do workspace

  constructor(public http: HttpClient, private _auth: AuthService) {
    super(http);
  }

  // Obter Projetos
  getVehicles() {
    const url = this._urlVehicles;
    return this.get(url, Vehicle);
  }

  // Obter Projeto
  getVehicle(vehicleId: string) {
    const url = this._urlVehicle.replace(':vehicleId', vehicleId);
    return this.get(url, Vehicle);
  }

  createVehicle(vehicle: Vehicle) {
    const url = this._urlVehicles;
    return this.post(url, vehicle, Vehicle);
  }

  removeVehicle(vehicleId: string) {
    const url = this._urlVehicle.replace(':vehicleId', vehicleId);
    return this.del(url, Vehicle);
  }

  removeCollectionVehicle(vehicles) {
    const vehiclesIds = vehicles.map(vehicle => vehicle.id);
    let idsJson = JSON.stringify(vehiclesIds);
    const url = this._urlRemoveCollectionVehicles + `?ids=${idsJson}`;
    return this.del(url, Vehicle);
  }

  updateVehicle(vehicle: Vehicle) {
    const url = this._urlVehicle.replace(':vehicleId', vehicle.id);
    return this.put(url, vehicle, Vehicle);
  }

  uploadImages(files, vehicleId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    const url = this.host + this._urlVehicleImages.replace(':vehicleId', vehicleId);
    return this.http.post(url, formData, { headers: reqHeaders });
  }

  removeImageList(fileId) { // Remove uma por vez
    const url = this.host + this._urlRemoveImageFile.replace(':fileId', fileId);
    return this.http.delete(url);
  }

  uploadMainImage(files, vehicleId: string) { // Sempre será só uma
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    const url = this.host + this._urlVehicleImage.replace(':vehicleId', vehicleId);
    return this.http.post(url, formData, { headers: reqHeaders });
  }
}
