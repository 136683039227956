import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComplementInformationEnum, VerticalSignalingEnum, VerticalSignalizationTypeEnum } from 'src/enumerators/vertical-signaling.enum';

@Component({
  selector: 'complement-information-component',
  templateUrl: './complement-information.component.html',
  styleUrls: ['./complement-information.component.css']
})
export class ComplementInformationComponent implements OnInit, OnChanges {

  complementInformationEnum = ComplementInformationEnum // para verificar estado do radio button
  verticalTypeEnum = VerticalSignalizationTypeEnum;
  verticalSignEnum = VerticalSignalingEnum;

  @Input() displayDirection: 'column' | 'row' = 'row'; // Orientação dos itens para aplicar em Tarefa e Cadastro
  @Input() complementInformationState: { type: ComplementInformationEnum, value: string, enableComplementInformation: boolean } = { type: null, value: null, enableComplementInformation: false } // Seta valor inicial e completa os dados quando na edição;
  @Input() switchId = '';
  @Input() verticalType: VerticalSignalizationTypeEnum;
  @Output() complementInformationEmmitter = new EventEmitter<{ type: ComplementInformationEnum, value: string, enableComplementInformation: boolean }>(); // Emite os valores para o componente pai;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.verticalType == VerticalSignalizationTypeEnum.Indication) {
      this.complementInformationState = { type: null, value: null, enableComplementInformation: false };
      this.complementInformationEmmitter.emit(this.complementInformationState);
    }
  }

  // Emite os valores para o componente pai
  emitComplementInformation() {
    if (!this.complementInformationState.enableComplementInformation)
      this.complementInformationState = { type: null, value: null, enableComplementInformation: false };
    this.complementInformationEmmitter.emit(this.complementInformationState);
  }
}
