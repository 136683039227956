export enum SystemModuleEnum {
  Contract = 'CONTRACT',
  Project = 'PROJECT',
  SignalingRegister = 'SIGNALING_REGISTER',
  Region = 'REGION',
  Area = 'AREA',
  Warranty = 'WARRANTY',
  Vehicle = 'VEHICLE',
  Team = 'TEAM',
  Task = 'TASK',
  Pendency = 'PENDENCY',
  ServiceOrder = 'SERVICE_ORDER',
  Path = 'PATH',
  CalculationMemory = 'CALCULATION_MEMORY',
  Catalog = 'CATALOG',
  Productivity = 'PRODUCTIVITY',
  Routes = 'ROUTE',
  Measurement = 'MEASUREMENT',
  Stock = 'STOCK',
}

export enum ChartSystemModulesEnum {
  Project = 'PROJECT',
  Pendency = 'PENDENCY',
  Task = 'TASK',
  ServiceOrder = 'SERVICE_ORDER',
  Team = 'TEAM',
  Catalog = 'CATALOG'
}

export enum MemberRemovalRequestJustificationEnum {
  NotMember = 'NOT_A_MEMBER',
  Resignation = 'RESIGNATION',
  Other = 'OTHER',
};
