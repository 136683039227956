import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskStatusEnum, TodayTaskEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { Task, Team, TeamAvailability, TeamMember, TeamTaskQuantity } from 'src/models';
import { RestApiService } from './rest-api.service';
import { BehaviorSubject } from 'rxjs';
import { TeamMemberCheckIn } from 'src/models/team-member';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends RestApiService {
  // Variáveis

  private _urlTeams = '/teams';
  private _urlTeam = '/teams/:teamId';
  private _urlTeamAvailability = '/teams/availability';
  private _urlRemoveCollectionTeams = '/teams/remove-collection';
  private _urlWorkspacesAvailabilities = '/teams/workspaces-availability?executionDate=:execDate&endDate=:endDate';
  private _urlTasksQuantity = '/teams/tasks-quantity';
  private _urlGetTeamsTasks = '/teams/:teamId/tasks';
  private _urlGetTeamsLocation = '/teams/location';
  private _urlTeamMembers = '/teams/members';
  private _urlTeamMember = '/teams/members/:memberId';
  private _urlRemoveCollectionTeamMember = '/teams/members/remove-collection';
  private _urlActiveCheckInTeamMembers = '/teams/:teamId/members/active-check-ins'

  private _urlForceCheckout = '/admin/teams/:id/check-out'
  environmentEndpoint = environment.endpoint();

  teams$: BehaviorSubject<Array<Team>>= new BehaviorSubject(null);
  teamMembers$: BehaviorSubject<Array<TeamMember>>= new BehaviorSubject(null);

  constructor(public http: HttpClient) {
    super(http);
  }

  // Obter Times
  getTeams() {
    const url = this._urlTeams;
    return this.get(url, Team);
  }

  // Obter time
  getTeam(teamId: string) {
    const url = this._urlTeam.replace(':teamId', teamId);
    return this.get(url, Team);
  }

  // Obter disponibilidade da equipe
  getWorkspaceAvailabilities(executionDate: Date, endDate: Date) {
    const url = this._urlWorkspacesAvailabilities
      .replace(':execDate', new Date(executionDate).toISOString())
      .replace(':endDate', new Date(endDate).toISOString());
    return this.get(url, TeamAvailability);
  }

  // Obter quantidade de tarefas por equipe
  getTasksQuantity(executionDate: Date, endDate: Date) {
    const queryParams = new HttpParams().appendAll({
      executionDate: new Date(executionDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      status: JSON.stringify([TaskStatusEnum.Paused, TaskStatusEnum.Started, TaskStatusEnum.ToStart]),
    });

    const url = this.environmentEndpoint + this._urlTasksQuantity;
    return this.http.get(url, { params: queryParams });
  }

  getTeamAvailability(date?: Date) {
    let url = this._urlTeamAvailability;
    if (date) url += `?executionDate=${date.toISOString()}`;
    return this.get(url, Team);
  }

  createTeam(team: Team) {
    const url = this._urlTeams;
    return this.post(url, team, Team);
  }

  removeTeam(teamId: string) {
    const url = this._urlTeam.replace(':teamId', teamId);
    return this.del(url, Team);
  }

  removeCollectionTeam(teams) {
    const teamsIds = teams.map((team) => team.id);

    let idsJson = JSON.stringify(teamsIds);
    const url = this._urlRemoveCollectionTeams + `?ids=${idsJson}`;
    return this.del(url, Team);
  }

  updateTeam(team: Team) {
    const url = this._urlTeam.replace(':teamId', team.id);
    return this.put(url, team, Team);
  }

  getTeamFilteredAvailability(filterOptions: any) {
    const params = new URLSearchParams({
      teamIds: Array.from(filterOptions.team.values).length
        ? JSON.stringify(Array.from(filterOptions.team.values))
        : null,
      contractIds: Array.from(filterOptions.contract.values).length
        ? JSON.stringify(Array.from(filterOptions.contract.values))
        : null,
      taskTypes: Array.from(filterOptions.taskType.values).length
        ? JSON.stringify(Array.from(filterOptions.taskType.values))
        : null,
      statuses: Array.from(filterOptions.taskStatus.values).length
        ? JSON.stringify(Array.from(filterOptions.taskStatus.values))
        : null,
      priorities: Array.from(filterOptions.priority.values).length
        ? JSON.stringify(Array.from(filterOptions.priority.values))
        : null,
      teamTypes: Array.from(filterOptions.teamType.values).length
        ? JSON.stringify(Array.from(filterOptions.teamType.values))
        : null,
    });
    const url = this._urlTeamAvailability + '?' + params;
    return this.get(url, Team);
  }

  getTeamVehicles() {
    const url = this.environmentEndpoint + this._urlGetTeamsLocation;
    return this.http.get(url);
  }

  getTeamTasks(teamId, queryList: Array<TodayTaskEnum>) {
    let url = this._urlGetTeamsTasks.replace(':teamId', teamId);
    const ot = queryList.includes(TodayTaskEnum.OfToday) ? 'ofToday=true&' : '';
    const ls = queryList.includes(TodayTaskEnum.LateToStart) ? 'lateToStart=true&' : '';
    const lf = queryList.includes(TodayTaskEnum.LateToFinish) ? 'lateToFinish=true&' : '';
    const ft = queryList.includes(TodayTaskEnum.FinishedToday) ? 'finishedToday=true&' : '';
    url += '?' + ot + ls + lf + ft;
    return this.get(url, Task);
  }

  //Membros de equipe 
  createTeamMember(member: TeamMember) {
    const url = this._urlTeamMembers
    return this.post(url, member, TeamMember);
  }

  getTeamMembers() {
    const url = this._urlTeamMembers
    return this.get(url, TeamMember);
  }

  updateTeamMember(member: TeamMember) {
    const url = this._urlTeamMember.replace(':memberId', member.id);
    return this.put(url, member, TeamMember);
  }

  removeTeamMember(member: TeamMember) {
    const url = this._urlTeamMember.replace(':memberId', member.id);
    return this.del(url, TeamMember);
  }

  removeTeamMemberCollection(teamMemberList: TeamMember[]) {
    const teamsIds = teamMemberList.map((team) => team.id);
    let idsJson = JSON.stringify(teamsIds);

    const url = this._urlRemoveCollectionTeamMember + `?ids=${idsJson}`;
    return this.del(url, TeamMember);
  }

  getActiveCheckInTeamMembers(team: Team) {
    const url = this._urlActiveCheckInTeamMembers.replace(':teamId', team.id);
    return this.get(url, TeamMember);
  }

  getTeamMemberInitials(member: TeamMember) {
    const nameParts = member.name.split(' ');
    let initials = '';

    initials = nameParts[0].charAt(0)
    
    if(nameParts.length > 1)
      initials += nameParts[nameParts.length-1].charAt(0);

    return initials;
  }

  forceCheckout(team: Team) {
    const url = this.environmentEndpoint + this._urlForceCheckout.replace(':id', team.id);
    return this.http.patch(url, {});
  }
}
