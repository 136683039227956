import { IRest } from "./rest.interface";
import { Team } from "./team";

export class TeamMember implements IRest {
  className: string = 'Membro de equipe'

  id: string;
  name: string;
  teams: Team[];
  teamsIds: string[];
  position: string;
  phone: string;
  workspaceId: string;
  check: boolean; //uso apenas no front

  constructor(data?) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      teams: (this.teams || []).map(t => t.Serialize()),
      teamsIds: this.teamsIds,
      position: this.position,
      phone: this.phone,
      workspaceId: this.workspaceId,
    };
    return out;
  }

  Deserialize(data: any) {
    if(data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.teams = (data['teams'] || []).map(t => new Team(t));
      this.teamsIds = data['teamsIds'] || [];
      this.phone = data['phone'] || '';
      this.position = data['position'] || '';
      this.workspaceId = data['workspaceId'] || '';
      this.check = false;
    }
    return this;
  }
}

export class TeamMemberCheckIn implements IRest {
  className: string = 'Check in do Membro de equipe'

  id: string;
  memberId: string;
  latitude: number;
  longitude: number;
  workspaceId: string;

  constructor(data?) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      memberId: this.memberId,
      latitude: this.latitude,
      longitude: this.longitude,
      workspaceId: this.workspaceId,
    };
    return out;
  }

  Deserialize(data: any) {
    if(data) {
      this.id = data['id'] || undefined;
      this.memberId = data['memberId'] || '';
      this.latitude = data['latitude'] || 0;
      this.longitude = data['longitude'] || 0;
      this.workspaceId = data['workspaceId'] || '';
    }
    return this;
  }
}
