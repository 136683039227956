import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContractService } from 'src/app/services/contract.service';
import { ProjectService } from 'src/app/services/project.service';
import { ServiceOrderService } from 'src/app/services/service-order.service';
import { TaskService } from 'src/app/services/task.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent implements OnInit {
  uploadedFiles = [];
  isFileOpen: { state: boolean; url: any; isDWG: boolean } = { state: false, url: '', isDWG: false };
  @Input() editFiles: [];
  @Input() type: string;
  @Output() shareFiles = new EventEmitter<any>();
  @Output() deleteFiles = new EventEmitter<any>();
  @Input() editMode: boolean = false;
  @Input() isProject: boolean = false
  saveFiles: boolean = false;

  constructor(
    private _sanitizer: DomSanitizer,
    private _contractService: ContractService,
    private _serviceOrderService: ServiceOrderService,
    private _projectService: ProjectService,
    private _toastService: ToastService,
    private _taskService: TaskService,
  ) { }

  ngOnInit(): void {
    if (this.editFiles) {
      this.uploadedFiles = this.editFiles;
    }
  }

  uploadFile(file) {
    let objectURL = this._sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file[0]));
    this.uploadedFiles.push({ file: file[0], url: objectURL });
    this.shareFiles.emit(this.uploadedFiles);
  }

  removeFile(fileName: string) {
    this.uploadedFiles.forEach((upFile, index) => {
      let uploadedFileName = upFile.file.originalName ?? upFile.file.name;
      if (uploadedFileName == fileName) {
        if (this.type == 'project' && !upFile.url) {
          this._projectService.deleteProjectFile(upFile.id).subscribe(
            (next) => this.uploadedFiles.splice(index, 1),
            (error) => {
              this._toastService.showError('Ocorreu um erro ao deletar o arquivo.');
            },
          );
        } else if (this.type == 'contract' && !upFile.url) {
          this.deleteFiles.emit(upFile);
          this.uploadedFiles.splice(index, 1);

        } else if (this.type == 'serviceOrder' && !upFile.url) {
          this._serviceOrderService.deleteServiceOrderFile(upFile.id).subscribe(
            (next) => this.uploadedFiles.splice(index, 1),
            (error) => {
              this._toastService.showError('Ocorreu um erro ao deletar o arquivo.');
            },
          );
        } else if (this.type == 'task' && !upFile.url) {
          this._taskService.deleteTaskFile(upFile.id).subscribe(
            (next) => this.uploadedFiles.splice(index, 1),
            (error) => {
              this._toastService.showError('Ocorreu um erro ao deletar o arquivo.');
            },
          );
        } else {
          this.uploadedFiles.splice(index, 1);
        }
      }
    });
    this.isFileOpen.state = false;
    this.shareFiles.emit(this.uploadedFiles);
  }

  handleFileSelection(file: FileList) {
    let objectURL = this._sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file[0]));
    this.uploadedFiles.push({ file: file[0], url: objectURL });
    this.shareFiles.emit(this.uploadedFiles);
  }

  openFile(file) {
    if (file.fileId) {
      if (this.type == 'serviceOrder') {
        this._serviceOrderService.downloadServiceOrderFile(file.id).subscribe(
          (next) => window.open(next.url),
          (error) => {
            this._toastService.showError('Não foi possível fazer o download do arquivo.');
          },
        );
      } else if (this.type == 'contract') {
        this._contractService.downloadFile(file.id).subscribe(
          (next) => window.open(next.url),
          (error) => {
            this._toastService.showError('Não foi possível fazer o download do arquivo.');
          },
        );
      } else if (this.type == 'task') {
        this._taskService.downloadTaskFile(file.id).subscribe(
          (next) => window.open(next.url),
          (error) => {
            this._toastService.showError('Não foi possível fazer o download do arquivo.');
          },
        );
      } else if (this.type == 'project') {
        this._projectService.downloadProjectFile(file.id).subscribe(
          (res) => {
            window.open(res.url);
          },
          (error) => {
            this._toastService.showError('Não foi possível fazer o download do arquivo.');
          },
        );
      }
    } else {
      window.open(file.url.changingThisBreaksApplicationSecurity);
    }
  }

  cancelUpload() {
    this.uploadedFiles = [];
    this.shareFiles.emit(this.uploadedFiles);
  }

  downloadFile(fileId: string) {
    if (this.type == 'project') {
      this._projectService.downloadProjectFile(fileId).subscribe((res) => window.open(res.url));
    } else {
      this._contractService.downloadFile(fileId).subscribe((res) => window.open(res.url));
    }
  }

  setMasterFile(indexOfFile) {
    this.uploadedFiles.forEach((file, index) => {
      if (index != indexOfFile) file.masterFile = false
      else file.masterFile = true
    })
  }
}
