export enum UserDutyEnum {
  Master = 'MASTER',
  Owner = 'OWNER',
  Admin = 'ADMINISTRATOR',
  Participant = 'PARTICIPANT',
}

export enum UserGenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  Another = 'ANOTHER',
}

export enum MemberRoleEnum {
  Manager = 'MANAGER',
  Operator = 'OPERATOR',
  TeamLeader = 'TEAM_LEADER'
}
