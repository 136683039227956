export enum DirectionEnum {
  East = 'EAST',
  West = 'WEST',
  North = 'NORTH',
  South = 'SOUTH',
};

export enum RetroStatusEnum {
  Bad = 'BAD',
  Regular = 'REGULAR',
  Good = 'GOOD',
  Excellent = 'EXCELLENT',
  Default = 'DEFAULT'
}