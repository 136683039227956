import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';


@Directive({
  selector: '[uploadFile]'
})
export class UploadFileDirective {

  @Output() onFileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') public background = '';
  @HostBinding('style.opacity') public opacity = '1';

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#52525240';
    this.opacity = '1'
  };

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#F5F5F5'
    this.opacity = '1'
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#F5F5F5'
    this.opacity = '1'
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }


  constructor() { }

}
