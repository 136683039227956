import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceSignaling, HorizontalSignaling, Path, PathMarker, VerticalSignaling } from 'src/models';
import { Lane, MeasuringStation, MeasuringStationMeasurement, PathData, PathQuantity, PathRetroreflectorization, Route, Segment } from 'src/models/path';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class PathService extends RestApiService {
  private _endpointVariable = environment.endpoint();
  private _urlPaths = '/paths';
  private _urlPathsData = '/paths/data';
  private _urlPathId = '/paths/:id';
  private _urlPathDataId = '/paths/:id/data';
  private _urlMarkers = '/markers';
  private _urlMarkerId = '/markers?pathId=:id';
  private _urlGetVerticalPath = '/vertical-signalings?pathId=';
  private _urlGetHorizontalPath = '/horizontal-signalings?pathId=';
  private _urlGetDevicePath = '/device-signalings?pathId=';
  private _urlPathLaneId = '/paths/:id/demarcation-lanes';
  private _urlPathSegments = '/paths/:id/segments';
  private _urlPathSegmentsMeasuringStation = '/paths/:id/segments/measuring-stations';
  private _urlSegments = '/segments';
  private _urlSegmentsId = '/segments/:id';
  private _urlSegmentsMeasuringStation = '/segments/:id/measuring-stations';
  private _urlSegmentsMeasuringStationAverage = '/segments/:id/measuring-stations/average';
  private _urlMeasurements = '/measuring-station-measurements';
  private _urlMeasurementsId = '/measuring-station-measurements/:id';
  private _urlMeasurementsHistory = '/measuring-station-measurements/:id/history';
  private _urlRoutes = '/routes';
  private _urlRoutesId = '/routes/:id';
  private _urlPathsRetro = '/paths/:id/retroreflectorization';
  private _urlPathsRetroId = '/paths/retroreflectorizations/:id';
  private _urlPathsQuantity = '/paths/quantity';

  constructor(public http: HttpClient) {
    super(http);
  }

  // Retorna os marcadores
  getMarkers() {
    return this.get(this._urlMarkers, PathMarker);
  }

  // Retorna as faixas do trajeto
  getPathLanes(pathId: string) {
    return this.get(this._urlPathLaneId.replace(':id', pathId), Lane);
  }

  // Retorna os trechos do trajeto
  getPathSegments(pathId: string) {
    return this.get(this._urlPathSegments.replace(':id', pathId), Segment);
  }

  // Retorna os trechos do trajeto
  getPathSegmentsMeasuringStation(pathId: string) {
    return this.get(this._urlPathSegmentsMeasuringStation.replace(':id', pathId), MeasuringStation);
  }

  // Retorna as faixas do trajeto
  createPathLanes(pathId: string, pathLane: Lane[]) {
    let url = this._endpointVariable + this._urlPathLaneId.replace(':id', pathId);
    return this.http.post(url, pathLane);
  }

  // Retorna as faixas do trajeto
  putPathLanes(pathId: string, pathLane: Lane[]) {
    let url = this._endpointVariable + this._urlPathLaneId.replace(':id', pathId);
    return this.http.put(url, pathLane);
  }

  // POST Cria trecho
  createSegment(segment: Segment) {
    return this.post(this._urlSegments, segment, Segment);
  }

  // PUT Atualiza o trecho
  updateSegment(segment: Segment) {
    return this.put(this._urlSegmentsId.replace(':id', segment.id), segment, Segment);
  }

  // GET recebe trechos do trajeto
  getSegments() {
    return this.get(this._urlSegments, Segment);
  }

  // GET recebe trechos do trajeto
  deleteSegment(segmentId: string) {
    return this.del(this._urlSegmentsId.replace(':id', segmentId), Segment);
  }

  // GET retorna trecho por ID
  getSegmentById(segmentId: string) {
    return this.get(this._urlSegmentsId.replace(':id', segmentId), Segment);
  }

  // GET Recebe estações do trecho *sem média
  getSegmentMeasuringStations(segmentId: string) {
    return this.get(this._urlSegmentsMeasuringStation.replace(':id', segmentId), Segment);
  }

  // PUT Atualiza apenas as estações de um segmento (Usado para Participantes/TeamLeader)
  updateSegmentMeasuringStations(segmentId: string, measuringStations: MeasuringStation[]) {
    return this.put(this._urlSegmentsMeasuringStation.replace(':id', segmentId), measuringStations, MeasuringStation);
  }
  // GET Recebe o historico da medição
  getMeasurementsHistory(measureId: string) {
    const url = this._endpointVariable + this._urlMeasurementsHistory.replace(':id', measureId);
    return this.http.get(url);
  }

  // POST Atualiza o trecho
  createMeasure(measure: MeasuringStationMeasurement) {
    return this.post(this._urlMeasurements, measure, MeasuringStationMeasurement);
  }

  // PUT Atualiza a leitura da estacao
  updateMeasure(measure: MeasuringStationMeasurement) {
    return this.put(this._urlMeasurementsId.replace(':id', measure.id), measure, MeasuringStationMeasurement);
  }

  // PUT Atualiza a leitura da estacao
  deleteMeasure(measure: MeasuringStationMeasurement) {
    return this.del(this._urlMeasurementsId.replace(':id', measure.id), MeasuringStationMeasurement);
  }

  // GET Recebe estações do trecho média
  getSegmentMeasuringStationsAverage(
    segmentId: string,
    chartState: { measuringStationsId: string; demarcationLanesId: string },
  ) {
    const url = this._endpointVariable + this._urlSegmentsMeasuringStationAverage.replace(':id', segmentId);
    var params = new HttpParams();

    if (chartState.measuringStationsId)
      params = params.append('measuringStationsIds', JSON.stringify([chartState.measuringStationsId]));
    if (chartState.demarcationLanesId)
      params = params.append('demarcationLanesIds', JSON.stringify([chartState.demarcationLanesId]));

    return this.http.get(url, { params: params });
  }

  // Retorna os marcadores
  getMarkerByPathId(pathId: string) {
    const url = this._urlMarkerId.replace(':id', pathId);
    return this.get(url, PathMarker);
  }

  // Retorna os trajetos
  getPaths(attributes?: Array<any>, limit?, offset?, ids?: string[]) {
    let url = this._urlPaths + `?`;

    if (ids) {
      const idsJSON = JSON.stringify(ids);
      url += `ids=${idsJSON}&`
    }

    if (offset >= 0 && limit > 1) url += `offset=${offset}&limit=${limit}&`;
    if (attributes?.length) url += `attributes=${JSON.stringify(attributes)}&`;
    return this.get(url, Path);
  }

  // Retorna os trajetos
  getPathsData(contractId) {
    let url = this._urlPathsData + '?';
    if (contractId) url += `contractId=${contractId}&`;
    return this.get(url, PathData);
  }

  // Retorna o trajeto pelo ID
  getPathById(pathId: string) {
    const url = this._urlPathId.replace(':id', pathId);
    return this.get(url, Path);
  }
  // retorna o custo do trajeto
  getPathData(pathId: string) {
    const url = this._endpointVariable + this._urlPathDataId.replace(':id', pathId);
    return this.http.get(url);
  }
  // Cria o trajeto
  createPath(path: Path) {
    return this.post(this._urlPaths, path, Path);
  }

  // Edita o trajeto
  editPath(path: Path) {
    const url = this._urlPathId.replace(':id', path.id);
    return this.put(url, path, Path);
  }

  // Deleta o trajeto
  deletePath(pathId: string) {
    const url = this._urlPathId.replace(':id', pathId);
    return this.del(url, Path);
  }

  // Get das Verticais vinculadas ao trajeto
  getVerticalPath(id) {
    const url = this._urlGetVerticalPath + id;
    return this.get(url, VerticalSignaling);
  }

  // Get das Horizontais vinculadas ao trajeto
  getHorizontalPath(id) {
    const url = this._urlGetHorizontalPath + id;
    return this.get(url, HorizontalSignaling);
  }

  // Get das Dispositivos vinculados ao trajeto
  getDevicePath(id) {
    const url = this._urlGetDevicePath + id;
    return this.get(url, DeviceSignaling);
  }

  // POST Cria rota
  createRoute(route: Route) {
    return this.post(this._urlRoutes, route, Route);
  }

  // PUT Atualiza o rota
  updateRoute(route: Route) {
    return this.put(this._urlRoutesId.replace(':id', route.id), route, Route);
  }

  // GET recebe rotas
  getRoutes() {
    return this.get(this._urlRoutes, Route);
  }

  // GET recebe rotas
  getRouteById(routeId: string) {
    return this.get(this._urlRoutesId.replace(':id', routeId), Route);
  }

  // DEL remove rotas
  deleteRoute(routeId: string) {
    return this.del(this._urlRoutesId.replace(':id', routeId), Route);
  }

  getPathRetro(pathId) {
    const url = this._urlPathsRetro.replace(':id', pathId);
    return this.get(url, PathRetroreflectorization);
  }

  updateRetroPath(pathRetroId, pathRetro: PathRetroreflectorization) {
    const url = this._urlPathsRetroId.replace(':id', pathRetroId);
    return this.put(url, pathRetro, PathRetroreflectorization);
  }

  getPathQuantity() {
    return this.get(this._urlPathsQuantity, PathQuantity);
  }
}
