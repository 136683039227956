export enum SignalingTypeEnum {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
  Device = 'DEVICE',
}

export enum TypeSignDimensionEnum {
  Circular = 'CIRCULAR',
  Triangular = 'TRIANGULAR',
  Lozenge = 'LOZENGE',
  Rectangular = 'RECTANGULAR',
  Octagonal = 'OCTAGONAL',
  Horizontal = 'HORIZONTAL'
}

export enum HorizontalSignalingDimensionSettingTypeEnum {
  WithHeight = 'WITH_HEIGHT',
  WithoutHeight = 'WITHOUT_HEIGHT',
};

export enum HorizontalWithHeight {
  LCO = 'LCO',
  MCI = 'MCI',
  LRE = 'LRE',
  LRV = 'LRV',
  LDP = 'LDP',
  FTP1 = 'FTP-1',
  FTP2 = 'FTP-2',
  MCC = 'MCC',
  MAC = 'MAC',
  MAE = 'MAE',
  MCF = 'MCF',
  LCA = 'LCA',
  ZPA_MAN = 'ZPA-MAN',
  ZPA_MCB = 'ZPA-MCB',
  ZPA_MAO = 'ZPA-MAO',
  ZPA_MTL = 'ZPA-MTL',
  ZPA_MIR = 'ZPA-MIR',
  LPP = 'LPP',
  MVE = 'MVE',
  MER = 'MER',
  PEM = 'PEM',
  MOF = 'MOF',
  IMC = 'IMC',
  SIP = 'SIP',
  SIF = 'SIF',
  SIC = 'SIC',
  SAS = 'SAS',
  DEF = 'DEF',
  IPL_LVR = 'IPL-LVR',
  IPL_ESC = 'IPL-ESC',
  IPL_IND = 'IPL-IND',
  IPL_PAR = 'IPL-PAR',
  IPL_MCD = 'IPL-MCD',
  PEM_RIGHT = 'PEM_DIREITA',
  PEM_LEFT = 'PEM_ESQUERDA',
  PEM_RETURN_RIGHT = 'PEM_RETORNO_DIREITA',
  PEM_RETURN_LEFT = 'PEM_RETORNO_ESQUERDA',
  PEM_KEEP = 'PEM_SIGA',
  PEM_SIGA_DIREITA = 'PEM_SIGA_DIREITA',
  PEM_SIGA_ESQUERDA = 'PEM_SIGA_ESQUERDA',
  PEM_SIGA_PEDESTRE = 'PEM_SIGA_PEDESTRE',
  HorizontalPersonalizada = 'HORIZONTAL_PERSONALIZADA'
}

export enum HorizontalWithoutHeight {
  LFO1 = 'LFO-1',
  LFO2 = 'LFO-2',
  LFO3 = 'LFO-3',
  LFO4 = 'LFO-4',
  LMS1 = 'LMS-1',
  LMS2 = 'LMS-2',
  LBO = 'LBO',
  MFE = 'MFE',
  MFP = 'MFP',
  MFR = 'MFR',
  ZPA_MAC = 'ZPA-MAC',
}

export enum VerticalCircular {
  R3 = 'R-3',
  R4a = 'R-4a',
  R4b = 'R-4b',
  R5a = 'R-5a',
  R5b = 'R-5b',
  R6a = 'R-6a',
  R6b = 'R-6b',
  R6c = 'R-6c',
  R7 = 'R-7',
  R8a = 'R-8a',
  R8b = 'R-8b',
  R9 = 'R-9',
  R10 = 'R-10',
  R11 = 'R-11',
  R12 = 'R-12',
  R13 = 'R-13',
  R14 = 'R-14',
  R15 = 'R-15',
  R16 = 'R-16',
  R17 = 'R-17',
  R18 = 'R-18',
  R19 = 'R-19',
  R20 = 'R-20',
  R21 = 'R-21',
  R22 = 'R-22',
  R23 = 'R-23',
  R24a = 'R-24a',
  R24b = 'R-24b',
  R25a = 'R-25a',
  R25b = 'R-25b',
  R25c = 'R-25c',
  R25d = 'R-25d',
  R26 = 'R-26',
  R27 = 'R-27',
  R28 = 'R-28',
  R29 = 'R-29',
  R30 = 'R-30',
  R31 = 'R-31',
  R32 = 'R-32',
  R33 = 'R-33',
  R34 = 'R-34',
  R35a = 'R-35a',
  R35b = 'R-35b',
  R36a = 'R-36a',
  R36b = 'R-36b',
  R37 = 'R-37',
  R38 = 'R-38',
  R39 = 'R-39',
  R40 = 'R-40',
}

export enum VerticalTriangular {
  R2 = 'R-2',
}

export enum VerticalRectangular {
  A26a = 'A-26a',
  A26b = 'A-26b',
  Identification = 'IDENTIFICACAO',
  DestinationOrientation = 'ORIENTACAO_DE_DESTINO',
  Educational = 'EDUCATIVAS',
  AuxiliaryServices = 'SERVICOS_AUXILIARES',
  TouristAttractions = 'ATRATIVOS_TURISTICOS',
  MixedUnformation = 'INFORMACAO_MISTA',
  InspectionPosts = 'POSTOS_DE_FISCALIZACAO',
  EspecialAdvertencia = 'ESPECIAL_ADVERTENCIA',
  EspecialRegulamentacao = 'ESPECIAL_REGULAMENTACAO',
  VerticalPersonalizada = 'VERTICAL_PERSONALIZADA',
  QrCode = 'QR_CODE',
}

export enum VerticalLozenge {
  A1a = 'A-1a',
  A1b = 'A-1b',
  A2a = 'A-2a',
  A2b = 'A-2b',
  A3a = 'A-3a',
  A3b = 'A-3b',
  A4a = 'A-4a',
  A4b = 'A-4b',
  A5a = 'A-5a',
  A5b = 'A-5b',
  A6 = 'A-6',
  A7a = 'A-7a',
  A7b = 'A-7b',
  A8 = 'A-8',
  A9 = 'A-9',
  A10a = 'A-10a',
  A10b = 'A-10b',
  A11a = 'A-11a',
  A11b = 'A-11b',
  A12 = 'A-12',
  A13a = 'A-13a',
  A13b = 'A-13b',
  A14 = 'A-14',
  A15 = 'A-15',
  A16 = 'A-16',
  A17 = 'A-17',
  A18 = 'A-18',
  A19 = 'A-19',
  A20a = 'A-20a',
  A20b = 'A-20b',
  A21a = 'A-21a',
  A21b = 'A-21b',
  A21c = 'A-21c',
  A21d = 'A-21d',
  A21e = 'A-21e',
  A22 = 'A-22',
  A23 = 'A-23',
  A24 = 'A-24',
  A25 = 'A-25',
  A27 = 'A-27',
  A28 = 'A-28',
  A29 = 'A-29',
  A30a = 'A-30a',
  A30b = 'A-30b',
  A30c = 'A-30c',
  A31 = 'A-31',
  A32a = 'A-32a',
  A32b = 'A-32b',
  A33a = 'A-33a',
  A33b = 'A-33b',
  A34 = 'A-34',
  A35 = 'A-35',
  A36 = 'A-36',
  A37 = 'A-37',
  A38 = 'A-38',
  A39 = 'A-39',
  A40 = 'A-40',
  A41 = 'A-41',
  A42a = 'A-42a',
  A42b = 'A-42b',
  A42c = 'A-42c',
  A43 = 'A-43',
  A44 = 'A-44',
  A45 = 'A-45',
  A46 = 'A-46',
  A47 = 'A-47',
  A48 = 'A-48',
}

export enum VerticalOctagonal {
  R1 = 'R-1'
}
