import { IRest } from "./rest.interface";

export class FormDataRest implements IRest {
    className = 'Pseudo classe para FormData';

    formData: FormData;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        return this.formData;
    }

    Deserialize(data: any) {
        this.formData = data['formData'] || new FormData();
        return this;
    }
}