import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionAreaService {

  endpoint = environment.endpoint();
  _urlRegions = '/regions'
  _urlRegionsId = '/regions/:id'
  _urlAreas = '/areas'
  _urlAreasId = '/areas/:id'
  _urlRegionSigns = '/regions?setSignalings=true'
  _urlAreaSigns = '/areas?setSignalings=true'



  constructor(private _http: HttpClient) { }

  submitRegion(body){
    const url = this.endpoint + this._urlRegions
    return this._http.post(url, body)
  }

  editRegion(region, regionId: string){
    const url = this.endpoint + this._urlRegionsId.replace(':id', regionId)
    return this._http.put(url, region)
  }

  editArea(area, areaId: string){
    const url = this.endpoint + this._urlAreasId.replace(':id', areaId)
    return this._http.put(url, area)
  }

  getRegions(){
    const url = this.endpoint + this._urlRegions
    return this._http.get(url)
  }
  submitArea(body){
    const url = this.endpoint + this._urlAreas
    return this._http.post(url, body)
  }
  getAreas(){
    const url = this.endpoint + this._urlAreas
    return this._http.get(url)
  }

  deleteRegion(id: string){
    const url = this.endpoint + this._urlRegionsId.replace(':id', id)
    return this._http.delete(url)
  }

  deleteAreas(id: string){
    const url = this.endpoint + this._urlAreasId.replace(':id', id)
    return this._http.delete(url)
  }

  getRegionSigns(){
    const url = this.endpoint + this._urlRegionSigns
    return this._http.get(url)
  }

  getAreaSigns(){
    const url = this.endpoint + this._urlAreaSigns
    return this._http.get(url)
  }

  getSingleRegion(id:string){
    const url = this.endpoint + this._urlRegionsId.replace(':id', id)
    return this._http.get(url)
  }

  getSingleArea(id:string){
    const url = this.endpoint + this._urlAreasId.replace(':id', id)
    return this._http.get(url)
  }
}
