import { ChartSystemModulesEnum, ChartTypeEnum } from 'src/enumerators';
import { DashboardTypeEnum } from 'src/enumerators/dashboard.enum';
import { IRest } from './rest.interface';

export class Dashboard implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  contract: {
    id: string;
    name: string;
    responsable: {
      id: string;
      user: { id: string; firstName: string; lastName: string };
    };
    startedAt: Date;
    finishedAt: Date;
    city: string;
    state: string;
    cost: number;
    investedAmount: number;
  };
  regions: Array<Region>;
  areas: Array<Area>;
  signalingsOutsideRegionOrArea: SignalingsOutsideRegionOrArea;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      contract: this.contract,
      regions: this.regions,
      areas: this.areas,
      signalingsOutsideRegionOrArea: this.signalingsOutsideRegionOrArea.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.contract = data['contract'] || '';
      this.regions = (data['regions'] || []).map((v) => new Region(v));
      this.areas = (data['areas'] || []).map((v) => new Area(v));
      this.signalingsOutsideRegionOrArea = new SignalingsOutsideRegionOrArea(
        data['signalingsOutsideRegionOrArea'] || {},
      );
    }
    return this;
  }
}

export class Region implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  id: string;
  name: string;
  cost: number;
  signalingsCost: SignalingsCost;
  signalingsWarrantyCost: SignalingsWarrantyCost;
  signalingsQuantity: SignalingsQuantity;
  vertices: { lat: number, lng: number }[]
  signalingsWarrantyQuantity: SignalingsWarrantyQuantity;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      cost: this.cost,
      signalingsCost: this.signalingsCost.Serialize(),
      signalingsWarrantyCost: this.signalingsWarrantyCost.Serialize(),
      signalingsQuantity: this.signalingsQuantity.Serialize(),
      signalingsWarrantyQuantity: this.signalingsWarrantyQuantity.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.cost = data['cost'] || 0;
      this.signalingsCost = new SignalingsCost(data['signalingsCost'] || {});
      this.signalingsWarrantyCost = new SignalingsWarrantyCost(data['signalingsWarrantyCost'] || {});
      this.signalingsQuantity = new SignalingsQuantity(data['signalingsQuantity'] || {});
      this.vertices = data['vertices'] || null;
      this.signalingsWarrantyQuantity = new SignalingsWarrantyQuantity(data['signalingsWarrantyQuantity'] || {});
    }
    return this;
  }
}

export class Area implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  id: string;
  name: string;
  cost: number;
  signalingsCost: SignalingsCost;
  signalingsWarrantyCost: SignalingsWarrantyCost;
  signalingsQuantity: SignalingsQuantity;
  signalingsWarrantyQuantity: SignalingsWarrantyQuantity;
  vertices: { lat: number, lng: number }[]

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      cost: this.cost,
      signalingsCost: this.signalingsCost.Serialize(),
      signalingsWarrantyCost: this.signalingsWarrantyCost.Serialize(),
      signalingsQuantity: this.signalingsQuantity.Serialize(),
      signalingsWarrantyQuantity: this.signalingsWarrantyQuantity.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.cost = data['cost'] || 0;
      this.signalingsCost = new SignalingsCost(data['signalingsCost'] || {});
      this.signalingsWarrantyCost = new SignalingsWarrantyCost(data['signalingsWarrantyCost'] || {});
      this.signalingsQuantity = new SignalingsQuantity(data['signalingsQuantity'] || {});
      this.signalingsWarrantyQuantity = new SignalingsWarrantyQuantity(data['signalingsWarrantyQuantity'] || {});
      this.vertices = data['vertices'] || null;

    }
    return this;
  }
}

export class SignalingsCost implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: number;
  horizontal: number;
  device: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || 0;
      this.horizontal = data['horizontal'] || 0;
      this.device = data['device'] || 0;
    }
    return this;
  }
}

export class SignalingsWarrantyCost implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: number;
  horizontal: number;
  device: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || 0;
      this.horizontal = data['horizontal'] || 0;
      this.device = data['device'] || 0;
    }
    return this;
  }
}

export class SignalingsQuantity implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: number;
  horizontal: number;
  device: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || 0;
      this.horizontal = data['horizontal'] || 0;
      this.device = data['device'] || 0;
    }
    return this;
  }
}

export class SignalingsWarrantyQuantity implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: number;
  horizontal: number;
  device: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || 0;
      this.horizontal = data['horizontal'] || 0;
      this.device = data['device'] || 0;
    }
    return this;
  }
}

export class SignalingsOutsideRegionOrArea implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  cost: number;
  signalingsCost: SignalingsCost;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      cost: this.cost,
      signalingsCost: this.signalingsCost.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.cost = data['cost'] || 0;
      this.signalingsCost = new SignalingsCost(data['signalingsCost'] || {});
    }
    return this;
  }
}

export class DayCost implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: [number, number];
  horizontal: [number, number];
  device: [number, number];
  warranty: [number, number];
  day: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
      warranty: this.warranty,
      day: this.day,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || [0, 0];
      this.horizontal = data['horizontal'] || [0, 0];
      this.device = data['device'] || [0, 0];
      this.warranty = data['warranty'] || [0, 0];
      this.day = data['day'] || 0;
    }
    return this;
  }
}

// Novos dashboards

export class DashboardOrganization implements IRest {
  className = 'Objeto para preenchimento da dashboard de organização';

  owner: {
    firstName: string,
    lastName: string,
  };
  amount: number;
  investedAmount: number;
  contracts: [
    {
      id: string,
      name: string,
      investedAmount: number
    },
    {
      id: string,
      name: string,
      investedAmount: number
    }
  ];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      owner: this.owner,
      amount: this.amount,
      investedAmount: this.investedAmount,
      contracts: this.contracts,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.owner = data['owner'] || { firstName: '', lastName: '' };
      this.amount = data['amount'] || 0;
      this.investedAmount = data['investedAmount'] || 0;
      this.contracts = data['contracts'] || [];
    }
    return this;
  }
}

export class ContractOrganizationData implements IRest {
  className = 'Objeto para preenchimento da dashboard de organização';

  amount: number;
  consumedQuantity: number;
  investedAmount: number;
  quantity: number;
  measurements: number;
  units: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      amount: this.amount,
      consumedQuantity: this.consumedQuantity,
      investedAmount: this.investedAmount,
      quantity: this.quantity,
      measurements: this.measurements,
      units: this.units,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.amount = data['amount'] ?? undefined;
      this.consumedQuantity = data['consumedQuantity'] ?? undefined;
      this.investedAmount = data['investedAmount'] ?? undefined;
      this.quantity = data['quantity'] ?? undefined;
      this.measurements = data['measurements'] ?? undefined;
      this.units = data['units'] ?? undefined;
    }
    return this;
  }
}

export class DashboardContractOrganizationData implements IRest {
  className = 'Objeto para preenchimento da dashboard de organização';


  trafficSigns: ContractOrganizationData;
  supports: ContractOrganizationData;
  horizontalSignalings: ContractOrganizationData;
  deviceSignalings: ContractOrganizationData;
  services: ContractOrganizationData;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      trafficSigns: this.trafficSigns.Serialize(),
      supports: this.supports.Serialize(),
      horizontalSignalings: this.horizontalSignalings.Serialize(),
      deviceSignalings: this.deviceSignalings.Serialize(),
      services: this.services.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.trafficSigns = new ContractOrganizationData(data['trafficSigns'] || {});
      this.supports = new ContractOrganizationData(data['supports'] || {});
      this.horizontalSignalings = new ContractOrganizationData(data['horizontalSignalings'] || {});
      this.deviceSignalings = new ContractOrganizationData(data['deviceSignalings'] || {});
      this.services = new ContractOrganizationData(data['services'] || {});
    }
    return this;
  }
}


export class SignRegisteredDate implements IRest {
  className = 'Objeto para preenchimento da dashboard de sinalizações cadastradas';

  areas: Array<AreaRegionDashboardValues>;
  regions: Array<AreaRegionDashboardValues>;
  total: SignsDashboardValues;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      areas: this.areas.map(a => a.Serialize()),
      regions: this.regions.map(r => r.Serialize()),
      total: this.total.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.areas = data['areas'] || [];
      this.regions = data['regions'] || [];
      this.total = data['total'] || {};
    }
    return this;
  }
}

export class SignRegisteredYear implements IRest {
  className = 'Objeto para preenchimento da dashboard de sinalizações cadastradas ao longo do ano';

  monthly: Array<SignsDashboardValues>;
  weekly: Array<SignsDashboardValues>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      monthly: this.monthly.map(m => m.Serialize()),
      weekly: this.weekly.map(w => w.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.monthly = (data['monthly'] || []).map(m => new SignsDashboardValues(m));
      this.weekly = (data['weekly'] || []).map(w => new SignsDashboardValues(w));
    }
    return this;
  }
}

export class SignRegisteredPerYear implements IRest {
  className = 'Objeto para preenchimento da dashboard de sinalizações cadastradas ao longo do ano';

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      return data;
    }
    return this;
  }
}

export class SignsDashboardValues implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: { cost: number, quantity: number, measurements: number };
  horizontal: { cost: number, quantity: number, measurements: number };
  device: { cost: number, quantity: number, dimensions: number, units: number };
  number: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
      number: this.number,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || { cost: 0, quantity: 0, measurements: 0 };
      this.horizontal = data['horizontal'] || { cost: 0, quantity: 0, measurements: 0 };
      this.device = data['device'] || { cost: 0, quantity: 0, dimensions: 0, units: 0 };
      this.number = data['number'] || 0;
    }
    return this;
  }
}

export class AreaRegionDashboardValues implements IRest {
  className = 'Objeto para preenchimento da dashboard';

  vertical: { cost: number, quantity: number, measurements: number };
  horizontal: { cost: number, quantity: number, measurements: number };
  device: { cost: number, quantity: number, dimensions: number, units: number };
  id: string;
  name: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
      id: this.id,
      name: this.name,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.vertical = data['vertical'] || { cost: 0, quantity: 0, measurements: 0 };
      this.horizontal = data['horizontal'] || { cost: 0, quantity: 0, measurements: 0 };
      this.device = data['device'] || { cost: 0, quantity: 0, dimensions: 0, units: 0 };
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
    }
    return this;
  }
}

export class ModalDashboardType implements IRest {
  className = 'Objeto para organização do dashboard, utilizado no modal';

  hidden: boolean;
  position: number;
  stretched: boolean;
  dashboardType: DashboardTypeEnum
  icon: string;
  chart: any;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      hidden: this.hidden,
      position: this.position,
      stretched: this.stretched,
      typeDashboard: this.dashboardType,
      icon: this.icon,
      chart: this.chart,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.hidden = data['hidden'] || false;
      this.position = data['position'] || 0;
      this.stretched = true;
      this.dashboardType = data['typeDashboard'] || undefined;
      this.icon = data['icon'] || '';
      this.chart = data['chart'] || undefined;
    }
    return this;
  }
}

export class DashboardContract implements IRest {
  className = 'Objeto para preenchimento da dashboard de organização';

  id: string;
  name: string;
  responsable: {
    firstName: string,
    lastName: string,
  };
  startedAt: Date;
  finishedAt: Date;
  vertical: {
    investedAmount: number,
    signalingQuantity: number
  };
  horizontal: {
    investedAmount: number,
    signalingQuantity: number
  };
  device: {
    investedAmount: number,
    signalingQuantity: number
  };
  service: {
    investedAmount: number,
    signalingQuantity: number
  };
  totalCost: number;
  totalQuantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      responsable: this.responsable,
      startedAt: this.startedAt.toISOString(),
      finishedAt: this.finishedAt.toISOString(),
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
      service: this.service,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.name = data['name'] || '';
      this.responsable = data['responsable'] || { firstName: '', lastName: '' };
      this.startedAt = data['startedAt'] || new Date(2000, 0, 1);
      this.finishedAt = data['finishedAt'] || new Date(2000, 0, 1);
      this.vertical = data['vertical'] || {
        investedAmount: 0,
        signalingQuantity: 0
      };
      this.horizontal = data['horizontal'] || {
        investedAmount: 0,
        signalingQuantity: 0
      };
      this.device = data['device'] || {
        investedAmount: 0,
        signalingQuantity: 0
      };
      this.service = data['service'] || {
        investedAmount: 0,
        signalingQuantity: 0
      };
      this.totalQuantity = data['vertical']['signalingQuantity'] + data['horizontal']['signalingQuantity'] + data['device']['signalingQuantity'] + data['service']['signalingQuantity'];
      this.totalCost = data['vertical']['investedAmount'] + data['horizontal']['investedAmount'] + data['device']['investedAmount'] + data['service']['investedAmount'];
    }

    return this;
  }
}

export class DashboardContractRegion implements IRest {
  className = 'Objeto para preenchimento da dashboard de organização';

  id: string;
  name: string;
  responsable: {
    firstName: string,
    lastName: string,
  };
  startedAt: Date;
  finishedAt: Date;
  amount: number;
  investedAmount: number;
  signalingOutsideRegionAmount: number;
  regions: Array<RegionDashboard>

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      responsable: this.responsable,
      startedAt: this.startedAt.toISOString(),
      finishedAt: this.finishedAt.toISOString(),
      amount: this.amount,
      investedAmount: this.investedAmount,
      signalingOutsideRegionAmount: this.signalingOutsideRegionAmount,
      regions: this.regions.map(r => r.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.name = data['name'] || '';
      this.responsable = data['responsable'] || { firstName: '', lastName: '' };
      this.startedAt = data['startedAt'] || new Date(2000, 0, 1);
      this.finishedAt = data['finishedAt'] || new Date(2000, 0, 1);
      this.amount = data['amount'] || 0;
      this.investedAmount = data['investedAmount'] || 0;
      this.signalingOutsideRegionAmount = data['signalingOutsideRegionAmount'] || 0;
      this.regions = (data['regions'] || []).map(r => new RegionDashboard(r));;
    }

    return this;
  }
}

export class RegionDashboard implements IRest {
  className = 'Objeto para preenchimento da dashboard ';

  id: string;
  name: string;
  amount: number;
  signalingsQuantity: number

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      amount: this.amount,
      signalingsQuantity: this.signalingsQuantity,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.amount = data['amount'] || 0;
      this.signalingsQuantity = data['signalingsQuantity'] || 0;
    }
    return this;
  }
}

export class DashboardStock implements IRest {
  className = 'Objeto para preenchimento da dashboard de estoque';

  deviceSignalings: {
    quantity: number,
    cost: number,
  };
  supports: {
    quantity: number,
    cost: number,
  };
  trafficSigns: {
    quantity: number,
    cost: number,
  };

  totalCost: number;
  totalQuantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      deviceSignalings: this.deviceSignalings,
      supports: this.supports,
      trafficSigns: this.trafficSigns,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.deviceSignalings = data['deviceSignalings'] || {
        quantity: 0,
        cost: 0
      };
      this.supports = data['supports'] || {
        quantity: 0,
        cost: 0
      };
      this.trafficSigns = data['trafficSigns'] || {
        quantity: 0,
        cost: 0
      };
      this.totalCost = data['trafficSigns']['cost'] + data['supports']['cost'] + data['deviceSignalings']['cost'];
      this.totalQuantity = data['trafficSigns']['quantity'] + data['supports']['quantity'] + data['deviceSignalings']['quantity'];
    }
    return this;
  }
}

export class DashboardPath implements IRest {
  className = 'Objeto para preenchimento da dashboard de trajetos';

  quantity: number;
  amount: number;
  paths: Array<{
    path: string,
    cost: number
    signalingsQuantity: number
  }>
  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      quantity: this.quantity,
      amount: this.amount,
      paths: this.paths,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.quantity = data['quantity'] || 0;
      this.amount = data['amount'] || 0;
      this.paths = data['paths'] || [];
    }
    return this;
  }
}

export class DashboardRetroreflection implements IRest {
  className = 'Objeto para preenchimento da dashboard de trajetos';

  total: {
    vertical: {
      cost: number,
      quantity: number,
      measurements: number,
    },
    horizontal: {
      cost: number,
      quantity: number,
      measurements: number
    },
  };
  regions: Array<RegionAreaDashboard>;
  areas: Array<RegionAreaDashboard>

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      total: this.total,
      regions: this.regions.map(r => r.Serialize()),
      areas: this.areas.map(a => a.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.total = data['total'] || { vertical: { cost: 0, quantity: 0, measurements: 0 }, horizontal: { cost: 0, quantity: 0, measurements: 0 } };
      this.regions = (data['regions'] || []).map(r => new RegionAreaDashboard(r));
      this.areas = (data['areas'] || []).map(a => new RegionAreaDashboard(a));
    }
    return this;
  }
}

export class RegionAreaDashboard implements IRest {
  className = 'Objeto para preenchimento da dashboard ';

  id: string;
  name: string;
  horizontal: {
    cost: number,
    quantity: number,
    measurements: number,
  };
  vertical: {
    cost: number,
    quantity: number,
    measurements: number,
  }

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      vertical: this.vertical,
      horizontal: this.horizontal,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.vertical = data['vertical'] || { cost: 0, quantity: 0, measurements: 0 };
      this.horizontal = data['horizontal'] || { cost: 0, quantity: 0, measurements: 0 };
    }
    return this;
  }
}

export class CustomChart implements IRest {
  className = 'Classe do gráfico personalizado';

  id: string;
  name: string;
  type: ChartSystemModulesEnum;
  workspaceId: string;
  memberId: string;
  data: ChartData | any;
  contractId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      type: this.type,
      workspaceId: this.workspaceId,
      memberId: this.memberId,
      contractId: this.contractId,
      data: this.data,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.type = data['type'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.memberId = data['memberId'] || undefined;
      this.contractId = data['contractId'] || undefined
      this.data = data['data'] || undefined;
    }
    return this;
  }
}

export class ChartData implements IRest {
  className = 'Classe do gráfico personalizado de projetos';

  type: ChartTypeEnum;
  totalCost: number;
  startEndDate: Date[]
  filterOptions: any;
  chartSelectionY: {
    investment: boolean,
    quantity: boolean,
    meter: boolean,
    square_meter: boolean,
  };
  chartSelectionX: {
    vertical: boolean,
    horizontal: boolean,
    device: boolean,
    end_of_warranty: boolean,
    expired_warranty: boolean,
    under_warranty: boolean,
    with_retro_warning: boolean,
    without_retro_warning: boolean,
  };
  chartData: {
    name: string,
    color: string;
    amount: number,
    quantity: number,
    meter: number,
    squareMeter: number,
    vertical: any;
    horizontal: any;
    device: any;
  }[];


  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      type: this.type,
      chartSelectionY: this.chartSelectionY,
      chartSelectionX: this.chartSelectionX,
      chartData: this.chartData,
      startEndDate: this.startEndDate,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.type = data['type'] || undefined;
      this.chartSelectionY = data['chartSelectionY'] || undefined
      this.chartSelectionX = data['chartSelectionX'] || undefined
      this.chartData = data['chartData'] || undefined
      this.startEndDate = data['startEndDate'] || null
    }
    return this;
  }

}
export class TeamDashCard implements IRest {
  className = 'Classe do card de equipes na dash';

  teamId: string;
  leaderMemberId: string;
  name: string;
  horizontal: boolean;
  vertical: boolean;
  device: boolean;
  tasks: {
    today: number,
    week: {
      late: number,
      started: number
      toStart: number,
      paused: number,
      completed: number,
    }
  };
  data: TeamDataCard;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      teamId: this.teamId,
      leaderMemberId: this.leaderMemberId,
      name: this.name,
      horizontal: this.horizontal,
      vertical: this.vertical,
      device: this.device,
      tasks: this.tasks,
      data: this.data,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.teamId = data['teamId'] || undefined;
      this.leaderMemberId = data['leaderMemberId'] || undefined;
      this.name = data['name'] || '';
      this.horizontal = data['horizontal'] || false;
      this.vertical = data['vertical'] || false;
      this.device = data['device'] || false;
      this.tasks = data['tasks'] || { today: 0, week: { late: 0, started: 0, toStart: 0, paused: 0, completed: 0 } }
      this.data = data['data'] || new TeamDataCard({});
    }
    return this;
  }
}

export class TeamDataCard implements IRest {
  className = 'Classe do card de equipes na dash';

  deviceSignalings: {
    amount: number,
    signalingQuantity: number,
    meters: number,
    units: number
  };
  horizontalSignalings: {
    amount: number,
    signalingQuantity: number,
    squareMeters: number
  };
  trafficSigns: {
    amount: number,
    signalingQuantity: number,
    squareMeters: number
  };
  supports: {
    amount: number,
    signalingQuantity: number
  };

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      deviceSignalings: this.deviceSignalings,
      horizontalSignalings: this.horizontalSignalings,
      trafficSigns: this.trafficSigns,
      supports: this.supports,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.deviceSignalings = data['deviceSignalings'] || { amount: 0, signalingQuantity: 0, meters: 0, units: 0 };
      this.horizontalSignalings = data['horizontalSignalings'] || { amount: 0, signalingQuantity: 0, squareMeters: 0 };
      this.trafficSigns = data['trafficSigns'] || { amount: 0, signalingQuantity: 0, squareMeters: 0 };
      this.supports = data['supports'] || { amount: 0, signalingQuantity: 0 };
    }
    return this;
  }
}

export class ServiceOrderDashboard implements IRest {
  className = 'Classe do card de equipes na dash';


  month: Array<ServiceOrderData>;
  week: Array<ServiceOrderData>

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      month: this.month.map(m => m.Serialize()),
      week: this.week.map(w => w.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.month = (data['month'] || []).map(m => new ServiceOrderData(m));
      this.week = (data['week'] || []).map(w => new ServiceOrderData(w));
    }
    return this;
  }
}

export class ServiceOrderData implements IRest {
  className = 'Classe do card de equipes na dash';

  serviceOrderId: string;
  name: string;
  amount: number;
  status: TaskStatus;
  types: TaskTypes;
  taskQuantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      serviceOrderId: this.serviceOrderId,
      name: this.name,
      amount: this.amount,
      taskQuantity: this.taskQuantity,
      status: this.status,
      types: this.types,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.serviceOrderId = data['serviceOrderId'] || undefined;
      this.name = data['name'] || '';
      this.amount = data['amount'] || 0;
      this.taskQuantity = data['taskQuantity'] || 0;
      this.status = data['status'] || new TaskStatus({});
      this.types = data['types'] || new TaskTypes({});
    }
    return this;
  }
}

export class TaskStatus implements IRest {
  className = 'Classe do card de equipes na dash';

  completed: number;
  late: number;
  paused: number;
  started: number;
  toStart: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      completed: this.completed,
      late: this.late,
      paused: this.paused,
      started: this.started,
      toStart: this.toStart,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.completed = data['completed'] || 0;
      this.late = data['late'] || 0;
      this.paused = data['paused'] || 0;
      this.started = data['started'] || 0
      this.toStart = data['toStart'] || 0
    }
    return this;
  }
}

export class TaskTypes implements IRest {
  className = 'Classe do card de equipes na dash';

  cataloging: number;
  implantation: number;
  maintenance: number;
  removal: number;
  replacement: number;
  revitalizing: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      cataloging: this.cataloging,
      implantation: this.implantation,
      maintenance: this.maintenance,
      removal: this.removal,
      replacement: this.replacement,
      revitalizing: this.revitalizing,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.cataloging = data['cataloging'] || 0;
      this.implantation = data['implantation'] || 0;
      this.maintenance = data['maintenance'] || 0;
      this.removal = data['removal'] || 0
      this.replacement = data['replacement'] || 0
      this.revitalizing = data['revitalizing'] || 0
    }
    return this;
  }
}

export class MonthlyTaskData implements IRest {
  className = 'Classe do card de equipes na dash';

  amount: number;
  status: TaskStatus;
  type: TaskTypes;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      amount: this.amount,
      status: this.status,
      type: this.type,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.amount = data['amount'] || 0;
      this.status = data['status'] || new TaskStatus({});
      this.type = data['type'] || new TaskTypes({});
    }
    return this;
  }
}

export class TeamDashModal implements IRest {
  className = 'Classe do card de equipes na dash';

  teamId: string;
  leaderMemberId: string;
  name: string;
  horizontal: TeamDashModalData;
  vertical: TeamDashModalData;
  device: TeamDashModalData;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      teamId: this.teamId,
      leaderMemberId: this.leaderMemberId,
      name: this.name,
      horizontal: this.horizontal,
      vertical: this.vertical,
      device: this.device,

    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.teamId = data['teamId'] || undefined;
      this.leaderMemberId = data['leaderMemberId'] || undefined;
      this.name = data['name'] || '';
      this.horizontal = data['horizontal'] || new TeamDashModalData({});
      this.vertical = data['vertical'] || new TeamDashModalData({});
      this.device = data['device'] || new TeamDashModalData({});

    }
    return this;
  }
}


export class TeamDashModalData implements IRest {
  className = 'Classe do modal de equipes na dash';

  completed: number;
  paused: number;
  late: number;
  started: number
  toStart: number;
  quantity: number;
  data: {
    trafficSigns: TypeData,
    supports: TypeData,
    deviceSignalings: TypeData,
    horizontalSignalings: TypeData
  };

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      completed: this.completed,
      paused: this.paused,
      late: this.late,
      started: this.started,
      toStart: this.toStart,
      quantity: this.quantity,
      data: this.data,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.completed = data['completed'] || undefined;
      this.paused = data['paused'] || undefined;
      this.late = data['late'] || '';
      this.started = data['started'] || false;
      this.toStart = data['toStart'] || false;
      this.quantity = data['quantity'] || false;
      this.data = data['data'] || { trafficSigns: new TypeData({}), supports: new TypeData({}), deviceSignalings: new TypeData({}), horizontalSignalings: new TypeData({}) };
    }
    return this;
  }
}

export class TypeData implements IRest {
  className = 'Classe do modal de equipes na dash';

  amount: number;
  signalingQuantity: number;
  meters: number;
  units: number
  squareMeters: number;
  quantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      amount: this.amount,
      signalingQuantity: this.signalingQuantity,
      meters: this.meters,
      units: this.units,
      squareMeters: this.squareMeters,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.amount = data['amount'] || 0;
      this.signalingQuantity = data['signalingQuantity'] || 0;
      this.meters = data['meters'] || 0;
      this.units = data['units'] || 0;
      this.squareMeters = data['squareMeters'] || 0;
    }
    return this;
  }
}





