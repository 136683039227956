import { ILocation } from 'src/app/services/map.service';
import { IRest } from './rest.interface';
import { DirectionEnum, RetroStatusEnum, RouteVehicleTypeEnum, WarrantyStatusEnum } from 'src/enumerators';
import { Member } from './user';
import { Contract } from './contract';

export class Path implements IRest {
  className = 'Classe de Trajeto';

  id: string;
  workspaceId: string;
  createdByMember: Member;
  createdByMemberId: string;
  contractId: string;
  contract: Contract;
  name: string;
  state: string;
  length: number;
  numberOfMarkers: number;
  startsAt: number;
  additionalInformation: string;
  vertices: ILocation[];
  createdAt: Date;
  updatedAt: Date;

  markers: PathMarker[];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      workspaceId: this.workspaceId || undefined,
      contractId: this.contractId || undefined,
      contract: this.contract,
      createdByMemberId: this.createdByMemberId || undefined,
      name: this.name,
      state: this.state,
      length: this.length,
      numberOfMarkers: this.numberOfMarkers,
      startsAt: this.startsAt,
      additionalInformation: this.additionalInformation,
      vertices: this.vertices,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      markers: this.markers.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.contract = data['contract'] || undefined;
      this.createdByMemberId = data['createdByMemberId'] || undefined;
      this.name = data['name'] || null;
      this.state = data['state'] || null;
      this.length = data['length'] || 0;
      this.numberOfMarkers = data['numberOfMarkers'] || 0;
      this.startsAt = data['startsAt'] || 0;
      this.vertices = data['vertices'] || [];
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 1, 1);
      this.markers = (data['markers'] || []).map((v) => new PathMarker(v));
    }
    return this;
  }
}

export class Route implements IRest {
  className = 'Classe de Rota';

  id: string;
  workspaceId: string;
  createdByMember: Member;
  createdByMemberId: string;
  contractId: string;
  contract: Contract;
  name: string;
  length: number;
  additionalInformation: string;
  vertices: ILocation[];
  createdAt: Date;
  updatedAt: Date;
  vehicleType: RouteVehicleTypeEnum;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      workspaceId: this.workspaceId || undefined,
      contractId: this.contractId || undefined,
      contract: this.contract,
      createdByMemberId: this.createdByMemberId || undefined,
      name: this.name,
      length: this.length,
      additionalInformation: this.additionalInformation,
      vertices: this.vertices,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      vehicleType: this.vehicleType,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.contract = data['contract'] || undefined;
      this.createdByMemberId = data['createdByMemberId'] || undefined;
      this.name = data['name'] || null;
      this.length = data['length'] || 0;
      this.vertices = data['vertices'] || [];
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 1, 1);
      this.vehicleType = data['vehicleType'] || undefined;
    }
    return this;
  }
}

export class PathMarker implements IRest {
  className = 'Classe de Marcador do Trajeto';

  id: string;
  workspaceId: string;
  pathId: string;
  kilometer: number;
  latitude: number;
  longitude: number;
  hidden: boolean;
  createdAt: Date;
  updatedAt: Date;
  marker: L.Marker;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      workspaceId: this.workspaceId || undefined,
      pathId: this.pathId || undefined,
      kilometer: this.kilometer,
      latitude: this.latitude,
      longitude: this.longitude,
      hidden: this.hidden,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.pathId = data['pathId'] || undefined;
      this.kilometer = data['kilometer'] || 0;
      this.latitude = data['latitude'] || 0;
      this.longitude = data['longitude'] || 0;
      this.hidden = data['hidden'] || false;
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 1, 1);
      this.marker = data['marker'] || undefined
    }
    return this;
  }
}

export class PathData implements IRest {
  className = 'Classe de Trajeto';

  id: string;
  length: number;
  quantity: number;
  situation: WarrantyStatusEnum;
  name: string;
  state: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      length: this.length,
      quantity: this.quantity,
      situation: this.situation,
      name: this.name,
      state: this.state,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.length = data['length'] || 0;
      this.quantity = data['quantity'] || 0;
      this.situation = data['situation'] || undefined;
      this.name = data['name'] || '';
      this.state = data['state'] || '';
    }
    return this;
  }
}

export class Segment implements IRest {
  className = 'Classe de Trecho';

  id: string;
  name: string;
  pathId: string;
  direction: DirectionEnum;
  startsAt: number;
  initialDistance: number;
  endsAt: number;
  finalDistance: number;
  measuringStations: MeasuringStation[];
  path: Path;
  lanes: Lane[]
  selectedLane: Lane;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      pathId: this.pathId || undefined,
      direction: this.direction,
      startsAt: this.startsAt,
      initialDistance: this.initialDistance,
      finalDistance: this.finalDistance,
      endsAt: this.endsAt,
      measuringStations: this.measuringStations.map((v) => v.Serialize()),
      path: this.path.Serialize(),
      lanes: this.lanes.map(v => v.Serialize()),
      selectedLane: this.selectedLane.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.pathId = data['pathId'] || undefined;
      this.path = new Path(data['path'] || {});
      this.direction = data['direction'] || DirectionEnum.East;
      this.startsAt = data['startsAt'] || 0;
      this.initialDistance = data['initialDistance'] || 0;
      this.finalDistance = data['finalDistance'] || 0;
      this.endsAt = data['endsAt'] || 0;
      this.measuringStations = (data['measuringStations'] || []).map((v) => {
        return new MeasuringStation(v);
      });
      this.lanes = (data['lanes'] || []).map((v) => {
        return new Lane(v);
      });
      this.selectedLane = new Lane(data['selectedLane'] || {});
    }
    return this;
  }
}

export class MeasuringStation implements IRest {
  className = 'Classe de Estação de medição';

  id: string;
  stationNumber: number;
  segmentName: string;
  segmentId: string;
  demarcationLaneId: string;
  marker: number;
  distance: number;
  distanceBetweenMeasurements: number;
  average: number;
  status: RetroStatusEnum;
  blockSignSelection: boolean
  createdAt: Date;
  updatedAt: Date;

  verticalSignalings: { verticalSignalingId: string }[];
  horizontalSignalings: { horizontalSignalingId: string }[];
  deviceSignalings: { deviceSignalingId: string }[];

  signalings: any[]
  measurements: MeasuringStationMeasurement[];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      segmentId: this.segmentId || undefined,
      demarcationLaneId: this.demarcationLaneId || undefined,
      marker: this.marker,
      distance: this.distance,
      distanceBetweenMeasurements: this.distanceBetweenMeasurements,
      verticalSignalings: this.verticalSignalings,
      horizontalSignalings: this.horizontalSignalings,
      deviceSignalings: this.deviceSignalings,
      average: this.average,
      blockSignSelection: this.blockSignSelection,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      status: this.status,
      measurements: this.measurements.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.segmentId = data['segmentId'] || undefined;
      this.demarcationLaneId = data['demarcationLaneId'] || undefined;
      this.marker = data['marker'] || 0;
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 1, 1);
      this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 1, 1);
      this.distance = data['distance'] || 0;
      this.distanceBetweenMeasurements = data['distanceBetweenMeasurements'] || 10;
      this.verticalSignalings = data['verticalSignalings'] || [];
      this.horizontalSignalings = data['horizontalSignalings'] || [];
      this.deviceSignalings = data['deviceSignalings'] || [];
      this.average = (Math.round((data['average'] || 0) * 100) / 100);
      this.blockSignSelection = data['blockSignSelection'] || false;
      this.status = data['status'] || RetroStatusEnum.Default;
      this.measurements = (data['measurements'] || []).map((v) => new MeasuringStationMeasurement(v));
      this.signalings = data['signalings'] || [];
    }
    return this;
  }
}

export class MeasuringStationMeasurement implements IRest {
  className = 'Classe de medição da estação';

  id: string;
  measuringStationId: string;
  value: number;
  status: RetroStatusEnum
  createdAt: Date;
  updatedAt: Date;
  history: any[];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      measuringStationId: this.measuringStationId || undefined,
      value: this.value,
      status: this.status,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      history: this.history,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.measuringStationId = data['measuringStationId'] || undefined;
      this.value = data['value'] || 0;
      this.status = data['status'] || null;
      this.createdAt = data['createdAt'] || new Date();
      this.updatedAt = data['updatedAt'] || new Date();
      this.history = data['history'] || [];
    }
    return this;
  }
}

export class Lane implements IRest {
  className = 'Classe de faixa do trajeto';

  id: string;
  name: string;
  order: number;
  pathId: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      order: this.order,
      pathId: this.pathId || undefined,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || 'Faixa';
      this.order = data['order'] || 0;
      this.pathId = data['pathId'] || undefined;
    }
    return this;
  }
}

export class PathRetroreflectorization implements IRest {
  className = 'Retro do contrato';

  id: string;
  pathId: string;
  bad: { from: number, to: number };
  regular: { from: number, to: number };
  good: { from: number, to: number };
  excellent: { from: number, to: number };
  maximumValue: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      pathId: this.pathId,
      bad: this.bad,
      regular: this.regular,
      good: this.good,
      excellent: this.excellent,
      maximumValue: this.maximumValue,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.pathId = data['pathId'] || null;
      this.bad = data['bad'] || { from: 0, to: 200 };
      this.regular = data['regular'] || { from: 200, to: 250 };
      this.good = data['good'] || { from: 250, to: 300 };
      this.excellent = data['excellent'] || { from: 300, to: 400 };
      this.maximumValue = data['maximumValue'] || 400;

    }
    return this;
  }
}

export class PathQuantity implements IRest {
  className = 'Classe de dados do diagrama de cada trajeto';

  measurements: number;
  demarcationLanes: number;
  pathId: string;
  segments: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      measurements: this.measurements,
      demarcationLanes: this.demarcationLanes,
      pathId: this.pathId,
      segments: this.segments,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.measurements = data['measurements'] || 0;
      this.demarcationLanes = data['demarcationLanes'] || 0;
      this.pathId = data['pathId'] || '';
      this.segments = data['segments'] || 0;
    }
    return this;
  }
}
