import {
  ActionEnum,
  ComplementInformationEnum,
  DeviceSignalingEnum,
  DimensionInformationEnum,
  HorizontalSignalingEnum,
  IndicationVerticalEnum,
  RegulationVerticalEnum,
  SignalingTypeEnum,
  VerticalGroupTypeEnum,
  VerticalSignalingEnum,
  VerticalSignalizationTypeEnum,
  WarningVerticalEnum,
} from 'src/enumerators';
import {
  Area,
  Contract,
  ContractDeviceItem,
  ContractHorizontalItem,
  ContractVerticalItemSign,
  ContractVerticalItemSupport,
  Member,
  Path,
  Region,
} from 'src/models';
import { IRest } from './rest.interface';

export class Project implements IRest {
  className = 'Projeto';
  id: string;
  name: string;
  cost: number;
  regions: Array<Region>;
  regionIds: Array<string>;
  areas: Array<Area>;
  areaIds: Array<string>;
  paths: Array<Path>;
  pathIds: Array<string>;
  contract: Contract;
  contractId: string;
  workspaceId: string;
  description: string;
  createdByMember: Member;
  createdByMemberId: string;
  createdAt: Date;
  updatedAt: Date;

  projectTrafficSigns: ProjectTrafficSign[];
  projectSupports: ProjectSupport[];
  projectDeviceSignalings: ProjectDeviceSignaling[];
  projectHorizontalSignalings: ProjectHorizontalSignaling[];

  // Atributos Fora do Modelo
  totalSignalings: number;
  quantityRegistered: number;
  history: Array<ProjectHistory>;

  projectFiles: ProjectFile[];

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      cost: this.cost,
      regions: this.regions,
      regionIds: this.regionIds,
      areas: this.areas,
      areaIds: this.areaIds,
      paths: this.paths,
      pathIds: this.pathIds,
      contractId: this.contractId,
      workspaceId: this.workspaceId,
      description: this.description,
      createdByMemberId: this.createdByMemberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,

      projectTrafficSigns: this.projectTrafficSigns.map((v) => v.Serialize()),
      projectSupports: this.projectSupports.map((v) => v.Serialize()),
      projectDeviceSignalings: this.projectDeviceSignalings.map((v) => v.Serialize()),
      projectHorizontalSignalings: this.projectHorizontalSignalings.map((v) => v.Serialize()),
      projectFiles: this.projectFiles,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id || undefined;
      this.name = data.name || '';
      this.cost = data.cost || 0;
      this.regionIds = data.regionIds || [];
      this.regions = (data.regions || []).map((v) => new Region(v));
      this.areaIds = data.areaIds || [];
      this.areas = (data.areas || []).map((v) => new Area(v));
      this.pathIds = data.pathIds || [];
      this.paths = (data.paths || []).map((v) => new Path(v));
      this.contractId = data.contractId || undefined;
      this.workspaceId = data.workspaceId || undefined;
      this.description = data.description || '';
      this.createdByMemberId = data.createdByMemberId || undefined;
      this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date(2000, 1, 1);
      this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date(2000, 1, 1);
      this.projectTrafficSigns = (data.projectTrafficSigns || []).map((v) => new ProjectTrafficSign(v));
      this.projectSupports = (data.projectSupports || []).map((v) => new ProjectSupport(v));
      this.projectDeviceSignalings = (data.projectDeviceSignalings || []).map((v) => new ProjectDeviceSignaling(v));
      this.projectHorizontalSignalings = (data.projectHorizontalSignalings || []).map(
        (v) => new ProjectHorizontalSignaling(v),
      );
      this.projectFiles = data['projectFiles'] || null
    }
    return this;
  }
}

export class ProjectTrafficSign implements IRest {
  className = 'Itens de Traffic do Projeto';
  id: string;
  projectId: string;
  contractVerticalItem: ContractVerticalItemSign;
  contractVerticalItemId: string;
  quantity: number;
  verticalType: VerticalSignalizationTypeEnum;
  verticalCode: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  signCode: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  dimensionType: DimensionInformationEnum;
  sideLength: number;
  diameter: number;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  complementInformationType: ComplementInformationEnum;
  complementInformation: string;
  warranty: Date;
  maximumRetroreflectionValue: number;
  minimumRetroreflectionValue: number;
  quantityRegistered: number;
  cost: number;
  type: SignalingTypeEnum;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      contractVerticalItem: this.contractVerticalItem.Serialize(),
      contractVerticalItemId: this.contractVerticalItemId,
      quantity: this.quantity,
      verticalType: this.verticalType,
      verticalCode: this.verticalCode,
      dimensionType: this.dimensionType,
      sideLength: this.sideLength,
      diameter: this.diameter,
      dimensionX: this.dimensionX,
      dimensionY: this.dimensionY,
      dimension: this.dimension,
      complementInformationType: this.complementInformationType,
      complementInformation: this.complementInformation,
      warranty: this.warranty,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      quantityRegistered: this.quantityRegistered,
      cost: this.cost,
      type: this.type,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id || undefined;
      this.projectId = data.projectId || undefined;
      this.contractVerticalItem = new ContractVerticalItemSign(data.contractVerticalItem || {});
      this.contractVerticalItemId = data.contractVerticalItemId || undefined;
      this.quantity = data.quantity || null;
      this.verticalType = data.verticalType || undefined;
      this.verticalCode = data.verticalCode || undefined;
      this.signCode = data.verticalCode || undefined;
      this.dimensionType = data.dimensionType || DimensionInformationEnum.Efetiva;
      this.sideLength = data.sideLength || 0;
      this.diameter = data.diameter || 0;
      this.dimensionX = data.dimensionX || 0;
      this.dimensionY = data.dimensionY || 0;
      this.dimension = data.dimension || 0;
      this.complementInformationType = data.complementInformationType || null;
      this.complementInformation = data.complementInformation || null;
      this.warranty = data.warranty || new Date(2000, 1, 1);
      this.maximumRetroreflectionValue = data.maximumRetroreflectionValue || null;
      this.minimumRetroreflectionValue = data.minimumRetroreflectionValue || null;
      this.quantityRegistered = data.quantityRegistered || undefined;
      this.cost = data.cost || 0;
      this.type = SignalingTypeEnum.Vertical;
    }
    return this;
  }
}

export class ProjectSupport implements IRest {
  className = 'Itens do Suporte do Projeto';
  id: string;
  projectId: string;
  contractVerticalItem: ContractVerticalItemSupport;
  contractVerticalItemId: string;
  quantity: number;
  quantityRegistered: number;
  cost: number;
  type: VerticalGroupTypeEnum;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      contractVerticalItem: this.contractVerticalItem.Serialize(),
      contractVerticalItemId: this.contractVerticalItemId,
      quantity: this.quantity,
      quantityRegistered: this.quantityRegistered,
      cost: this.cost,
      type: VerticalGroupTypeEnum.Support,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id || undefined;
      this.projectId = data.projectId || undefined;
      this.contractVerticalItem = new ContractVerticalItemSupport(data.contractVerticalItem || {});
      this.contractVerticalItemId = data.contractVerticalItemId || undefined;
      this.quantity = data.quantity || null;
      this.quantityRegistered = data.quantityRegistered ?? undefined;
      this.cost = data.cost || 0;
      this.type = VerticalGroupTypeEnum.Support;
    }
    return this;
  }
}

export class ProjectDeviceSignaling implements IRest {
  className = 'Itens de Dispositivos do Projeto';
  id: string;
  projectId: string;
  contractDeviceItem: ContractDeviceItem;
  contractDeviceItemId: string;
  deviceCode: DeviceSignalingEnum;
  signCode: DeviceSignalingEnum;
  quantity: number;
  units: number;
  dimension: number;
  warranty: Date;
  quantityRegistered: number;
  cost: number;
  type: SignalingTypeEnum;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      contractDeviceItem: this.contractDeviceItem.Serialize(),
      contractDeviceItemId: this.contractDeviceItemId,
      deviceCode: this.deviceCode,
      quantity: this.quantity || 1,
      units: this.units || 1,
      dimension: this.dimension,
      warranty: this.warranty,
      quantityRegistered: this.quantityRegistered,
      cost: this.cost,
      type: this.type,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id || undefined;
      this.projectId = data.projectId || undefined;
      this.contractDeviceItem = new ContractDeviceItem(data.contractDeviceItem || {});
      this.contractDeviceItemId = data.contractDeviceItemId || undefined;
      this.deviceCode = data.deviceCode || undefined;
      this.signCode = data.deviceCode || undefined;
      this.quantity = data.quantity || 1;
      this.units = data.units || 1;
      this.dimension = data.dimension || 0;
      this.warranty = data.warranty || new Date(2000, 1, 1);
      this.quantityRegistered = data.quantityRegistered || undefined;
      this.cost = data.cost || 0;
      this.type = SignalingTypeEnum.Device;
    }
    return this;
  }
}

export class ProjectHorizontalSignaling implements IRest {
  className = 'Itens de Horizontal do Projeto';
  id: string;
  projectId: string;
  contractHorizontalItem: ContractHorizontalItem;
  contractHorizontalItemId: string;
  quantity: number;
  horizontalCode: HorizontalSignalingEnum;
  signCode: HorizontalSignalingEnum;
  units: number;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  warranty: Date;
  maximumRetroreflectionValue: number;
  minimumRetroreflectionValue: number;
  quantityRegistered: number;
  cost: number;
  type: SignalingTypeEnum;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      contractHorizontalItem: this.contractHorizontalItem.Serialize(),
      contractHorizontalItemId: this.contractHorizontalItemId,
      quantity: this.quantity,
      horizontalCode: this.horizontalCode,
      units: this.units,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX,
      dimensionY: this.dimensionY,
      dimension: this.dimension,
      warranty: this.warranty,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      quantityRegistered: this.quantityRegistered,
      cost: this.cost,
      type: this.type,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id || undefined;
      this.projectId = data.projectId || undefined;
      this.contractHorizontalItem = new ContractHorizontalItem(data.contractHorizontalItem || {});
      this.contractHorizontalItemId = data.contractHorizontalItemId || undefined;
      this.quantity = data.quantity || null;
      this.horizontalCode = data.horizontalCode || undefined;
      this.signCode = data.horizontalCode || undefined;
      this.units = data.units || 0;
      this.dimensionType = data.dimensionType || DimensionInformationEnum.Efetiva;
      this.dimensionX = data.dimensionX || 0;
      this.dimensionY = data.dimensionY || 0;
      this.dimension = data.dimension || 0;
      this.warranty = data.warranty || new Date(2000, 1, 1);
      this.maximumRetroreflectionValue = data.maximumRetroreflectionValue || null;
      this.minimumRetroreflectionValue = data.minimumRetroreflectionValue || null;
      this.quantityRegistered = data.quantityRegistered || undefined;
      this.cost = data.cost || 0;
      this.type = SignalingTypeEnum.Horizontal;
    }
    return this;
  }
}
export class ProjectHistory implements IRest {
  className = 'Itens de Dispositivos do Projeto';
  id: string;
  projectId: string;
  memberId: string;
  action: ActionEnum;
  createdAt: Date;
  member: Member;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      memberId: this.memberId,
      action: this.action,
      createdAt: this.createdAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data.id;
      this.projectId = data.projectId;
      this.memberId = data.memberId;
      this.action = data.action;
      this.createdAt = data.createdAt;
    }
    return this;
  }
}

export class ProjectFile implements IRest {
  className = 'Arquivos do Projeto';

  id: string;
  projectId: string;
  fileId: string;
  file: any;
  masterFile: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) {
      this.Deserialize(data);
    }
  }

  Serialize() {
    const out = {
      id: this.id,
      projectId: this.projectId,
      fileId: this.fileId,
      file: this.file,
      masterFile: this.masterFile,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.projectId = data['projectId'] || undefined;
      this.fileId = data['fileId'] || undefined;
      this.file = data['file'] || null
      this.masterFile = data['masterFile'] || false;
      this.createdAt = new Date(data['createdAt'] || 2000)
      this.updatedAt = new Date(data['updatedAt'] || 2000)
    }
    return this;
  }
}
