import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ServiceOrderStatusEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { ServiceOrder, ServiceOrderSignalingsProgress } from 'src/models';
import { AuthService } from './auth.service';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceOrderService extends RestApiService {
  private _serviceOrders = '/service-orders';
  private _serviceOrdersId = '/service-orders/:id';
  private _serviceOrdersWithAmount = '/service-orders/:id?amount=true';
  private _serviceOrdersFiles = '/service-orders/:id/files';
  private _urlServiceFilesId = '/service-orders/files/:id';
  private _serviceOrdersRemoveCollection = '/service-orders/remove-collection';
  private _urlCalculationMemoryReport = '/service-orders/calculation-memory-report';
  private _urlServiceOrderProgress = '/service-orders/signalings/progress';
  private _urlServiceOrderRequesters = '/service-orders/requesters'

  private environmentEndpoint = environment.endpoint();

  constructor(public http: HttpClient, private _auth: AuthService) {
    super(http);
  }

  // GET Retorna as ordens
  getOrders() {
    return this.get(this._serviceOrders, ServiceOrder);
  }

  // GET Retorna a ordem pela ID
  getOrderById(orderId: string) {
    return this.get(this._serviceOrdersWithAmount.replace(':id', orderId), ServiceOrder);
  }

  // POST para Criar a Ordem de serviço
  createServiceOrder(body: ServiceOrder) {
    return this.post(this._serviceOrders, body, ServiceOrder);
  }

  // PUT atualiza a ordem de serviço
  updateServiceOrder(body: ServiceOrder) {
    return this.put(this._serviceOrdersId.replace(':id', body.id), body, ServiceOrder);
  }

  // PUT atualiza apenas o status da ordem de serviço
  updateServiceOrderStatus(body: { id: string; status: ServiceOrderStatusEnum }) {
    const url = this.environmentEndpoint + this._serviceOrdersId.replace(':id', body.id);
    return this.http.put(url, body);
  }

  // DELETE Remove a collection de ordem de servico
  removeServiceOrderCollection(orderIds: string[]) {
    const url = this.environmentEndpoint + this._serviceOrdersRemoveCollection;
    let params = new HttpParams().set('serviceOrderIds', JSON.stringify(orderIds));
    return this.http.delete(url, { params: params });
  }

  // Salva arquivos de OS
  uploadServiceOrderFiles(files: [{ file: File; url: SafeResourceUrl }], orderId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }

    const url = this.environmentEndpoint + this._serviceOrdersFiles.replace(':id', orderId);
    return this.http.post(url, formData, { headers: reqHeaders });
  }
  // Baixa o arquivo da O.S
  downloadServiceOrderFile(fileId: string) {
    const url = this.environmentEndpoint + this._urlServiceFilesId.replace(':id', fileId);
    return this.http.get<{ url: string }>(url);
  }

  // Deleta o arquivo da O.S
  deleteServiceOrderFile(fileId: string) {
    const url = this.environmentEndpoint + this._urlServiceFilesId.replace(':id', fileId);
    return this.http.delete(url);
  }

  // Emite um relatório de memória de cálculo
  emitCalculationMemoryReport(serviceOrderIds: string[], financial: boolean, executionDate?: Date, endDate?: Date, requesterList?: Array<string>) {
    const url = this.environmentEndpoint + this._urlCalculationMemoryReport;
    const params = new HttpParams().appendAll({
      serviceOrderIds: JSON.stringify(serviceOrderIds),
      financial: true,
      executionDate: (executionDate || new Date(2020, 4, 2)).toISOString(),
      endDate: (endDate || new Date()).toISOString(),
      requesters: requesterList?.length ? JSON.stringify(requesterList) : '',
    });
    return this.http.get(url, { params: params });
  }

  // retorna as ordens com intervalo de tempo
  getServiceOrdersWithRange(startDate: Date, endDate: Date) {
    const url = this.environmentEndpoint + this._serviceOrders;
    const params = new HttpParams().appendAll({
      startDate: (startDate || new Date(2020, 4, 2)).toISOString(),
      endDate: (endDate || new Date()).toISOString(),
    });
    return this.http.get(url, { params: params });
  }

  // retorna o progresso de cada ordem
  getServiceOrderProgress() {
    const url = this._urlServiceOrderProgress;
    return this.get(url, ServiceOrderSignalingsProgress);
  }

  getServiceOrderRequesters() {
    const url = this.environmentEndpoint + this._urlServiceOrderRequesters;
    return this.http.get(url);
  }
}
