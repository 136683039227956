import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from 'src/app/services';
import {
  DimensionInformationEnum, HorizontalSignalingDimensionSettingTypeEnum, HorizontalWithHeight, HorizontalWithoutHeight, SignalingTypeEnum,
  TypeSignDimensionEnum,
  VerticalCircular,
  VerticalLozenge,
  VerticalOctagonal,
  VerticalRectangular,
  VerticalSignalingEnum, VerticalSignalizationTypeEnum, VerticalTriangular
} from 'src/enumerators';
import { CadenceHorizontalSignaling, ContinuosHorizontalSignaling, HorizontalSignalingEnum } from 'src/enumerators/horizontal-signaling.enum';
import { SettingsDimesionHorizontalData, SettingsDimesionTrafficData } from 'src/models';

@Component({
  selector: 'app-dimension-information',
  templateUrl: './dimension-information.component.html',
  styleUrls: ['./dimension-information.component.css']
})
export class DimensionInformationComponent implements OnInit, OnChanges {

  dimensionInformationEnum = DimensionInformationEnum // para verificar estado do radio button
  typeSignEnum = SignalingTypeEnum;
  verticalSignaling = VerticalSignalingEnum;
  verticalSignalingType = VerticalSignalizationTypeEnum;
  // Enums para dimensões
  horizontalWithHeight = HorizontalWithHeight;
  horizontalWithoutHeight = HorizontalWithoutHeight;
  verticalCircular = VerticalCircular;
  verticalTriangular = VerticalTriangular;
  verticalRectangular = VerticalRectangular;
  verticalLozenge = VerticalLozenge;
  verticalOctagonal = VerticalOctagonal;
  dimensionLocked1 = false; // Trava a dimensão total caso escreva alguma outra dimensão
  dimensionLocked2 = false; // Trava a dimensão total caso escreva alguma outra dimensão

  @Input() displayDirection: 'column' | 'row' = 'row'; // Orientação dos itens para aplicar em Tarefa e Cadastro
  @Input() dimensionInformationState: {
    dimensionType: DimensionInformationEnum,
    dimension: number,
    dimensionX: number,
    dimensionY: number,
    diameter: number,
    sideLength: number,
    units: number,
    quantity: number,
    dash: number,
    spacing: number
  } = {
      dimensionType: DimensionInformationEnum.Envolvente,
      dimension: 0,
      dimensionX: 0,
      dimensionY: 0,
      diameter: 0,
      sideLength: 0,
      units: 0,
      quantity: 0,
      dash: 0,
      spacing: 0
    }; // Seta valor inicial e completa os dados quando na edição;
  @Input() projectSignalingItemId = '';
  @Input() stockItemId = '';
  @Input() verticalType = undefined;
  @Input() signCode = undefined;
  @Input() switchId = '';
  @Input() indexTraffic = 0;
  @Input() signType: SignalingTypeEnum;
  @Input() idSign; // Caso seja edição
  @Output() dimensionInformationEmmitter = new EventEmitter<{
    dimensionType: DimensionInformationEnum,
    dimension: number,
    dimensionX: number,
    dimensionY: number,
    diameter: number,
    sideLength: number,
    units: number,
    quantity: number,
    dash: number,
    spacing: number
  }>(); // Emite os valores para o componente pai;
  dimensionHorizontalList: SettingsDimesionHorizontalData[] = [];
  dimensionTrafficList: SettingsDimesionTrafficData[] = [];
  parseFloat = parseFloat;

  //variáveis relacionadas com cadência
  hasCadence: boolean = false; //flag para exibir cadência na interface
  cadenceHorizontalSignaling = Object.values(CadenceHorizontalSignaling);
  continuosHorizontalSignaling = Object.values(ContinuosHorizontalSignaling);

  constructor(
    private _settingsService: SettingsService
  ) { }


  ngOnInit(): void {
    this.hasCadence = this.cadenceHorizontalSignaling.includes(this.signCode);
    
    if (this.idSign) {
      this.setAutoCompleteDimension(this.dimensionInformationState);
    }
    this.getDimensionSettings();
  }

  // Esse caso aqui é mais pra se tiver projeto, o resto funciona de boa
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.signCode) {
      this.signCode = changes.signCode.currentValue;
      this.hasCadence = this.cadenceHorizontalSignaling.includes(changes.signCode.currentValue);

      if (this.hasCadence) this.calculateUnits();
      if (this.continuosHorizontalSignaling.includes(this.signCode)) {
        if (this.signCode == HorizontalSignalingEnum.LFO3 || this.signCode == HorizontalSignalingEnum.MFE) {
          this.dimensionInformationState.quantity = 2;
        } else {
          this.dimensionInformationState.quantity = 1;
        }
      }
    }
    if (changes.dimensionInformationState) {
      this.setAutoCompleteDimension(changes.dimensionInformationState.currentValue);
    }
  }

  // Emite os valores para o componente pai, ele apaga todos os valores que não são usados
  emitDimensionInformation() {
    if (this.signType == SignalingTypeEnum.Vertical) {

      if (this.dimensionInformationState.dimensionType == DimensionInformationEnum.Envolvente) {
        this.dimensionInformationState.sideLength = 0;
        this.dimensionInformationState.diameter = 0;

      } else {
        if (this.verticalType == VerticalSignalizationTypeEnum.Regulation && !(this.signCode == VerticalSignalingEnum.R1 || this.signCode == VerticalSignalingEnum.R2)) {
          this.dimensionInformationState.sideLength = 0;
          this.dimensionInformationState.dimensionX = 0;
          this.dimensionInformationState.dimensionY = 0;

        } else if ((this.signCode == VerticalSignalingEnum.R1 || this.signCode == VerticalSignalingEnum.R2)) {
          this.dimensionInformationState.diameter = 0;
          this.dimensionInformationState.sideLength = 0;
          this.dimensionInformationState.dimensionX = 0;

        } else {
          this.dimensionInformationState.diameter = 0;
          this.dimensionInformationState.dimensionX = 0;
          this.dimensionInformationState.dimensionY = 0;
        }
      }
    } else if (this.signType == SignalingTypeEnum.Horizontal) {
      if (this.dimensionInformationState.dimensionType == DimensionInformationEnum.Envolvente) {
        this.dimensionInformationState.quantity = 0;
      }
    }

    this.dimensionInformationEmmitter.emit(this.dimensionInformationState);
  }

  // Compara o tipo de dimensão passada com o tipo da sinalização
  checkedDimensionType(dimensionType): boolean {
    if (dimensionType == this.dimensionInformationState.dimensionType) {
      return true;
    }
    return false;
  }

  // Altera o tipo da dimensão na sinalização
  changeDimensionType(dimensionType) {

    this.dimensionInformationState.dimensionType = dimensionType;
    setTimeout(() => {
      if (dimensionType == DimensionInformationEnum.Efetiva) {
        this.calculateDimension('totalDimension2');
        this.dimensionLocked1 = true;
        this.dimensionLocked2 = false;
      } else {
        this.calculateDimension('totalDimension1');
        this.dimensionLocked1 = false;
        this.dimensionLocked2 = true;
      }
    }, 200);
  }

  // Cálculo para o valor total de cada tipo de placa
  calculateDimension(idDimension:string) {
    if (this.signType == SignalingTypeEnum.Vertical) {

      if (this.dimensionInformationState.dimensionType == DimensionInformationEnum.Envolvente) {
        this.dimensionInformationState.dimension =
          this.removeSpace(this.dimensionInformationState.dimensionX) * (this.removeSpace(this.dimensionInformationState.dimensionY));

      } else {

        if (this.verticalType == VerticalSignalizationTypeEnum.Regulation) {

          if (this.signCode == VerticalSignalingEnum.R1) {
            this.dimensionInformationState.dimension =
              2 * (this.removeSpace(this.dimensionInformationState.dimensionY) ** 2) * (Math.sqrt(2) - 1);

          } else if (this.signCode == VerticalSignalingEnum.R2) {
            this.dimensionInformationState.dimension =
              ((this.removeSpace(this.dimensionInformationState.dimensionY) ** 2) * (Math.sqrt(3))) / 3;

          } else {
            this.dimensionInformationState.dimension =
              (this.removeSpace(this.dimensionInformationState.diameter) / 2) ** 2 * Math.PI;

          }
        } else {
          this.dimensionInformationState.dimension = this.removeSpace(this.dimensionInformationState.sideLength) ** 2;

        }
      }
      (document.getElementById(idDimension + this.switchId) as HTMLInputElement).value = (this.dimensionInformationState.dimension ? this.dimensionInformationState.dimension.toFixed(3) : '');

    } else if (this.signType == SignalingTypeEnum.Horizontal) {
      this.dimensionInformationState.dimension = this.removeSpace(this.dimensionInformationState.dimensionX) * this.removeSpace(this.dimensionInformationState.dimensionY);

      if (this.dimensionInformationState.dimensionType == DimensionInformationEnum.Efetiva) {
        this.dimensionInformationState.dimension = this.dimensionInformationState.dimension * (this.dimensionInformationState.quantity);

      }
      (document.getElementById(idDimension + this.switchId) as HTMLInputElement).value = (this.dimensionInformationState.dimension ? this.dimensionInformationState.dimension.toFixed(3) : '');
    }
    this.emitDimensionInformation();
  }

  calculateUnits() {
    if (this.dimensionInformationState.dash && this.dimensionInformationState.spacing) {
      const dash = typeof this.dimensionInformationState.dash == 'string' ? parseFloat(this.dimensionInformationState.dash) : this.dimensionInformationState.dash;
      const spacing = typeof this.dimensionInformationState.spacing == 'string' ? parseFloat(this.dimensionInformationState.spacing) : this.dimensionInformationState.spacing;
      const totalLength = dash + spacing;
      this.dimensionInformationState.quantity = dash / totalLength;

      if (this.signCode == HorizontalSignalingEnum.MFR) {
        this.dimensionInformationState.quantity += this.dimensionInformationState.quantity;
      } else if (this.signCode == HorizontalSignalingEnum.LFO4) {
        this.dimensionInformationState.quantity += 1;
      }

      const areaHTMLElement = (document.getElementById('totalDimension2' + this.switchId)) as HTMLElement;
      if(areaHTMLElement) this.calculateDimension('totalDimension2');
    }
  }

  clearCadence() {
    this.dimensionInformationState.dash = 0;
    this.dimensionInformationState.spacing = 0;
    (document.getElementById('dash' + this.switchId) as HTMLInputElement).value = '0';
    (document.getElementById('spacing' + this.switchId) as HTMLInputElement).value = '0';
  }

  // Inicia os valores caso seja edição
  setAutoCompleteDimension(value) {
    setTimeout(() => {
      if (this.dimensionInformationState.dimensionType == DimensionInformationEnum.Envolvente) {
        (document.getElementById('dimensionY1' + this.switchId) as HTMLInputElement).value = value.dimensionY;
        (document.getElementById('dimensionX1' + this.switchId) as HTMLInputElement).value = value.dimensionX;
        (document.getElementById('totalDimension1' + this.switchId) as HTMLInputElement).value = value.dimension;
        this.dimensionLocked1 = false;
        this.dimensionLocked2 = true;

      } else {
        if (this.signType == SignalingTypeEnum.Vertical) {
          if (!(this.signCode == this.verticalSignaling.R1 || this.signCode == this.verticalSignaling.R2)
            && this.verticalType == this.verticalSignalingType.Regulation)
            (document.getElementById('diameter' + this.switchId) as HTMLInputElement).value = value.diameter;

          else if ((this.signCode == this.verticalSignaling.R1 || this.signCode == this.verticalSignaling.R2))
            (document.getElementById('dimensionY2' + this.switchId) as HTMLInputElement).value = value.dimensionY;

          else
            (document.getElementById('sideLength' + this.switchId) as HTMLInputElement).value = value.sideLength;

          (document.getElementById('totalDimension2' + this.switchId) as HTMLInputElement).value = value.dimension;
          this.dimensionLocked1 = true;
          this.dimensionLocked2 = false;

        } else if (this.signType == SignalingTypeEnum.Horizontal) {
          (document.getElementById('dimensionY2' + this.switchId) as HTMLInputElement).value = value.dimensionY;
          (document.getElementById('dimensionX2' + this.switchId) as HTMLInputElement).value = value.dimensionX;
          (document.getElementById('quantity' + this.switchId) as HTMLInputElement).value = value.quantity;
          (document.getElementById('totalDimension2' + this.switchId) as HTMLInputElement).value = value.dimension;
          if(this.hasCadence) {
            (document.getElementById('dash' + this.switchId) as HTMLInputElement).value = value.dash;
            (document.getElementById('spacing' + this.switchId) as HTMLInputElement).value = value.spacing;
          }
          this.dimensionLocked1 = true;
          this.dimensionLocked2 = false;
        } else {
          (document.getElementById('units' + this.switchId) as HTMLInputElement).value = value.units;
          (document.getElementById('extension' + this.switchId) as HTMLInputElement).value = value.dimension;
        }
      }
    }, 200);  // Timeout serve pq alguns componentes podem não existir na hora que vou usar os mesmos
  }


  // Recebe a string e troca ' ' por ''
  removeSpace(value: any) {
    if (value) {
      let parsedValue = typeof value === 'number' ? value.toString() : value;
      const formatedValue = parsedValue.replace(' ', '');
      return formatedValue;
    } else {
      return null;
    }
  }

  getDimensionSettings() {
    this._settingsService.getHorizontalDimensionsSettings().subscribe((res: SettingsDimesionHorizontalData[]) => {
      this.dimensionHorizontalList = res;
    })

    this._settingsService.getTrafficDimensionsSettings().subscribe((res: SettingsDimesionTrafficData[]) => {
      this.dimensionTrafficList = res;
    })
  }

  filterHorizontalDimensionList(): SettingsDimesionHorizontalData[] {

    if (Object.values(HorizontalWithHeight).includes(this.signCode))
      return this.dimensionHorizontalList.filter(d => d.type == HorizontalSignalingDimensionSettingTypeEnum.WithHeight);

    else if (Object.values(HorizontalWithoutHeight).includes(this.signCode))
      return this.dimensionHorizontalList.filter(d => d.type == HorizontalSignalingDimensionSettingTypeEnum.WithoutHeight);

    else return [];
  }

  filterTrafficDimensionList(): SettingsDimesionTrafficData[] {
    if (Object.values(VerticalCircular).includes(this.signCode))
      return this.dimensionTrafficList.filter(d => d.shape == TypeSignDimensionEnum.Circular && d.dimensionType == this.dimensionInformationState.dimensionType);

    else if (Object.values(VerticalOctagonal).includes(this.signCode))
      return this.dimensionTrafficList.filter(d => d.shape == TypeSignDimensionEnum.Octagonal && d.dimensionType == this.dimensionInformationState.dimensionType);

    else if (Object.values(VerticalLozenge).includes(this.signCode))
      return this.dimensionTrafficList.filter(d => d.shape == TypeSignDimensionEnum.Lozenge && d.dimensionType == this.dimensionInformationState.dimensionType);

    else if (Object.values(VerticalRectangular).includes(this.signCode))
      return this.dimensionTrafficList.filter(d => d.shape == TypeSignDimensionEnum.Rectangular && d.dimensionType == this.dimensionInformationState.dimensionType);

    else if (Object.values(VerticalTriangular).includes(this.signCode))
      return this.dimensionTrafficList.filter(d => d.shape == TypeSignDimensionEnum.Triangular && d.dimensionType == this.dimensionInformationState.dimensionType);

    else return [];
  }

  setDimensions(dimensionSetting) {
    this.dimensionInformationState.dimension = dimensionSetting?.dimension;
    this.dimensionInformationState.dimensionX = dimensionSetting?.dimensionX;
    this.dimensionInformationState.dimensionY = dimensionSetting?.dimensionY;
    this.dimensionInformationState.diameter = dimensionSetting?.diameter;
    this.dimensionInformationState.sideLength = dimensionSetting?.sideLength;
    this.emitDimensionInformation();
  }
}
