import {
  ComplementInformationEnum,
  DeviceSignalingEnum,
  DimensionInformationEnum,
  IndicationVerticalEnum,
  RegulationVerticalEnum,
  StockedSignalingsActionEnum,
  StockInputTypeEnum,
  VerticalSignalizationTypeEnum,
  WarningVerticalEnum,
} from 'src/enumerators';
import { Contract, ContractDeviceItem, ContractVerticalItemSign, ContractVerticalItemSupport } from './contract';
import { IRest } from './rest.interface';

export class Stock implements IRest {
  className: 'Estoque';

  id: string;
  contractId: string;
  contract: Contract;
  inputType: StockInputTypeEnum;
  stockedTrafficSign: StockedTrafficSign;
  stockedSupport: StockedSupport;
  stockedDeviceSignaling: StockedDeviceSignaling;
  createdAt: Date;
  cost: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      contract: this.contract.Serialize(),
      inputType: this.inputType,
      stockedTrafficSign: this.stockedTrafficSign.Serialize(),
      stockedSupport: this.stockedSupport.Serialize(),
      stockedDeviceSignaling: this.stockedDeviceSignaling.Serialize(),
      createdAt: this.createdAt.toISOString(),
      cost: this.cost,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.contract = new Contract(data['contract'] || {});
    this.inputType = data['inputType'] || null;
    this.stockedTrafficSign = new StockedTrafficSign(data['stockedTrafficSign'] || {});
    this.stockedSupport = new StockedSupport(data['stockedSupport'] || {});
    this.stockedDeviceSignaling = new StockedDeviceSignaling(data['stockedDeviceSignaling'] || {});
    this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 1, 1);
    this.cost = data['cost'] || 0;
    return this;
  }
}

export class StockedTrafficSign implements IRest {
  className: 'Entrada de Placa em Estoque';

  id: string;
  contractId: string;
  complementInformation: string;
  complementInformationType: ComplementInformationEnum;
  contractVerticalItemId: string;
  contractVerticalItem: ContractVerticalItemSign;
  quantity: number;
  verticalType: VerticalSignalizationTypeEnum;
  verticalCode: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  dimensionType: DimensionInformationEnum;
  dimensionX: number;
  dimensionY: number;
  dimension: number;
  diameter: number;
  sideLength: number;
  cost: number;
  warranty: Date;
  maximumRetroreflectionValue?: number;
  minimumRetroreflectionValue?: number;
  quantityRegistered: number;
  inputType: StockInputTypeEnum;
  createdAt: Date;
  observation: string;
  responsibleMemberId: string;

  historic: StockedTrafficSignHistory[]

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      complementInformation: this.complementInformation,
      complementInformationType: this.complementInformationType,
      contractVerticalItemId: this.contractVerticalItemId,
      contractVerticalItem: this.contractVerticalItem.Serialize(),
      quantity: this.quantity || 0,
      verticalType: this.verticalType,
      verticalCode: this.verticalCode,
      dimensionType: this.dimensionType,
      dimensionX: this.dimensionX,
      dimensionY: this.dimensionY,
      dimension: this.dimension,
      diameter: this.diameter,
      sideLength: this.sideLength,
      warranty: this.warranty,
      cost: this.cost,
      maximumRetroreflectionValue: this.maximumRetroreflectionValue,
      minimumRetroreflectionValue: this.minimumRetroreflectionValue,
      quantityRegistered: this.quantityRegistered,
      createdAt: this.createdAt,
      observation: this.observation,
      responsibleMemberId: this.responsibleMemberId,
      historic: this.historic.map(v => v.Serialize())
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || '';
    this.contractId = data['contractId'] || undefined;
    this.complementInformation = data['complementInformation'] || '';
    this.complementInformationType = data['complementInformationType'] || undefined;
    this.contractVerticalItemId = data['contractVerticalItemId'] || '';
    this.contractVerticalItem = new ContractVerticalItemSign(data['contractVerticalItem'] || {});
    this.quantity = data['quantity'] || 0;
    this.verticalCode = data['verticalCode'] || '';
    this.verticalType = data['verticalType'] || '';
    this.cost = data['cost'] || 0;
    this.dimensionType = data['dimensionType'] || DimensionInformationEnum.Envolvente;
    this.dimensionX = data['dimensionX'] || 0;
    this.dimensionY = data['dimensionY'] || 0;
    this.dimension = data['dimension'] || 0;
    this.diameter = data['diameter'] || 0;
    this.sideLength = data['sideLength'] || 0;
    this.warranty = data['warranty'] ? new Date(data['warranty']) : new Date(2000, 1, 1);
    this.maximumRetroreflectionValue = data['maximumRetroreflectionValue'] || 0;
    this.minimumRetroreflectionValue = data['minimumRetroreflectionValue'] || 0;
    this.quantityRegistered = data['quantityRegistered'] || 0;
    this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
    this.inputType = StockInputTypeEnum.TrafficSign;
    this.observation = data['observation'] || null;
    this.responsibleMemberId = data['responsibleMemberId'] || undefined;
    this.historic = (data['historic'] || []).map(v => new StockedTrafficSignHistory(v))

    return this;
  }
}

export class StockedTrafficSignHistory implements IRest {
  className: 'historico de entrada de placas';

  id: string;
  stockedTrafficSignId: string;
  data: object;
  action: StockedSignalingsActionEnum;
  memberId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      stockedTrafficSignId: this.stockedTrafficSignId,
      data: this.data,
      action: this.action,
      memberId: this.memberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.stockedTrafficSignId = data['stockedTrafficSignId'] || undefined;
    this.data = data['data'] || undefined;
    this.action = data['action'] || null;
    this.memberId = data['memberId'] || undefined;
    this.createdAt = data['createdAt'] || new Date(2000,1,1);
    this.updatedAt = data['updatedAt'] || new Date(2000,1,1)
    return this;
  }
}

export class StockedDeviceSignaling implements IRest {
  className: 'Entrada de Dispositivo em Estoque';

  id: string;
  contractId: string;
  contractDeviceItemId: string;
  contractDeviceItem: ContractDeviceItem;
  deviceCode: DeviceSignalingEnum;
  quantity: number;
  units: number;
  dimension: number;
  cost: number;
  warranty: Date;
  inputType: StockInputTypeEnum;
  createdAt: Date;
  quantityRegistered: number;
  observation: string;
  responsibleMemberId: string;

  historic: StockedDeviceSignalingHistory[]


  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      contractDeviceItemId: this.contractDeviceItemId || undefined,
      contractDeviceItem: this.contractDeviceItem.Serialize(),
      deviceCode: this.deviceCode,
      quantity: this.quantity,
      units: this.units,
      dimension: this.dimension,
      warranty: this.warranty,
      cost: this.cost,
      createdAt: this.createdAt,
      quantityRegistered: this.quantityRegistered,
      observation: this.observation,
      responsibleMemberId: this.responsibleMemberId,
      historic: this.historic.map(v => v.Serialize())

    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.contractDeviceItemId = data['contractDeviceItemId'] || undefined;
    this.contractDeviceItem = new ContractDeviceItem(data['contractDeviceItem'] || {});
    this.deviceCode = data['deviceCode'] || '';
    this.quantity = data['quantity'] || 0;
    this.units = data['units'] || 0;
    this.dimension = data['dimension'] || 0;
    this.cost = data['cost'] || 0;
    this.warranty = data['warranty'] ? new Date(data['warranty']) : new Date(2000, 1, 1);
    this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
    this.inputType = StockInputTypeEnum.Device;
    this.quantityRegistered = data['quantityRegistered'] || 0;
    this.observation = data['observation'] || null;
    this.responsibleMemberId = data['responsibleMemberId'] || undefined;
    this.historic = (data['historic'] || []).map(v => new StockedDeviceSignalingHistory(v))


    return this;
  }
}

export class StockedDeviceSignalingHistory implements IRest {
  className: 'historico de entrada de dispositivos';

  id: string;
  stockedDeviceSignalingId: string;
  data: object;
  action: StockedSignalingsActionEnum;
  memberId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      stockedDeviceSignalingId: this.stockedDeviceSignalingId,
      data: this.data,
      action: this.action,
      memberId: this.memberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.stockedDeviceSignalingId = data['stockedDeviceSignalingId'] || undefined;
    this.data = data['data'] || undefined;
    this.action = data['action'] || null;
    this.memberId = data['memberId'] || undefined;
    this.createdAt = data['createdAt'] || new Date(2000,1,1);
    this.updatedAt = data['updatedAt'] || new Date(2000,1,1)
    return this;
  }
}

export class StockedSupport implements IRest {
  className: 'Entrada de Suporte em Estoque';

  id: string;
  contractId: string;
  contractVerticalItemId: string;
  contractVerticalItem: ContractVerticalItemSupport;
  quantity: number;
  cost: number;
  inputType: StockInputTypeEnum;
  createdAt: Date;
  quantityRegistered: number;
  observation: string;
  responsibleMemberId: string;
  historic: StockedSupportHistory[]


  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      contractVerticalItemId: this.contractVerticalItemId || undefined,
      contractVerticalItem: this.contractVerticalItem.Serialize(),
      quantity: this.quantity,
      cost: this.cost,
      createdAt: this.createdAt,
      quantityRegistered: this.quantityRegistered,
      observation: this.observation,
      responsibleMemberId: this.responsibleMemberId,
      historic: this.historic.map(v => v.Serialize())
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.contractVerticalItemId = data['contractVerticalItemId'] || '';
    this.quantity = data['quantity'] || 0;
    this.cost = data['cost'] || 0;
    this.contractVerticalItem = new ContractVerticalItemSupport(data['contractVerticalItem'] || {});
    this.createdAt = data['createdAt'] || new Date(2000, 1, 1);
    this.inputType = StockInputTypeEnum.Support;
    this.quantityRegistered = data['quantityRegistered'] || 0;
    this.observation = data['observation'] || null;
    this.responsibleMemberId = data['responsibleMemberId'] || undefined;
    this.historic = (data['historic'] || []).map(v => new StockedSupportHistory(v))


    return this;
  }
}

export class StockedSupportHistory implements IRest {
  className: 'historico de entrada de dispositivos';

  id: string;
  stockedSupportId: string;
  data: object;
  action: StockedSignalingsActionEnum;
  memberId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      stockedSupportId: this.stockedSupportId,
      data: this.data,
      action: this.action,
      memberId: this.memberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.stockedSupportId = data['stockedSupportId'] || undefined;
    this.data = data['data'] || undefined;
    this.action = data['action'] || null;
    this.memberId = data['memberId'] || undefined;
    this.createdAt = data['createdAt'] || new Date(2000,1,1);
    this.updatedAt = data['updatedAt'] || new Date(2000,1,1)
    return this;
  }
}

export class TrafficSignMinimumStock implements IRest {
  className: 'Estoque minimo para placa';

  id: string;
  workspaceId: string;
  contractId: string;
  quantity: number;
  verticalCode: RegulationVerticalEnum | WarningVerticalEnum | IndicationVerticalEnum;
  additionalInformation: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      workspaceId: this.workspaceId,
      quantity: this.quantity,
      verticalCode: this.verticalCode,
      additionalInformation: this.additionalInformation,
   
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.workspaceId = data['workspaceId'] || undefined;
    this.quantity = data['quantity'] || 0;
    this.verticalCode = data['verticalCode'] || '';
    this.additionalInformation = data['additionalInformation'] || null
    return this;
  }
}

export class SupportMinimumStock implements IRest {
  className: 'Estoque minimo para suporte';

  id: string;
  workspaceId: string;
  contractId: string;
  quantity: number;
  contractSupportItem: ContractVerticalItemSupport;
  contractSupportItemId: string;
  additionalInformation: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      workspaceId: this.workspaceId,
      quantity: this.quantity,
      contractSupportItem: this.contractSupportItem.Serialize(),
      contractSupportItemId: this.contractSupportItemId || undefined,
      additionalInformation: this.additionalInformation,
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.workspaceId = data['workspaceId'] || undefined;
    this.quantity = data['quantity'] || 0;
    this.contractSupportItem = new ContractVerticalItemSupport(data['contractSupportItem'] || {});
    this.contractSupportItemId = data['contractSupportItemId'] || undefined;
    this.additionalInformation = data['additionalInformation'] || null
    return this;
  }
}

export class DeviceSignalingMinimumStock implements IRest {
  className: 'Estoque minimo para dispositivos';

  id: string;
  workspaceId: string;
  contractId: string;
  quantity: number;
  deviceCode: DeviceSignalingEnum;
  additionalInformation: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      workspaceId: this.workspaceId,
      quantity: this.quantity,
      deviceCode: this.deviceCode,
      additionalInformation: this.additionalInformation,
   
    };
    return out;
  }
  Deserialize(data: any) {
    this.id = data['id'] || undefined;
    this.contractId = data['contractId'] || undefined;
    this.workspaceId = data['workspaceId'] || undefined;
    this.quantity = data['quantity'] || 0;
    this.deviceCode = data['deviceCode'] || '';
    this.additionalInformation = data['additionalInformation'] || null
    return this;
  }
}
