<!-- Formaulario Vertical - Suporte -->
<!-- Formaulario Horizontal -->
<ng-container *ngFor="let sign of supportTaskActivities; index as indexOfSupport">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [isOpen]="sign?.isAccOpen">
      <div class="row mx-0 w-100" style="cursor: auto;" accordion-heading>
        <div class="col sign-container">

          <!-- SVG do Suporte -->
          <div class="svg-collapse" (click)="openAccordionGroup(sign)">
            <img [src]="sign?.contractVerticalItem?.supportMaterial ? (sign.contractVerticalItem.supportMaterial | enumSvg: 'Signaling') : ('assets/icons/v-support-task-icon.svg')" alt="" height="48px" width="48px">
          </div>
          <!-- SVG do Suporte -->



          <!-- Nome do Suporte -->
          <div class="col mt-2 cursor-pointer" *ngIf="!sign?.isAccOpen" (click)="openAccordionGroup(sign)">
            <div class="ml-2">
              <h4 class="item-title">Sinalização selecionada</h4>
              <p>{{sign.contractVerticalItem.supportMaterial ? (sign.contractVerticalItem.supportMaterial |
                enumText:'RoadSignSupport') : 'Não informado'}}</p>
            </div>
          </div>
          <!-- Nome do Suporte -->


          <!-- Formulário do Suporte -->
          <div class="col mt-2" *ngIf="sign?.isAccOpen">
            <div class="content-contractual">
              <form>
                <div class="my-3" *ngIf="stockEntries?.length && !isStock">
                  <div class="row mx-0 d-flex align-items-center justify-content-between">
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="supportStockOrContractRadio{{indexOfSupport + 1}}"
                        id="supportStockOrContractRadio{{indexOfSupport + 1}}1"
                        class="custom-control-input cursor-pointer" (change)="setBond('contract',indexOfSupport)"
                        [checked]="radioState[indexOfSupport]?.contract">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="supportStockOrContractRadio{{indexOfSupport + 1}}1">Item do
                        Contrato</label>
                    </div>
                    <div class="col custom-control custom-radio mb-2">
                      <input type="radio" name="supportStockOrContractRadio{{indexOfSupport + 1}}"
                        id="supportStockOrContractRadio{{indexOfSupport + 1}}2"
                        class="custom-control-input cursor-pointer" (change)="setBond('stock', indexOfSupport)"
                        [checked]="radioState[indexOfSupport]?.stock">
                      <label class="custom-control-label pl-2" style="font-size: 16px;"
                        for="supportStockOrContractRadio{{indexOfSupport + 1}}2">Vínculo
                        com Estoque</label>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="form-group col-2 pl-0">
                    <label for="quantity">Quantidade</label>
                    <input name="quantity" class="form-control" required placeholder="0" type="number"
                      [(ngModel)]="sign.quantity" (change)="emitValidation(); setCost(sign);" min="0"
                      oninput="if(this.value < 0) this.value = 0;">
                  </div>
                  <div class="form-group w-100" *ngIf="!radioState[indexOfSupport]?.stock">
                    <label for="contractVerticalItemId">Selecione o item do Contrato</label>
                    <select name="contractVerticalItemId" required class="form-control"
                      placeholder="Selecione o item do contrato"
                      (change)="sign.contractVerticalItemId = $event.target.value;setContractItem(sign); emitValidation()"
                      [(ngModel)]="sign.contractVerticalItemId">
                      <option value="" hidden>Selecione o item do Contrato</option>
                      <option *ngFor="let option of supportItems" [value]="option.id">{{option?.name}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group w-100" *ngIf="radioState[indexOfSupport]?.stock">
                    <label for="stockedTrafficSignId">Selecione o item do Estoque</label>
                    <select name="stockedTrafficSignId" class="form-control" [(ngModel)]="sign.stockedSupportId"
                      required (change)="setContractItem(sign, $event.target.value, indexOfSupport); emitValidation()">
                      <option [value]="undefined" hidden>Selecione...</option>
                      <option *ngFor="let item of stockEntries" [value]="item.id">
                        {{item.contractVerticalItem.name+ ' - '+(item.quantity - item.quantityRegistered)+'
                        disponíveis'}}
                      </option>
                    </select>
                  </div>

                </div>
              </form>


              <div class="row  px-4 justify-content-between align-items-center  my-2 py-2">
                <div class="col px-0">
                  <div>
                    <label for="signQuant" class="text-muted">Qnt. de Sinalizações</label>
                    <p>{{sign?.quantity || 0}}</p>
                  </div>
                </div>
                <div class="col px-0">
                  <label for="supportMaterial" class="text-muted">Tipo de Suporte</label>
                  <p>{{sign.contractVerticalItem.supportMaterial ? (sign.contractVerticalItem.supportMaterial |
                    enumText:'RoadSignSupport') : 'Não informado'}}</p>
                </div>
                <div class="col px-0">
                  <label for="signUnid" class="text-muted">Vr. Und. da Sinalização</label>
                  <p class="text-success">{{sign.contractVerticalItem ? (sign.quantity *
                    sign.contractVerticalItem.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
                <div class="col px-0">
                  <label for="itemValue" class="text-muted">Valor do Item</label>
                  <p>{{ sign.contractVerticalItem ? ((sign.contractVerticalItem.unitValue |
                    currency:'BRL':'R$')+'/'+(sign.contractVerticalItem.unit)) : ('R$0,00/--')}}</p>
                </div>
                <div class="col px-0">
                  <label for="totalValue" class="text-muted">Valor Total</label>
                  <p class="text-success">{{sign.contractVerticalItem ? (sign.quantity *
                    sign.contractVerticalItem.unitValue | currency:'BRL':'R$') : ('R$0,00')}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Formulário do Suporte -->


          <!-- Collapse -->
          <div class="collapse-button" (click)="openAccordionGroup(sign)">
            <i class="fa-regular fa-lg" [ngClass]="sign?.isAccOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
          </div>
          <!-- Collapse -->

        </div>
        <div class="col-auto px-0 pl-4" *ngIf="!isStock">
          <button class="btn btn-icon btn-icon-alt" (click)="removeActivity(indexOfSupport)">
            <i class="fa-regular fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
      </div>
    </accordion-group>
  </accordion>
</ng-container>
