// src/app/auth/auth-guard.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MemberRoleEnum, UserDutyEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { User } from 'src/models';
import { AuthService } from './auth.service';
import { OrganizationService } from './organization.service';
import { ToastService } from './toast.service';
@Injectable()
export class AuthGuardService {
  endpoint = environment.endpoint();
  _urlCheckInvite = '/organization/check-invite?accessCode=';
  _urlCheckPassToken = '/password-recovery?token=';
  accessCode: string;
  passToken: string;
  userSub: BehaviorSubject<User> = new BehaviorSubject(undefined);
  constructor(
    public _auth: AuthService,
    public _router: Router,
    private _http: HttpClient,
    private _activedRoute: ActivatedRoute,
    private _organizationService: OrganizationService,
    private _toastService: ToastService,
  ) { }

  //If false, we send the user back to '/login' page
  canActivate(): boolean {
    var userDuty: UserDutyEnum;
    if (!this._auth.loggedUser) {
      this._auth.currentUser().subscribe((user: User) => {
        this._auth.loggedUser = user;
        userDuty = this._auth.loggedUser.duty;
        if (userDuty == UserDutyEnum.Master) {
          this._router.navigate(['/master']);
        }
        this.userSub.next(user);
      }, error => {
        this._router.navigate(['/login']);
      });
    } else {
      this.userSub.next(this._auth.loggedUser);
    }
    if (!this._auth.isLoggedIn()) {
      this._router.navigate(['/login']);
      return false;
    }
    return true;
  }

  checkOwnerInvite() {
    this._activedRoute.queryParams.subscribe((res) => (this.accessCode = res.accessCode));
    const url = this.endpoint + this._urlCheckInvite + this.accessCode;
    return this._http.get<any>(url).pipe(
      tap(
        (next) => {
          this._organizationService.newOrganizationId = next.organizationId;
          this._toastService.showSuccess('Convite na nova organização feito com sucesso!');
          setTimeout(() => {
            if (next.userAlreadyExist) this._router.navigate(['/login']);
          }, 2000);
        },
        (error) => {
          this._router.navigate(['/login']);
        },
      ),
    );
  }
  public checkPassToken() {
    this._activedRoute.queryParams.subscribe((res) => (this.passToken = res.token));
    const url = this.endpoint + this._urlCheckPassToken + this.passToken;
    return this._http.get<any>(url).pipe(
      tap(
        (next) => { },
        (error) => {
          this._router.navigate(['/login']);
        },
      ),
    );
  }
  public checkForPermission(invalidPermissions: Array<MemberRoleEnum>) {
    const isInvalid = invalidPermissions.some((duty) => {
      return duty == this._auth?.currentMember?.role;
    });
    if (isInvalid) {
      this._auth.logout();
    }
    return isInvalid;
  }
}
