export enum PendingStatusEnum {
  Approved = 'APPROVED',
  Removed = 'REMOVED',
  Pending = 'PENDING',
  Denied = 'DENIED',
  Paths = 'PATHS'
}

export enum ServiceOrderStatusEnum {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum ContactStatusEnum{
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}