export const enumerators = {
  'RetroStatus': {
    'BAD': 'Ruim',
    'REGULAR': 'Regular',
    'GOOD': 'Bom',
    'EXCELLENT': 'Excelente',
    'DEFAULT': 'Sem Leituras'
  },
  'OrderStatus': {
    'OPEN': 'Aberta',
    'CLOSED': 'Fechada'
  },
  'UserPermission': {
    'OWNER': 'Proprietário',
    'ADMINISTRATOR': 'Administrador',
    'MANAGER': 'Proprietário',
    'OPERATOR': 'Administrador',
    'PARTICIPANT': 'Participante',
    'TEAM_LEADER': 'Participante'
  },

  'RoadSignSubstrate': {
    'POLYESTER_FIBERGLASS': 'Fibra de vidro (Poliester)',
    'STEEL': 'Aço',
    'GALVANIZED_STEEL': 'Aço galvanizado',
    'ALUMINIUM': 'Alumínio',
    'REINFORCED_PLASTIC': 'Plástico reforçado',
    'IMMUNIZED_WOOD': 'Madeira imunizada',
    'FIBERGLASS': 'Fibra de vidro',
    'ALUMINIUM_COMPOSITE': 'ACM (Alumínio Composto)'
  },

  'RoadSignSupport': {
    'GALVANIZED_STEEL': 'Aço galvanizado',
    'STEEL': 'Aço',
    'IMMUNIZED_WOOD': 'Madeira imunizada',
    'ECOLOGICAL_SUPPORT': 'Suporte ecológico',
    'REFORESTED_WOOD': 'Madeira reflorestada',
    'RECYCLED_MATERIALS': 'Materiais reciclados',
    'SEMI_PORTAL': 'Semi pórtico',
    'SEMI_PORTAL_B2': 'Semi pórtico B2',
    'SEMI_PORTAL_B4': 'Semi pórtico B4',
    'SEMI_PORTAL_B5': 'Semi pórtico B5',
    'SEMI_PORTAL_B6': 'Semi pórtico B6',
    'LATTICE_PORTAL': 'Pórtico treliçado'
  },

  'VerticalGroupType': {
    'ROAD_SIGN': 'Placa',
    'SUPPORT': 'Suporte'
  },

  'SignalizationType': {
    'WARNING': 'Advertência',
    'REGULATION': 'Regulamentação',
    'INDICATION': 'Indicação',
    'SPECIAL': 'Especial',
  },

  'HorizontalType': {
    'LONGITUDINAL_LINES': 'Linhas Longitudinais',
    'TRANSVERSAL_LINES': 'Linhas Transversais',
    'CHANNELING_MARKS': 'Marcas de Canalização',
    'DELIMITATION_MARKS': 'Marcas de Delimitação',
    'INSCRIPTIONS_ON_THE_FLOOR': 'Inscrições no Pavimento',
    'POSITIONING_ARROWS': 'Setas de Posicionamento',
    'SPECIAL': 'Especial'
  },

  'SystemModule': {
    'CONTRACT': 'Contratos',
    'PROJECT': 'Projetos',
    'SIGNALING_REGISTER': 'Sinalização',
    'REGION': 'Regiões',
    'AREA': 'Áreas',
    'PENDENCY': 'Pendências',
    'TEAM': 'Equipes',
    'VEHICLE': 'Veículos',
    'SERVICE_ORDER': 'Ordens de Serviço',
    'TASK': 'Tarefas',
    'PATH': 'Trajetos',
    'CALCULATION_MEMORY': 'Memórias de Cálculo',
    'CATALOG': 'Catálogo',
    'WARRANTY': 'Garantia',
    'PRODUCTIVITY': 'Produtividade',
    'ROUTE': 'Rotas',
    'MEASUREMENT': 'Medição',
    'STOCK': 'Estoque',
  },

  'SystemModuleIcon': {
    'CONTRACT': 'far fa-file-lines',
    'PROJECT': 'far fa-file',
    'SIGNALING_REGISTER': 'far fa-list',
    'REGION': 'far fa-map',
    'AREA': 'fas fa-map',
    'PENDENCY': 'far fa-file',
    'TEAM': 'far fa-user-friends',
    'VEHICLE': 'far fa-truck',
    'SERVICE_ORDER': 'far fa-clipboard-list',
    'TASK': 'far fa-list-check',
    'PATH': 'far fa-file',
    'CALCULATION_MEMORY': 'far fa-table',
    'CATALOG': 'far fa-list',
    'WARRANTY': 'far fa-file',
    'PRODUCTIVITY': 'far fa-chart-simple',
    'ROUTE': 'far fa-route',
    'MEASUREMENT': 'fas fa-usd-circle',
  },

  'Signaling': {
    // VERTICAL
    'R-1': 'R-1 – Parada obrigatória',
    'R-2': 'R-2 – Dê a preferência',
    'R-3': 'R-3 – Sentido proibido',
    'R-4a': 'R-4a – Proibido virar à esquerda',
    'R-4b': 'R-4b – Proibido virar à direita',
    'R-5a': 'R-5a – Proibido retornar à esquerda',
    'R-5b': 'R-5b – Proibido retornar à direita',
    'R-6a': 'R-6a – Proibido estacionar',
    'R-6b': 'R-6b – Estacionamento regulamentado',
    'R-6c': 'R-6c – Proibido parar e estacionar',
    'R-7': 'R-7 – Proibido ultrapassar',
    'R-8a': 'R-8a – Proibido mudar de faixa ou pista de trânsito da esquerda para direita',
    'R-8b': 'R-8b – Proibido mudar de faixa ou pista de trânsito da direita para esquerda',
    'R-9': 'R-9 – Proibido trânsito de caminhões',
    'R-10': 'R-10 – Proibido trânsito de veículos automotores',
    'R-11': 'R-11 – Proibido trânsito de veículos de tração animal',
    'R-12': 'R-12 – Proibido trânsito de bicicletas',
    'R-13': 'R-13 – Proibido trânsito de tratores e máquinas de obras',
    'R-14': 'R-14 – Peso bruto total máximo permitido',
    'R-15': 'R-15 – Altura máxima permitida',
    'R-16': 'R-16 – Largura máxima permitida',
    'R-17': 'R-17 – Peso máximo permitido por eixo',
    'R-18': 'R-18 – Comprimento máximo permitido',
    'R-19': 'R-19 – Velocidade máxima permitida',
    'R-20': 'R-20 – Proibido acionar buzina ou sinal sonoro',
    'R-21': 'R-21 – Alfândega',
    'R-22': 'R-22 – Uso obrigatório de corrente',
    'R-23': 'R-23 – Conserve-se à direita',
    'R-24a': 'R-24a – Sentido de circulação da via ou pista',
    'R-24b': 'R-24b – Passagem obrigatória',
    'R-25a': 'R-25a – Vire à esquerda',
    'R-25b': 'R-25b – Vire à direita',
    'R-25c': 'R-25c – Siga em frente ou à esquerda',
    'R-25d': 'R-25d – Siga em frente ou à direita',
    'R-26': 'R-26 – Siga em frente',
    'R-27': 'R-27 – Ônibus, caminhões e veículos de grande porte, mantenham-se à direita',
    'R-28': 'R-28 – Duplo sentido de circulação',
    'R-29': 'R-29 – Proibido trânsito de pedestres',
    'R-30': 'R-30 – Pedestre, ande pela esquerda',
    'R-31': 'R-31 – Pedestre, ande pela direita',
    'R-32': 'R-32 – Circulação exclusiva de ônibus',
    'R-33': 'R-33 – Sentido de circulação na rotatória',
    'R-34': 'R-34 – Circulação exclusiva de bicicletas',
    'R-35a': 'R-35a – Ciclista, transite à esquerda',
    'R-35b': 'R-35b – Ciclista, transite à direita',
    'R-36a': 'R-36a – Ciclistas à esquerda, pedestres à direita',
    'R-36b': 'R-36b – Pedestres à esquerda, ciclistas à direita',
    'R-37': 'R-37 – Proibido trânsito de motocicletas, motonetas e ciclomotores',
    'R-38': 'R-38 – Proibido trânsito de ônibus',
    'R-39': 'R-39 – Circulação exclusiva de caminhão',
    'R-40': 'R-40 – Trânsito proibido a carros de mão',
    'ESPECIAL_REGULAMENTACAO': 'Especial de Regulamentação',
    'A-1a': 'A-1a – Curva acentuada à esquerda',
    'A-1b': 'A-1b – Curva acentuada à direita',
    'A-2a': 'A-2a – Curva à esquerda',
    'A-2b': 'A-2b – Curva à direita',
    'A-3a': 'A-3a – Pista sinuosa à esquerda',
    'A-3b': 'A-3b – Pista sinuosa à direita',
    'A-4a': 'A-4a – Curva acentuada em “S” à esquerda',
    'A-4b': 'A-4b – Curva acentuada em “S” à direita',
    'A-5a': 'A-5a – Curva em “S” à esquerda',
    'A-5b': 'A-5b – Curva em “S” à direita',
    'A-6': 'A-6 – Cruzamento de vias',
    'A-7a': 'A-7a – Via lateral à esquerda',
    'A-7b': 'A-7b – Via lateral à direita',
    'A-8': 'A-8 – Interseção em “T”',
    'A-9': 'A-9 – Bifurcação em “Y”',
    'A-10a': 'A-10a – Entroncamento oblíquo à esquerda',
    'A-10b': 'A-10b – Entroncamento oblíquo à direita',
    'A-11a': 'A-11a – Junções sucessivas contrárias, primeira à esquerda',
    'A-11b': 'A-11b – Junções sucessivas contrárias, primeira à direita',
    'A-12': 'A-12 – Interseção em círculo',
    'A-13a': 'A-13a – Confluência à esquerda',
    'A-13b': 'A-13b – Confluência à direita',
    'A-14': 'A-14 – Semáforo à frente',
    'A-15': 'A-15 – Parada obrigatória à frente',
    'A-16': 'A-16 – Bonde',
    'A-17': 'A-17 – Pista irregular',
    'A-18': 'A-18 – Saliência ou lombada',
    'A-19': 'A-19 – Depressão',
    'A-20a': 'A-20a – Declive acentuado',
    'A-20b': 'A-20b – Aclive acentuado',
    'A-21a': 'A-21a – Estreitamento de pista ao centro',
    'A-21b': 'A-21b – Estreitamento de pista à esquerda',
    'A-21c': 'A-21c – Estreitamento de pista à direita',
    'A-21d': 'A-21d – Alargamento de pista à esquerda',
    'A-21e': 'A-21e – Alargamento de pista à direita',
    'A-22': 'A-22 – Ponte estreita',
    'A-23': 'A-23 – Ponte móvel',
    'A-24': 'A-24 – Obras',
    'A-25': 'A-25 – Mão dupla adiante',
    'A-26a': 'A-26a – Sentido único',
    'A-26b': 'A-26b – Sentido duplo',
    'A-27': 'A-27 – Área com desmoronamento',
    'A-28': 'A-28 – Pista escorregadia',
    'A-29': 'A-29 – Projeção de cascalho',
    'A-30a': 'A-30a – Trânsito de ciclistas',
    'A-30b': 'A-30b – Passagem sinalizada de ciclistas',
    'A-30c': 'A-30c – Trânsito compartilhado por ciclistas e pedestres',
    'A-31': 'A-31 – Trânsito de tratores ou maquinaria agrícola',
    'A-32a': 'A-32a – Trânsito de pedestres',
    'A-32b': 'A-32b – Passagem sinalizada de pedestres',
    'A-33a': 'A-33a – Área escolar',
    'A-33b': 'A-33b – Passagem sinalizada de escolares',
    'A-34': 'A-34 – Crianças',
    'A-35': 'A-35 – Animais',
    'A-36': 'A-36 – Animais selvagens',
    'A-37': 'A-37 – Altura limitada',
    'A-38': 'A-38 – Largura limitada',
    'A-39': 'A-39 – Passagem de nível sem barreira',
    'A-40': 'A-40 – Passagem de nível com barreira',
    'A-41': 'A-41 – Cruz de Santo André',
    'A-42a': 'A-42a – Início de pista dupla',
    'A-42b': 'A-42b – Fim de pista dupla',
    'A-42c': 'A-42c – Pista dividida',
    'A-43': 'A-43 – Aeroporto',
    'A-44': 'A-44 – Vento lateral',
    'A-45': 'A-45 – Rua sem saída',
    'A-46': 'A-46 – Peso bruto total limitado',
    'A-47': 'A-47 – Peso limitado por eixo',
    'A-48': 'A-48 – Comprimento limitado',
    'ESPECIAL_ADVERTENCIA': 'Especial de Advertência',
    'ATRATIVOS_TURISTICOS': 'Atrativos Turísticos',
    'EDUCATIVAS': 'Educativas',
    'IDENTIFICACAO': 'Identificação',
    'INFORMACAO_MISTA': 'Informação Mista',
    'ORIENTACAO_DE_DESTINO': 'Orientação de Destino',
    'POSTOS_DE_FISCALIZACAO': 'Postos de Fiscalização',
    'SERVICOS_AUXILIARES': 'Serviços Auxiliares',
    'VERTICAL_PERSONALIZADA': 'Vertical Personalizada',
    'VIA_FISCALIZADA_POR_CAMERAS': 'Via Fiscalizada por Câmeras',
    'BUS_STOP': 'Parada de Ônibus',
    'QR_CODE': 'QR Code',

    // HORIZONTAL
    'LBO': 'LBO – Linha de bordo',
    'LCO': 'LCO – Linha de continuidade',
    'LFO-1': 'LFO-1 – Linha simples contínua',
    'LFO-2': 'LFO-2 – Linha simples seccionada',
    'LFO-3': 'LFO-3 – Linha dupla contínua',
    'LFO-4': 'LFO-4 – Linha contínua/seccionada',
    'LMS-1': 'LMS-1 – Linha simples contínua',
    'LMS-2': 'LMS-2 – Linha simples seccionada',
    'MFE': 'MFE – Marcação de faixa exclusiva',
    'MFP': 'MFP – Marcação de faixa preferencial',
    'MFR': 'MFR – Marcação de faixa reversível no contra-fluxo',
    'MCI': 'MCI – Marcação de ciclofaixa ao longo da via',

    'FTP-1': 'FTP-1 – Faixa de travessia de pedestres',
    'FTP-2': 'FTP-2 – Faixa de travessia de pedestres',
    'LDP': 'LDP – Linha de “Dê a preferência”',
    'LRE': 'LRE – Linha de retenção',
    'LRV': 'LRV – Linhas de estímulo a redução de velocidade',
    'MAC': 'MAC – Marcação de área de conflito',
    'MAE': 'MAE – Marcação de área de cruzamento com faixa exclusiva',
    'MCC': 'MCC – Marcação de cruzamento rodocicloviário',
    'MCF': 'MCF – Marcação de cruzamento rodoferroviário',

    'LCA': 'LCA – Linha de canalização',
    'ZPA-MAN': 'ZPA-MAN – Marcação de áreas de pavimento não utilizáveis',
    'ZPA-MCB': 'ZPA-MCB – Marcação de confluências, bifurcações e entroncamentos',
    'ZPA-MAO': 'ZPA-MAO – Marcação de aproximação de obstáculos permanentes',
    'ZPA-MTL': 'ZPA-MTL – Marcação de transição de largura de pista',
    'ZPA-MAC': 'ZPA-MAC – Marcação de acostamento pavimentado e de canteiros centrais fictícios',
    'ZPA-MIR': 'ZPA-MIR – Marcação de interseção em rotatória',

    'LPP': 'LPP – Linha de indicação de proibição de estacionamento e/ou parada',
    'MVE': 'MVE – Marca delimitadora de Parada de veículos específicos',
    'MER': 'MER – Marca delimitadora de Estacionamento regulamentado',

    'DEF': 'DEF – Símbolo indicativo de local de estacionamento para deficientes físicos',
    'IMC': 'IMC – Seta indicativa de movimento em curva',
    'MOF': 'MOF – Seta indicativa de mudança obrigatória de faixa',
    'PEM': 'PEM – Setas indicativas de posicionamento na pista para a execução de movimentos',
    'SAS': 'SAS – Símbolo indicativo de área ou local de serviços de saúde "Serviços de Saúde”',
    'SIC': 'SIC – Símbolo indicativo de via, pista ou faixa de trânsito de uso de ciclistas “Bicicleta”',
    'SIF': 'SIF – Símbolo indicativo de cruzamento rodoferroviário “Cruz de Santo André”',
    'SIP': 'SIP – Símbolo indicativo de interseção com via que tem preferência “Dê a preferência”',
    'IPL-LVR': 'IPL-LVR – Legenda velocidade regulamentada – “.....km/h”',
    'IPL-ESC': 'IPL-ESC – Legenda “DEVAGAR”',
    'IPL-IND': 'IPL-IND – Legenda de indicação de distância – “A...m”',
    'IPL-PAR': 'IPL-PAR – Legenda “PARE”',
    'IPL-MCD': 'IPL-MCD – Legendas “MOTO, AMBULÂNCIA, CARGA E DESCARGA, ...',
    'PEM_DIREITA': 'PEM – Vire à Direita',
    'PEM_ESQUERDA': 'PEM – Vire à Esquerda',
    'PEM_RETORNO_DIREITA': 'PEM – Retorne à Direita',
    'PEM_RETORNO_ESQUERDA': 'PEM – Retorne à Esquerda',
    'PEM_SIGA': 'PEM – Siga em Frente',
    'PEM_SIGA_DIREITA': 'PEM – Siga em Frente ou Vire à Direita',
    'PEM_SIGA_ESQUERDA': 'PEM – Siga em Frente ou Vire à Esquerda',
    'PEM_SIGA_PEDESTRE': 'PEM – Siga em Frente (Pedestre)',
    'ROTATORIA': 'ROT – Rotatória',
    'ONDULACAO_TRANSVERSAL_PINTURA': 'ZPA – Ondulação Transversal',

    'HORIZONTAL_PERSONALIZADA': 'Horizontal Personalizada',



    // DISPOSITIVO
    'ATENUADOR_IMPACTO_FIXO': 'Atenuador de Impacto Fixo',
    'ATENUADOR_IMPACTO_MOVEL': 'Atenuador de Impacto Móvel',
    'BALIZADOR': 'Balizador',
    'BALIZADOR_RETRORREFLETIVO': 'Balizador Retrorrefletivo',
    'BARREIRA_ANTIOFUSCAMENTO': 'Barreira Antiofuscamento Manufaturada',
    'BARREIRA_ACUSTICA': 'Barreira Acústica',
    'BARREIRA_CONCRETO': 'Barreira de Concreto',
    'BARREIRA_METALICA_REMOVIVEL': 'Barreira Metálica Removível',
    'CERCA_VIVA': 'Cerca Viva',
    'CILINDRO_DELIMITADOR': 'Cilíndro Delimitador',
    'DEFENSA_METALICA': 'Defensa Metálica',
    'DISPOSITIVO_CONCRETO': 'Dispositivo de Concreto',
    'DISPOSITIVO_CONTENCAO': 'Dispositivo de Contenção e Bloqueio',
    'FAIXA_ELEVADA_PEDESTRES': 'Faixa Elevada',
    'GRADIL_FIXO': 'Gradil Fixo',
    'GRADIL_MOVEL': 'Gradil Móvel',
    'MARCADOR_OBSTACULOS': 'Marcador de Obstáculos',
    'MARCADOR_PERIGO': 'Marcador de Perigo',
    'MARCADOR_ALINHAMENTO': 'Marcador de Alinhamento',
    'ONDULACAO_TRANSVERSAL': 'Ondulação Transversal',
    'PAINEL_ELETRONICO_FIXO': 'Painel Eletrônico Fixo',
    'PAVIMENTO_COLORIDO': 'Pavimento Colorido',
    'PAVIMENTO_MICROFRESADO': 'Pavimento Microfresado',
    'PONTALETE': 'Pontalete',
    'REVESTIMENTO_RUGOSO': 'Revestimento Rugoso',
    'REVESTIMENTO_SONORIZADOR_LONGITUDINAL': 'Revestimento com Sonorizador Longitudinal',
    'SEMI_PORTICO_TRELICADO_PROJETADO': 'Semi-Pórtico Treliçado com Braço Projetado',
    'SONORIZADOR': 'Sonorizador',
    'TACHA': 'Tacha',
    'TACHAO': 'Tachão',
    'TERMINAL_ABSORVEDOR': 'Terminal Absorvedor',
    'TERMINAL_AEREO': 'Terminal Aereo',
    'TERMINAL_ENTERRADO': 'Terminal Enterrado',
    'VASO': 'Vaso ou floreira',
    'SEGREGADOR': 'Segregador',
    'DISPOSITIVO_PERSONALIZADO': 'Dispositivo Personalizado',

    // SUPORTE (para estoque)
    'GALVANIZED_STEEL': 'Aço galvanizado',
    'STEEL': 'Aço',
    'IMMUNIZED_WOOD': 'Madeira imunizada',
    'ECOLOGICAL_SUPPORT': 'Suporte ecológico',
    'REFORESTED_WOOD': 'Madeira reflorestada',
    'RECYCLED_MATERIALS': 'Materiais reciclados',
    'SEMI_PORTAL': 'Semi pórtico',
    'SEMI_PORTAL_B2': 'Semi pórtico B2',
    'SEMI_PORTAL_B4': 'Semi pórtico B4',
    'SEMI_PORTAL_B5': 'Semi pórtico B5',
    'SEMI_PORTAL_B6': 'Semi pórtico B6',
    'LATTICE_PORTAL': 'Pórtico treliçado'
  },

  'VehicleType': {
    'BONGO': 'Bongo Manutenção Vertical',
    'CARROCERIA': 'Caminhão Carroceria',
    'PINTURA_A_FRIO': 'Caminhão Pintura à Frio',
    'PINTURA_FRIO_MANUAL': 'Caminhão Pintura à Frio – Manual',
    'PINTURA_FRIO_ASPERSAO_SIMPLES': 'Caminhão Pintura à Frio – Aspersão Simples',
    'PINTURA_FRIO_ASPERSAO_DUPLA': 'Caminhão Pintura à Frio – Dupla Aspersão',
    'PINTURA_QUENTE_EXTRUDADO': 'Caminhão Pintura à Quente – Extrudado',
    'PINTURA_QUENTE_HOTSPRAY': 'Caminhão Pintura à Quente – Hot Spray',
    'BATE_ESTACAS': 'Caminhão Bate-Estacas',
    'MUNCK': 'Caminhão Munck',
    'PLATAFORMA': 'Caminhão Plataforma',
    'VEICULO_PASSEIO': 'Veículo Passeio',
  },

  'OrderPriority': {
    'HIGH': 'Prioridade Alta',
    'MEDIUM': 'Prioridade Média',
    'LOW': 'Prioridade Baixa'
  },

  'TaskPriority': {
    'HIGH': 'Alta',
    'MEDIUM': 'Média',
    'LOW': 'Baixa'
  },

  'SignalingType': {
    'VERTICAL': 'Vertical',
    'HORIZONTAL': 'Horizontal',
    'DEVICE': 'Dispositivo',
  },

  'TaskStatus': {
    'TO_START': 'À iniciar',
    'STARTED': 'Em execução',
    'PAUSED': 'Pausada',
    'COMPLETED': 'Concluída',
    'CANCELED': 'Cancelada',
    'LATE': 'Atrasadas',
  },

  'TaskType': {
    'IMPLANTATION': 'Implantação',
    'REMOVAL': 'Remoção',
    'MAINTENANCE': 'Manutenção',
    'CATALOGING': 'Catalogação',
    'REPLACEMENT': 'Substituição',
    'REVITALIZING': 'Revitalização'
  },

  'TaskActionTitle': {
    'CREATED': 'Criação da Tarefa',
    'EDITED': 'Edição',
    'TO_START': 'Tarefa Pronta para Execução',
    'STARTED': 'Execução da Tarefa – Início',
    'PAUSED': 'Execução da Tarefa – Pausa',
    'COMPLETED': 'Execução da Tarefa – Concluída',
    'CANCELED': 'Execução da Tarefa – Cancelada'
  },

  'TaskActionLabel': {
    'CREATED': 'Usuário(a) criou esta tarefa.',
    'EDITED': 'Usuário(a) editou parâmetros dessa sinalização.',
    'TO_START': 'Esta tarefa está pronta para execução.',
    'STARTED': 'Usuário(a) iniciou a execução da tarefa. Sinalizações estão prontas para o cadastramento.',
    'PAUSED': 'Usuário(a) pausou a execução da tarefa. Sinalizações ficarão em aguardo para o cadastramento.',
    'COMPLETED': 'Usuário(a) sinalizou que a tarefa está concluída. Clique em Detalhes para visualizar as sinalizações cadastradas.',
    'CANCELED': 'Usuário(a) sinalizou que a tarefa está cancelada. Sinalizações não poderão ser cadastradas usando informações desta tarefa.',
  },

  'ComplementInformation': {
    'INCORPORATED': 'Incorporada',
    'ADDITIONAL': 'Adicional',
  },

  'LicenseType': {
    'FULL': 'Full',
    'LIGHT': 'Light',
    'DEV': 'Desenvolvimento',
  },

  'Retroreflection': {
    'UNICO': 'Único',
    'SEGMENTADO': 'Segmentado'
  },

  'SignHistoryAction': {
    'CREATED': 'Criação da sinalização',
    'EDITED': 'Edição',
    'REMOVED': 'Remoção da Sinalização do Catálago',
    'RETROREFLECTION': 'Retrorrefletorização',
    'ADDITION': 'Adição: Novo Valor Financeiro',
    'REFUND': 'Restituição: Novo Valor Financeiro',
    'MAINTENANCE': 'Serviço de Manutenção',
    'REPLACEMENT': 'Serviço de Substituição',
    'APPROVED': 'Sinalização Aprovada',
    'DENIED': 'Sinalização Recusada',
  },


  'ProjectHistoryAction': {
    'CREATED': 'Criação do Projeto',
    'EDITED': 'Edição',
    'REMOVED': 'Remoção do Projeto',
    'TASK_CREATED': 'Criação de Tarefa com Vinculo Projetual',
    'TASK_COMPLETED': 'Tarefa com Vinculo Projetual Concluída',
    'SIGNALING_CREATED': 'Sinalização com Vinculo Projetual cadastrada'
  },

  'SignHistoryActionText': {
    'CREATED': 'Usuário(a) criou esta sinalização',
    'EDITED': 'Usuário(a) editou os parâmetros dessa sinalização',
    'REMOVED': 'Esta sinalização foi transferida para a lista de sinalizações removidas.',
    'RETROREFLECTION': 'Usuário editou os parâmetros da retrorrefletorização',
    'MAINTENANCE': 'Serviço de manutenção executado por Usuário(a).',
    'REPLACEMENT': 'Serviço de substituição executado por Usuário(a).',
    'APPROVED': 'Foi aprovado o cadastramento desta sinalização pelo Administrador.',
  },

  'Month': {
    '0': 'Janeiro',
    '1': 'Fevereiro',
    '2': 'Março',
    '3': 'Abril',
    '4': 'Maio',
    '5': 'Junho',
    '6': 'Julho',
    '7': 'Agosto',
    '8': 'Setembro',
    '9': 'Outubro',
    '10': 'Novembro',
    '11': 'Dezembro'
  },

  'Direction': {
    'EAST': 'Leste',
    'WEST': 'Oeste',
    'NORTH': 'Norte',
    'SOUTH': 'Sul',
  },

  'StockInputType': {
    'TRAFFIC_SIGN': 'Placa',
    'SUPPORT': 'Suporte',
    'DEVICE': 'Dispositivo'
  },

  'DashboardTypeEnum': {
    'REGISTERED_SIGN': 'Sinalizações Cadastradas',
    'EXPENDITURE': 'Consumo Total',
    'CONTRACT': 'Contrato',
    'REGISTERED_SIGN_YEAR': 'Sin. Cadastradas ao Longo do Ano',
    'ORGANIZATION': 'Organização',
    'CONTRACT_INVESTMENT': 'Investimento Contratual',
    'INVENTORY': 'Estoque',
    'PATHS': 'Trajetos',
    'RETRO_ALERT': 'Alerta de Retrorrefletorização',
    'TEAM': 'Equipe',
    'SERVICE_ORDER': 'Ordem de Serviço',
    'TASK': 'Tarefa',
    'PROJECT': 'Projeto',
    'CATALOG': 'Catálogo',
    'PENDENCY': 'Pendência',
  },

  'DashboardIconTypeEnum': {
    'REGISTERED_SIGN': 'fa-list-ul',
    'EXPENDITURE': 'fa-arrows',
    'CONTRACT': 'fa-memo',
    'REGISTERED_SIGN_YEAR': 'fa-diamond-turn-right ',
    'ORGANIZATION': 'fa-briefcase-blank',
    'CONTRACT_INVESTMENT': 'fa-badge-dollar',
    'INVENTORY': 'fa-box-open',
    'PATHS': 'fa-route',
    'RETRO_ALERT': 'fa-adjust',
    'TEAM': 'fa-user-friends',
    'SERVICE_ORDER': 'fa-clipboard-list-check',
    'TASK': 'fa-list-check',
  },

  'ChartOptions': {
    'WITH_RETRO_WARNING': 'Com Alerta',
    'WITHOUT_RETRO_WARNING': 'Sem Alerta',
    'UNDER_WARRANTY': 'Garantia ativa',
    'END_OF_WARRANTY': 'Fim da Garantia',
    'EXPIRED_WARRANTY': 'Garantia expirada',
    'QUANTITY': 'Quantidade',
    'INVESTMENT': 'Investimento',
    'METER': 'Metro',
    'SQUARE_METER': 'Metro quadrado',
  },

  'DashboardChartIconEnum': {
    'PIE': 'fa-solid fa-chart-pie-simple',
    'COLUMN': 'fa-solid fa-chart-simple',
    'ROW': 'fa-solid fa-chart-simple-horizontal',
    'DONUT': 'fa-duotone fa-spinner-third',
    'TREEMAP': 'fa-solid fa-chart-tree-map',
  },

  'SignalingQualityEnum': {
    'EXCELLENT': 'Excelente',
    'GOOD': 'Boa',
    'REGULAR': 'Regular',
    'BAD': 'Ruim',
    'TERRIBLE': 'Péssima',
    'OTHER': 'Outra',
  },

  'MaintenanceTaskActivityType': {
    'GENERAL_MAINTENANCE': 'Manutenção Geral',
    'GENERIC_MAINTENANCE': 'Manutenção Genérica',
    'CLEANING': 'Limpeza',
    'VEGETATION_PRUNING': 'Poda de Vegetação',
    'DRILLING': 'Perfuração',
    'IDENTIFICATION_OF_INVADED_AREAS': 'Identificação de Áreas Invadidas'
  },

  'ProblemType': {
    'REVIEW': 'Revisão',
    'OTHERS': 'Outros',
    'DANIFICATION': 'Danificação',
    'MAINTENANCE': 'Manutenção',
    'CLEANING': 'Limpeza',
  },

  'TypeSignDimension': {
    'CIRCULAR': 'Vertical Circular',
    'TRIANGULAR': 'Vertical Triangular',
    'LOZENGE': 'Vertical Losangular',
    'RECTANGULAR': 'Vertical Retangular',
    'OCTAGONAL': 'Vertical Octagonal',
    'HORIZONTAL': 'Horizontal'
  },

  'SystemModuleText': {
    'CONTRACT': {
      'GROUP': 'contratos',
      'SINGLE': 'contrato',
    },
    'PROJECT': {
      'GROUP': 'projetos',
      'SINGLE': 'projeto',
    },
    'SIGNALING_REGISTER': {
      'GROUP': 'sinalizações',
      'SINGLE': 'sinalização',
    },
    'REGION': {
      'GROUP': 'regiões',
      'SINGLE': 'região',
    },
    'AREA': {
      'GROUP': 'áreas',
      'SINGLE': 'área',
    },
    'PENDENCY': {
      'GROUP': 'pendências',
      'SINGLE': 'pendência',
    },
    'TEAM': {
      'GROUP': 'equipes',
      'SINGLE': 'equipe',
    },
    'VEHICLE': {
      'GROUP': 'veículos',
      'SINGLE': 'veículo',
    },
    'SERVICE_ORDER': {
      'GROUP': 'ordens de serviço',
      'SINGLE': 'ordem de serviço',
    },
    'TASK': {
      'GROUP': 'tarefas',
      'SINGLE': 'tarefa',
    },
    'PATH': {
      'GROUP': 'trajetos',
      'SINGLE': 'trajeto',
    },
    'CALCULATION_MEMORY': {
      'GROUP': 'memórias de cálculo',
      'SINGLE': 'memória de cálculo',
    },
    'WARRANTY': {
      'GROUP': 'garantias',
      'SINGLE': 'garantia',
    },
    'ROUTE': {
      'GROUP': 'rotas',
      'SINGLE': 'rota',
    },
    'STOCK': {
      'GROUP': 'estoques',
      'SINGLE': 'estoque',
    },
  },

  'ActionSimpleText': {
    'CREATED': 'cadastrou',
    'EDITED': 'editou',
    'EDITED_REMOVED': 'editou',
    'REMOVED': 'removeu',
    'APPROVED': 'aprovou',
    'DENIED': 'recusou',

  },

  'MemberRemovalRequestJustification': {
    'NOT_A_MEMBER': 'Não faço parte dessa organização',
    'RESIGNATION': 'Fui desligado da empresa',
    'OTHER': 'Outro',
  },

  'SignalingNotificationSetting': {
    'onCreate': 'cadastro',
    'onUpdate': 'edição',
    'onDelete': 'remoção',
    'onCreateMap': 'cadastro',
    'onDeleteMap': 'remoção',
  },

  'OccurrenceStatus': {
    'TO_START': 'À Iniciar',
    'STARTED': 'Iniciada',
    'REVIEW': 'Em Revisão',
    'CANCELED': 'Cancelada',
    'COMPLETED': 'Concluída'
  },

  'RouteVehicleType': {
    'CAR': 'Carro',
    'MOTORCYCLE': 'Moto',
    'TRUCK': 'Caminhão',
    'SCOOTER': 'Patinete',
    'BICYCLE': 'Bicicleta',
    'OTHER': 'Outros'
  }
};
