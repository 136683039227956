import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LicenseTypeEnum, MemberRoleEnum, UserDutyEnum } from 'src/enumerators';
import { AuthService } from './auth.service';

export class tokenData {
  user: { id: string; duty: UserDutyEnum; organizationId: string };
  license: { type: LicenseTypeEnum };

  constructor(data?: any) {
    if (data) { this.Deserialize(data); }
  }

  Deserialize(data) {
    (this.user = data.user || undefined), (this.license = data.license || undefined);
    return this;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {

  requested = false;

  constructor(private _auth: AuthService, private _jwtHelperService: JwtHelperService) { }
  // Para verificação de licença
  licenseTypeEnum = LicenseTypeEnum; // Enum para verificar licença da organização
  decodedToken = new tokenData(this._jwtHelperService.decodeToken(this._auth.getJwtToken())); // token decodificado para bloquear componentes pela licença

  checkLicenseType(type: LicenseTypeEnum): boolean {
    const userLicenseType = this.decodedToken.license['type'];
    // retorna true para licença de desenvolvimento
    if (userLicenseType == LicenseTypeEnum.Dev) { return true; }
    return userLicenseType == type;
  }

  // Para verificar role do membro, recebe array de membros que possuem autorização
  checkMemberRole(availableRoles: MemberRoleEnum[]): boolean {
    let available = false;

    if (this._auth.currentMember) {
      for (const role of availableRoles) {
        if (this._auth.currentMember?.role == role) { available = true; }
      }
      return available;
    } else {
      if (!this.requested){
        this._auth.setCurrentMember();
        this.requested = true;
      }
      for (const role of availableRoles) {
        if (this._auth.currentMember?.role == role) { available = true; }
      }
      return available;
    }
  }
}
