import { Injectable } from '@angular/core';
import booleanContains from '@turf/boolean-contains';
import booleanIntersects from '@turf/boolean-intersects';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point, polygon } from '@turf/helpers';

export interface ILocation {
  lat: number;
  lng: number;
}

type  ILonLatTuple = [number, number];
type IPolygonCollection = ILocation[][];

@Injectable({
  providedIn: 'root'
})
export class PolygonService {

  constructor() { }

  pointInPolygon(location: ILocation, currentPolygon: ILocation[]): boolean {
    const locationTuple = this.locationToTuple(location);
    const polygonTuple = this.polygonLocationToTuple(currentPolygon);
    const pt = point(locationTuple);
    const poly = polygon([polygonTuple]);
    return booleanPointInPolygon(pt, poly);
  };

  private locationToTuple(location: ILocation): ILonLatTuple {
    return [location.lng, location.lat];
  };

  private polygonLocationToTuple(polygon: ILocation[]): ILonLatTuple[] {
    return polygon.map((location) => this.locationToTuple(location));
  };

  polygonIntersects(currentPolygon: ILocation[], otherPolygon: ILocation[]) {
    const currentPoly = polygon([this.polygonLocationToTuple(currentPolygon)]);
    const otherPoly = polygon([this.polygonLocationToTuple(otherPolygon)]);
    return booleanIntersects(currentPoly, otherPoly);
  };

  polygonIntersectsCollection(currentPolygon: ILocation[], polyconCollection: IPolygonCollection) {
    const currentPoly = polygon([this.polygonLocationToTuple(currentPolygon)]);
    const polyCollection = polygon(this.polygoLocationCollectionToTuple(polyconCollection));
    return booleanIntersects(currentPoly, polyCollection);
  };

  private polygoLocationCollectionToTuple(polyconCollection: IPolygonCollection) {
    return polyconCollection.map((polygon) => this.polygonLocationToTuple(polygon));
  };

  validatePolygon(locations: ILocation[]) {
    const polygonTuple = this.polygonLocationToTuple(locations);
    polygon([polygonTuple]);
    return true;
  };

  polygonOneContainsPolygonTwo(polygonOne: ILocation[], polygonTwo: ILocation[]) {
    const polyOne = polygon([this.polygonLocationToTuple(polygonOne)]);
    const polyTwo = polygon([this.polygonLocationToTuple(polygonTwo)]);
    return booleanContains(polyOne, polyTwo);
  };
}
