import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsDimesionHorizontalData, SettingsDimesionTrafficData, SettingsReportData } from 'src/models/settings';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends RestApiService {

  private _urlMemberAutomaticReportSettings = '/member-automatic-report-settings';
  private _urlHorizontalDimensionsSettings = '/horizontal-signaling-dimensions';
  private _urlTrafficDimensionsSettings = '/traffic-sign-dimensions';

  constructor(public http: HttpClient) {
    super(http);
  }

  getSettingsData() {
    let url = this._urlMemberAutomaticReportSettings;
    return this.get(url, SettingsReportData);
  }

  postSettingsData(settingsData: SettingsReportData) {
    let url = this._urlMemberAutomaticReportSettings;
    return this.post(url, settingsData, SettingsReportData);
  }

  putSettingsData(settingsData: SettingsReportData) {
    let url = this._urlMemberAutomaticReportSettings;
    return this.put(url, settingsData, SettingsReportData);
  }

  getHorizontalDimensionsSettings() {
    let url = this._urlHorizontalDimensionsSettings;
    return this.get(url, SettingsDimesionHorizontalData);
  }

  putHorizontalDimensionsSettings(dimensionList: SettingsDimesionHorizontalData[]) {
    let url = this._urlHorizontalDimensionsSettings;
    return this.put(url, dimensionList, SettingsDimesionHorizontalData);
  }

  getTrafficDimensionsSettings() {
    let url = this._urlTrafficDimensionsSettings;
    return this.get(url, SettingsDimesionTrafficData);
  }

  putTrafficDimensionsSettings(dimensionList: SettingsDimesionTrafficData[]) {
    let url = this._urlTrafficDimensionsSettings;
    return this.put(url, dimensionList, SettingsDimesionTrafficData);
  }
}
