import { OccurrenceStatusEnum } from "src/enumerators";
import { IRest } from "./rest.interface";
import { Member } from "./user";

export class DeviceSignalingOccurrence implements IRest {
    className = 'Ocorrências de Dispositivos';

    id: string;
    workspaceId: string;
    memberId: string;
    description: string;
    files: Array<any>;
    deviceSignalingId: string;
    status: OccurrenceStatusEnum;
    createdAt: Date;
    updatedAt: Date;
    check: boolean; //usado apenas no front
    member: Member; //usado apenas no front

    constructor(data?: any) {
        if(data) this.Deserialize(data);
    }

    Serialize() {
        const out ={
            id: this.id,
            workspaceId: this.workspaceId,
            memberId: this.memberId,
            description: this.description,
            files: this.files,
            deviceSignalingId: this.deviceSignalingId,
            status: this.status,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
        return out;
    }

    Deserialize(data: any) {
        if(data) {
            this.id = data['id'] || undefined;
            this.workspaceId = data['workspaceId'] || '';
            this.memberId = data['memberId'] || '';
            this.description = data['description'] || '';
            this.files = data['files'] || [];
            this.deviceSignalingId = data['deviceSignalingId'] || '';
            this.status = data['status'] || OccurrenceStatusEnum.ToStart;
            this.createdAt = data['createdAt'] || new Date(2000, 0);
            this.updatedAt = data['updatedAt'] || new Date(2000, 0);
            this.member = new Member(data['member'] ||{});
            this.check = false;
        }
        return this;
    }
}

export class VerticalSignalingOccurrence implements IRest {
    className = 'Ocorrências de Sinalizações Verticais';

    id: string;
    workspaceId: string;
    memberId: string;
    description: string;
    files: Array<any>;
    verticalSignalingId: string;
    status: OccurrenceStatusEnum;
    createdAt: Date;
    updatedAt: Date;
    check: boolean; //usado apenas no front
    member: Member; //usado apenas no front

    constructor(data?: any) {
        if(data) this.Deserialize(data);
    }

    Serialize() {
        const out ={
            id: this.id,
            workspaceId: this.workspaceId,
            memberId: this.memberId,
            description: this.description,
            files: this.files,
            verticalSignalingId: this.verticalSignalingId,
            status: this.status,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
        return out;
    }

    Deserialize(data: any) {
        if(data) {
            this.id = data['id'] || undefined;
            this.workspaceId = data['workspaceId'] || '';
            this.memberId = data['memberId'] || '';
            this.description = data['description'] || '';
            this.files = data['files'] || [];
            this.verticalSignalingId = data['verticalSignalingId'] || '';
            this.status = data['status'] || OccurrenceStatusEnum.ToStart;
            this.createdAt = data['createdAt'] || new Date(2000, 0);
            this.updatedAt = data['updatedAt'] || new Date(2000, 0);
            this.member = new Member(data['member'] ||{});
            this.check = false;
        }
        return this;
    }
}

export class HorizontalSignalingOccurrence implements IRest {
    className = 'Ocorrências de Sinalizações Horizontais';

    id: string;
    workspaceId: string;
    memberId: string;
    description: string;
    files: Array<any>;
    horizontalSignalingId: string;
    status: OccurrenceStatusEnum;
    createdAt: Date;
    updatedAt: Date;
    check: boolean; //usado apenas no front
    member: Member; //usado apenas no front

    constructor(data?: any) {
        if(data) this.Deserialize(data);
    }

    Serialize() {
        const out ={
            id: this.id,
            workspaceId: this.workspaceId,
            memberId: this.memberId,
            description: this.description,
            files: this.files,
            horizontalSignalingId: this.horizontalSignalingId,
            status: this.status,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
        return out;
    }

    Deserialize(data: any) {
        if(data) {
            this.id = data['id'] || undefined;
            this.workspaceId = data['workspaceId'] || '';
            this.memberId = data['memberId'] || '';
            this.description = data['description'] || '';
            this.files = data['files'] || [];
            this.horizontalSignalingId = data['horizontalSignalingId'] || '';
            this.status = data['status'] || OccurrenceStatusEnum.ToStart;
            this.createdAt = data['createdAt'] || new Date(2000, 0);
            this.updatedAt = data['updatedAt'] || new Date(2000, 0);
            this.member = new Member(data['member'] ||{});
            this.check = false;
        }
        return this;
    }
}