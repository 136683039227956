import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { UploadFileDirective } from '../directives/upload-file.directive';
import { PipesModule } from '../pipes/pipes.module';
import { DeviceActivityComponent } from '../services-page/task-page/new-task/forms/device-activity/device-activity.component';
import { HorizontalActivityComponent } from '../services-page/task-page/new-task/forms/horizontal-activity/horizontal-activity.component';
import { SupportActivityComponent } from '../services-page/task-page/new-task/forms/support-activity/support-activity.component';
import { VerticalActivityComponent } from '../services-page/task-page/new-task/forms/vertical-activity/vertical-activity.component';
import { ComplementInformationComponent } from './complement-information/complement-information.component';
import { DimensionInformationComponent } from './dimension-information/dimension-information.component';
import { DisplaySummaryComponent } from './display-summary/display-summary.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HeaderComponent } from './header/header.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PathDiagramSliderComponent } from './path-diagram-slider/path-diagram-slider.component';
import { ProjectCardModalComponent } from './project-card-modal/project-card-modal.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { RetrorreflectionSliderComponent } from './retrorreflection-slider/retrorreflection-slider.component';
import { SidebarCatalogComponent } from './sidebars/sidebar-catalog/sidebar-catalog.component';
import { SidebarDashboardComponent } from './sidebars/sidebar-dashboard/sidebar-dashboard.component';
import { SidebarLogComponent } from './sidebars/sidebar-log/sidebar-log.component';
import { SidebarMapComponent } from './sidebars/sidebar-map/sidebar-map.component';
import { SidebarPathComponent } from './sidebars/sidebar-map/sidebar-path/sidebar-path.component';
import { SidebarTaskComponent } from './sidebars/sidebar-map/sidebar-task/sidebar-task.component';
import { SidebarTeamComponent } from './sidebars/sidebar-map/sidebar-team/sidebar-team.component';
import { SidebarReportComponent } from './sidebars/sidebar-report/sidebar-report.component';
import { SidebarServicesComponent } from './sidebars/sidebar-services/sidebar-services.component';
import { SidebarSettingsComponent } from './sidebars/sidebar-settings/sidebar-settings.component';
import { SidebarComponent } from './sidebars/sidebar/sidebar.component';
import { SignListModalComponent } from './sign-list-modal/sign-list-modal.component';
import { TaskCardComponent } from './task-card/task-card.component';
import { ToggleSidebarComponent } from './toggle-sidebar/toggle-sidebar.component';
import { UploadFileItemContractComponent } from './upload-file-item-contract/upload-file-item-contract.component';
import { FileSizePipe } from './upload-file/file-size-pipe';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UploadImageFileComponent } from './upload-image-file/upload-image-file.component';
import { WarrantyInputComponent } from './warranty-input/warranty-input.component';
import { WarrantyViewComponent } from './warranty-view/warranty-view.component';
import { DimensionCardComponent } from './dimension-card/dimension-card.component';
import { DimensionConfigInputComponent } from './dimension-config-input/dimension-config-input.component';
import { OccurrenceCreationModalComponent } from './occurrence-creation-modal/occurrence-creation-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SideDashboardComponent } from './sidebars/sidebar-map/side-dashboard/side-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TableComponent } from './table/table.component';
import { DisplaySummaryServiceComponent } from './display-summary-service/display-summary-service.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    NavbarComponent,
    UploadFileComponent,
    UploadFileDirective,
    HeaderMenuComponent,
    ToggleSidebarComponent,
    SidebarCatalogComponent,
    SidebarLogComponent,
    FileSizePipe,
    LoadingPageComponent,
    SidebarReportComponent,
    SidebarServicesComponent,
    UploadImageFileComponent,
    TaskCardComponent,
    ComplementInformationComponent,
    SignListModalComponent,
    DimensionInformationComponent,
    ProjectCardComponent,
    VerticalActivityComponent,
    SupportActivityComponent,
    HorizontalActivityComponent,
    DeviceActivityComponent,
    DisplaySummaryComponent,
    SidebarMapComponent,
    SidebarPathComponent,
    ProjectCardModalComponent,
    SidebarTeamComponent,
    SidebarTaskComponent,
    SidebarDashboardComponent,
    SidebarSettingsComponent,
    RetrorreflectionSliderComponent,
    PathDiagramSliderComponent,
    UploadFileItemContractComponent,
    WarrantyInputComponent,
    WarrantyViewComponent,
    DimensionCardComponent,
    DimensionConfigInputComponent,
    SideDashboardComponent,
    TableComponent,
    OccurrenceCreationModalComponent,
    DisplaySummaryServiceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    PipesModule,
    AccordionModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    DragDropModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TooltipModule,
    NgApexchartsModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    NavbarComponent,
    UploadFileComponent,
    UploadFileItemContractComponent,
    UploadImageFileComponent,
    HeaderMenuComponent,
    ToggleSidebarComponent,
    SidebarCatalogComponent,
    LoadingPageComponent,
    SidebarReportComponent,
    SidebarLogComponent,
    SidebarServicesComponent,
    TaskCardComponent,
    ComplementInformationComponent,
    DimensionInformationComponent,
    SignListModalComponent,
    DisplaySummaryComponent,
    SidebarMapComponent,
    SidebarPathComponent,
    ProjectCardComponent,
    ProjectCardModalComponent,
    DisplaySummaryComponent,
    VerticalActivityComponent,
    SupportActivityComponent,
    HorizontalActivityComponent,
    DeviceActivityComponent,
    SidebarDashboardComponent,
    SidebarSettingsComponent,
    RetrorreflectionSliderComponent,
    PathDiagramSliderComponent,
    WarrantyInputComponent,
    WarrantyViewComponent,
    DimensionCardComponent,
    TableComponent,
    OccurrenceCreationModalComponent,
    DisplaySummaryServiceComponent,
  ],
  providers: [
    provideNgxMask(maskConfig),
    [CurrencyPipe]
  ]
})
export class ComponentsModule { }
