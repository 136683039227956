import { AfterViewInit, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from 'src/app/models';
import { AuthGuardService, OrganizationService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import {
  ActionEnum,
  MemberRoleEnum,
  SignalingTypeEnum, SystemModuleEnum,
  UserDutyEnum
} from 'src/enumerators';
import { Log } from 'src/models';
import { SocketService } from './../../services/socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  //Variáveis
  @Input() user: User; //Usuário da Sessão
  listUsers: any = [];
  userDutyEnum = UserDutyEnum;
  systemModule = SystemModuleEnum;
  typeSignaling = SignalingTypeEnum;
  memberRoleEnum = MemberRoleEnum
  currentWorkspace;
  modalsRef?: Array<BsModalRef> = [];
  environment = environment;

  constructor(
    private _authGuard: AuthGuardService,
    private _modalService: BsModalService,
    private _router: Router,

    public authService: AuthService,
    public socketService: SocketService,
    public organizationService: OrganizationService,
    public authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {
    this.StartSocket();

  }

  // Troca de página para detalhes
  ngAfterViewInit(): void {
    this.authService.memberSub.asObservable().subscribe(next => {
      if (!next) return

      if (this.organizationService.listWorkspaces.length == 0) {
        this.organizationService.getWorkspaces().subscribe((res: any) => {
          this.organizationService.listWorkspaces = res;
          this.organizationService.setWorkspace();
        });
      }
    });
  }

  //Troca de página para detalhes
  signDetailRouting(element: Log) {

    let id;
    let hash;
    let url;

    if (!element.isGroup) {
      switch (element.module) {
        case SystemModuleEnum.SignalingRegister:
          let type;
          if (element.data.signalingType == this.typeSignaling.Vertical) {
            type = 1;
            id = element.data?.id;
          } else {
            type = element.data?.signalingType;
            id = element.data?.id;
          }
          if (element.action != ActionEnum.Removed) {
            this._router.navigate([`/catalog/detail/${id}`], { queryParams: { type } }).then(refresh => {
              if (hash.slice(1, 16) == url && `${url}/${id}?type=${type}` != hash.slice(1)) {
                window.location.reload();
              }
            });
          }
          break;

        case SystemModuleEnum.Warranty:
          this._router.navigate([`/catalog`]);
          break;

        case SystemModuleEnum.Contract:
          if (element.action != ActionEnum.Removed) {
            this._router.navigate([`/contracts`]).then(refresh => {
              window.location.reload();
            });
          }
          break;

        case SystemModuleEnum.Project:
          id = element.data.id;
          this._router.navigate([`/projects/display/${id}`]);
          break;

        case SystemModuleEnum.Vehicle:
          id = element.data.id;
          hash = window.location.hash;
          url = '/services/vehicles/checkout';

          this._router.navigate([url], { queryParams: { id, detail: true } }).then(refresh => {
            if (hash.slice(1, 28) == url && `${url}/${id}?detail=true` != hash.slice(1)) {
              window.location.reload();
            }
          });
          break;

        case SystemModuleEnum.Team:
          id = element.data.id;
          hash = window.location.hash;
          url = `/services/teams/checkout`;
          this._router.navigate([url], { queryParams: { id, detail: true } }).then(refresh => {
            if (hash.slice(1, 25) == url && `${url}/${id}?detail=true` != hash.slice(1)) {
              window.location.reload();
            }
          });
          break;

        case SystemModuleEnum.Task:
          id = element.data.id;
          hash = window.location.hash;
          url = `/services/task/resume/task-resume`;
          this._router.navigate([`${url}/${id}`]).then(refresh => {
            if (hash.slice(1, 34) == url && `${url}/${id}` != hash.slice(1)) {
              window.location.reload();
            }
          });
          break;

        case SystemModuleEnum.ServiceOrder:
          id = element.data.id;
          this._router.navigate([`/services/order/resume/${id}`]);
          break;

        case SystemModuleEnum.Path:
          this._router.navigate([`/paths/${element?.data?.id}`]);
          break;

        case SystemModuleEnum.Stock:
          this._router.navigate([`/stock/resume/${element?.data?.type}/${element?.data?.id}`]);
          break;

        case SystemModuleEnum.Routes:
          this._router.navigate([`/region-area/routes`]);
          break;

        default:
          break;
      }
    } else {
      switch (element.module) {
        case SystemModuleEnum.SignalingRegister:
          if (element.action != ActionEnum.Removed) {
            this._router.navigate([`/catalog`]);
          }
          break;

        case SystemModuleEnum.Contract:
          if (element.action != ActionEnum.Removed) {
            this._router.navigate([`/contracts`]);
          }
          break;

        case SystemModuleEnum.Project:
          this._router.navigate([`/projects`]);
          break;

        case SystemModuleEnum.Vehicle:
          this._router.navigate([`/services/team-vehicle?type=vehicles`]);
          break;

        case SystemModuleEnum.Team:
          this._router.navigate([`/services/team-vehicle?type=teams`]);
          break;

        case SystemModuleEnum.Task:
          this._router.navigate([`/services/tasks`]);
          break;

        case SystemModuleEnum.ServiceOrder:
          id = element.data.id;
          this._router.navigate([`/services/order`]);
          break;

        case SystemModuleEnum.Path:
          this._router.navigate([`/paths`]);
          break;

        case SystemModuleEnum.Stock:
          this._router.navigate([`/stock/list`]);
          break;

        case SystemModuleEnum.Routes:
          this._router.navigate([`/region-area/routes`]);
          break;

        default:
          break;
      }
    }
  }

  switchWorkspace(id) {
    this.organizationService.switchOrg = false;
    this.organizationService.switchWorkspace(id).subscribe((res: any) => {
      this.organizationService.setSwitchWorkspace(res, true);
    });
  }

  // Abre modal
  openModal(template: TemplateRef<any>) {
    const modalRefAux: BsModalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
    });
    this.modalsRef.push(modalRefAux);
  }

  // Fecha modal
  closeModal() {
    const last: number = this.modalsRef.length - 1;
    if (last >= 0) { this.modalsRef[last].hide(); }
    this.modalsRef.pop();
  }

  // Inscrever no Socket de Notificações
  StartSocket() {
    this.socketService.StartNotificationsSocket();
  }

  StopSocket() {
    this.socketService.StopNotificationsSocket();
  }

  // Encerrar Sessão
  logout() {
    this.authService.logout();
    this.StopSocket();
  }
}
