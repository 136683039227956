import { VehicleTypeEnum } from 'src/enumerators';
import { IRest } from 'src/models';

export class Vehicle implements IRest {
  className = 'Veículo';

  id: string;
  workspaceId: string;
  createdByMemberId: string;
  createdAt: Date;
  updatedAt: Date;
  brand: string;
  model: string;
  color: string;
  vehicleType: VehicleTypeEnum;
  licensePlate: string;
  year: number;
  equipments: string;
  observation: string;
  vehicleImageId: string;
  vehicleImage: VehicleImage;
  vehicleFiles: Array<VehicleImage>;
  check: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      workspaceId: this.workspaceId,
      createdByMemberId: this.createdByMemberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      brand: this.brand,
      model: this.model,
      color: this.color,
      vehicleType: this.vehicleType,
      licensePlate: this.licensePlate,
      year: this.year,
      equipments: this.equipments,
      observation: this.observation,
      vehicleImageId: this.vehicleImageId,
      vehicleImage: this.vehicleImage?.Serialize() || undefined,
      vehicleFiles: this.vehicleFiles,
      check: false
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.createdByMemberId = data['createdByMemberId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.brand = data['brand'] || '';
      this.model = data['model'] || '';
      this.color = data['color'] || '';
      this.vehicleType = data['vehicleType'] || VehicleTypeEnum.VeiculoPasseio;
      this.licensePlate = data['licensePlate'] || '';
      this.year = data['year'] || 0;
      this.equipments = data['equipments'] || '';
      this.observation = data['observation'] || '';
      this.vehicleImageId = data['vehicleImageId'] || undefined;
      this.vehicleImage = new VehicleImage(data['vehicleImage'] || {});
      this.vehicleFiles = data['vehicleFiles'] || ['', '', '', ''];
      this.check = false;
    }
    return this;
  }
}


export class VehicleImage implements IRest {
  className = 'Imagem Principal do Veículo';

  id: string;
  vehicleId: string;
  fileId: string;
  createdAt: Date;
  updateAt: Date;
  file: FileImage;
  url: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      vehicleId: this.vehicleId,
      fileId: this.fileId,
      createdAt: this.createdAt,
      updateAt: this.updateAt,
      file: this.file.Serialize(),
      url: this.url,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.vehicleId = data['vehicleId'] || undefined;
      this.fileId = data['fileId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updateAt = data['updateAt'] || new Date(2000, 0);
      this.file = new FileImage(data['file'] || {});
      this.url = data['url'] || '';
    }
    return this;
  }
}

export class FileImage implements IRest {
  className = 'Imagem do Veículo';

  id: string;
  key: string;
  organizationId: string;
  originalName: string;
  mimeType: string;
  extension: string;
  size: string;
  isStoredLocally: boolean;
  createdAt: Date;
  updateAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      key: this.key,
      organizationId: this.organizationId,
      originalName: this.originalName,
      mimeType: this.mimeType,
      extension: this.extension,
      size: this.size,
      createdAt: this.createdAt,
      updateAt: this.updateAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.key = data['key'] || '';
      this.organizationId = data['organizationId'] || undefined;
      this.originalName = data['originalName'] || '';
      this.mimeType = data['mimeType'] || '';
      this.extension = data['extension'] || '';
      this.size = data['size'] || '';
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updateAt = data['updateAt'] || new Date(2000, 0);
    }
    return this;
  }
}
