import { ActionEnum, SystemModuleEnum } from 'src/enumerators';
import { IRest } from './rest.interface';
import { User } from './user';


export class WarrantyNotificationSetting implements IRest {
  className = 'Notificação da garantia';

  id: string;
  frequencyInDays: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      frequencyInDays: this.frequencyInDays,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.frequencyInDays = data['frequencyInDays'] || 7;
    }
    return this;
  }
}

export class NotificationSetting implements IRest {
  className = 'Notificacação dos Módulos do Sistema';

  id: string;
  onCreate: boolean;
  onUpdate: boolean;
  onDelete: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      onCreate: this.onCreate,
      onUpdate: this.onUpdate,
      onDelete: this.onDelete,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.onCreate = data['onCreate'] || false;
      this.onUpdate = data['onUpdate'] || false;
      this.onDelete = data['onDelete'] || false;
    }
    return this;
  }
}

export class SignalingNotificationSetting implements IRest {
  className = 'Notificação da sinalização';

  id: string;
  notificationSettingId: string;
  onCreate: boolean;
  onUpdate: boolean;
  onDelete: boolean;
  onCreateMap: boolean;
  onDeleteMap: boolean;
  // onWarranty: boolean;
  // onWarrantyMap: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      notificationSettingId: this.notificationSettingId,
      onCreate: this.onCreate,
      onUpdate: this.onUpdate,
      onDelete: this.onDelete,
      onCreateMap: this.onCreateMap,
      onDeleteMap: this.onDeleteMap,
      // onWarranty: this.onWarranty,
      // onWarrantyMap: this.onWarrantyMap,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.notificationSettingId = data['notificationSettingId'] || undefined;
      this.onCreate = data['onCreate'] || false;
      this.onUpdate = data['onUpdate'] || false;
      this.onDelete = data['onDelete'] || false;
      this.onCreateMap = data['onCreateMap'] || false;
      this.onDeleteMap = data['onDeleteMap'] || false;
      // this.onWarranty = data['onWarranty'] || false;
      // this.onWarrantyMap = data['onWarrantyMap'] || false;
    }
    return this;
  }
}

export class MemberNotificationSetting implements IRest {
  className = 'Notificação de membros';

  id: string;
  memberId: string;
  viewToastNotification: boolean;
  projectNotificationSetting: NotificationSetting;
  contractNotificationSetting: NotificationSetting;
  teamNotificationSetting: NotificationSetting;
  vehicleNotificationSetting: NotificationSetting;
  pathNotificationSetting: NotificationSetting;
  serviceOrderNotificationSetting: NotificationSetting;
  taskNotificationSetting: NotificationSetting;
  areaNotificationSetting: NotificationSetting;
  regionNotificationSetting: NotificationSetting;
  routeNotificationSetting: NotificationSetting;
  stockNotificationSetting: NotificationSetting;
  signalingNotificationSetting: SignalingNotificationSetting;
  warrantyNotificationSetting: WarrantyNotificationSetting;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      memberId: this.memberId,
      viewToastNotification: this.viewToastNotification,
      projectNotificationSetting: this.projectNotificationSetting.Serialize(),
      contractNotificationSetting: this.contractNotificationSetting.Serialize(),
      teamNotificationSetting: this.teamNotificationSetting.Serialize(),
      vehicleNotificationSetting: this.vehicleNotificationSetting.Serialize(),
      pathNotificationSetting: this.pathNotificationSetting.Serialize(),
      serviceOrderNotificationSetting: this.serviceOrderNotificationSetting.Serialize(),
      taskNotificationSetting: this.taskNotificationSetting.Serialize(),
      areaNotificationSetting: this.areaNotificationSetting.Serialize(),
      regionNotificationSetting: this.regionNotificationSetting.Serialize(),
      routeNotificationSetting: this.regionNotificationSetting.Serialize(),
      stockNotificationSetting: this.regionNotificationSetting.Serialize(),
      signalingNotificationSetting: this.signalingNotificationSetting.Serialize(),
      warrantyNotificationSetting: this.warrantyNotificationSetting.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.memberId = data['memberId'] || undefined;
      this.viewToastNotification = data['viewToastNotification'] || false;
      this.projectNotificationSetting = new NotificationSetting(data['projectNotificationSetting'] || {});
      this.contractNotificationSetting = new NotificationSetting(data['contractNotificationSetting'] || {});
      this.teamNotificationSetting = new NotificationSetting(data['teamNotificationSetting'] || {});
      this.vehicleNotificationSetting = new NotificationSetting(data['vehicleNotificationSetting'] || {});
      this.pathNotificationSetting = new NotificationSetting(data['pathNotificationSetting'] || {});
      this.serviceOrderNotificationSetting = new NotificationSetting(data['serviceOrderNotificationSetting'] || {});
      this.taskNotificationSetting = new NotificationSetting(data['taskNotificationSetting'] || {});
      this.areaNotificationSetting = new NotificationSetting(data['areaNotificationSetting'] || {});
      this.regionNotificationSetting = new NotificationSetting(data['regionNotificationSetting'] || {});
      this.routeNotificationSetting = new NotificationSetting(data['routeNotificationSetting'] || {});
      this.stockNotificationSetting = new NotificationSetting(data['stockNotificationSetting'] || {});
      this.signalingNotificationSetting = new SignalingNotificationSetting(data['signalingNotificationSetting'] || {});
      this.warrantyNotificationSetting = new WarrantyNotificationSetting(data['warrantyNotificationSetting'] || {});
    }
    return this;
  }
}

export class Log implements IRest {
  className = 'Log';

  id: string;
  userId: string;
  user: User;
  memberId: string;
  workspaceId: string;
  data: any;
  isGroup: boolean;
  action: ActionEnum;
  module: SystemModuleEnum;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      userId: this.userId,
      user: this.user,
      memberId: this.memberId,
      workspaceId: this.workspaceId,
      data: this.data,
      isGroup: this.isGroup,
      action: this.action,
      module: this.module,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.userId = data['userId'] || undefined;
      this.user = data['user'] || null;
      this.memberId = data['memberId'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.data = data['data'] || {};
      this.isGroup = data['isGroup'] || false;
      this.action = <ActionEnum>(data['action'] || '');
      this.module = <SystemModuleEnum>(data['module'] || '');
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
      this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 0);
    }
    return this;
  }
}
