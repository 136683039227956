import { Pipe, PipeTransform } from '@angular/core';
import { svgElement } from 'src/dictionaries/svg-sign';
import { SignalingTypeEnum } from 'src/enumerators';

@Pipe({
  name: 'enumSvg'
})
export class EnumSvgPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (!svgElement[args[0]]) { return 'Enumerador Inválido'; }
    if (!value) {
      if (args[1] == SignalingTypeEnum.Vertical) { return 'assets/icons/map-vertical.svg'; }
      else if (args[1] == SignalingTypeEnum.Horizontal) { return 'assets/icons/map-horizontal.svg'; }
      else if (args[1] == SignalingTypeEnum.Device) { return 'assets/icons/map-devices.svg'; }
    }
    if (!svgElement[args[0]][value]) { return (args[0] + ' Inválido'); }

    return svgElement[args[0]][value];
  }

}
