import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormDataRest, IRest } from 'src/models';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  //Variáveis
  private endpoint = environment.endpoint();
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

  constructor(protected http: HttpClient) { }

  protected get<T extends IRest>(url: string, type: { new(): T; }): Observable<T | Array<T>> {
    return this.http.get((this.endpoint + url), this.httpOptions).pipe(
      retry(1),
      map((data) => this.deserialize(data, type))
    );
  }

  protected post<T extends IRest>(url: string, data: Array<IRest> | IRest, type: { new(): T; }): Observable<T> {
    let body;

    if (Array.isArray(data)) body = (data as Array<IRest>)?.map((v) => v.Serialize());
    else body = (data as IRest).Serialize();

    // Cabeçalho Diferente Quando Estiver Manipulando Arquivos
    let header = undefined;
    if (data instanceof FormDataRest)
      header = new HttpHeaders({ 'FormData': 'true' });
    else
      header = this.httpOptions;

    return this.http.post((this.endpoint + url), JSON.stringify(body), header).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  protected put<T extends IRest>(url: string, data: Array<IRest> | IRest, type: { new(): T; }): Observable<T> {
    let body;

    if (Array.isArray(data)) body = (data as Array<IRest>)?.map((v) => v.Serialize());
    else body = (data as IRest).Serialize();

    return this.http.put((this.endpoint + url), JSON.stringify(body), this.httpOptions).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  protected del<T extends IRest>(url: string, type: { new(): T; }): Observable<T> {
    return this.http.delete((this.endpoint + url), this.httpOptions).pipe(
      map((res) => <T>this.deserialize(res, type))
    );
  }

  private deserialize<T extends IRest>(data: any, type: { new(): T; }): T | Array<T> {
    if (data instanceof Array) {
      const arrT: Array<T> = [];
      data.forEach(item => {
        const t: IRest = new type();
        arrT.push(t.Deserialize(item));
      });
      return arrT;
    } else {
      const t: IRest = new type();
      return t.Deserialize(data);
    }
  }
}
