import { IRest, Task, TeamMember } from 'src/models';
import { Vehicle } from './vehicle';

export class Team implements IRest {
  className = 'Equipe';

  id: string;
  workspaceId: string;
  createdByMemberId: String;
  leaderMemberId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  tasks: Array<Task>;
  vertical: boolean;
  horizontal: boolean;
  device: boolean;
  observation: string;
  check: boolean;
  members: Array<TeamMember>;
  membersIds: Array<string>;
  activeCheckIn: boolean;
  vehicle: Vehicle; // Uso interno
  leaderMember: any; // Uso interno

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      workspaceId: this.workspaceId,
      activeCheckIn: this.activeCheckIn,
      createdByMemberId: this.createdByMemberId,
      leaderMemberId: this.leaderMemberId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      name: this.name,
      vertical: this.vertical,
      horizontal: this.horizontal,
      device: this.device,
      membersIds: this.membersIds,
      tasks: this.tasks.map((v) => v.Serialize()),
      members: this.members.map((v) => v.Serialize()),
      observation: this.observation,
      check: false,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.createdByMemberId = data['createdByMemberId'] || undefined;
      this.leaderMemberId = data['leaderMemberId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.name = data['name'] || '';
      this.tasks = (data['tasks'] || []).map((v) => new Task(v));
      this.members = (data['members'] || []).map((v) => new TeamMember(v));
      this.vertical = data['vertical'] || false;
      this.horizontal = data['horizontal'] || false;
      this.device = data['device'] || false;
      this.observation = data['observation'] || '';
      this.membersIds = data['membersIds'] || [];
      this.activeCheckIn = data['activeCheckIn'] || false;
      this.check = false;
    }
    return this;
  }
}

export class ShortTaskData implements IRest {
  className = 'Tarefa para Disponibilidade na Agenda';

  id: string;
  executionDate: Date;
  endDate: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      endDate: this.endDate,
      executionDate: this.executionDate,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.endDate = data['endDate'] ? new Date(data['endDate']) : new Date(2000, 1, 1);
      this.executionDate = data['executionDate'] ? new Date(data['executionDate']) : new Date(2000, 1, 1);
    }
    return this;
  }
}

export class TeamAvailability implements IRest {
  className = 'Disponibilidade da equipe';

  userId: string;
  tasks: ShortTaskData[];

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      userId: this.userId,
      tasks: this.tasks.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.userId = data['userId'] || undefined;
      this.tasks = (data['tasks'] || []).map((v) => new ShortTaskData(v));
    }
    return this;
  }
}

export class TeamTaskQuantity implements IRest {
  className = 'Quantidade de tarefas da Equipe';

  teamId: string;
  quantity: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      teamId: this.teamId,
      quantity: this.quantity,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.teamId = data['teamId'] || undefined;
      this.quantity = data['quantity'] || 0;
    }
    return this;
  }
}
