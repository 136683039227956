export enum ChartOptionLinesEnum {
  Investment = 'INVESTMENT',
  Quantity = 'QUANTITY',
  Meter = 'METER',
  SquareMeter = 'SQUARE_METER',
}

export enum ChartOptionWarrantyEnum {
  UnderWarranty = 'UNDER_WARRANTY',
  EndOfWarranty = 'END_OF_WARRANTY',
  ExpiredWarranty = 'EXPIRED_WARRANTY',
}

export enum ChartOptionRetroEnum {
  WithRetroWarning = 'WITH_RETRO_WARNING',
  WithoutRetroWarning = 'WITHOUT_RETRO_WARNING',
}

export enum ChartTypeEnum {
  Pie = 'PIE',
  Column = 'COLUMN',
  Row = 'ROW',
  Donut = 'DONUT',
  Treemap = 'TREEMAP',
}

export enum ChartOptionStatusEnum {
  Completed = 'COMPLETED',
  Late = 'LATE',

}
