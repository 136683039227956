import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  texts = [
    {
      title: 'Como alterar permissões de um usuário?',
      description:
        'Para alterar a permissão de um usuário primeiro vá em Organização na sua Home, clique em Editar na seção Usuários, escolha o usuário que deseja editar e selecione uma nova permissão.',
      isOpen: false,
    },
    {
      title: 'Como adicionar novos membros na organização?',
      description:
        'Para adicionar um novo membro primeiro vá em Organização na sua Home, clique em Editar na seção Usuários, em seguida no ícone de adição e então informe os dados pedidos.',
      isOpen: false,
    },
    {
      title: 'Como realizar o cadastro de itens?',
      description:
        'Para cadastrar um item primeiro vá em Workspace na sua Home, acesse Catálogo na Navbar, em seguida clique em Novo Cadastro e então preencha o formulário com os dados pedidos.',
      isOpen: false,
    },
    {
      title: 'Como cadastrar um contrato?',
      description:
        'Para cadastrar um contrato primeiro vá em Contratos na sua Home, em seguida clique no botão Contrato e então preencha o formulário com os dados pedidos.',
      isOpen: false,
    },
  ];

  constructor() {}

  ngOnInit() {}
}
