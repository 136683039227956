import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ComponentsModule } from '../components/components.module';
import { Router, RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, AccordionModule, ComponentsModule, RouterModule],
  declarations: [FaqComponent],
  exports: [],
})
export class FaqModule {}
