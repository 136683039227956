import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { SignalingTypeEnum, VerticalGroupTypeEnum } from 'src/enumerators';
import { Contract, ContractVerticalItemSupport, ProjectSupport, SupportTaskActivities, Task } from 'src/models';

@Component({
  selector: 'app-support-project',
  templateUrl: './support-project.component.html',
  styleUrls: ['../form-style.css'],
})
export class SupportProjectComponent implements OnInit {
  stockEntries: any[] = [];
  supportItems: ContractVerticalItemSupport[] = [];
  radioState = [];
  signalingTypeEnum = SignalingTypeEnum;


  @Input() supportTaskActivities: any[];
  @Input() contract: Contract;
  @Output() validationEvent = new EventEmitter<boolean>();
  @Input() task: Task;
  @Input() isStock: boolean = false

  constructor(
    private _toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.contract?.verticalGroups.forEach((group) => {
      if (group.groupType == VerticalGroupTypeEnum.Support) {
        group.verticalItems.forEach((item: ContractVerticalItemSupport) => {
          this.supportItems.push(item);
        });
      }
    });
    this.supportTaskActivities.forEach((sign) => {
      this.radioState.push(sign.stockedSupportId ? { contract: false, stock: true } : { contract: true, stock: false })
      if (sign?.contractVerticalItemId) {
        this.setContractItem(sign);
      }
      sign['isAccOpen'] = false;
    });

  }

  // Defini o item do contrato para autocomplete e calculo
  setContractItem(sign: SupportTaskActivities, stockedId?, index?) {
    var item: any
    if (!stockedId) {
      item = this.supportItems.find((item) => item.id == sign?.contractVerticalItemId);
      sign.contractVerticalItem =  new ContractVerticalItemSupport(item);
      if (sign.quantity) this.setCost(sign);
    } else {
      item = this.stockEntries.find((item) => item.id == stockedId).contractVerticalItem
      this.supportTaskActivities[index].contractVerticalItem = new ContractVerticalItemSupport(item);
      this.supportTaskActivities[index].contractVerticalItemId = item.id;
      this.supportTaskActivities[index].stockedSupportId = stockedId;
      this.setCost(this.supportTaskActivities[index]);
    }
  }

  // Atribui valor à sinalização
  setCost(sign: SupportTaskActivities) {
    sign.cost = sign.quantity * sign?.contractVerticalItem?.unitValue ?? 0;
  }


  // Remove a atividade
  removeActivity(index: number) {
    this.supportTaskActivities.splice(index, 1);
  }

  // Emite validação do form
  emitValidation() {
    let canPress = true;
    this.supportTaskActivities.forEach((item: ProjectSupport) => {
      if (!item.contractVerticalItemId || item.quantity == 0) {
        canPress = false;
        return false;
      }
    });
    this.validationEvent.emit(canPress);
  }

  setBond(type, index) {
    if (type == 'stock') {
      this.radioState[index] = { stock: true, contract: false }
    } else {
      this.radioState[index] = { stock: false, contract: true }
    }
  }

  openAccordionGroup(item) {
    item.isAccOpen = !item.isAccOpen;
  }
}
