import { MemberRoleEnum, UserDutyEnum, UserGenderEnum } from 'src/enumerators';
import { IRest } from './rest.interface';
export class Member implements IRest {
  className = 'Membro';

  id: string;
  role: MemberRoleEnum;
  userId: string;
  workspaceId: string;
  user: User;
  superAdmin: boolean;
  groupId: string;
  memberId: string;
  softDelete: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      role: this.role,
      userId: this.userId,
      workspaceId: this.workspaceId,
      user: this.user.Serialize(),
      superAdmin: this.superAdmin,
      groupId: this.groupId,
      softDelete: this.softDelete,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.role = data['role'] || MemberRoleEnum.Operator;
      this.userId = data['userId'] || '';
      this.workspaceId = data['workspaceId'] || '';
      this.user = new User(data['user'] || {});
      this.superAdmin = data['superAdmin'] || false;
      this.groupId = data['groupId'] || '';
      this.softDelete = data['softDelete'] || false;
    }
    return this;
  }
}

export class User implements IRest {
  className = 'Estado do usuário logado';

  companyName: string;
  createdAt: Date;
  duty: UserDutyEnum;
  email: string;
  firstName: string;
  gender: UserGenderEnum;
  id: string;
  img: string;
  lastName: string;
  organizationId: string;
  imageURL: string;
  phone: string;
  positionHeld: string;
  registerConfirmed: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      companyName: this.companyName,
      createdAt: this.createdAt,
      duty: this.duty,
      email: this.email,
      firstName: this.firstName,
      gender: this.gender,
      id: this.id,
      img: this.img,
      imageURL: this.imageURL,
      lastName: this.lastName,
      organizationId: this.organizationId,
      phone: this.phone,
      positionHeld: this.positionHeld,
      registerConfirmed: this.registerConfirmed,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.companyName = data['companyName'] || '';
      this.createdAt = data['concreatedAttractId'] || new Date(2000, 0);
      this.duty = <UserDutyEnum>(data['duty'] || '');
      this.email = data['email'] || '';
      this.firstName = data['firstName'] || '';
      this.gender = <UserGenderEnum>(data['gender'] || '');
      this.id = data['id'] || '';
      this.img = data['img'] || '';
      this.lastName = data['lastName'] || '';
      this.organizationId = data['organizationId'] || '';
      this.phone = data['phone'] || '';
      this.positionHeld = data['positionHeld'] || '';
      this.registerConfirmed = data['registerConfirmed'] || false;
      this.imageURL = data['imageURL'] || '';
    }
    return this;
  }
}
