import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberRoleEnum } from 'src/enumerators';
import { User } from '../models/index';
import { AuthGuardService, OrganizationService } from '../services';
import { AuthService } from '../services/auth.service';
import { AuthorizationService } from '../services/authorization.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  user: User;
  loading = true;

  cards = [
    {
      title: 'Perfil',
      subtitle: 'Visualize e edite seus dados pessoais ou senha.',
      image: 'assets/home-key.svg',
      routing: 'profile',
      blocks: ['']
    },
    {
      title: 'Organização',
      subtitle: 'Organize, acrescente ou remova membros da sua organização.',
      image: 'assets/home-organization.svg',
      routing: 'organization',
      blocks: []
    },
    {
      title: 'Workspace',
      subtitle: 'Acesse seu worskpace, gerencie seus itens, tarefas e acompanhe suas equipes.',
      image: 'assets/home-workspace.svg',
      routing: 'map',
      blocks: ['']
    },
    {
      title: 'Contratos',
      subtitle: 'Faça a gestão dos seus contratos e nomeie responsáveis.',
      image: 'assets/home-case.svg',
      routing: 'contracts',
      blocks: [MemberRoleEnum.TeamLeader]
    },
    {
      title: 'Ajuda',
      subtitle: 'Tire suas dúvidas ou acesse as perguntas mais frequentes.',
      image: 'assets/home-help.svg',
      routing: 'help',
      blocks: ['']
    }
  ];

  currentOrganization;
  constructor(
    private _router: Router,
    private _authGuard: AuthGuardService,
    public organizationService: OrganizationService,
    public authService: AuthService,
    public authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {
    this.loading = false;

    setTimeout(() => {
      this.organizationService.switchOrg = false;
    }, 6000);
    this._authGuard.userSub.asObservable().subscribe(next => {

      if (!next) return
      if (!this.authService.loggedUser) {
        this._router.navigate(['/login']);
      }
      this.organizationService.getWorkspaces().subscribe((res: any) => {
        this.organizationService.getWorkspace().subscribe((work: any) => {
          res.forEach((element: any) => {
            if (element.id == work.id) {
              this.currentOrganization = element;
            }
          });
        });
      });
    });
  }
}
