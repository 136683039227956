import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceSignaling, HorizontalSignaling, Task, Team, TrafficSign, Vehicle, VerticalSignaling } from 'src/models';
import { Organization } from '../models';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class PublicService extends RestApiService {
  envEndpoint = environment.endpoint();

  private _urlOrganizations = '/public/organizations/:id?reportId=:reportId';
  private _urlTaskById = '/public/tasks/:id?reportId=:reportId';
  private _urlTasks = '/public/tasks?serviceOrderId=:id&amount=true&reportId=:reportId';
  private _urlTeams = '/public/teams/:id?reportId=:reportId';
  private _urlVehicles = '/public/vehicles/:id?reportId=:reportId';

  private _urlTrafficSigns = '/public/traffic-signs?taskId=:taskId&reportId=:reportId';
  private _urlVerticalSignalingsId = '/public/vertical-signalings/:id?reportId=:reportId';
  private _urlGetTrafficSignsByArray = '/public/traffic-signs?ids=:ids&reportId=:reportId';

  private _urlHorizontalSignalings = '/public/horizontal-signalings?taskId=:taskId&reportId=:reportId';
  private _urlHorizontalSignalingsId = '/public/horizontal-signalings/:id?reportId=:reportId';
  private _urlGetHorizontalSignsByArray = '/public/horizontal-signalings?ids=:ids&reportId=:reportId';

  private _urlDeviceSignalings = '/public/device-signalings?taskId=:taskId&reportId=:reportId';
  private _urlDeviceSignalingsId = '/public/device-signalings/:id?reportId=:reportId';
  private _urlGetDeviceSignsByArray = '/public/device-signalings?ids=:ids&reportId=:reportId';

  private _urlHorizontalSignalingsMeasurements = '/public/horizontal-signaling-measurements';

  private _urlPublicReportTask = '/public-report/task';
  private _urlPublicReportServiceOrder = '/public-report/service-order';
  private _urlPublicReportId = '/public-report/:id';

  constructor(public http: HttpClient) {
    super(http);
  }

  // Dados da organização
  getOrg(orgId: string, reportId: string) {
    const url = this.envEndpoint + this._urlOrganizations.replace(':id', orgId).replace(':reportId', reportId);
    return this.http.get(url);
  }

  // Dados da tarefa
  getTask(taskId: string, reportId: string) {
    const url = this._urlTaskById.replace(':id', taskId).replace(':reportId', reportId);
    return this.get(url, Task);
  }

  // Dados da tarefa
  getTaskByServiceOrderId(serviceOrderId: string, reportId: string) {
    const url = this._urlTasks.replace(':id', serviceOrderId).replace(':reportId', reportId);
    return this.get(url, Task);
  }

  // Dados do time
  getTeam(teamId: string, reportId: string) {
    const url = this._urlTeams.replace(':id', teamId).replace(':reportId', reportId);
    return this.get(url, Team);
  }

  // Dados do veiculo
  getVehicle(vehicleId: string, reportId: string) {
    const url = this._urlVehicles.replace(':id', vehicleId).replace(':reportId', reportId);
    return this.get(url, Vehicle);
  }

  // Dados da sinalização Vertical pela tarefa
  getTrafficSignsByTaskId(taskId: string, reportId: string) {
    const url = this._urlTrafficSigns.replace(':taskId', taskId).replace(':reportId', reportId);
    return this.get(url, TrafficSign);
  }

  // Dados da sinalização Vertical por ID
  getVerticalSignaling(signId: string, reportId: string) {
    const url = this._urlVerticalSignalingsId.replace(':id', signId).replace(':reportId', reportId);
    return this.get(url, VerticalSignaling);
  }

  // Dados da sinalização Horizontal pela tarefa
  getHorizontalSignalingsByTaskId(taskId: string, reportId: string) {
    const url = this._urlHorizontalSignalings.replace(':taskId', taskId).replace(':reportId', reportId);
    return this.get(url, HorizontalSignaling);
  }

  // Dados da sinalização Horizontal por ID
  getHorizontalSignaling(signId: string, reportId: string) {
    const url = this._urlHorizontalSignalingsId.replace(':id', signId).replace(':reportId', reportId);
    return this.get(url, HorizontalSignaling);
  }

  // Dados de Dispositivos pela tarefa
  getDeviceSignalingsByTaskId(taskId: string, reportId: string) {
    const url = this._urlDeviceSignalings.replace(':taskId', taskId).replace(':reportId', reportId);
    return this.get(url, DeviceSignaling);
  }

  // Dados de Dispositivos por ID
  getDeviceSignaling(signId: string, reportId: string) {
    const url = this._urlDeviceSignalingsId.replace(':id', signId).replace(':reportId', reportId);
    return this.get(url, DeviceSignaling);
  }

  // Cria Link de report publico para Tarefas
  createTaskPublicReport(body: { tasksIds: string[]; executionDate: Date; endDate: Date; sharingGroupsIds: string[] }) {
    const url = this.envEndpoint + this._urlPublicReportTask;
    return this.http.post(url, body);
  }

  // Cria Link de report publico para Ordem de Serviço
  createServiceOrderkPublicReport(body: {
    serviceOrdersIds: string[];
    executionDate: Date;
    endDate: Date;
    sharingGroupsIds: string[];
  }, requesterList: Array<any>) {
    body['requesters'] = requesterList;
    const url = this.envEndpoint + this._urlPublicReportServiceOrder;
    return this.http.post(url, body);
  }

  // Recebe dados do report
  getPublicReport(reportId: string) {
    const url = this.envEndpoint + this._urlPublicReportId.replace(':id', reportId);
    return this.http.get(url);
  }

  // Retorna medições de horizontal
  getHorizontalSignalingMeasurements(horizontalSignalingId: string, reportId: string) {
    const url = this.envEndpoint + this._urlHorizontalSignalingsMeasurements;
    const params = new HttpParams().appendAll({ horizontalSignalingId: horizontalSignalingId, reportId: reportId });

    return this.http.get(url, { params: params });
  }

  getTrafficSignsByArray(signIds: string[], reportId: string) {
    const url = this._urlGetTrafficSignsByArray.replace(':ids', JSON.stringify(signIds)).replace(':reportId', reportId);
    return this.get(url, TrafficSign);
  }

  getHorizontalSignsByArray(signIds: string[], reportId: string) {
    const url = this._urlGetHorizontalSignsByArray
      .replace(':ids', JSON.stringify(signIds))
      .replace(':reportId', reportId);
    return this.get(url, HorizontalSignaling);
  }

  getDeviceSignsByArray(signIds: string[], reportId: string) {
    const url = this._urlGetDeviceSignsByArray.replace(':ids', JSON.stringify(signIds)).replace(':reportId', reportId);
    return this.get(url, DeviceSignaling);
  }
}
