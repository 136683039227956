import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Log, MemberNotificationSetting } from 'src/models';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService extends RestApiService {

  private _urlLogs = '/logs';
  private _urlMemberNotificationsSettings = '/member-notification-settings';

  constructor(public http: HttpClient) {
    super(http);
  }

  getLogs(offSet?: number, limit?: number) {
    let url = this._urlLogs;
    if (offSet >= 0 && limit >= 0) {
      url += `?offset=${offSet}&limit=${limit}`;
    }

    return this.get(url, Log);
  }

  getLogsGroup(leng?: number, startDate?: Date, endDate?: Date) {
    let url = this._urlLogs;
    let startISO = startDate ? `startDate=${startDate.toISOString()}&` : '';
    let endISO = endDate ? `endDate=${endDate.toISOString()}&` : '';
    let len = leng ? `length=${leng}&` : '';
    url += `?${startISO}${endISO}${len}group=true`;
    return this.get(url, Log);
  }

  getMemberNotificationsSetting() {
    const url = this._urlMemberNotificationsSettings;
    return this.get(url, MemberNotificationSetting);
  }

  putMemberNotificationsSetting(data: MemberNotificationSetting) {
    const url = this._urlMemberNotificationsSettings;
    return this.put(url, data, MemberNotificationSetting);
  }
}
