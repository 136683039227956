<app-header></app-header>
<div *ngIf="loading" style="height: 90vh;">
  <app-loading-page></app-loading-page>
</div>
<div *ngIf="!loading" class="container-fluid d-flex flex-column align-content-center align-items-center"
  style="height: calc(100vh - 60px); justify-content: space-evenly;">

  <div class="box-organization" *ngIf="organizationService.switchOrg">
    <img class="img-workspace" [src]="organizationService.currentWorkspace?.imageURL ?? 'assets/org-mono.svg'" />
    <h3>{{organizationService.currentWorkspace?.name}}</h3>
    <span style="color: var(--gray-base)"> {{authService?.loggedUser?.firstName + ' ' +
      authService?.loggedUser?.lastName}} •
      {{organizationService?.currentWorkspace?.memberRole| enumText: 'UserPermission'}}</span>
  </div>

  <div class="container-content text-center" style="max-width: 650px; margin-bottom: -100px;">
    <h1 class="home-title pb-4">Bem-vindo, {{ authService?.loggedUser?.firstName }}!</h1>
    <p class="home-subtitle">
      Clique nos cards abaixo para acessar os conteúdos correspondentes. <br />
      Para iniciar o gerenciamento de sua sinalização, acesse seu workspace.
    </p>
  </div>
  <div class="card-row">
    <div *ngFor="let card of cards" [ngClass]="authorizationService.checkMemberRole(card.blocks) ? 'd-none' : false">
      <div class="card-container text-center p-2 mx-4 px-4">
        <div class="card-content" [routerLink]="card.routing">
          <img href="#" alt="" class="my-4" src="{{ card.image }}" />
          <h3 class="mb-2" style="color: var(--gray-dark)">{{ card.title }}</h3>
          <p style="color: var(--gray-base)">{{ card.subtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
