import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { format } from 'date-fns';
import * as Leaflet from 'leaflet';
import 'leaflet-draw';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import { svgElement } from 'src/dictionaries/svg-sign';
import {
  ComplementInformationEnum, ComplementInformationSignEnum,
  SignalingTypeEnum, VerticalSignalizationTypeEnum, TaskStatusEnum, TaskTypeEnum,
  VerticalSignalingEnum
} from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { Area, Region, Task, Team, TeamMember, VerticalCombinedSign } from 'src/models';
import { DateService } from './date.service';
import { PolygonService } from './polygon.service';
import { RegionAreaService } from './region-area.service';
import { ServiceModel } from 'src/models/service';

export class ILocation {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  // Cor global do mapa
  mapColors = { greenSuccess: '#00c714' };

  private googleAPI = 'AIzaSyAZ_HekO0lhvxlR00enMyeH4csXhEAw-yU';
  private centroid: Leaflet.LatLngExpression = [-20.2684813, -40.292953];

  uri = environment.uri;
  verticalSignEnum = VerticalSignalingEnum;

  sidebarMapOpen = false;

  verticalMarker = Leaflet.icon({
    iconUrl: 'assets/icons/map-vertical-marker.svg',
    iconSize: [50, 80],
    iconAnchor: [23, 67],
  });

  markerAIcon = Leaflet.icon({
    iconUrl: 'assets/icons/pointA-icon.svg',
    iconSize: [50, 80],
    iconAnchor: [23, 67],
  });

  markerBIcon = Leaflet.icon({
    iconUrl: 'assets/icons/pointB-icon.svg',
    iconSize: [50, 80],
    iconAnchor: [23, 67],
  });

  constructor(
    private _http: HttpClient,
    private _polygonService: PolygonService,
    private _regionArea: RegionAreaService,
    private _dateService: DateService,
  ) { }

  initMap(id: string, zoom: number = 13, centerCoordinates?: Leaflet.LatLngExpression, saveZoom?: boolean) {
    const locations = JSON.parse(localStorage.getItem('mapLocations'));
    // Verifica se possui histórico de zoom no localStorage e centraliza o mapa
    if (locations && !centerCoordinates) {
      centerCoordinates = [locations.lat, locations.lng]
      zoom = locations.zoom
    }

    const map = new Leaflet.Map(id, {
      drawControl: true,
      preferCanvas: true,
    }).setView(centerCoordinates || this.centroid, zoom);

    const tiles = Leaflet.tileLayer('	https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
      maxZoom: 22,
      minZoom: 5,
    });

    tiles.addTo(map);

    if (!localStorage.getItem('mapLocations')) {
      map.locate({
        setView: true,
        enableHighAccuracy: true,
      });
    }

    if (saveZoom) {
      setTimeout(() => {
        map.on('zoomend', (event) => {
          if (event['target']['_animateToCenter']) {
            localStorage.setItem(
              'mapLocations',
              JSON.stringify({
                lat: event['target']['_animateToCenter']['lat'],
                lng: event['target']['_animateToCenter']['lng'],
                zoom: event['target']['_zoom'],
              }),
            );
          }
        });
      }, 1000);
    }

    return map;
  }

  addZone(map: Leaflet.Map, clickMethod: any, drawnItems, message: string, isRegion?) {
    var vertices: EventEmitter<Array<{ lat: number; lng: number }>> = new EventEmitter();

    Leaflet.drawLocal.draw.handlers.polygon.tooltip.start = '';
    Leaflet.drawLocal.draw.handlers.polygon.tooltip.cont = '';
    Leaflet.drawLocal.draw.handlers.polygon.tooltip.end = '';

    // Iniciar Desenho do Polígono
    const drawPolygon = new Leaflet.Draw.Polygon(<Leaflet.DrawMap>map, {
      allowIntersection: false,
      showArea: false,
      showLength: true,
      metric: true,
      drawError: {
        color: '#b00b00',
        timeout: 2500,
        message: 'Você nao pode cruzar as linhas',
      },
      shapeOptions: { color: '#525252', fillOpacity: 0.2 },
    });
    var enabled = true
    drawPolygon.enable();

    // Evento ao Terminar o Polígono
    map.on(Leaflet.Draw.Event.CREATED, (event) => {
      enabled = false
      const layer = event['layer'];
      const positions: Array<{ lat: number; lng: number }> = layer['editing']['latlngs'][0][0];

      // Criar Polígono
      const polygon = Leaflet.polygon(positions, {
        stroke: true,
        weight: 3,
        color: this.mapColors.greenSuccess,
        fillColor: this.mapColors.greenSuccess,
        fillOpacity: 0.25,
      });

      drawnItems.addLayer(polygon);
      // Remover Evento Atual
      map.removeEventListener(Leaflet.Draw.Event.CREATED);
      polygon.addTo(map).on('click', (target) => clickMethod(target));
      vertices.emit(polygon['_latlngs']);
      this.listPolygons(polygon['_latlngs'], polygon, message, map, isRegion);
    });
    // Função de Drag enquanto desenha o poligono, remove o ultimo ponto

    map.on('dragstart', (event) => {
      if (enabled) {
        drawPolygon.deleteLastVertex();
      }
    });

    return vertices;
  }

  initLayer(map: Leaflet.Map, layer: Leaflet.LayerGroup) {
    map.addLayer(layer);
  }

  focusOn(map: Leaflet.Map, polygon: Leaflet.Polygon) {
    let bounds = polygon.getBounds();
    map.fitBounds(bounds, {
      animate: true,
      maxZoom: 18,
    });
  }

  setEditablePolygon(polygon: Leaflet.Polygon, map: Leaflet.Map) {
    var vertices: EventEmitter<Array<{ lat: number; lng: number }>> = new EventEmitter();
    polygon['editing'].enable();
    polygon.setStyle({
      fillColor: '#FF8515',
      stroke: true,
      opacity: 100,
      color: '#FF8515',
      className: 'editable-polygon',
    });
    polygon.on('edit', (event) => {
      vertices.emit(event.target['editing']['latlngs'][0]);
    });
    return vertices;
  }

  listPolygons(currentPolygon, polygon: Leaflet.Polygon, message: string, map: Leaflet.Map, isRegion?) {
    let polygons: Array<[{ lat: number; lng: number }]> = [];
    if (isRegion) {
      this._regionArea.getRegions().subscribe((res) => {
        Object.values(res).forEach((e) => {
          if (this._polygonService.polygonIntersects(currentPolygon[0], Object.values(e.vertices)).valueOf()) {
            polygon.setStyle({
              color: '#FF0010',
              fillColor: '#FF0000',
            });
            polygon['editing'].enable();

            Leaflet.popup().setLatLng(polygon.getCenter()).setContent(`<h3>${message}</h3>`).openOn(map);
          } else {
            polygon['editing'].disabled();
          }
        });
      });
    } else {
      this._regionArea.getAreas().subscribe((res) => {
        Object.values(res).forEach((e) => {
          if (this._polygonService.polygonIntersects(currentPolygon[0], Object.values(e.vertices)).valueOf()) {
            polygon.setStyle({
              color: '#FF0010',
              fillColor: '#FF0000',
            });
            polygon['editing'].enable();
            Leaflet.popup().setLatLng(polygon.getCenter()).setContent(`<h3>${message}</h3>`).openOn(map);
          }
        });
      });
    }
    return polygons;
  }

  addPolygon(positions: Array<{ lat: number; lng: number }>, layer: Leaflet.LayerGroup, isRegion?) {
    if (isRegion) {
      // Criar Polígono
      const polygon = Leaflet.polygon(positions, {
        stroke: true,
        weight: 3,
        color: '#94D4CE',
        fillColor: '#A2EDDF',
        fillOpacity: 0.25,
      });
      polygon.addTo(layer);
      return polygon;
    } else {
      const polygon = Leaflet.polygon(positions, {
        stroke: true,
        weight: 3,
        color: '#00799B',
        fillColor: '#00799B',
        fillOpacity: 0.25,
      });
      polygon.addTo(layer);
      return polygon;
    }
  }

  // Retorna Event: LatLng
  callMarkerAdd(map: Leaflet.Map) {
    let evt = new EventEmitter<[number, number]>();
    map.on('click', <LeafletMouseEvent>(e) => {
      evt.emit([e.latlng.lat, e.latlng.lng]);
    });
    return evt;
  }

  // Adiciona marcador padrao na nova task
  clickMarkerTask(latlng: [number, number], layer: Leaflet.LayerGroup, isDraggable = true) {
    layer.clearLayers();
    let evt = new EventEmitter<[number, number]>();
    let markIcon = 'assets/img/map-vertical.svg';
    let marker = this.addMarker(
      latlng,
      layer,
      'assets/icons/pin-ellipse.svg',
      { draggable: !isDraggable },
      false,
      true,
      61,
      78,
      false,
      undefined,
      '',
      false,
    );

    marker.on('dragend', <LeafletMouseEvent>(e) => {
      evt.emit([e.target?._latlng.lat, e.target?._latlng.lng]);
    });
    marker.addTo(layer);
    layer.off('click');
    return evt;
  }

  // Adiciona marker de vertical padrão
  clickMarkerVertical(latlng: [number, number], map: Leaflet.Map, layer: Leaflet.LayerGroup, optionBool: boolean, opacity?: number) {
    let evt = new EventEmitter<[number, number]>();
    let markIcon = 'assets/img/map-vertical.svg';
    let marker = this.addMarker(
      latlng,
      layer,
      markIcon,
      { draggable: optionBool },
      false,
      true,
      61,
      78,
      false,
      undefined,
      '',
    );
    if (opacity >= 0) marker.setOpacity(opacity);
    marker.on('dragend', <LeafletMouseEvent>(e) => {
      evt.emit([e.target?._latlng.lat, e.target?._latlng.lng]);
    });
    marker.addTo(layer);
    map.off('click');
    return evt;
  }

  addTaskMarkerOnMap(latlng: [number, number], layer: Leaflet.LayerGroup, map: Leaflet.Map, color, width, height) {
    let markIcon = 'assets/icons/map-task-pin.svg';

    const iconStyle = `--fa-primary-color: ${color}; --fa-secondary-color: white; --fa-secondary-opacity: 1.0;`

    const divHTML =
      width > 20 ? `<div class="d-flex align-items-center justify-content-center">
                        <img height="${height}" width="${width}" src="${markIcon}">
                        <span class="position-absolute d-flex align-items-center justify-content-center" style="margin-bottom: 10px">
                          <i class="fa-solid fa-circle position-absolute fa-3x" style="color: ${color};"></i>
                          <i class="fa-duotone fa-clipboard-list-check position-absolute fa-xl pb-1" style="${iconStyle}"></i>
                      </span>
                    </div>`
        : `<div class="d-flex align-items-center justify-content-center">
                   <i class="fa-solid fa-circle position-absolute" style="color: ${color};"></i>
                  </div>` ;
    const iconDiv = Leaflet.divIcon({
      className: 'marker-map-task',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height]
    });
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: iconDiv }));

    marker.addTo(layer);
    return marker;
  }

  clickMarkerPointA(latlng: [number, number], layer: Leaflet.LayerGroup, map: Leaflet.Map, optionBool: boolean, opacity?: number) {
    let evt = new EventEmitter<[number, number]>();
    let markIcon = 'assets/img/pinSignA.svg';
    let marker = this.addMarker(
      latlng,
      layer,
      markIcon,
      { draggable: optionBool, className: 'pointA' },
      true,
      true,
      61,
      78,
      false,
      undefined,
      '',
    );
    if (opacity >= 0) marker.setOpacity(opacity);
    marker.on('dragend', <LeafletMouseEvent>(e) => {
      evt.emit([e.target?._latlng.lat, e.target?._latlng.lng]);
    });
    marker.addTo(layer);
    map.off('click');
    return evt;
  }

  clickMarkerPointB(latlng: [number, number], layer: Leaflet.LayerGroup, map: Leaflet.Map, optionBool: boolean, opacity?: number) {
    let evt = new EventEmitter<[number, number]>();
    let markIcon = 'assets/img/pinSignB.svg';
    let marker = this.addMarker(
      latlng,
      layer,
      markIcon,
      { draggable: optionBool, className: 'pointB' },
      true,
      true,
      61,
      78,
      false,
      undefined,
      '',
    );
    if (opacity) marker.setOpacity(opacity);
    marker.on('dragend', <LeafletMouseEvent>(e) => {
      evt.emit([e.target?._latlng.lat, e.target?._latlng.lng]);
    });
    marker.addTo(layer);
    map.off('click');
    return evt;
  }

  setDefinitiveMarker(latlng: [number, number], layer: Leaflet.LayerGroup, type: 'vertical' | 'A' | 'B') {
    var markIcon: string;
    switch (type) {
      case 'vertical':
        markIcon = 'assets/img/map-vertical.svg';
        break;
      case 'A':
        markIcon = 'assets/img/pinSignA.svg';
        break;
      case 'B':
        markIcon = 'assets/img/pinSignB.svg';
        break;
    }
    let marker = this.addMarker(
      latlng,
      layer,
      markIcon,
      { draggable: false },
      false,
      true,
      61,
      78,
      false,
      undefined,
      '',
    );
    marker.addTo(layer);
    layer.off('click');
  }

  getGeoAddress(lat: number, lng: number) {
    let url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
    return this._http.get(url);
  }

  // 'https:// nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng + '&format=json'

  centralizeMap(latlng: [number, number], map: Leaflet.Map, zoom: number = 15) {
    map.setView([latlng[0], latlng[1]], zoom);
  }

  /**
   * Adicionar marcador no mapa
   * @param latlng Localização geográfica do marcador a ser adicionado.
   * @param layer Camada que irá pertencer, layer ou cluster.
   * @param icon Ícone ou imagem principal do marcador.
   * @param options Opções do marcador (ex.: permitir arraste do marcador).
   * @param html Para marcadores que são ícones por dentro, com pingsign em volta, tamanho obrigatório, [61,78].
   * @param svg Para marcadores que são svg por dentro, com pingsign em volta, tamanho obrigatório, [61,78].
   * @param width Largura do marcador.
   * @param height Altura do marcador.
   * @param conjugate Apenas para Sinalização Vertical, se é conjugada ou não.
   * @param internalCaption Apenas para Sinalização Vertical, se possuir legenda interna.
   * @param classInternalCaption Apenas para Sinalização Vertical, a classe utilizada na legenda.
   * @param newIcon A classe responsável em criar a animação de um novo marcador.
   * @returns Marcador instanciado e posicionado.
   * */
  addMarker(
    latlng: [number, number],
    layer: Leaflet.LayerGroup,
    icon: string,
    options: Object,
    html: boolean,
    svg: boolean,
    width: number,
    height: number,
    conjugate: boolean,
    internalCaption: number,
    classInternalCaption: string,
    newIcon: boolean = false,
    complementSignType?: ComplementInformationSignEnum,
    signCode?,
    occurrence?: boolean
  ) {
    var m;
    var svgPinSign = '';
    const caps = ['R-14', 'R-15', 'R-16', 'R-17', 'R-18', 'R-19', 'A-37', 'A-38', 'A-46', 'A-47', 'A-48'];
    if (icon.includes('pinSign')) {
      svgPinSign = 'ab-marker-catalog';
    } else {
      if (icon.includes('map-vertical')) {
        svgPinSign = 'vertical-marker-catalog';
      } else {
        svgPinSign = icon != svgElement['Signaling']['VIA_FISCALIZADA_POR_CAMERAS'] ? 'svg-marker-catalog' : 'svg-marker-catalog-cam';
      }
    }
    const busStop = signCode == VerticalSignalingEnum.BusStop ? 'background-size: contain; background-repeat: no-repeat;' : '';

    if (svg) {
      let divHTML = `<div style="height: 78px">
          <img src="assets/img/pinSign.svg">
          <div class="position-relative" style="top: -80px">
            <div class="position-relative ${classInternalCaption} internal-caption"
             style="${icon == svgElement['Signaling']['VIA_FISCALIZADA_POR_CAMERAS'] ? 'height: 32px; width: 32px; top: 12px; left: 18px;' : 'height: 32px; width: 32px; top: 14px; left: 14px;'}">
              <span style="z-index: 1000">${width > 30 ? ((caps.find((v) => signCode == v)) ? internalCaption : '') : ''}</span>
              <img src= ${icon} class=${svgPinSign}>
            </div>
          </div>
        </div>`;
      m = Leaflet.divIcon({
        className: 'marker-map',
        html: divHTML,
        iconSize: [width, height],
        iconAnchor: [width / 2, height],
      });
    } else {
      // Esse caso é quando se tem um ícone dentro do pinSign, a borda do marcador, até o momento não está sendo utilizada
      if (html) {
        const divHTML = `<div><img src="assets/img/pinSign.svg"><i class="fak ${icon} icon-marker-catalog"></i></div>`;
        m = Leaflet.divIcon({
          className: 'marker-map',
          html: divHTML,
          iconSize: [width, height],
          iconAnchor: [width / 2, height],
        });
      } else {
        if (newIcon) {
          const divHTML = `<div class="new-element" style="position: relative; background-color: transparent !important;"><img src="assets/icons/new-icon-animation.svg"></div>`;
          m = Leaflet.divIcon({
            className: 'marker-map',
            html: divHTML,
            iconSize: [45, 65],
            iconAnchor: [22.5, 65],
            popupAnchor: [0, -65],
          });
        } else if (complementSignType) {
          const link = conjugate
            ? width > 20
              ? `<img src="assets/img/linkConjugate.svg" style="width:20px; height:20px; position: absolute; left: 24px; top: -12px">`
              : ''
            : '';
          const complementSvg = width < 30 ? '' : svgElement.ComplementInformation[complementSignType];
          const complementType =
            width < 30
              ? ''
              : (complementSignType == ComplementInformationSignEnum.AdditionalRegulation ||
                complementSignType == ComplementInformationSignEnum.AdditionalWarning)
                ? ComplementInformationEnum.Additional
                : ComplementInformationEnum.Incorporated;
          const internalCaptionSvg = width < 30 ? '' : `${signCode}-32`;
          const classComplement = complementType == ComplementInformationEnum.Additional ? 'marker-complement-additional' : 'marker-complement-incorporated';
          const h = complementType == ComplementInformationEnum.Additional ? 20 : 60;
          const b = complementType == ComplementInformationEnum.Additional ? 8 : 5;
          const imgComponent =
            width < 30
              ? ''
              : `<div class="${classComplement}" style="background-image:url(${complementSvg}); width: 40px; height: ${h}px; position: absolute; bottom: ${b}px;"></div>`;

          width = width > 30 ? 32 : width;
          height = height > 30 ? 32 : height;
          const divHTML = `<div class="d-flex justify-content-center position-relative" style="height: 60px; width: 40px;">
                          ${imgComponent}
                          <div
                            class="position-relative internal-caption ${internalCaptionSvg}" style="height: 32px; width: 32px;">
                            <span class="complement-marker-span" style="z-index: 1;">
                              ${width > 30 ? ((caps.find((v) => signCode == v)) ? internalCaption : '') : ''}
                            </span>
                            <div class="marker-background-image" style="background-image:url(${icon}); width: ${width}px; height: ${height}px; ${busStop}"></div>
                          </div>${link}
                        </div>`;
          m = Leaflet.divIcon({
            className: 'marker-map',
            html: divHTML,
            iconSize: [width, height],
            iconAnchor: [width / 2, height],
            popupAnchor: [0, -height],
          });
        } else {
          const link = conjugate
            ? width > 20
              ? `<img src="assets/img/linkConjugate.svg" style="width:20px; height:20px;position: absolute;left: 20px; top: -6px">`
              : ''
            : '';
          const occurrenceIcon = !occurrence ? '' :
            `<i class="fa-solid fa-message-exclamation fa-lg occurrence-map-icon"></i>`;

          const divHTML = `<div class= "internal-caption ${classInternalCaption} position-relative">
              <span style="z-index: 1000">${width > 30 ? ((caps.find((v) => signCode == v)) ? internalCaption : '') : ''}</span>
              <div class="marker-background-image" style="background-image:url(${icon}); width: ${width}px; height: ${height}px; ${busStop}"></div>${link}
              ${occurrenceIcon}
            </div>`;

          m = Leaflet.divIcon({
            className: 'marker-map',
            html: divHTML,
            iconSize: [width, height],
            iconAnchor: [width / 2, height],
            popupAnchor: [0, -height],
          });
        }
      }
    }
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }, options));
    if (layer) marker.addTo(layer);
    return marker;
  }

  newMarkerHTML(icon, width, height, internalCaption = '', classInternalCaption = '', conjugate = false): string {
    let link = conjugate
      ? width > 20
        ? `<img src="assets/img/linkConjugate.svg" style="width:20px; height:20px;position: absolute; left: 20px; top: -6px">`
        : ''
      : '';
    let divHTML = `<div class= "${classInternalCaption} internal-caption" style="position: relative;">
          <span style="z-index: 1000">${width > 30 ? (internalCaption ? internalCaption : '') : ''}</span>
          <img src=${icon} style="width:${width}px; height:${height}px; position: absolute;">${link}
        </div>`;
    return divHTML;
  }

  removeMarkerHTML(icon, width, height, internalCaption = '', classInternalCaption = '', conjugate = false): string {
    let link = conjugate
      ? width > 20
        ? `<img src="assets/img/linkConjugate.svg" style="width:20px; height:20px;position: absolute;left: 20px; top: -6px">`
        : ''
      : '';
    let divHTML = `<div class= "${classInternalCaption} internal-caption remove-marker" style="position: relative;">
          <span style="z-index: 1000">${width > 30 ? (internalCaption ? internalCaption : '') : ''}</span>
          <img src=${icon} style="width:${width}px; height:${height}px; position: absolute;">${link}
        </div>`;
    return divHTML;
  }

  addRetroMarker(layer, width, height, latlng, options?) {
    let icon =
      width > 20
        ? `<div style="height: 60px">
            <img src="assets/img/pinSign.svg" style="width: 40px; height: 60px">
            <div class="position-relative" style="top: -80px">
              <div class="position-relative" style="height: 32px; width: 32px; top: 25px; left: 5px;">
              <img src="assets/img/retro-marker.svg" style="width: ${width}px; height: ${height}px; position: absolute;">
              </div>
            </div>
          </div>`
        : `<img src="assets/img/retro-marker-min.svg" style="width: ${width}px; height: ${height}px; position: absolute;">`;

    let divHTML = `
    <div class= "position-relative">
      ${icon}
    </div>
    `;
    const w = width > 20 ? 40 : width;
    const h = width > 20 ? 60 : height;

    const m = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [w, h],
      iconAnchor: [w / 2, h],
      popupAnchor: [0, -h],
    });

    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }, options));
    marker.addTo(layer);
    return marker;
  }

  addPathLayer(polylineCoordinates, layer: Leaflet.LayerGroup, highLight?: boolean, colorType?: number, register = false): Leaflet.Polyline {
    let polyline: Leaflet.Polyline;
    if (!register) {
      polyline = Leaflet.polyline(polylineCoordinates, {
        color: (highLight && !colorType) ? ('#007bff') : (colorType ? (colorType == 1 ? '#FF8515' : '#D30E18') : ('#B3D7E1')),
        weight: highLight ? 7 : 5,
      });
    }
    else {
      polyline = Leaflet.polyline(polylineCoordinates, {
        color: (highLight && !colorType) ? ('#007bff') : (colorType ? (colorType == 1 ? '#FF8515' : '#D30E18') : ('#B3D7E1')),
        weight: highLight ? 7 : 5,
      });
    }
    return polyline;
  }

  addPathMarker(layer, width, height, internalCaption, state = '', latlng, options?) {
    let icon =
      width > 20
        ? `<img src="assets/signalings/pathMarker.svg" style="width: ${width}px; height: ${height}px; position: absolute;">`
        : `<img src="assets/signalings/miniPathMarker.svg" style="width: ${width}px; height: ${height}px; position: absolute;">`;

    let classInternal = width > 20 ? 'path-marker-internal' : '';

    let spanInternalCaption = internalCaption !== '' ? `<span class="path-internal"> ${internalCaption} </span>` : '';

    let spanState = state ? `<span class="path-state"> ${state} </span>` : '';

    let divHTML = `
    <div class= "${classInternal} position-relative">
      ${icon}
      ${spanInternalCaption}
      ${spanState}
    </div>
    `;
    const m = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height],
    });
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }, options));
    marker.addTo(layer);
    return marker;
  }

  addVehicleMarker(layer, width, height, latlng, type, angle) {
    let icon =
      `<img src="assets/img/vehicle-horizontal.svg" style="width: ${width}px; height: ${height}px; position: absolute; transform: rotate(${-angle}deg); transform-origin: center">`

    let divHTML = `
        <div class= "position-relative">
          ${icon}
        </div>
        `;

    const m = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height],
    });

    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }));
    marker.addTo(layer);

    return marker;
  }

  addTeamMarker(layer, width, height, latlng, teamVehicle) {
    const markIcon = 'assets/icons/map-team-pin.svg';
    const imgRoundedTeam = teamVehicle.team?.leaderMember?.user?.imageURL ? teamVehicle.team?.leaderMember?.user?.imageURL : 'assets/icons/camera.svg';
    const imgTeam = teamVehicle?.teamId ?
      `<img class="image-team-rounded" src=${imgRoundedTeam} alt="">`
      : '';

    const divHTML =
      width > 20 ? `<div class="d-flex align-items-center justify-content-center">
                        <img height="${height}" width="${width}" src="${markIcon}">
                        <span class="position-absolute d-flex align-items-center justify-content-center" style="margin-bottom: 12px">
                        ${imgTeam}
                      </span>
                    </div>`
        : `<div class="d-flex align-items-center justify-content-center">
                   <i class="fa-solid fa-circle position-absolute" style="color: var(--blue-base);"></i>
                  </div>` ;
    const iconDiv = Leaflet.divIcon({
      className: 'marker-map-task',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height]
    });
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: iconDiv }));
    marker.addTo(layer);
    return marker;
  }


  addEditablePathMarker(layer, width, height, internalCaption = '', state = '', latlng, options?) {
    let icon =
      width > 20
        ? `<img src="assets/signalings/pathMarker.svg" style="width: ${width}px; height: ${height}px; position: absolute;">`
        : `<img src="assets/signalings/miniPathMarker.svg" style="width: ${width}px; height: ${height}px; position: absolute;">`;

    let classInternal = width > 20 ? 'path-marker-internal-edition' : '';

    let spanInternalCaption = internalCaption ? `<span class="path-internal-edition"> ${internalCaption} </span>` : '';

    let spanState = state ? `<span class="path-state"> ${state} </span>` : '';

    let divHTML = `
    <div class= "${classInternal} position-relative">
      ${icon}
      ${spanInternalCaption}
      ${spanState}
    </div>
    `;
    const m = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height],
    });
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }, options));
    marker.addTo(layer);
    return marker
  }

  addPopupPathMarker(pathMarker) {
    let content = `
      <div class="d-flex flex-column align-items-center" style="width: 144px">
        <div style="height: 24px;">
          <h4 style="color:var(--gray-base)">${pathMarker.pathName}</h4>
        </div>
        <hr class="my-2" style="width: 100%; height: 1px">
        <div class="d-flex flex-column align-items-center">
        <h3 style="color:var(--gray-base)">KM</h3>
        <h1>${pathMarker.kilometer}</h1>
        </div>
        <hr class="my-2" style="width: 100%; height: 1px">
        <div>
          <h3 style="color:var(--gray-base)">${pathMarker.pathState}</h3>
        </div>
      </div>
    `;
    return content;
  }

  // Retorna qual é o tipo da informação complementar sabendo qual é o tipo da placa WARNING/REGULATION
  complementType(element): ComplementInformationSignEnum {
    if (element.complementInformationType == ComplementInformationEnum.Additional) {
      if (element.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.AdditionalWarning;
      } else {
        return ComplementInformationSignEnum.AdditionalRegulation;
      }
    } else if (element.complementInformationType == ComplementInformationEnum.Incorporated) {
      if (element.verticalType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.IncorporatedWarning;
      } else {
        return ComplementInformationSignEnum.IncorporatedRegulation;
      }
    } else {
      return undefined;
    }
  }

  addPopup(element, index) {
    let code;
    let id;
    let verticalId;
    let progress;
    let conjugateList: Array<VerticalCombinedSign> = [];
    let conjugateSignHover = ''; // Efeito do hover para mostrar as diferentes placas
    let conjugateSign1 = ''; // Conteúdo do popover, da primeira sinalização
    let conjugateSign2 = ''; // Conteúdo do popover, da segunda sinalização
    let conjugateSign3 = ''; // Conteúdo do popover, da terceira sinalização
    let classInternalCaption;
    let internalCaption;
    if (element.type == SignalingTypeEnum.Vertical && element.combined > 0) {
      conjugateList = element.combinedSign;
    }

    let warranty = element.warranty;
    let warrantyCurrent = element?.warrantyCurrent;
    let warrantyEnd = element?.warrantyEnd;

    if (element.warranty) {
      if (warrantyEnd[0] && warrantyCurrent[0]) {
        progress = warrantyCurrent[0] / warrantyEnd[0];
      } else {
        progress = 0;
      }
    }

    if (element.type == SignalingTypeEnum.Vertical) {
      let signCode = element['signCode'];
      classInternalCaption = `${element['signCode']}-40`;
      internalCaption = element.verticalType == VerticalSignalizationTypeEnum.Indication ? '' : element['internalCaption'];
      id = element.groupId;
      verticalId = 1;
      code = element?.verticalType == VerticalSignalizationTypeEnum.Indication || element?.signCode?.includes('ESPECIAL') ? enumerators.SignalizationType[element?.verticalType] : element?.signCode;

    } else {
      id = element.id;
      verticalId = '';
      code = element.signCode;
    }
    let active = element.active;

    internalCaption = internalCaption ?? '';
    conjugateList.forEach((conjugateSign: VerticalCombinedSign) => {
      conjugateSign['classInternalCaption'] = `${conjugateSign['signCode']}-40-map`;
    });

    let endLife2;
    let date2;
    let color2;
    let timeDate2;
    let progressBar2;
    let internalCaptionSvg2;
    let imgComponent2;
    let height2;
    let width2;
    let svgW2;
    let svgH2;

    let endLife3;
    let date3;
    let color3;
    let timeDate3;
    let progressBar3;
    let internalCaptionSvg3;
    let imgComponent3;
    let height3;
    let width3;
    let svgW3;
    let svgH3;

    if (conjugateList.length >= 1) {
      if (conjugateList.length == 1) {
        conjugateSignHover = `<div class="plate p2" style="margin-left:38px">
        <img src=${conjugateList[0].svg} style="${conjugateList[0].signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || conjugateList[0].signCode == this.verticalSignEnum.QrCode ? 'width:20px; height:30px' : conjugateList[0].signCode == this.verticalSignEnum.BusStop ? 'width:24px; height:27px' : 'width:24px; height:24px'}">
      </div>
      <div class="plate p1">
        <img src=${element.svg} style="${element.signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || element.signCode == this.verticalSignEnum.QrCode ? 'width:20px; height:30px' : element.signCode == this.verticalSignEnum.BusStop ? 'width:24px; height:27px' : 'width:24px; height:24px'}">
      </div>`;
      } else {
        if (conjugateList.length == 2) {
          conjugateSignHover = `<div class="plate p3" style="margin-left:76px">
            <img src=${conjugateList[1].svg} style="${conjugateList[1].signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || conjugateList[1].signCode == this.verticalSignEnum.QrCode ? 'width:20px; height:30px' : conjugateList[1].signCode == this.verticalSignEnum.BusStop ? 'width:24px; height:27px' : 'width:24px; height:24px'}">
          </div>
          <div class="plate p2" style="margin-left:38px">
            <img src=${conjugateList[0].svg} style="${conjugateList[0].signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || conjugateList[0].signCode == this.verticalSignEnum.QrCode ? 'width:20px; height:30px' : conjugateList[0].signCode == this.verticalSignEnum.BusStop ? 'width:24px; height:27px' : 'width:24px; height:24px'}">
          </div>
          <div class="plate p1">
            <img src=${element.svg} style="${element.signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || element.signCode == this.verticalSignEnum.QrCode ? 'width:20px; height:30px' : element.signCode == this.verticalSignEnum.BusStop ? 'width:24px; height:27px' : 'width:24px; height:24px'}">
          </div>`;
        }
      }

      endLife2 =
        conjugateList[0]['active'] == 3
          ? `<p class="m-0" style="color:var(--red-base); font-weight: bold; font-size: 12px">
                                          <i class="fa-solid fa-triangle-exclamation" style="margin-right: 8px;"></i>Garantia Expirada</p>`
          : '';
      date2 = this._dateService.getTextDate(conjugateList[0]['warrantyCurrent']);

      color2 = conjugateList[0]['active'] == 1 ? 'var(--green-base)' : 'var(--red-base)';
      timeDate2 =
        conjugateList.length >= 1
          ? conjugateList[0]['active'] <= 2
            ? `<p class="m-0"  style="font-size: 12px; font-weight: 700; color: ${color2}; line-weight: 20px">${date2}</p>`
            : ''
          : '';

      const colorBar2 =
        conjugateList[0]['active'] == 1 ? 'progress-bar-success bg-success' : 'progress-bar-danger bg-danger';
      const progressBarColor2 =
        conjugateList.length >= 1
          ? `<progressbar class="mr-2 progress" style="border-radius: 8px; height: 8px; width: 112px;">
              <bar role="progressbar" class="${colorBar2} progress-bar" aria-valuenow="${conjugateList[0]['progress'] * 100
          }"
              aria-valuetext="${conjugateList[0]['progress'] * 100}%" style="height: 100%; width: ${conjugateList[0]['progress'] * 100
          }%;">
              </bar>
            </progressbar>`
          : '';

      progressBar2 = conjugateList.length >= 1 ? (conjugateList[0]['active'] <= 2 ? progressBarColor2 : '') : '';

      const complementSignType2 = this.complementType(conjugateList[0]);
      const complementSvg2 = svgElement.ComplementInformation[complementSignType2];

      internalCaptionSvg2 = conjugateList[0].verticalType == VerticalSignalizationTypeEnum.Indication ? '' : !conjugateList[0]?.internalCaption
        ? ''
        : conjugateList[0].complementInformationType
          ? `${conjugateList[0].signCode}-32 internal-caption`
          : `${conjugateList[0].signCode}-40-map internal-caption`;

      const h2 = conjugateList[0].complementInformationType == ComplementInformationEnum.Additional ? 20 : 60;

      imgComponent2 = !conjugateList[0].complementInformationType
        ? ''
        : conjugateList[0].complementInformationType == ComplementInformationEnum.Additional
          ? `<img src="${complementSvg2}" style="width: 40px; height: ${h2}px; position: absolute; bottom: 0px;">`
          : '';
      height2 = conjugateList[0].complementInformationType ? 54 : 40;
      width2 = conjugateList[0].complementInformationType ? 32 : 40;
      svgW2 = conjugateList[0].complementInformationType ? 32 : 40;
      svgH2 = conjugateList[0].complementInformationType ? 32 : conjugateList[0]?.signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || conjugateList[0]?.signCode == this.verticalSignEnum.QrCode ? 60 : 40;
      const code2 = conjugateList[0]?.verticalType == VerticalSignalizationTypeEnum.Indication || element?.signCode?.includes('ESPECIAL') ? enumerators.SignalizationType[conjugateList[0]?.verticalType] : conjugateList[0].signCode;
      conjugateSign2 = `<div class="info p2">
        <div class="d-flex mb-3 w-100 align-items-center" style="height: ${height2}px">
          <div class="position-relative mr-3 d-flex">
            <div class="d-flex justify-content-center" style="width: 32px">
              ${imgComponent2}
              <div class="${internalCaptionSvg2}" style='position: relative; width: ${width2}px; height: ${height2}px;'>
                <span style="z-index: 1000">${conjugateList[0].verticalType == VerticalSignalizationTypeEnum.Indication ? '' : conjugateList[0]?.internalCaption ?? ''}</span>
                <img src=${conjugateList[0].svg} style="width: ${svgW2}px; height: ${svgH2}px; object-fit: contain;">
              </div>
            </div>
            <div class="ml-3">
              <p class="m-0" style="font-weight: 500; font-size: 16px; font-family: Roboto,sans-serif; color: var(--gray-dark)">${code2}
              </p> <p class="m-0" style="font-size: 12px; font-weight: 400; font-family: Roboto,sans-serif; color:var(--gray-base); width: 80%">${conjugateList[0].signName
        }
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 mb-2 align-items-center">
          ${progressBar2}
          ${timeDate2}
          ${endLife2}
        </div>
      </div>`;

      if (conjugateList.length >= 2) {
        endLife3 =
          conjugateList[1]['active'] == 3
            ? `<p class="m-0" style="color:var(--red-base); font-weight: bold; font-size: 12px">
                                              <i class="fa-solid fa-triangle-exclamation" style="margin-right: 8px;"></i>Garantia Expirada</p>`
            : '';
        date3 = this._dateService.getTextDate(conjugateList[1]['warrantyCurrent']);

        color3 = conjugateList[1]['active'] == 1 ? 'var(--green-base)' : 'var(--red-base)';
        timeDate3 =
          conjugateList[1]['active'] <= 2
            ? `<p class="m-0"  style="font-size: 12px; font-weight: 700; color: ${color3}; line-weight: 20px">${date3}</p>`
            : '';
        const colorBar3 =
          conjugateList.length >= 2
            ? conjugateList[1]['active'] == 1
              ? 'progress-bar-success bg-success'
              : 'progress-bar-danger bg-danger'
            : '';

        const progressBarColor3 = `<progressbar class="mr-2 progress" style="border-radius: 8px; height: 8px; width: 112px;">
              <bar role="progressbar" class="${colorBar3} progress-bar" aria-valuenow="${conjugateList[1]['progress'] * 100
          }"
              aria-valuetext="${conjugateList[1]['progress'] * 100}%" style="height: 100%; width: ${conjugateList[1]['progress'] * 100
          }%;
              "></bar>
            </progressbar>`;

        progressBar3 = conjugateList[1]['active'] <= 2 ? progressBarColor3 : '';

        const complementSignType3 = this.complementType(conjugateList[1]);
        const complementSvg3 = svgElement.ComplementInformation[complementSignType3];

        internalCaptionSvg3 = conjugateList[1].verticalType == VerticalSignalizationTypeEnum.Indication ? '' : !conjugateList[1]?.internalCaption
          ? ''
          : conjugateList[1].complementInformationType
            ? `${conjugateList[1].signCode}-32 internal-caption`
            : `${conjugateList[1].signCode}-40-map internal-caption`;

        const h3 = conjugateList[1].complementInformationType == ComplementInformationEnum.Additional ? 20 : 60;

        imgComponent3 = !conjugateList[1].complementInformationType
          ? ''
          : `<img src="${complementSvg3}" style="width: 40px; height: ${h3}px; position: absolute; bottom: 0px;">`;
        height3 = conjugateList[1].complementInformationType ? 54 : 40;
        width3 = conjugateList[1].complementInformationType ? 32 : 40;
        svgW3 = conjugateList[1].complementInformationType ? 32 : 40;
        svgH3 = conjugateList[1].complementInformationType ? 32 : conjugateList[1]?.signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || conjugateList[1]?.signCode == this.verticalSignEnum.QrCode ? 60 : 40;
        const code3 = conjugateList[1]?.verticalType == VerticalSignalizationTypeEnum.Indication || element?.signCode?.includes('ESPECIAL') ? enumerators.SignalizationType[conjugateList[1]?.verticalType] : conjugateList[1].signCode;

        conjugateSign3 = `<div class="info p3">
              <div class="d-flex mb-3 w-100 align-items-center">
                <div class="position-relative mr-3 d-flex">
                  <div class="d-flex justify-content-center" style="width: 32px">
                    ${imgComponent3}
                    <div class="${internalCaptionSvg3}" style='position: relative; width: ${width3}px; height: ${height3}px;'>
                      <span style="z-index: 1000">${conjugateList[1].verticalType == VerticalSignalizationTypeEnum.Indication ? '' : conjugateList[1]?.internalCaption ?? ''}</span>
                      <img src=${conjugateList[1].svg} style="width: ${svgW3}px; height: ${svgH3}px; object-fit: contain;">
                    </div>
                  </div>
                  <div class="ml-3">
                    <p class="m-0" style="font-weight: 500; font-size: 16px; font-family: Roboto,sans-serif; color: var(--gray-dark)">${code3}
                    </p> <p class="m-0" style="font-size: 12px; font-weight: 400; font-family: Roboto,sans-serif; color:var(--gray-base); width: 80%">${conjugateList[1].signName
          }
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex w-100 mb-2 align-items-center">
                ${progressBar3}
                ${timeDate3}
                ${endLife3}
              </div>
            </div>`;
      }
    }

    const color = active == 1 ? 'var(--green-base)' : 'var(--red-base)';

    const endLife =
      active == 3
        ? `<p class="m-0" style="color:var(--red-base); font-weight: bold; font-size: 12px">
                                    <i class="fa-solid fa-triangle-exclamation" style="margin-right: 8px;"></i>Garantia Expirada</p>`
        : '';
    const date = this._dateService.getTextDate(warrantyCurrent);
    const timeDate =
      active <= 2
        ? `<p class="m-0"  style="font-size: 12px; font-weight: 700; color: ${color}; line-weight: 20px">${date}</p>`
        : '';
    const circle =
      active <= 2 ? `<i class="p-icon fas fa-circle mr-3 mt-1" style="color: ${color}; font-size: 16px"></i>` : '';
    const url =
      verticalId != ''
        ? `${this.uri}/#/catalog/detail/${id}?type=${verticalId}`
        : `${this.uri}/#/catalog/detail/${id}?type=${element.type}`;
    const AB = index == 0 ? 'A' : index == 1 ? 'B' : '';
    const position =
      index < 2
        ? `<div class="d-flex justify-content-center" style="width: 40px; height: 24px; background-color: var(--gray-base); border-radius: 8px"><h3 style="color: white">${AB}</h3></div>`
        : '';

    const colorBar = active == 1 ? 'progress-bar-success bg-success' : 'progress-bar-danger bg-danger';

    const progressBarColor = `<progressbar class="mr-2 progress" style="border-radius: 8px; height: 8px; width: 112px;">
      <bar role="progressbar" class="${colorBar} progress-bar" aria-valuenow="${progress * 100}"
      aria-valuetext="${progress * 100}%" style="height: 100%; width: ${progress * 100}%;"></bar></progressbar>`;

    const progressBar = active <= 2 ? progressBarColor : '';

    const complementSignType1 = this.complementType(element);
    const complementSvg1 = svgElement.ComplementInformation[complementSignType1];

    const internalCaptionSvg1 = element.verticalType == VerticalSignalizationTypeEnum.Indication ? '' : !element.internalCaption
      ? ''
      : element.complementInformationType
        ? `${element.signCode}-32 internal-caption`
        : `${element.signCode}-40-map internal-caption`;

    const h = element.complementInformationType == ComplementInformationEnum.Additional ? 20 : 60;
    const imgComponent1 = !element.complementInformationType
      ? ''
      : `<img src="${complementSvg1}" style="width: 40px; height: ${h}px; position: absolute; bottom: 0px;">`;
    const height1 = element.complementInformationType ? 54 : 40;
    const width1 = element.complementInformationType ? 32 : 40;
    const svgW = element.complementInformationType ? 32 : 40;
    const svgH = element.complementInformationType ? 32 : element?.signCode == this.verticalSignEnum.ViaFiscalizadaPorCameras || element?.signCode == this.verticalSignEnum.QrCode ? 60 : 40;

    conjugateSign1 =
      conjugateList.length >= 1
        ? `<div class="info p1">
        <div class="d-flex mb-3 w-100 align-items-center">
          <div class="position-relative d-flex">
            <div class="d-flex justify-content-center" style="width: 32px">
              ${imgComponent1}
              <div class="${internalCaptionSvg1}" style='position: relative; width: ${width1}px; height: ${height1}px;'>
                <span style="z-index: 1000">${internalCaption}</span>
                <img src=${element.svg} style="width: ${svgW}px; height: ${svgH}px; object-fit: contain;">
              </div>
            </div>
            <div class="ml-3">
              <p class="m-0" style="font-weight: 500; font-size: 16px; font-family: Roboto,sans-serif; color: var(--gray-dark); width: 200px; overflow: hidden; text-overflow: ellipsis;">${code}</p>
              <p class="m-0" style="font-size: 12px; font-weight: 400; font-family: Roboto,sans-serif; color:var(--gray-base); word-wrap: break-word; width: 80%">${element.signName}</p>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 mb-2 align-items-center">
          ${progressBar}
          ${timeDate}
          ${endLife}
        </div>
      </div>`
        : '';
    const files = element?.verticalSignalingFiles ?? element?.horizontalSignalingFiles ?? element?.deviceSignalingFiles
    const signImageUrl = files.length ?
      `<div class="sign-image-popover">
        <img src=${files.length ? files[0]?.url : ''} class="image-popover">
      </div>`
      : `<div class="sign-image-popover">
          <img src="assets/img/image-popover-default.png" class="image-popover">
        </div>`

    if (element?.occurrence?.horizontalSignalingId) element.occurrence.type = SignalingTypeEnum.Horizontal;
    else if (element?.occurrence?.verticalSignalingId) element.occurrence.type = SignalingTypeEnum.Vertical;
    else if (element?.occurrence?.deviceSignalingId) element.occurrence.type = SignalingTypeEnum.Device;
    const occurrenceHref = element?.occurrence?.id && element?.occurrence?.type ?
      `href="#/catalog/occurrences/display?id=${element.occurrence.id}&type=${element.occurrence.type}"` : '';

    const occurrenceContent = `
      <div class="position-relative d-flex  align-items-center" style="font-size: 20px;">
        <a ${occurrenceHref}
          class="fa-regular fa-message-exclamation fa-lg cursor-pointer"
          style="color: var(${occurrenceHref ? '--orange-base' : '--gray-base'}) !important; text-decoration: none; pointer-events:none"
          id="occBtn">
        </a>
      </div>`;

    let content =
      conjugateList.length == 0
        ? `
            ${signImageUrl}
            <div class="d-flex mb-3 w-100 align-items-center flex-column" style="min-width: 240px">
              <div class="d-flex w-100 mb-3 justify-content-between">
                <div class="position-relative d-flex">
                  <div class="d-flex justify-content-center" style="width: 32px">
                    ${imgComponent1}
                    <div class="${internalCaptionSvg1}" style='position: relative; width: ${width1}px; height: ${height1}px;'>
                      <span style="z-index: 1000">${internalCaption}</span>
                      <img src=${element.svg} style="width: ${svgW}px; height: ${svgH}px;">
                    </div>
                  </div>

                  <div class="ml-3">
                    <p class="m-0" style="font-weight: 500; font-size: 16px; font-family: Roboto,sans-serif; color: var(--gray-dark); width: 200px; overflow: hidden; text-overflow: ellipsis;">${code}</p>
                    <p class="m-0" style="font-size: 12px; font-weight: 400; font-family: Roboto,sans-serif; color:var(--gray-base); word-wrap: break-word; width: 80%">${element.signName}
                    </p>
                  </div>
                </div>
                ${occurrenceContent}
              </div>
              <div class="d-flex w-100 mb-2 align-items-center">
                ${progressBar}
                ${timeDate}
                ${endLife}
              </div>
              <div class="d-flex w-100 align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  ${circle}
                  ${position}
                </div>
                <a href="${url}" class="btn btn-link btn-popup d-flex align-items-center justify-content-center p-2" style="height: 30px">
                  VER ITEM <i class="fas fa-angle-right ml-2 mr-0" style="font-size: 20px;"></i>
                </a>
              </div>
            </div>`
        : `
            ${signImageUrl}
            <div class="map-info-window-group"  style="min-width: 240px">
              ${conjugateSignHover}
              ${conjugateSign1}
              ${conjugateSign2}
              ${conjugateSign3}
              <div style="position: absolute; top: 24px; right: 0px">
                ${occurrenceContent}
              </div>
              <div class="d-flex w-100 align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  ${circle}
                  ${position}
                </div>
                <a href="${url}" class="btn btn-link btn-popup d-flex align-items-center justify-content-center p-2" style="height: 30px">
                  VER ITEM <i class="fas fa-angle-right ml-2 mr-0" style="font-size: 20px;"></i>
                </a>
              </div>
            </div>
      `;

    return content;
  }

  addPopupTeam(teamIn: any) {
    const imgRoundedTeam = teamIn.team?.leaderMember?.user?.imageURL ? teamIn.team?.leaderMember?.user?.imageURL : 'assets/icons/camera.svg';
    const imgTeam = teamIn?.teamId ?
      `<img class="image-rounded" src=${imgRoundedTeam} alt="">`
      : '';

    const leaderName = teamIn.team?.leaderMember?.user?.firstName + ' ' + teamIn.team?.leaderMember?.user?.lastName;
    const dateUpdate = format(new Date(teamIn?.location?.createdAt), 'dd/MM/yyyy - HH:mm');

    const url = `${this.uri}/#/services/teams/checkout?id=${teamIn?.team?.id}&detail=true`;

    const membersName = this.getMembersName(teamIn.team?.members ?? [])

    let content =
      `<div class="popover-team">
        <div class="popover-flex">
          ${imgTeam}
          <span>${teamIn.name}</span>
        </div>
        <div class="d-flex">
          <h4>Atualizado: <h4 class="text-muted ml-1">${dateUpdate}</h4></h4>
        </div>
        <div class="d-flex">
          <h4>Líder de equipe: <h4 class="text-muted ml-1">${leaderName}</h4></h4>
        </div>
        <div class="d-flex">
          <h4>Membros: <h4 class="text-muted ml-1">${membersName}</h4></h4>
        </div>
        <div class="d-flex justify-content-end">
          <a href="${url}" class="btn btn-link btn-popup detail-sidebar-button">
            VER DETALHES <i class="fas fa-angle-right ml-2 mr-0" style="font-size: 20px;"></i>
          </a>
        </div>
       </div>`;

    return content
  }

  addPopupVehicleTeam(teamVehicle: any) {
    const imgRoundedVehicle = teamVehicle.vehicle?.vehicleImage?.url ? teamVehicle.vehicle?.vehicleImage?.url : 'assets/icons/camera.svg';
    const imgVehicle = teamVehicle?.teamId ?
      `<img class="image-rounded" src=${imgRoundedVehicle} alt="">`
      : '';

    const imgRoundedTeam = teamVehicle.team?.leaderMember?.user?.imageURL ? teamVehicle.team?.leaderMember?.user?.imageURL : 'assets/icons/camera.svg';
    const imgTeam = teamVehicle?.teamId ?
      `<img class="image-rounded" src=${imgRoundedTeam} alt="">`
      : '';

    const iconLink = (teamVehicle?.teamId && teamVehicle?.vehicle?.id) ? '<i class="fa-solid fa-link text-muted"></i>' : ''
    const dateUpdate = format(new Date(teamVehicle?.location?.createdAt), 'dd/MM/yyyy - HH:mm');

    let membersName = this.getMembersName(teamVehicle?.team?.members ?? []);

    let content =
      `<div class="popover-team">

        <div class="popover-row">
          <div class="popover-flex">
            <i class="far fa-user-friends fa-xl"></i>
            <h4 class="text-overflow" style="width: 140px; font-size: 16px;">${teamVehicle?.team?.name}</h4>
          </div>
          <div style="position: relative;">
            <i class="far fa-clipboard-list-check text-muted fa-2xl"></i>
            <div class="number-task-popover">${teamVehicle?.totalTasksToday}</div>
          </div>
        </div>

        <div class="d-flex">
          <h4>Atualizado: <h4 class="text-muted ml-1">${dateUpdate}</h4></h4>
        </div>

        <div class="d-flex">
          <h4>Membros: <h4 class="text-muted ml-1">${membersName}</h4></h4>
        </div>

        <div class="popover-row">
          <div class="popover-flex">
            ${imgTeam}
            ${iconLink}
            ${imgVehicle}
          </div>

          <a id="detailsViewPopupTeam${teamVehicle?.teamId}" class="btn btn-link btn-popup detail-sidebar-button">
            VER DETALHES <i class="fas fa-angle-right ml-2 mr-0" style="font-size: 20px;"></i>
          </a>

        </div>
      </div>
    `
    return content
  }

  getMembersName(teamMembers: TeamMember[]) {
    let membersName = '';
    if (teamMembers?.length > 0) {
      membersName = teamMembers.map(member => member.name).join(', ');
      const lastComma = membersName.lastIndexOf(',');

      if (teamMembers.length > 1) {
        membersName = membersName.slice(0, lastComma) + " e " + membersName.slice(lastComma + 1);
      }
    } else {
      membersName = '-';
    }

    return membersName;
  }

  openSideBar(open = true): any {
    this.sidebarMapOpen = open;
  }

  addPopupTask(task: Task) {
    const imgRoundedVehicle = task?.vehicle?.vehicleImage?.url ? task?.vehicle?.vehicleImage?.url : 'assets/icons/camera.svg';
    const imgVehicle = task?.teamId ?
      `<img class="image-rounded" src=${imgRoundedVehicle} alt="">`
      : '';

    const imgRoundedTeam = task?.team?.leaderMember?.user?.imageURL ? task.team?.leaderMember?.user?.imageURL : 'assets/icons/camera.svg';
    const imgTeam = task?.teamId ?
      `<img class="image-rounded" src=${imgRoundedTeam} alt="">`
      : '';

    const iconLink = (task?.teamId && task?.vehicle?.id) ? '<i class="fa-solid fa-link text-muted"></i>' : ''

    const url = '';

    const type = enumerators.SignalingType[task?.signalingType];

    const address = (task?.address['address']?.road ? task?.address['address']?.road + ', ' : '') +
      (task?.address['address']?.road ? task?.address['address']?.house_number ?
        task?.address['address']?.house_number + ' - ' : 's/ nº - ' : '') +
      (task?.address['address']?.suburb ? task?.address['address']?.suburb + ', ' : '') +
      (task?.address['address']?.city ? task?.address['address']?.city : '') +
      (task?.address['address']?.postcode ? (', ' + task?.address['address']?.postcode) : '')

    let taskActivitiesProgress = [];
    for (let index = 0; index < task?.progress?.activities; index++) {
      taskActivitiesProgress.push(index < task?.progress?.completedActivities);
    }

    const divProgress = this.setProgressTaskBar(taskActivitiesProgress);

    const divActivity = task.taskType == TaskTypeEnum.Cataloging ? '' : ` <div class="d-flex flex-column">
                            <div class="popover-row mb-2">
                              <span> Atividade </span>
                              <span> Progresso  ${task?.progress?.completedActivities}/${task?.progress?.activities}</span>
                            </div>

                            <div class="d-flex">
                              ${divProgress}
                            </div>
                          </div>`

    const dateUpdate = format(new Date(task?.updatedAt), 'dd/MM/yyyy - HH:mm');

    let content =
      `<div class="popover-team">

        <div class="popover-row">
          <div class="popover-flex">
            <i class="fas fa-circle fa-xl ${task?.status}"></i>
            <i class="far fa-square-plus fa-xl"></i>
            <h4 class="text-overflow" style="width: 140px; font-size: 16px;">${task?.name}</h4>
          </div>
          <div class="popover-flex">
            <div class="type-sign-box">
              <span class="type-sign-text">${type}</span>
            </div>
            <i class="fas fa-flag ${task?.priority} fa-2xl"></i>
          </div>
        </div>

        <div class="popover-flex">
          <i class="far fa-map-location text-muted fa-xl"></i>
          <span class="text-muted">${address}</span>
        </div>

        ${divActivity}

        <div class="d-flex">
          <h4>Atualizado: <h4 class="text-muted ml-1">${dateUpdate}</h4></h4>
        </div>

        <div class="popover-row">
          <div class="popover-flex">
            ${imgTeam}
            ${iconLink}
            ${imgVehicle}
          </div>

          <a id="detailsViewPopupTask${task?.id}" class="btn btn-link btn-popup detail-sidebar-button">
            VER DETALHES <i class="fas fa-angle-right ml-2 mr-0" style="font-size: 20px;"></i>
          </a>

        </div>
      </div>
    `
    return content
  }

  setProgressTaskBar(taskActivitiesProgress: Array<boolean>) {
    let content = '';
    taskActivitiesProgress.forEach((activities, index) => {
      content += activities
        ? index == (taskActivitiesProgress.length - 1) ? `<div class="progress-task-bar-green"></div>` : `<div class="progress-task-bar-green mr-2"></div>`
        : index == (taskActivitiesProgress.length - 1) ? `<div class="progress-task-bar-snow"></div>` : `<div class="progress-task-bar-snow mr-2"></div>`
    });

    return content;
  }

  // Retorna lat lng com input de texto, serve para pesquisa no mapa
  mapSearch(searchInput: string, provider: 'openStreet' | 'googleMaps') {
    let url = '';

    switch (provider) {
      case 'openStreet':
        url = 'https://nominatim.openstreetmap.org/search?format=json';
        url += `&q=${searchInput}`;
        url += '&countrycodes=BR';
        break;

      case 'googleMaps':
        url = 'https://maps.googleapis.com/maps/api/geocode/json';
        url += `?key=${this.googleAPI}`;
        url += `&address=${searchInput}`;
        break;
    }

    return this._http.get(url);
  }
  // Recebe lista de sinalizações e atribui bounds ao mapa
  setRegionAreaMapBoundaries(itens: Region[] | Area[], map: Leaflet.Map) {
    let bounds = [];
    itens.map((item) => {
      item.vertices.forEach((latLng) => {
        bounds.push([latLng.lat, latLng.lng]);
      });
    });
    map.fitBounds(bounds as Leaflet.LatLngBoundsExpression, {
      animate: true,
    });
  }

  // Remove a layer criada do mapa com cor verde '#00c714'
  removeLastLayer(map: Leaflet.Map) {
    map.eachLayer((layer) => {
      if (layer['options']['color'] == this.mapColors.greenSuccess) layer.remove();
    });
  }

  // Adiciona marker da procura do mapa geral
  markerSearchCreate(latlng: [number, number], map: Leaflet.Map) {
    const divHTML = `<img style="width=48px; height: 78px;" src="assets/icons/marker-search.svg">`
    const m = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [60, 78],
      iconAnchor: [30, 78],
    });
    const marker = Leaflet.marker([latlng[0], latlng[1]], Object.assign({ icon: m }));

    return marker;
  }

  addMarkerStreetView(icon, index) {
    const svgPinSign = 'svg-marker-catalog';
    const marker = icon ? `<img src= ${icon} class=${svgPinSign}>` : `<div class="${svgPinSign}">${index}</div>`
    const divHTML =
      `<div style="height: 78px">
        <img src="assets/icons/pin-sign-create.svg">
        </div>`;
    return divHTML;
  }

  // Mesma função de addMarker, porém resumida, quero o html que me retorna ao inves do marcador, e só cria o marcador simplório
  contentMarkerHTML(
    icon: string,
    width: number,
    height: number,
    conjugate: boolean,
    internalCaption: number,
    classInternalCaption: string,
    signCode?: string,
  ) {
    let divHTML
    const caps = ['R-14', 'R-15', 'R-16', 'R-17', 'R-18', 'R-19', 'A-37', 'A-38', 'A-46', 'A-47', 'A-48'];
    const link = conjugate
      ? `<img src="assets/img/linkConjugate.svg" style="width:20px; height:20px;position: absolute;left: 20px; top: -6px">`
      : '';
    const busStop = signCode == VerticalSignalingEnum.BusStop ? 'background-size: contain; background-repeat: no-repeat;' : '';

    divHTML = `<div class= "${classInternalCaption} internal-caption" style="position: relative; width: ${width}px; height: ${height}px">
        <span style="z-index: 1000">${width > 30 ? ((caps.find((v) => signCode == v)) ? internalCaption : '') : ''}</span>
        <div class="marker-background-image" style="background-image:url(${icon}); width: ${width}px; height: ${height}px; ${busStop}"></div>${link}
        </div>`;
    return divHTML;
  }


  contentTaskMarkerHTML(color, width, height) {
    let markIcon = 'assets/icons/map-task-pin.svg';

    const iconStyle = `--fa-primary-color: ${color}; --fa-secondary-color: white; --fa-secondary-opacity: 1.0;`

    const divHTML =
      width > 20 ? `<div class="d-flex align-items-center justify-content-center">
                        <img height="${height}" width="${width}" src="${markIcon}">
                        <span class="position-absolute d-flex align-items-center justify-content-center" style="margin-bottom: 10px">
                          <i class="fa-solid fa-circle position-absolute fa-3x" style="color: ${color};"></i>
                          <i class="fa-duotone fa-clipboard-list-check position-absolute fa-xl pb-1" style="${iconStyle}"></i>
                      </span>
                    </div>`
        : `<div class="d-flex align-items-center justify-content-center">
                   <i class="fa-solid fa-circle position-absolute" style="color: ${color};"></i>
                  </div>` ;
    const iconDiv = Leaflet.divIcon({
      className: 'marker-map-task',
      html: divHTML,
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      popupAnchor: [0, -height]
    });
    return divHTML;
  }


  addZonePolyline(map: Leaflet.Map, clickMethod: any, drawnItems) {
    var vertices: EventEmitter<any> = new EventEmitter();

    Leaflet.drawLocal.draw.handlers.polygon.tooltip.start = '';
    Leaflet.drawLocal.draw.handlers.polygon.tooltip.cont = '';
    Leaflet.drawLocal.draw.handlers.polygon.tooltip.end = '';

    // Iniciar Desenho do Polígono
    const drawPolygon = new Leaflet.Draw.Polygon(<Leaflet.DrawMap>map, {
      allowIntersection: false,
      showArea: false,
      showLength: true,
      metric: true,
      drawError: {
        color: '#b00b00',
        timeout: 2500,
        message: 'Você nao pode cruzar as linhas',
      },
      shapeOptions: { color: '#525252', fillOpacity: 0.2 },
    });
    var enabled = true
    drawPolygon.enable();

    // Evento ao Terminar o Polígono
    map.on(Leaflet.Draw.Event.CREATED, (event) => {
      enabled = false
      const layer = event['layer'];
      const positions: Array<{ lat: number; lng: number }> = layer['editing']['latlngs'][0][0];

      // Criar Polígono
      const polygon = Leaflet.polygon(positions, {
        stroke: true,
        weight: 3,
        color: this.mapColors.greenSuccess,
        fillColor: this.mapColors.greenSuccess,
        fillOpacity: 0.25,
      });

      drawnItems.addLayer(polygon);
      // Remover Evento Atual
      map.removeEventListener(Leaflet.Draw.Event.CREATED);
      polygon.addTo(map).on('click', (target) => clickMethod(target));
      vertices.emit(polygon['_latlngs']);
      this.listPolygons(polygon['_latlngs'], polygon, 'Você nao pode cruzar as linhas', map, false);
    });

    return vertices;
  }

  // Mesma função de addMarker, porém resumida, quero o html que me retorna ao inves do marcador, e só cria o marcador simplório
  contentMarkerHTMLDefault(
  ) {
    let divHTML = `<div style="height: 78px">
        <img src="assets/img/pinSign.svg">
        <div class="position-relative" style="top: -70px; left: 14px;">
          <div style="height: 32px; width: 32px; top: 12px; left: 18px;background-color: #7E7E80; border-radius: 50%">
          </div>
        </div>
      </div>`;
    return divHTML;
  }

  addServiceMark(service: ServiceModel, layer) {
    let marker;
    const divHTML = `<div><img src="assets/img/servicePin.svg"></div>`;
    let icon = Leaflet.divIcon({
      className: 'marker-map',
      html: divHTML,
      iconSize: [40, 60],
      iconAnchor: [40 / 2, 60],
    });
    marker = Leaflet.marker([service?.latitude, service?.longitude], Object.assign({ icon }));
    if (layer) marker.addTo(layer);

  }
}
