import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import { ActionEnum, SignalingTypeEnum, SystemModuleEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { Log, MemberNotificationSetting } from 'src/models';
import { AuthService } from './auth.service';
import { ContractService } from './contract.service';
import { LogsService } from './logs.service';
import { RestApiService } from './rest-api.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService extends RestApiService {
  // Variáveis
  private _socketConnection: Socket = null;
  private _urlNotifications = '/api/notification';
  private readonly LAST_LOG = 'LAST_LOG_DATE';
  eventNotifications: BehaviorSubject<Log> = new BehaviorSubject(undefined);
  eventNotificationsVehicle: BehaviorSubject<Log> = new BehaviorSubject(undefined);
  listNotifications: Array<Log> = [];
  listGroupNotifications: Array<Log> = [];
  listNotificationsDays: Array<any> = [];
  listGroupNotificationsDays: Array<any> = [];
  listUsers: any;
  showNewLog = false;
  lastLogDate: Date;
  memberNotificationSettings: MemberNotificationSetting = new MemberNotificationSetting({});

  constructor(public http: HttpClient,
    private _auth: AuthService,
    private _contract: ContractService,
    private _logsService: LogsService,
    private _toastService: ToastService,
  ) {
    super(http);
  }

  // Iniciar Conexão Socket com o Servidor
  StartNotificationsSocket(): void {
    const url = environment.endpoint();
    const token = this._auth.getJwtToken();

    // Servidor Para Escutar Eventos
    if (this._socketConnection && !this._socketConnection?.disconnected) { return null; }

    // Get lista de usuários
    this._contract.listAllWorkspaceUsers().subscribe((res: any) => {
      this.listUsers = res.members;
      this._logsService.getLogs(0, 10).subscribe((resLog: Array<Log>) => {
        this.listNotifications = resLog;
        this.lastLogDate = this.listNotifications.length > 0 ? this.listNotifications[0].updatedAt : undefined;

        if (!this.getLastLog()) {
          this.storeLastLog(this.lastLogDate);
        }

        this.listNotifications = this.usersLogs(this.listNotifications);
        this.listNotificationsDays = this.createLogsDays(this.listNotifications);

      });

      const startDate: Date = new Date();
      startDate.setDate(startDate.getDate() - 5);
      startDate.setHours(0, 0, 0, 0);

      this._logsService.getLogsGroup(30, startDate).subscribe((resLog: Array<Log>) => {
        this.listGroupNotifications = resLog;

        this.lastLogDate = this.listGroupNotifications.length > 0 ? this.listGroupNotifications[0].updatedAt : undefined;

        if (!this.getLastLog()) {
          this.storeLastLog(this.lastLogDate);
        }

        this.listGroupNotifications = this.usersLogs(this.listGroupNotifications);

        this.listGroupNotificationsDays = this.createLogsDays(this.listGroupNotifications);
      });
    });

    this._socketConnection = io(url, {
      path: this._urlNotifications,
      transports: ['websocket'],
      query: { token }
    });

    // Pegando a parte das configurações de notificações
    this.getMemberNotificationSettings()

    // Sucesso e Erro na Conexão com o Socket
    this._socketConnection.on('connect', () => { console.log('Conexão Estabelecida (Notificações)!'); this.ListenNotifications(); });
    this._socketConnection.on('connect_error', () => { console.error('Ocorreu um erro ao criar a comunicação com o serviço de notificações'); });

  }

  // GET das configurações de notificações do membro
  getMemberNotificationSettings() {
    this._logsService.getMemberNotificationsSetting().subscribe((res: MemberNotificationSetting) => {
      this.memberNotificationSettings = res;
    });
  }

  // Encerrar Conexão Socket com o Servidor
  StopNotificationsSocket(): void {
    if (this._socketConnection?.connected) { this._socketConnection.disconnect(); }
  }

  // Salva a data do ultimo log no localstorage
  storeLastLog(lastLog: Date) {
    localStorage.setItem(this.LAST_LOG, lastLog.toISOString());
  }

  // Retorna a data do ultimo log do localstorage
  getLastLog() {
    return new Date(localStorage.getItem(this.LAST_LOG));
  }

  // Limpa a data do ultimo log do localstorage
  private removeLastLog() {
    localStorage.removeItem(this.LAST_LOG);
  }

  createLogsDays(listDays: Array<Log>): Array<any> {
    let list: Array<any> = [];
    if (this.lastLogDate > this.getLastLog()) {
      list = this.GroupDates(listDays);
      this.showNewLog = true;
      this.storeLastLog(this.lastLogDate);
    } else {
      list = this.GroupDates(listDays);
    }
    return list;
  }

  usersLogs(listLogs): Array<Log> {
    listLogs.forEach((log: Log) => {
      const user = this.listUsers?.find(user => user.userId == log.userId);
      if (user) { log.user = user.user; }
    });
    return listLogs;
  }


  // Escutar Notificações
  ListenNotifications() {
    if (this._socketConnection?.connected) {
      this._socketConnection.on('notification', res => {
        const log = new Log(res);
        const user = this.listUsers?.find(user => user.userId == log.userId);
        if (user) { log.user = user.user; }
        this.eventNotifications.next(log);
        this.listNotifications.unshift(log);
        this.listGroupNotifications.unshift(log);
        this.listNotificationsDays = this.GroupDates(this.listNotifications);
        this.listGroupNotificationsDays = this.GroupDates(this.listGroupNotifications);
        this.showNewLog = true;
        this.storeLastLog(log.updatedAt);
        if (this.memberNotificationSettings.viewToastNotification)
          this._toastService.showNotification(this.getNotificationDescriptionFull(log));
      });

      this._socketConnection.on('team-location', res => {
        this.eventNotificationsVehicle.next(res);
      });

      this._socketConnection.on('check-in', res => {
        this.eventNotificationsVehicle.next(res);
      });

      this._socketConnection.on('check-out', (res: any) => {
        res['checkout'] = true;
        this.eventNotificationsVehicle.next(res);
      });

      this._socketConnection.on('team-check-out', (res: any) => {
        res['checkout'] = true;
        this.eventNotificationsVehicle.next(res);
      });

      this._socketConnection.on('task-status-update', res => {
        this.eventNotificationsVehicle.next(res);
      });
    }
  }

  // Agrupar Logs por Data
  GroupDates(logs: Array<Log>, fullBool: boolean = false): Array<any> {
    const list = [];
    const datesRef = {}; // Dia: Log[]
    const add = false;

    logs.forEach(log => {
      // Dia Por Extenso
      const newDate = new Date(log.createdAt);
      const date = newDate.toLocaleDateString('pt-br', { weekday: 'long', day: '2-digit', month: 'long' });
      let text: string;

      fullBool ? text = this.getNotificationDescriptionFull(log) : text = this.getNotificationDescription(log);
      // Adicionar ou Criar Objeto
      if (datesRef[date]) { datesRef[date].push([log, text]); }
      else { datesRef[date] = [[log, text]]; }
    });

    // Ordenar Dias
    for (let date in datesRef) {
      list.push([date, datesRef[date]]);
    }

    // Alterar Legenda de 'Hoje'
    const today = new Date();
    if (list[0] && list[0][0] == today.toLocaleDateString('pt-br', { weekday: 'long', day: '2-digit', month: 'long' })) {
      list[0][0] = 'Hoje';
    }

    // Alterar Legenda de 'Ontem'
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    if (list[0] && list[0][0] == yesterday.toLocaleDateString('pt-br', { weekday: 'long', day: '2-digit', month: 'long' })) {
      list[0][0] = 'Ontem';
    } else {
      if (list[1] && list[1][0] == yesterday.toLocaleDateString('pt-br', { weekday: 'long', day: '2-digit', month: 'long' })) {
        list[1][0] = 'Ontem';
      }
    }
    return list;
  }

  isFemaleGender(log: Log): boolean {
    if (log.module == SystemModuleEnum.SignalingRegister || log.module == SystemModuleEnum.Area || log.module == SystemModuleEnum.Team || log.module == SystemModuleEnum.Task || log.module == SystemModuleEnum.ServiceOrder || log.module == SystemModuleEnum.Routes) return true;
  }

  // Cria o texto para cada log
  getNotificationDescription(log: Log): string {
    let text = log.user?.firstName + ' ' + log.user?.lastName + ' ';

    if (log.module == SystemModuleEnum.Warranty) {
      const numberSigns = log.data.device + log.data.horizontal + log.data.vertical;
      const sign = numberSigns == 1 ? 'sinalização entrou' : 'sinalizações entraram';
      text = `${numberSigns} ${sign} em alerta de fim da garantia.`;
    } else {
      // Escreve a ação
      const genderOrGroupText = log.isGroup ? log.data.quantity : this.isFemaleGender(log) ? 'uma' : 'um';
      text += enumerators['ActionSimpleText'][log.action] + ' ' + genderOrGroupText + ' ';

      // Escreve o tipo
      const auxGroup = log.isGroup ? 'GROUP' : 'SINGLE';
      text += enumerators['SystemModuleText'][log.module][auxGroup];

    }

    return text;
  }

  // Cria o texto para cada log em historico
  getNotificationDescriptionFull(log: Log): string {
    let text = log?.user?.firstName + ' ' + log?.user?.lastName + ' ';

    if (log.module == SystemModuleEnum.Warranty) {
      // Apenas garantia não tem nome do usuário
      const numberSigns = log.data.device + log.data.horizontal + log.data.vertical;
      const sign = numberSigns == 1 ? 'sinalização entrou' : 'sinalizações entraram';
      text = `${numberSigns} ${sign} em alerta de fim da garantia.`;
    } else {
      if (log.module == SystemModuleEnum.Team) {
        // Apenas equipe começa assim
        const leader = this.listUsers?.find(user => user.id == log.data.leaderMemberId);
        text = `Equipe de ${log.data.type} '${log.data.name}' foi criada com o líder '${leader?.user?.firstName} ${leader?.user?.lastName}'`;
      } else {
        // Escreve a ação
        const genderText = this.isFemaleGender(log) ? 'uma' : 'um';
        text += enumerators['ActionSimpleText'][log.action] + ' ' + genderText + ' ';

        if (log.module == SystemModuleEnum.Contract || log.module == SystemModuleEnum.Project)
          text += ` ${enumerators['SystemModuleText'][log.module]['SINGLE']} "${log.data.name}".`;

        else if (log.module == SystemModuleEnum.SignalingRegister) {
          const signCode = log?.data?.verticalCode || log?.data?.horizontalCode || log?.data?.deviceCode || log?.data?.trafficSigns[0]?.verticalCode;

          const verticalType = log?.data?.trafficSigns?.length ? log?.data?.trafficSigns[0]?.verticalType
            : log?.data?.verticalType || '';

          const signName = enumerators.Signaling[signCode];
          const signType = verticalType ? enumerators.SignalizationType[verticalType] : '';
          text += `sinalização de ${signType} "${signName}".`;
        }

        else if (log.module == SystemModuleEnum.Region || log.module == SystemModuleEnum.Area)
          text += enumerators['SystemModuleText'][log.module]['SINGLE'];

        else if (log.module == SystemModuleEnum.Vehicle) text += ` "${enumerators.VehicleType[log.data.vehicleType]}".`;

        else if (log.module == SystemModuleEnum.Task)
          text += ` tarefa de ${enumerators.SignalingType[log.data.signalingType]} '${log.data.name}' com ${enumerators.OrderPriority[log.data.priority]}.`;

        else if (log.module == SystemModuleEnum.ServiceOrder) text += ` ordem de serviço '${log.data.name}'.`;

        else if (log.module == SystemModuleEnum.Path)
          text += ` trajeto '${log.data.name}' com ${log.data.length.toFixed(2)}km de extensão.`;

        else if (log.module == SystemModuleEnum.Routes)
          text += ` rota '${log.data.name}' com ${log.data.length.toFixed(2)}km de extensão.'`;

        else if (log.module == SystemModuleEnum.Stock) {
          const code = log.data.type == SignalingTypeEnum.Device ? log.data.deviceCode : log.data.type == SignalingTypeEnum.Vertical ? log.data.verticalCode : log.data.horizontalCode;
          const signName = enumerators.Signaling[code];
          const signType = enumerators.SignalingType[log.data.type];
          text += `estoque de ${signType} "${signName}".`;
        }

      }
    }
    return text;
  }
}
