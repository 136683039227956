import { SystemModuleEnum } from 'src/enumerators';
import { IRest } from './rest.interface';

export class FilterReportState implements IRest {
  className = 'Estado do filtro do relatório';

  listSelected: Set<any>;
  pathIds: Set<string>;
  userIds: Set<string>;
  contractId: string;
  checkAll: boolean;
  contractCheck: boolean;
  verticalCheck: boolean;
  horizontalCheck: boolean;
  deviceCheck: boolean;
  financeCheck: boolean;
  activeCheck: boolean;
  warrantyCheck: boolean;
  regionCheck: boolean;
  dateCheck: boolean;
  taskInfoCheck: boolean;
  taskLocationCheck: boolean;
  imageViewCheck: boolean;
  pendenciesRadio: boolean;
  startDate: Date;
  endDate: Date;
  countPlus: number; // Uso interno
  countPlusPath: number; //Uso interno
  countPlusUser: number; //Uso interno
  lastSelected: any; // Uso interno
  photosCheck: any; // Uso interno
  reportType: 'PDF' | 'XLS';

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      listSelected: this.listSelected,
      contractId: this.contractId,
      checkAll: this.checkAll,
      contractCheck: this.contractCheck,
      verticalCheck: this.verticalCheck,
      horizontalCheck: this.horizontalCheck,
      deviceCheck: this.deviceCheck,
      financeCheck: this.financeCheck,
      activeCheck: this.activeCheck,
      warrantyCheck: this.warrantyCheck,
      regionCheck: this.regionCheck,
      dateCheck: this.dateCheck,
      taskInfoCheck: this.taskInfoCheck,
      taskLocationCheck: this.taskLocationCheck,
      imageViewCheck: this.imageViewCheck,
      pendenciesRadio: this.pendenciesRadio,
      startDate: this.startDate,
      endDate: this.endDate,
      pathIds: this.pathIds,
      userIds: this.userIds,
      reportType: this.reportType
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.listSelected = data['listSelected'] || new Set();
      this.pathIds = data['pathIds'] || new Set();
      this.userIds = data['userIds'] || new Set();
      this.contractId = data['contractId'] || '';
      this.checkAll = data['checkAll'] || false;
      this.contractCheck = data['contractCheck'] || false;
      this.verticalCheck = data['verticalCheck'] || true;
      this.horizontalCheck = data['horizontalCheck'] || true;
      this.deviceCheck = data['deviceCheck'] || true;
      this.financeCheck = data['financeCheck'] || true;
      this.activeCheck = data['activeCheck'] || true;
      this.warrantyCheck = data['warrantyCheck'] || true;
      this.regionCheck = data['regionCheck'] || false;
      this.dateCheck = data['dateCheck'] || true;
      this.taskInfoCheck = data['taskInfoCheck'] || true;
      this.taskLocationCheck = data['taskLocationCheck'] || true;
      this.imageViewCheck = data['imageViewCheck'] || true;
      this.pendenciesRadio = data['pendenciesRadio'] || true;
      this.startDate = data['startDate'] || undefined;
      this.endDate = data['endDate'] || undefined;
      this.reportType = data['reportType'] || 'PDF';
    }
    return this;
  }
}

export class FileAttributes implements IRest {
  className = 'Atributos do arquivo';

  id: string;
  key: string;
  organizationId: string;
  originalName: string;
  mimeType: string;
  extension: string;
  size: number;
  isStoredLocally: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      key: this.key,
      organizationId: this.organizationId,
      originalName: this.originalName,
      mimeType: this.mimeType,
      extension: this.extension,
      size: this.size,
      isStoredLocally: this.isStoredLocally,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.key = data['key'] || '';
      this.organizationId = data['organizationId'] || '';
      this.originalName = data['originalName'] || '';
      this.mimeType = data['mimeType'] || '';
      this.extension = data['extension'] || '';
      this.size = data['size'] || 0;
      this.isStoredLocally = data['isStoredLocally'] || false;
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
      this.updatedAt = data['updatedAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
    }
    return this;
  }
}

export class UserRo implements IRest {
  className = 'Usuário dentro do relatório';

  id: string;
  firstName: string;
  lastName: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.firstName = data['firstName'] || '';
      this.lastName = data['lastName'] || '';
    }
    return this;
  }
}

export class Report implements IRest {
  className = 'Relatório';

  id: string;
  workspaceId: string;
  reportIssuedByUserId: string;
  reportIssuedByUser: UserRo;
  fileId: string;
  file: FileAttributes;
  module: SystemModuleEnum;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  check: boolean;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      workspaceId: this.workspaceId,
      reportIssuedByUserId: this.reportIssuedByUserId,
      reportIssuedByUser: this.reportIssuedByUser.Serialize(),
      fileId: this.fileId,
      file: this.file.Serialize(),
      module: this.module,
      url: this.url,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      check: this.check,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || '';
      this.workspaceId = data['workspaceId'] || '';
      this.reportIssuedByUserId = data['reportIssuedByUserId'] || '';
      this.reportIssuedByUser = new UserRo(data['reportIssuedByUser'] || {});
      this.fileId = data['fileId'] || '';
      this.file = new FileAttributes(data['file'] || {});
      this.module = <SystemModuleEnum>(data['module'] || '');
      this.url = data['url'] || false;
      this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
      this.updatedAt = data['updatedAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
      this.check = data['check'] || false;
    }
    return this;
  }
}
