import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumCaption'
})
export class EnumCaptionPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (args[2]) { return `${value}-${args[1]}-${args[2]}`; }
    return `${value}-${args[1]}`;
  }

}
