<div
  id="header-menu-container"
  class="d-flex row justify-content-center align-center header-menu-container"
  [ngStyle]="{ left: calcWidth }"
>
  <div class="col-auto mx-4 menu-link" #link routerLink="/map" routerLinkActive="active-link">
    <div></div>
    <p>Mapa</p>
    <div class="bottom-border" #border></div>
  </div>
  <div
    class="col-auto mx-4 menu-link"
    #link
    routerLink="{{ router }}"
    routerLinkActive="active-link"
    *ngIf="
      authorizationService.checkLicenseType(licenseTypeEnum.Full) &&
      checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])
    "
  >
    <div></div>
    <p>Gestão</p>
    <div class="bottom-border" #border></div>
  </div>
  <div class="col-auto mx-4 menu-link" #link routerLink="/catalog" routerLinkActive="active-link">
    <div></div>
    <p>Catálogo</p>
    <div class="bottom-border" #border></div>
  </div>
  <div
    class="col-auto mx-4 menu-link"
    #link
    routerLink="/services"
    routerLinkActive="active-link"
    *ngIf="
      authorizationService.checkLicenseType(licenseTypeEnum.Full) &&
      checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])
    "
  >
    <div></div>
    <p>Serviços</p>
    <div class="bottom-border" #border></div>
  </div>
  <div
    class="col-auto mx-4 menu-link"
    #link
    routerLink="/report"
    routerLinkActive="active-link"
    *ngIf="checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])"
  >
    <div></div>
    <p>Relatórios</p>
    <div class="bottom-border" #border></div>
  </div>
  <div
    class="col-auto mx-4 menu-link"
    #link
    routerLink="/dashboard"
    routerLinkActive="active-link"
    *ngIf="checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])"
  >
    <div></div>
    <p>Dashboard</p>
    <div class="bottom-border" #border></div>
  </div>
</div>
