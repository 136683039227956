import {
  ItemUnitEnum, SignalingTypeEnum,
  SupportMaterialTypeEnum, TaskTypeEnum,
  VerticalGroupTypeEnum
} from 'src/enumerators';
import { IRest } from './rest.interface';
import { User } from './user';
import { WarrantyTypeEnum } from 'src/enumerators/action.enum';

export class Contract implements IRest {
  className = 'Contrato';

  id: string;
  name: string;
  createdAt: Date;
  finishedAt: Date;
  startedAt: Date;
  updatedAt: Date;
  img: File;
  imageURL: string;
  city: string;
  state: string;
  contractFiles: Array<any>;
  contractorName: string;
  cost: number;
  details: string;
  workspaceId: string;
  responsableId: string;
  verticalGroups: ContractVerticalGroup[];
  horizontalGroups: ContractHorizontalGroup[];
  deviceGroups: ContractDeviceGroup[];
  responsable: ContractResponsable;
  serviceGroups: ContractServiceGroup[];
  adjustmentPercentage: number;
  adjustmentPercentageDate: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      createdAt: this.createdAt,
      finishedAt: this.finishedAt,
      startedAt: this.startedAt,
      updatedAt: this.updatedAt,
      img: this.img,
      imageURL: this.imageURL,
      city: this.city,
      state: this.state,
      contractFiles: this.contractFiles,
      contractorName: this.contractorName,
      details: this.details,
      workspaceId: this.workspaceId,
      responsableId: this.responsableId,
      verticalGroups: this.verticalGroups.map((v) => v.Serialize()),
      horizontalGroups: this.horizontalGroups.map((v) => v.Serialize()),
      deviceGroups: this.deviceGroups.map((v) => v.Serialize()),
      responsable: this.responsable.Serialize(),
      serviceGroups: this.serviceGroups.map((v) => v.Serialize()),
      adjustmentPercentage: this.adjustmentPercentage,
      adjustmentPercentageDate: this.adjustmentPercentageDate,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.createdAt = data['createdAt'] || new Date(2000, 0, 1);
      this.startedAt = data['startedAt'] || new Date(2000, 0, 1);
      this.finishedAt = data['finishedAt'] || new Date(2000, 0, 1);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0, 1);
      this.img = data['img'] || null;
      this.imageURL = data['imageURL'] || '';
      this.city = data['city'] || '';
      this.state = data['state'] || '';
      this.contractFiles = data['contractFiles'] || [];
      this.contractorName = data['contractorName'] || '';
      this.details = data['details'] || '';
      this.workspaceId = data['workspaceId'] || undefined;
      this.responsableId = data['responsableId'] || undefined;
      this.verticalGroups = (data['verticalGroups'] || []).map((v) => new ContractVerticalGroup(v));
      this.horizontalGroups = (data['horizontalGroups'] || []).map((v) => new ContractHorizontalGroup(v));
      this.deviceGroups = (data['deviceGroups'] || []).map((v) => new ContractDeviceGroup(v));
      this.serviceGroups = (data['serviceGroups'] || []).map((v) => new ContractServiceGroup(v));
      this.responsable = new ContractResponsable(data['responsable'] || {});
      this.cost = data['cost'] || 0;
      this.adjustmentPercentage = data['adjustmentPercentage'] || null;
      this.adjustmentPercentageDate = data['adjustmentPercentageDate'] || new Date();
    }
    return this;
  }
}

export class ContractServiceGroup implements IRest {
  className = 'Grupo de serviços';

  id: string;
  contractId: string;
  name: string;
  serviceType: TaskTypeEnum;
  signalingType: SignalingTypeEnum;
  updatedAt: Date;
  serviceItems: ContractServiceItem[]

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      contractId: this.contractId || undefined,
      serviceType: this.serviceType,
      signalingType: this.signalingType,
      name: this.name,
      updatedAt: this.updatedAt,
      serviceItems: this.serviceItems.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.serviceType = data['serviceType'] || TaskTypeEnum.Implantation;
      this.signalingType = data['signalingType'] || SignalingTypeEnum.Vertical;
      this.name = data['name'] || '';
      this.updatedAt = data['updatedAt'] || new Date(2000, 0, 1);
      this.serviceItems = (data['serviceItems'] || []).map((v) => new ContractServiceItem(v));
    }
    return this;
  }
}

export class ContractServiceItem implements IRest {
  className = 'Item do grupo de Serviços';

  id: string;
  name: string;
  amount: number;
  unit: string;
  unitValue: number;
  serviceGroupId: string;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
  adjustments: ItemAdjustment[]
  adjustedUnitValue: number;
  files: Array<any>;
  code: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id || undefined,
      name: this.name,
      amount: this.amount,
      unit: this.unit,
      unitValue: this.unitValue,
      serviceGroupId: this.serviceGroupId || undefined,
      quantity: this.quantity,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      adjustments: this.adjustments.map((v) => v.Serialize()),
      files: this.files,
      code: this.code
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.amount = data['amount'] || 0;
      this.unit = data['unit'] || ItemUnitEnum.und;
      this.unitValue = data['unitValue'] || 0;
      this.serviceGroupId = data['serviceGroupId'] || undefined;
      this.quantity = data['quantity'] || 0;
      this.createdAt = data['createdAt'] || undefined;
      this.updatedAt = data['updatedAt'] || undefined;
      this.adjustments = (data['adjustments'] || []).map((v) => new ItemAdjustment(v))
      this.files = data['files'] || [];
      this.code = data['code'] || '';
    }
    return this;
  }
}

export class ContractVerticalGroup implements IRest {
  className = 'Grupo de verticais';

  id: string;
  contractId: string;
  groupType: VerticalGroupTypeEnum;
  name: string;
  updatedAt: Date;
  verticalItems: Array<ContractVerticalItemSign | ContractVerticalItemSupport>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      contractId: this.contractId,
      groupType: this.groupType,
      name: this.name,
      updatedAt: this.updatedAt,
      verticalItems: this.verticalItems.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.contractId = data['contractId'] || undefined;
      this.groupType = data['groupType'] || VerticalGroupTypeEnum.RoadSign;
      this.name = data['name'] || '';
      this.updatedAt = data['updatedAt'] || new Date(2000, 0, 1);
      this.verticalItems = (data['verticalItems'] || []).map((v) => {
        if (data['groupType'] !== VerticalGroupTypeEnum.Support) {
          return new ContractVerticalItemSign(v);
        } else {
          return new ContractVerticalItemSupport(v);
        }
      });
    }
    return this;
  }
}

export class ContractVerticalItemSign implements IRest {
  className = 'Item do grupo de verticais do tipo Placa';

  id: string;
  name: string;
  amount: number;
  unit: string;
  unitValue: number;
  verticalGroupId: string;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
  roadSignFilmItems: Array<RoadSignFilmItem>;
  roadSignSubstrate: RoadSignSubstrate;
  roadSignSubstrateId: string;
  adjustments: ItemAdjustment[];
  adjustedUnitValue: number;
  files: Array<any>;
  warranty: Date;
  warrantyMonth: number;
  warrantyType: WarrantyTypeEnum;
  warrantyYear: number;
  code: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      amount: this.amount,
      unit: this.unit,
      unitValue: this.unitValue,
      verticalGroupId: this.verticalGroupId,
      quantity: this.quantity,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      roadSignFilmItems: this.roadSignFilmItems.map((v) => v.Serialize()),
      roadSignSubstrate: this.roadSignSubstrate.Serialize(),
      roadSignSubstrateId: this.roadSignSubstrateId,
      adjustments: this.adjustments.map((v) => v.Serialize()),
      files: this.files,
      warranty: this.warranty,
      warrantyMonth: this.warrantyMonth,
      warrantyType: this.warrantyType,
      warrantyYear: this.warrantyYear,
      code: this.code
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.amount = data['amount'] || 0;
      this.unit = data['unit'] || ItemUnitEnum.und;
      this.unitValue = data['unitValue'] || 0;
      this.verticalGroupId = data['verticalGroupId'] || undefined;
      this.quantity = data['quantity'] || 0;
      this.createdAt = data['createdAt'] || undefined;
      this.updatedAt = data['updatedAt'] || undefined;
      this.roadSignFilmItems = (data['roadSignFilmItems'] || []).map((v) => new RoadSignFilmItem(v));
      this.roadSignSubstrate = new RoadSignSubstrate(data['roadSignSubstrate'] || {});
      this.roadSignSubstrateId = data['roadSignSubstrateId'] || undefined;
      this.adjustments = (data['adjustments'] || []).map((v) => {
        return new ItemAdjustment(v)
      });
      this.files = data['files'] || [];
      this.warranty = data['warranty'] || undefined;
      this.warrantyMonth = data['warrantyMonth'] || 0;
      this.warrantyType = data['warrantyType'] || WarrantyTypeEnum.FixedExpiration;
      this.warrantyYear = data['warrantyYear'] || 0;
      this.code = data['code'] || '';

    }
    return this;
  }
}

export class ItemAdjustment implements IRest {
  className = 'Substrato da Placa';

  id: string;
  unitValue: number;
  baseDate: Date;
  percentageBase: number;
  contractVerticalItemId?: string;
  contractHorizontalItemId?: string;
  contractDeviceItemId?: string;
  contractServiceItemId?: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      unitValue: this.unitValue,
      baseDate: this.baseDate,
      percentageBase: this.percentageBase,
      contractVerticalItemId: this.contractVerticalItemId,
      contractHorizontalItemId: this.contractHorizontalItemId,
      contractDeviceItemId: this.contractDeviceItemId,
      contractServiceItemId: this.contractServiceItemId,

    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.unitValue = data['unitValue'] || null;
      this.baseDate = data['baseDate'] ? new Date(data['baseDate']) : new Date();
      this.percentageBase = data['percentageBase'] || undefined;
      this.contractVerticalItemId = data['contractVerticalItemId'] || undefined;
      this.contractHorizontalItemId = data['contractHorizontalItemId'] || undefined;
      this.contractDeviceItemId = data['contractDeviceItemId'] || undefined;
      this.contractServiceItemId = data['contractServiceItemId'] || undefined;

    }
    return this;
  }
}


export class RoadSignSubstrate implements IRest {
  className = 'Substrato da Placa';

  id: string;
  name: string;
  organizationId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      organizationId: this.organizationId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.organizationId = data['organizationId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
    }
    return this;
  }
}

export class RoadSignFilmItem implements IRest {
  className = 'Película';

  id: string;
  createdAt: Date;
  roadSignFilmId: string;
  updatedAt: Date;
  verticalItemId: string;
  roadSignFilm: RoadSignFilm;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      createdAt: this.createdAt,
      roadSignFilmId: this.roadSignFilmId,
      updatedAt: this.updatedAt,
      roadSignFilm: this.roadSignFilm.Serialize(),
      verticalItemId: this.verticalItemId,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      if (data['id']) {
        this.id = data['id'] || undefined;
        this.createdAt = data['createdAt'] ? new Date(data['createdAt']) : new Date(2000, 0);
        this.updatedAt = data['updatedAt'] ? new Date(data['updatedAt']) : new Date(2000, 0);
      }
      this.roadSignFilmId = data['roadSignFilmId'] || undefined;
      this.roadSignFilm = new RoadSignFilm(data['roadSignFilm'] || {});
    }
    return this;
  }
}

export class RoadSignFilm implements IRest {
  className = 'Atributos da Película';

  id: string;
  name: string;
  organizationId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      organizationId: this.organizationId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.organizationId = data['organizationId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
    }
    return this;
  }
}

export class ContractVerticalItemSupport implements IRest {
  className = 'Item do grupo de verticais do tipo Suporte';

  id: string;
  name: string;
  amount: number;
  unit: string;
  unitValue: number;
  verticalGroupId: string;
  quantity: number;
  updatedAt: Date;
  supportMaterial: SupportMaterialTypeEnum;
  adjustments: ItemAdjustment[]
  adjustedUnitValue: number;
  files: Array<any>;
  warranty: Date;
  warrantyMonth: number;
  warrantyType: WarrantyTypeEnum;
  warrantyYear: number;
  code: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      amount: this.amount,
      unit: this.unit,
      unitValue: this.unitValue,
      verticalGroupId: this.verticalGroupId,
      quantity: this.quantity,
      updatedAt: this.updatedAt,
      supportMaterial: this.supportMaterial,
      adjustments: this.adjustments.map((v) => v.Serialize()),
      files: this.files,
      warranty: this.warranty,
      warrantyMonth: this.warrantyMonth,
      warrantyType: this.warrantyType,
      warrantyYear: this.warrantyYear,
      code: this.code
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.amount = data['amount'] || 0;
      this.unit = data['unit'] || ItemUnitEnum.und;
      this.unitValue = data['unitValue'] || 0;
      this.verticalGroupId = data['verticalGroupId'] || undefined;
      this.quantity = data['quantity'] || 0;
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.supportMaterial = data['supportMaterial'] || '';
      this.adjustments = (data['adjustments'] || []).map((v) => new ItemAdjustment(v))
      this.files = data['files'] || [];
      this.warranty = data['warranty'] || undefined;
      this.warrantyMonth = data['warrantyMonth'] || 0;
      this.warrantyType = data['warrantyType'] || WarrantyTypeEnum.FixedExpiration;
      this.warrantyYear = data['warrantyYear'] || 0;
      this.code = data['code'] || '';
    }
    return this;
  }
}

export class ContractHorizontalGroup implements IRest {
  className = 'Grupo de horizontais';

  id: string;
  name: string;
  contractId: string;
  createdAt: Date;
  updatedAt: Date;
  horizontalItems: Array<ContractHorizontalItem>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      contractId: this.contractId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      horizontalItems: this.horizontalItems.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.contractId = data['contractId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.horizontalItems = (data['horizontalItems'] || []).map((v) => new ContractHorizontalItem(v));
    }
    return this;
  }
}

export class ContractHorizontalItem implements IRest {
  className = 'Item do grupo de horizontais';

  id: string;
  name: string;
  contractId: string;
  createdAt: Date;
  updatedAt: Date;
  amount: number;
  horizontalGroupId: string;
  quantity: number;
  roadSignMarkingPaint: RoadSignMarkingPaint;
  roadSignMarkingPaintId: string;
  unit: string;
  unitValue: number;
  adjustments: ItemAdjustment[]
  adjustedUnitValue: number;
  files: Array<any>;
  warranty: Date;
  warrantyMonth: number;
  warrantyType: WarrantyTypeEnum;
  warrantyYear: number;
  code: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      contractId: this.contractId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      amount: this.amount,
      horizontalGroupId: this.horizontalGroupId,
      quantity: this.quantity,
      roadSignMarkingPaint: this.roadSignMarkingPaint.Serialize(),
      roadSignMarkingPaintId: this.roadSignMarkingPaintId,
      unit: this.unit,
      unitValue: this.unitValue,
      adjustments: this.adjustments.map((v) => v.Serialize()),
      files: this.files,
      warranty: this.warranty,
      warrantyMonth: this.warrantyMonth,
      warrantyType: this.warrantyType,
      warrantyYear: this.warrantyYear,
      code: this.code
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.contractId = data['contractId'] || undefined;
      this.createdAt = data['createdAt'] || undefined;
      this.updatedAt = data['updatedAt'] || undefined;
      this.amount = data['amount'] || 0;
      this.horizontalGroupId = data['horizontalGroupId'] || undefined;
      this.quantity = data['quantity'] || 0;
      this.roadSignMarkingPaint = new RoadSignMarkingPaint(data['roadSignMarkingPaint'] || {});
      this.roadSignMarkingPaintId = data['roadSignMarkingPaintId'] || undefined;
      this.unit = data['unit'] || 'm';
      this.unitValue = data['unitValue'] || 0;
      this.adjustments = (data['adjustments'] || []).map((v) => new ItemAdjustment(v))
      this.files = data['files'] || [];
      this.warranty = data['warranty'] || undefined;
      this.warrantyMonth = data['warrantyMonth'] || 0;
      this.warrantyType = data['warrantyType'] || WarrantyTypeEnum.FixedExpiration;
      this.warrantyYear = data['warrantyYear'] || 0;
      this.code = data['code'] || '';
    }
    return this;
  }
}

export class RoadSignMarkingPaint implements IRest {
  className = 'Aplicação de Horizontais';

  id: string;
  name: string;
  organizationId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      organizationId: this.organizationId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.organizationId = data['organizationId'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
    }
    return this;
  }
}

export class ContractDeviceGroup implements IRest {
  className = 'Grupo de dispositivos';

  id: string;
  name: string;
  contractId: string;
  createdAt: Date;
  updatedAt: Date;
  deviceItems: Array<ContractDeviceItem>;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deviceItems: this.deviceItems.map((v) => v.Serialize()),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.deviceItems = (data['deviceItems'] || []).map((v) => new ContractDeviceItem(v));
    }
    return this;
  }
}

export class ContractDeviceItem implements IRest {
  className = 'Membro participante do contrato';

  id: string;
  name: string;
  amount: number;
  createdAt: Date;
  deviceGroupId: string;
  quantity: number;
  signaling: string;
  unit: string;
  unitValue: number;
  updatedAt: Date;
  adjustments: ItemAdjustment[]
  adjustedUnitValue: number;
  files: Array<any>;
  warranty: Date;
  warrantyMonth: number;
  warrantyType: WarrantyTypeEnum;
  warrantyYear: number;
  code: string;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      amount: this.amount,
      quantity: this.quantity,
      unit: this.unit,
      unitValue: this.unitValue,
      deviceGroupId: this.deviceGroupId,
      signaling: this.signaling,
      adjustments: this.adjustments.map((v) => v.Serialize()),
      files: this.files,
      warranty: this.warranty,
      warrantyMonth: this.warrantyMonth,
      warrantyType: this.warrantyType,
      warrantyYear: this.warrantyYear,
      code: this.code
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.name = data['name'] || '';
      this.createdAt = data['createdAt'] || undefined;
      this.updatedAt = data['updatedAt'] || undefined;
      this.amount = data['amount'] || 0;
      this.quantity = data['quantity'] || 0;
      this.unit = data['unit'] || ItemUnitEnum.und;
      this.unitValue = data['unitValue'] || 0;
      this.deviceGroupId = data['deviceGroupId'] || undefined;
      this.signaling = data['signaling'] || '';
      this.adjustments = (data['adjustments'] || []).map((v) => new ItemAdjustment(v))
      this.files = data['files'] || [];
      this.warranty = data['warranty'] || undefined;
      this.warrantyMonth = data['warrantyMonth'] || 0;
      this.warrantyType = data['warrantyType'] || WarrantyTypeEnum.FixedExpiration;
      this.warrantyYear = data['warrantyYear'] || 0;
      this.code = data['code'] || 0;

    }
    return this;
  }
}

export class ContractResponsable implements IRest {
  className = 'Membro responsável do contrato';

  id: string;
  createdAt: Date;
  updatedAt: Date;
  role: string;
  userId: string;
  workspaceId: string;
  user: User;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      role: this.role,
      userId: this.userId,
      workspaceId: this.workspaceId,
      user: this.user.Serialize(),
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.createdAt = data['createdAt'] || new Date(2000, 0);
      this.updatedAt = data['updatedAt'] || new Date(2000, 0);
      this.role = data['role'] || '';
      this.userId = data['userId'] || undefined;
      this.workspaceId = data['workspaceId'] || undefined;
      this.user = new User(data['user'] || {});
    }
    return this;
  }
}

export class ContractRetroreflectorization implements IRest {
  className = 'Retro do contrato';

  id: string;
  contractId: string;
  bad: { from: number, to: number };
  regular: { from: number, to: number };
  good: { from: number, to: number };
  excellent: { from: number, to: number };
  maximumValue: number;

  constructor(data?: any) {
    if (data) this.Deserialize(data);
  }

  Serialize() {
    const out = {
      id: this.id,
      contractId: this.contractId,
      bad: this.bad,
      regular: this.regular,
      good: this.good,
      excellent: this.excellent,
      maximumValue: this.maximumValue,
    };
    return out;
  }

  Deserialize(data: any) {
    if (data) {
      this.id = data['id'] || undefined;
      this.contractId = data['contractId'] || null;
      this.bad = data['bad'] || { from: 0, to: 200 };
      this.regular = data['regular'] || { from: 200, to: 250 };
      this.good = data['good'] || { from: 250, to: 300 };
      this.excellent = data['excellent'] || { from: 300, to: 400 };
      this.maximumValue = data['maximumValue'] || 400;

    }
    return this;
  }
}
