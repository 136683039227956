<div class="main-container"
  [ngStyle]="{'background-color': displayDirection == 'row' ? 'var(--snow-light)' : 'none', 'box-shadow': displayDirection == 'row' ? '0px 4px 6px rgba(36, 36, 36, 0.1)' : 'none'}">
  <div class="d-flex form-group align-items-center m-0">
    <div class="custom-control custom-switch">
      <input
        (change)="this.complementInformationState.enableComplementInformation = !this.complementInformationState.enableComplementInformation; emitComplementInformation()"
        [id]="'addInfo'+switchId" class="custom-control-input"
        [checked]="complementInformationState.enableComplementInformation" type="checkbox"
        [disabled]="verticalType == verticalTypeEnum.Indication || verticalType == verticalTypeEnum.Special">
      <label class="custom-control-label"
        [ngStyle]="{'color': verticalType == verticalTypeEnum.Indication ? 'var(--gray-base)' : 'var(--gray-dark)'}"
        [for]="'addInfo'+switchId">Habilitar informações complementares</label>
    </div>
  </div>

  <div *ngIf="this.complementInformationState.enableComplementInformation" class="form-container"
    [attr.disabled]="!complementInformationState.enableComplementInformation"
    [ngStyle]="{'flex-direction': displayDirection}">
    <div class="d-flex row mx-0 px-2"
      [ngClass]="{'col-9': displayDirection=='column', 'col-6': displayDirection=='row'}">
      <div class="col-auto px-0">
        <div class="custom-control custom-radio">
          <input type="radio" [id]="'complementInformation1'+switchId"
            [disabled]="!complementInformationState.enableComplementInformation"
            [name]="'complementInformation1'+switchId" class="custom-control-input"
            (change)="complementInformationState.type = complementInformationEnum.Incorporated; emitComplementInformation()"
            [checked]="complementInformationState.type == complementInformationEnum.Incorporated">
          <label class="custom-control-label" [for]="'complementInformation1'+switchId"></label>
        </div>
      </div>
      <div class="col pr-0" [ngClass]="{'text-muted': !complementInformationState.enableComplementInformation}">
        <p class="mb-3">Legenda Incorporada</p>
        <div class="form-group">
          <label for="incorporatedInput"
            [ngClass]="{'text-muted': !complementInformationState.enableComplementInformation}">Informação
            Complementar</label>
          <input type="text" class="form-control" placeholder="Escreva a informação aqui."
            [disabled]="complementInformationState.type != complementInformationEnum.Incorporated"
            name="complementInformation"
            [value]="complementInformationState.type != complementInformationEnum.Incorporated ? null : complementInformationState.value ? complementInformationState.value : null"
            (change)="complementInformationState.value = $event.target.value;emitComplementInformation()">
        </div>
      </div>
    </div>
    <div class="d-flex row mx-0 px-2"
      [ngClass]="{'col-9': displayDirection=='column', 'col-6': displayDirection=='row'}">
      <div class="col-auto px-0">
        <div class="custom-control custom-radio">
          <input type="radio" [id]="'complementInformation2'+switchId"
            [disabled]="!complementInformationState.enableComplementInformation"
            [name]="'complementInformation2'+switchId" class="custom-control-input"
            (change)="complementInformationState.type = complementInformationEnum.Additional; emitComplementInformation()"
            [checked]="complementInformationState.type == complementInformationEnum.Additional">
          <label class="custom-control-label" [for]="'complementInformation2'+switchId"></label>
        </div>
      </div>
      <div class="col pr-0" [ngClass]="{'text-muted': !complementInformationState.enableComplementInformation}">
        <p class="mb-3">Legenda em Placa Adicional</p>
        <div class="form-group">
          <label for="additionalInput"
            [ngClass]="{'text-muted': !complementInformationState.enableComplementInformation}">Informação
            Complementar</label>
          <input type="text" class="form-control" placeholder="Escreva a informação aqui."
            [disabled]="complementInformationState.type != complementInformationEnum.Additional"
            name="complementInformation"
            [value]="complementInformationState.type != complementInformationEnum.Additional ? null : complementInformationState.value ? complementInformationState.value : null"
            (change)="complementInformationState.value = $event.target.value;emitComplementInformation()">
        </div>
      </div>
    </div>
  </div>
</div>
