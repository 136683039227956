import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Leaflet from 'leaflet';
import 'leaflet.markercluster';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services';
import { CatalogService } from 'src/app/services/catalog.service';
import { ContractService } from 'src/app/services/contract.service';
import { DateService } from 'src/app/services/date.service';
import { MapService } from 'src/app/services/map.service';
import { ProjectService } from 'src/app/services/project.service';
import { ServiceOrderService } from 'src/app/services/service-order.service';
import { ToastService } from 'src/app/services/toast.service';
import { SignalElement, SignalsElement } from 'src/app/utils/signalsElements';
import { svgElement } from 'src/dictionaries/svg-sign';
import {
  ActionEnum,
  ComplementInformationEnum,
  ComplementInformationSignEnum,
  DimensionInformationEnum,
  ItemUnitEnum,
  SignalingHistoryActionMadeOnEnum,
  SignalingTypeEnum,
  TaskTypeEnum,
  VerticalGroupTypeEnum,
  VerticalSignalingEnum,
  VerticalSignalizationTypeEnum,
} from 'src/enumerators';
import {
  Contract,
  ContractHorizontalItem,
  ContractVerticalItemSign,
  DeviceSignaling,
  HorizontalSignaling,
  ServiceOrder,
  TrafficSign,
  User,
  VerticalSignaling,
  VerticalTrafficSignaling,
} from 'src/models';
import {
  Project,
  ProjectDeviceSignaling,
  ProjectFile,
  ProjectHistory,
  ProjectHorizontalSignaling,
  ProjectSupport,
  ProjectTrafficSign,
} from 'src/models/project';

@Component({
  selector: 'app-resume-project',
  templateUrl: './resume-project.component.html',
  styleUrls: ['./resume-project.component.css'],
})
export class ResumeProjectComponent implements OnInit {
  editMode: boolean = false;
  hashId: string = '';
  currentUser: User;
  mapZoom: boolean = true;
  update: boolean = false;
  loading: boolean = true;

  map: Leaflet.Map;
  layer: Leaflet.LayerGroup = Leaflet.markerClusterGroup({
    showCoverageOnHover: false,
    iconCreateFunction: function (cluster) {
      return Leaflet.divIcon({ html: "<div class='icon-cluster'><h4>" + cluster.getChildCount() + '</h4></div>' });
    },
  });

  mapModal: Leaflet.Map;
  layerModal: Leaflet.LayerGroup = Leaflet.markerClusterGroup({
    showCoverageOnHover: false,
    iconCreateFunction: function (cluster) {
      return Leaflet.divIcon({ html: "<div class='icon-cluster'><h4>" + cluster.getChildCount() + '</h4></div>' });
    },
  });
  signs: {
    vertical: Set<VerticalTrafficSignaling>;
    horizontal: Set<HorizontalSignaling>;
    device: Set<DeviceSignaling>;
  } = {
    vertical: new Set(),
    horizontal: new Set(),
    device: new Set(),
  };
  projectImages: Array<any>; // imagens do projeto
  displayMasterSigns: Array<any> = []; // lista de todas as sinalizações do arquivo mestre
  masterSigns: Array<any> = []; // sinalizações que possuem arquivo mestre
  selectedFile: any = ''; // Imagem Selecionada no Arquivo Mestre
  mapArqMaster: boolean = false; // false é map, true é arquivo mestre
  listFiles = []; // Lista com as Imagens do Projeto
  verticalGroupState: Array<boolean> = [];
  horizontalGroupState: Array<boolean> = [];
  deviceGroupState: Array<boolean> = [];
  selectedPointA = { posX: 0, posY: 0, fileDimensions: '0x0' }; // Ponto A
  selectedPointB = { posX: 0, posY: 0, fileDimensions: '0x0' }; // Ponto B
  pointsList: Array<{ posX: number; posY: number; fileDimensions: string; svg: string; signCode: string }> = [];
  complementInformationType = ComplementInformationEnum;
  verticalGroupTypeEnum = VerticalGroupTypeEnum;
  itemUnitEnum = ItemUnitEnum;
  actionEnum = ActionEnum;
  signalingHistoryActionMadeOnEnum = SignalingHistoryActionMadeOnEnum;
  verticalSignType = VerticalSignalizationTypeEnum;
  verticalSign = VerticalSignalingEnum;
  dimensionType = DimensionInformationEnum;
  signTypeEnum = SignalingTypeEnum;

  // Modal de serviços
  serviceOrderList: ServiceOrder[];
  modalSignList = {
    projectTrafficSigns: [],
    projectSupports: [],
    projectHorizontalSignalings: [],
    projectDeviceSignalings: [],
  };
  modalCheckState: { vertical: boolean; horizontal: boolean; device: boolean } = {
    vertical: true,
    horizontal: true,
    device: true,
  };
  modalSelectedOption: number = 0; // OS nova, Tarefa Independente e O.S existente
  selectedServiceOrderId: string;

  // Referencia do modal
  modalRef: BsModalRef;

  signalsElements: SignalElement[] = Object.values(SignalsElement); // Sinalizações para o SVG
  amoutType = [0, 0]; // O valor de cada um dos filtros para somar ao total depois
  blockSubmit = false;

  modalTaskState;
  displayPage = false; // variavel que sabe se a página é display ou não

  masterFile: ProjectFile; // arquivo mestre de projetos
  modalSignFilterCode: string; // recebe signCode para filtrar mapa ou arq mestre

  mapReady: EventEmitter<boolean> = new EventEmitter(true); // emitir quando o mapa está pronto para inicializar

  @Output() newController = new EventEmitter<string>();
  @Input() projectBody: Project;
  @Input() uploadedFiles: any;
  @Input() contract: Contract;

  constructor(
    private _projectService: ProjectService,
    private _toastService: ToastService,
    private _router: Router,
    private _activedRoute: ActivatedRoute,
    private _contractService: ContractService,
    public _auth: AuthService,
    private _catalog: CatalogService,
    private _map: MapService,
    private _dateService: DateService,
    private _modalService: BsModalService,
    private _serviceOrderService: ServiceOrderService,
  ) {}

  ngOnInit() {
    this.subscribeMap();
    this.currentUser = this._auth.loggedUser;
    this.displayPage =
      this._activedRoute.snapshot?.url.length > 1 && this._activedRoute.snapshot?.url[1]?.path == 'display';

    this._activedRoute.params.subscribe((res) => {
      if (res.id) {
        this.hashId = res.id;
        this.update = true;
        if (!this.projectBody?.id) {
          this.editMode = true;
          this._projectService.getProject(this.hashId).subscribe(
            (next: Project) => {
              this.projectBody = next;
              if (this.displayPage) {
                this._projectService.getProjectHistory(this.projectBody).subscribe((res: Array<ProjectHistory>) => {
                  this.projectBody.history = res;
                  this._contractService.listAllWorkspaceUsers().subscribe((res: any) => {
                    this.projectBody.createdByMember = res.members.find(
                      (member) => member.id == this.projectBody.createdByMemberId,
                    );

                    this.projectBody.history.forEach((history: ProjectHistory) => {
                      history.member = res.members.find((member) => member.id == history.memberId);
                    });

                    this.getMasterFile();
                  });

                  this.getSigns(this.layer);
                  this.uploadedFiles = this.projectBody?.projectFiles;
                });
              }

              this._contractService.getContract(this.projectBody?.contractId).subscribe((res: any) => {
                this.contract = res;

                // Pegar os substrato em traffic
                this.projectBody.projectTrafficSigns.forEach((traffic: ProjectTrafficSign) => {
                  this.contract.verticalGroups.forEach((group) => {
                    if (group.groupType == VerticalGroupTypeEnum.RoadSign) {
                      group.verticalItems.forEach((item: ContractVerticalItemSign) => {
                        if (item.id == traffic.contractVerticalItemId) {
                          traffic.contractVerticalItem = item;
                        }
                      });
                    }
                  });
                });

                // Pegar o material aplicado em Horizontal
                this.projectBody.projectHorizontalSignalings.forEach((traffic: ProjectHorizontalSignaling) => {
                  this.contract.horizontalGroups.forEach((group) => {
                    group.horizontalItems.forEach((item: ContractHorizontalItem) => {
                      if (item.id == traffic.contractHorizontalItemId) {
                        traffic.contractHorizontalItem = item;
                      }
                    });
                  });
                });
                this.loading = false;
                this.mapReady.emit(true);
              });
            },
            (error) => {
              if (error.error?.message.includes('not found')) {
                this._toastService.showError('O projeto não foi encontrado!');
              } else {
                this._toastService.showError('Ocorreu um erro inesperado, tente novamente!');
              }
              this._router.navigate([`/projects/`]);
            },
          );
        }
        if (this.projectBody?.id) {
          this._contractService.listAllWorkspaceUsers().subscribe((res: any) => {
            this.projectBody.createdByMember = res.members.find(
              (member) => member.id == this.projectBody.createdByMemberId,
            );

            this.loading = false;
          });
        }
      } else {
        this.calculateCost();
        this.loading = false;
      }
    });
  }

  // Aguarda pipe finalizar para iniciar o mapa
  subscribeMap() {
    this.mapReady.subscribe((next) => {
      if (!next) return;
      this.map = this._map.initMap('project-map');
      this._map.initLayer(this.map, this.layer);
      this.mapReady.unsubscribe();
    });
  }

  // Emite a variavel que controla a rota da página
  emitPageController(value: string) {
    this.newController.emit(value);
  }

  //Retorna uma lista com os tempos em anos, meses, dias, horas e minutos, e a diferença em milissegundos
  getRelativeTime(warranty, date) {
    return this._dateService.getRelativeTime(warranty, date);
  }

  calculateCost() {
    const projectDeviceSignalingsAmount = this.projectBody.projectDeviceSignalings.reduce((acc, current) => {
      return acc + (current.cost ?? 0);
    }, 0);

    const projectHorizontalSignalingsAmount = this.projectBody.projectHorizontalSignalings.reduce((acc, current) => {
      return acc + (current.cost ?? 0);
    }, 0);

    const projectTrafficSignsAmount = this.projectBody.projectTrafficSigns.reduce((acc, current) => {
      return acc + (current.cost ?? 0);
    }, 0);

    const projectSupportsAmount = this.projectBody.projectSupports.reduce((acc, current) => {
      return acc + (current.cost ?? 0);
    }, 0);

    this.projectBody.cost = projectDeviceSignalingsAmount + projectHorizontalSignalingsAmount + projectTrafficSignsAmount + projectSupportsAmount;
  };

  // Atualiza os itens do contrato na aplicação
  private updateApplied(applied: any, contractItem: any, type: SignalingTypeEnum) {
    switch (type) {
      case SignalingTypeEnum.Vertical:
        if (applied.supportMaterial) {
          delete applied['signaling'];
          applied['contractVerticalItem'] = contractItem;
          applied['supportMaterial'] = contractItem.supportMaterial;
        } else {
          if (!this.blockSubmit) this.blockSubmit = !applied['signaling'] ? true : false;
          applied['contractVerticalItem'] = contractItem;
          applied['roadSignSubstrateId'] = contractItem?.roadSignSubstrateId;
          applied['roadSignSubstrate'] = contractItem?.roadSignSubstrate;
          applied['roadSignFilmItems'] = [{ roadSignFilmId: contractItem?.roadSignFilmItems[0].roadSignFilmId }];
        }
        break;
      case SignalingTypeEnum.Horizontal:
        if (!this.blockSubmit) this.blockSubmit = !applied['signaling'] ? true : false;

        applied['roadSignMarkingPaintId'] = contractItem?.roadSignMarkingPaintId;
        applied['roadSignMarkingPaint'] = contractItem?.roadSignMarkingPaint;
        break;
      case SignalingTypeEnum.Device:
        applied['signaling'] = contractItem?.signaling;
        break;
    }
  }

  getSigns(layer) {
    this._catalog.getAllVerticalFromProject(this.hashId).subscribe((res: Array<VerticalSignaling>) => {
      var markersToCentralize = []
      res.forEach((element) => {
        if (element.verticalSignalingMasterFile.id) {
          this.masterSigns.push(element.verticalSignalingMasterFile);
        }

        element.trafficSigns.forEach((vertical: TrafficSign, index) => {
          element['useTraffic'] = index;
          const sign: VerticalTrafficSignaling = new VerticalTrafficSignaling(element);
          markersToCentralize.push([sign.lat, sign.lng])
          this.signs.vertical.add(sign);
          if (sign.order == 1) {
            this.addMarker(sign, layer);
          }
        });
      });
      this._catalog.getAllHorizontalFromProject(this.hashId).subscribe((res: Array<HorizontalSignaling>) => {
        res.forEach((element: HorizontalSignaling) => {
          if (element.horizontalSignalingMasterFile.id) {
            this.masterSigns.push(element.horizontalSignalingMasterFile);
          }
          markersToCentralize.push([element.locations[0].lat, element.locations[0].lng])
          if(element.locations[1]) markersToCentralize.push([element.locations[1].lat, element.locations[1].lng])
          this.signs.horizontal.add(element);
          this.addMarker(element, layer);
        });
        this._catalog.getAllDeviceFromProject(this.hashId).subscribe((res: Array<DeviceSignaling>) => {
          res.forEach((element: DeviceSignaling) => {
            if (element.deviceSignalingMasterFile.id) {
              this.masterSigns.push(element.deviceSignalingMasterFile);
            }
            markersToCentralize.push([element.locations[0].lat, element.locations[0].lng])
            if(element.locations[1]) markersToCentralize.push([element.locations[1].lat, element.locations[1].lng])
            this.signs.device.add(element);
            this.addMarker(element, layer);
          });
          this.map.fitBounds(markersToCentralize, {padding: [15,15]})
          this.getMasterFile();
        });
      });
    });
  }

  stringToFloatFixed(string): string {
    const formatedValue = typeof string === 'number' ? string.toString().replace(' ', '') : string.replace(' ', '');
    let coefficient = Math.pow(10, 3);
    let number = Math.floor(parseFloat(formatedValue) * coefficient) / coefficient;

    let options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
      useGrouping: false,
    };
    return number.toLocaleString('pt-BR', options);
  }

  initMap() {
    this.map = this._map.initMap('mapContainer');
    this._map.initLayer(this.map, this.layer);
  }

  // POST criar projeto
  submitProject() {
    if (this.update) {
      this._projectService.updateProject(this.projectBody).subscribe(
        (next: Project) => {
          if (this.uploadedFiles.length) {
            this._projectService.uploadProjectFiles(this.uploadedFiles, this.hashId).subscribe(
              (res: any) => {
                for (let file of this.uploadedFiles) {
                  if (file.masterFile) {
                    const fileId = !!file.id
                      ? file.id
                      : res.files.find((f) => f.file.originalName == (file.file.name || file.file.originalName))
                          .projectFile.id;
                    this._projectService.updateMasterFile(next.id, fileId).subscribe();
                  }
                }
                this._toastService.showSuccess('Projeto editado com sucesso!');
                this._router.navigate(['/projects']);
              },
              (error) => {
                this._toastService.showError('Ocorreu um erro ao criar o Projeto');
              },
            );
          } else {
            this._toastService.showSuccess('Projeto editado com sucesso!');
            this._router.navigate(['/projects']);
          }
        },
        (error) => {
          this._toastService.showError('Ocorreu um erro ao criar o Projeto');
        },
      );
    } else {
      this._projectService.createProject(this.projectBody).subscribe(
        (next: Project) => {
          if (this.uploadedFiles.length) {
            this._projectService.uploadProjectFiles(this.uploadedFiles, next?.id).subscribe(
              (res: any) => {
                for (let file of this.uploadedFiles) {
                  if (file.masterFile) {
                    const fileId = !!file.id
                      ? file.id
                      : res.files.find((f) => f.file.originalName == (file.file.name || file.file.originalName))
                          .projectFile.id;
                    this._projectService.updateMasterFile(next.id, fileId).subscribe((fileRes) => {});
                  }
                }
                this._toastService.showSuccess('Projeto criado com sucesso!');
                this._router.navigate(['/projects']);
              },
              (error) => {
                this._toastService.showError('Ocorreu um erro ao criar o Projeto');
              },
            );
          } else {
            this._toastService.showSuccess('Projeto criado com sucesso!');
            this._router.navigate(['/projects']);
          }
        },
        (error) => {
          this._toastService.showError('Ocorreu um erro ao criar o Projeto');
        },
      );
    }
  }

  //Adiciona marcador no mapa
  addMarker(element, layer) {
    let markIcon = element['svg'];
    let iconSize = [40, 40];
    let latlng: [number, number];
    let mark;
    let content;
    if (element.type == SignalingTypeEnum.Vertical) {
      let classInternalCaption = `${element['signCode']}-40`;
      latlng = [element.lat, element.lng];
      let combined = element['combined'] ? true : false;
      mark = this._map.addMarker(
        latlng,
        layer,
        markIcon,
        {},
        false,
        false,
        iconSize[0],
        iconSize[1],
        combined,
        element['internalCaption'],
        classInternalCaption,
      );
      content = this._map.addPopup(element, 2);
      let popup = Leaflet.popup({ closeButton: false }).setContent(content);
      mark.bindPopup(popup);
    } else {
      if (element.type == SignalingTypeEnum.Device) {
        element.locations.forEach((location, index) => {
          latlng = [location['lat'], location['lng']];
          mark = this._map.addMarker(
            latlng,
            layer,
            markIcon,
            {},
            false,
            false,
            iconSize[0],
            iconSize[1],
            false,
            undefined,
            '',
          );
          content = this._map.addPopup(element, index);
          let popup = Leaflet.popup({ closeButton: false }).setContent(content);
          mark.bindPopup(popup);
        });
      } else {
        element.locations.forEach((location, index) => {
          latlng = [location['lat'], location['lng']];
          mark = this._map.addMarker(
            latlng,
            layer,
            markIcon,
            {},
            false,
            false,
            iconSize[0],
            iconSize[1],
            false,
            undefined,
            '',
          );
          content = this._map.addPopup(element, index);
          let popup = Leaflet.popup({ closeButton: false }).setContent(content);
          mark.bindPopup(popup);
        });
      }
    }
  }

  deleteProject() {
    this._activedRoute.params.subscribe((res) => {
      this._projectService.deleteProject(res.id).subscribe(
        (next) => {
          this._toastService.showSuccess('Projeto removido com sucesso!');
          this._router.navigate(['/projects']);
          this.modalRef.hide();
        },
        (error) => {
          this._toastService.showError('Não foi possível remover o projeto');
        },
      );
    });
  }

  goToEdition() {
    this._router.navigate([`/projects/new-project/${this.hashId}`]);
  }

  // abre modal
  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
    });
  }

  openLargeModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });

    this.modalSignList = {
      projectTrafficSigns: [],
      projectSupports: [],
      projectHorizontalSignalings: [],
      projectDeviceSignalings: [],
    };

    this._serviceOrderService.getOrders().subscribe((res: ServiceOrder[]) => (this.serviceOrderList = res));

    this.projectBody.projectTrafficSigns.forEach((sign) => {
      this.modalSignList.projectTrafficSigns.push(new ProjectTrafficSign(sign));
    });
    this.projectBody.projectSupports.forEach((sign) => {
      this.modalSignList.projectSupports.push(new ProjectSupport(sign));
    });
    this.projectBody.projectHorizontalSignalings.forEach((sign) => {
      this.modalSignList.projectHorizontalSignalings.push(new ProjectHorizontalSignaling(sign));
    });
    this.projectBody.projectDeviceSignalings.forEach((sign) => {
      this.modalSignList.projectDeviceSignalings.push(new ProjectDeviceSignaling(sign));
    });
    this.masterSigns.forEach((master) => {
      this.GetMarkers(master);
    });
    this.centerImage();
  }
  centerImage() {
    const image = new Image();
    image.src = this.masterFile as any;
    const elementImg = document.getElementById('master-file-edit') || document.getElementById('view-master-on-resume');
    image.onload = () => {
      elementImg.scrollTop = elementImg.clientHeight;
    };
  }

  downloadFile(fileId: string) {
    this._projectService.downloadProjectFile(fileId).subscribe((res) => {
      window.open(res.url);
    });
  }

  // Handle do switch: Mapa <-> Arquivo-mestre
  changeMarkerView(type: 'file' | 'map') {
    if (type == 'file') {
      this.mapArqMaster = true;
    } else {
      this.mapArqMaster = false;
    }
  }

  //Obter as Imagens do Projeto
  getMasterFile() {
    const file = this.projectBody.projectFiles.find((pFile) => pFile.masterFile == true);
    if (file) {
      this._projectService.downloadProjectFile(file?.id).subscribe((res: any) => {
        this.masterFile = res.url;
        this.centerImage();
        this.masterSigns.forEach((master) => {
          this.GetMarkers(master);
        });
      });
    }
  }

  setMasterSigns(fileId: string, div: any) {
    this.pointsList = [];
    this.displayMasterSigns = [];
    this.selectedFile = this.projectImages.find((img) => {
      return img.projectFileId == fileId;
    });
    this.masterSigns.forEach((master) => {
      if (master.projectFileId == fileId) {
        this.GetMarkers(master);
        master['pointA'] = this.selectedPointA;
        master['pointB'] = this.selectedPointB;
        this.displayMasterSigns.push(master);
      }
    });
  }

  //Obter Marcadores Caso Exista
  GetMarkers(masterFile) {
    const id = masterFile['projectFileId'];
    const file = masterFile.projectFile;

    if (file) {
      //Imagem Original
      const image = new Image();
      image.src = this.masterFile as any;
      image.onload = () => {
        //Dimensões da Imagem Original
        const fileWidth = image.width;
        const fileHeight = image.height;

        let signCode: string;
        if (masterFile.verticalSignalingId) {
          // Vertical
          this._catalog.getVertical(masterFile.verticalSignalingId).subscribe((res: any) => {
            signCode = res.trafficSigns[0].signCode;
            this.setPoints(masterFile, fileWidth, fileHeight, signCode);
          });
        } else {
          if (masterFile.horizontalSignalingId) {
            //horizontal
            this._catalog.getHorizontal(masterFile.horizontalSignalingId).subscribe((res: any) => {
              signCode = res.signCode;
              this.setPoints(masterFile, fileWidth, fileHeight, signCode);
            });
          } else {
            //dispositivos
            this._catalog.getDevice(masterFile.deviceSignalingId).subscribe((res: any) => {
              signCode = res.signCode;
              this.setPoints(masterFile, fileWidth, fileHeight, signCode);
            });
          }
        }
      };
    }
  }

  // Retorna qual é o tipo da informação complementar sabendo qual é o tipo da placa WARNING/REGULATION
  complementType(applied): ComplementInformationSignEnum {
    if (applied.complementInformationType == ComplementInformationEnum.Additional) {
      if (applied.signalizationType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.AdditionalWarning;
      } else {
        return ComplementInformationSignEnum.AdditionalRegulation;
      }
    } else {
      if (applied.signalizationType == VerticalSignalizationTypeEnum.Warning) {
        return ComplementInformationSignEnum.IncorporatedWarning;
      } else {
        return ComplementInformationSignEnum.IncorporatedRegulation;
      }
    }
  }

  setPoints(masterFile, fileWidth, fileHeight, signCode) {
    const selectedPointA = {
      posX: masterFile['locations'][0]['pointA']['x'] || 0,
      posY: masterFile['locations'][0]['pointA']['y'] || 0,
      fileDimensions: fileWidth + 'x' + fileHeight,
      svg: svgElement.Signaling[signCode],
      signCode: signCode,
    };

    const selectedPointB = {
      posX: masterFile['locations'][0]['pointB']['x'] || 0,
      posY: masterFile['locations'][0]['pointB']['y'] || 0,
      fileDimensions: fileWidth + 'x' + fileHeight,
      svg: svgElement.Signaling[signCode],
      signCode: signCode,
    };
    this.pointsList.push(selectedPointA);
    if (masterFile['locations'][0]['pointB']['x']) this.pointsList.push(selectedPointB);
  }

  //Obter Posição do Ponto Sobre o Arquivo Mestre
  GetMarkerPosition(div: any, marker: any) {
    //Dimensões da Div
    const divWidth = div.offsetWidth;
    const divHeight = div.offsetHeight;

    //Dimensões da Imagem Original
    const fileWidth = marker.fileDimensions.split('x')[0];
    const fileHeight = marker.fileDimensions.split('x')[1];

    //Escalas
    const xScale = divWidth / fileWidth;
    const yScale = divHeight / fileHeight;
    let scale = 0;
    let xOffset = 0;
    let yOffset = 0;

    //Determinar Offset
    if (xScale > yScale) {
      scale = xScale;
      yOffset = (divHeight - fileHeight * scale) / 2;
    } else {
      scale = yScale;
      xOffset = (divWidth - fileWidth * scale) / 2;
    }

    //Retornar Distância Proporcional
    let top = marker.posY * scale + yOffset;
    top -= 15; //Ícone Possui 30px de Altura

    let left = marker.posX * scale + xOffset;
    left -= 15; //Ícone Possui 30px de Largura

    return `top: ${top}px; left: ${left}px;`;
  }

  sendToTask() {
    const list = {
      vertical: JSON.stringify(this.modalCheckState.vertical ? this.modalSignList.projectTrafficSigns : []),
      horizontal: JSON.stringify(this.modalCheckState.horizontal ? this.modalSignList.projectHorizontalSignalings : []),
      device: JSON.stringify(this.modalCheckState.device ? this.modalSignList.projectDeviceSignalings : []),
    };
    const supports = JSON.stringify(this.modalCheckState.vertical ? this.modalSignList.projectSupports : []);

    const type = TaskTypeEnum.Implantation;
    const projectId = this.projectBody.id;
    const contractId = this.projectBody.contractId;
    const send = JSON.stringify({ type, list, projectId, contractId, supports });

    localStorage.setItem('signsCatalog', send);

    if (this.modalSelectedOption == 0) {
      this._router.navigate(['/services/order/new']);
    } else if (this.modalSelectedOption == 1) {
      this._router.navigate(['/services/tasks']);
    } else if (this.modalSelectedOption == 2) {
      this._router.navigate([`services/order/new/${this.selectedServiceOrderId}`]);
    }
  }

  removeSignFromModal(arr: any[], index: number) {
    arr.splice(index, 1);
  }

  createMapModal() {
    this.mapModal = this._map.initMap('project-modal-map');
    this._map.initLayer(this.mapModal, this.layerModal);
    this.signs = {
      vertical: new Set(),
      horizontal: new Set(),
      device: new Set(),
    };
    this.getSigns(this.layerModal);
  }

  handleSignCodeEmit(event, isMap?, type?: 'vertical' | 'device' | 'horizontal') {
    this.modalSignFilterCode = event == this.modalSignFilterCode ? null : event;
    if (isMap && this.modalSignFilterCode) {
      this.filterMap(event, type);
    }
  }

  filterMap(signCode, type) {
    this.layerModal.clearLayers();
    this.signs[type].forEach((sign) => {
      this.addMarker(sign, this.layerModal);
    });
  }
}
