<div
  class="p-4 side sidebar-container"
  [ngClass]="toggleOpen ? 'open' : 'closed'"
  style="position: fixed; left: 0px; top: 60px"
  (mouseenter)="toggleOpen = !toggleOpen"
  (mouseleave)="toggleOpen = !toggleOpen"
>
  <div class="row justify-content-center my-2 mb-4">
    <i [ngClass]="!toggleOpen ? 'fak fa-bars-close fa-lg' : 'fak fa-bars-open fa-lg'"></i>
  </div>

  <div
    class="row my-2 justify-content-center"
    *ngIf="checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])"
  >
    <button
      [ngClass]="!toggleOpen ? 'btn btn-icon btn-icon-closed' : 'btn btn-primary btn-icon-open'"
      routerLink="/region-area"
      routerLinkActive="active-link"
    >
      <i class="fa-regular fa-map" style="width: 30px"></i>
      {{ toggleOpen ? ' Marcações' : '' }}
    </button>
  </div>

  <div class="row my-2 justify-content-center" *ngIf="org?.paths">
    <button
      [ngClass]="!toggleOpen ? 'btn btn-icon btn-icon-closed' : 'btn btn-primary btn-icon-open'"
      routerLink="/paths"
      routerLinkActive="active-link"
    >
      <div class="d-flex align-items-center" [ngStyle]="{ width: toggleOpen ? '30px' : '20px' }">
        <div class="path-icon">KM</div>
      </div>
      {{ toggleOpen ? ' Trajetos' : '' }}
    </button>
  </div>

  <div
    class="row my-2 justify-content-center"
    *ngIf="checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])"
  >
    <button
      [ngClass]="!toggleOpen ? 'btn btn-icon btn-icon-closed' : 'btn btn-primary btn-icon-open'"
      routerLink="/projects"
      routerLinkActive="active-link"
    >
      <i class="fa-regular fa-file-lines" style="width: 30px"></i>
      {{ toggleOpen ? ' Projetos' : '' }}
    </button>
  </div>

  <div
    class="row my-2 justify-content-center"
    *ngIf="checkMemberRole([memberRoleEnum.Manager, memberRoleEnum.Operator])"
  >
    <button
      [ngClass]="!toggleOpen ? 'btn btn-icon btn-icon-closed' : 'btn btn-primary btn-icon-open'"
      routerLink="/stock"
      routerLinkActive="active-link"
    >
      <i class="fa-regular fa-box-open" style="width: 30px"></i>
      {{ toggleOpen ? ' Estoque' : '' }}
    </button>
  </div>

  <div class="sidebar-footer">
    <img [src]="org?.imageURL ?? 'assets/icons/camera.svg'" alt="org-img" class="org-img" />
    <div class="d-flex">
      <span *ngIf="toggleOpen" style="font-size: 11px; line-height: 15px">{{
        org?.name ?? 'Organização não informada'
      }}</span>
    </div>
  </div>
</div>
