<app-header></app-header>
<div class="container" style="margin-top: 150px;">
  <div class="col">
    <h1 class="text-center">FAQ</h1>
  </div>
  <div class="col">
    <div class="form-group" style="margin: 40px 0;">
      <input style="padding-left: 64px;
      position: relative;" formControlName="src" type="search" class="form-control form-control-lg"
        placeholder="Escreva sua dúvida">
      <i style="position: absolute; top: 37px; left: 47px;" class="far fa-search"></i>
    </div>
    <div class="row">
      <div class="col content--accord">
        <accordion [isAnimated]="true">
          <accordion-group *ngFor="let text of texts" isOpenChange event listener (isOpenChange)="text.isOpen = $event">
            <div accordion-heading style="margin-top: 30px;">
              <div class="row">
                <div class="col-auto d-flex align-items-center">
                  <div class="circle">
                  </div>
                </div>
                <div>
                  <div class="col-auto">
                    <h3 class="h3">{{text.title}}</h3>
                  </div>
                </div>

                <div class="col" style="display: flex; justify-content: flex-end;">
                  <i [ngClass]="text.isOpen? 'fa fa-angle-up fa-lg' : 'fa fa-angle-down fa-lg' "></i>
                </div>
              </div>
            </div>
            <div class="description" style="margin-left: 38px; color: var(--gray-base);">
              <p>{{text.description}}</p>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>

</div>
<div class="container-fluid" style="bottom: 150px;
position: absolute;">
  <div class="row">
    <div class="col-1"></div>
    <div class="col-4">
      <a class="contentBtn--link" [routerLink]="['/']" style="color: var(--gray-base);">
        <i class="fal fa-arrow-left contentBtn--icon"></i>
        Voltar
      </a>
    </div>
    <!-- <div class="col">
      <a class="contentBtn--link" style="margin-left: 95px;">
        Ver Tutorial
      </a>
    </div> -->
  </div>


</div>
