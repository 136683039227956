import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalingTypeEnum, SystemModuleEnum } from 'src/enumerators';
import { DeviceSignaling, HorizontalSignaling, Task, Team, TrafficSign, Vehicle, VerticalSignaling } from 'src/models';
import { Organization } from '../models';
import { CatalogService } from '../services/catalog.service';
import { PublicService } from '../services/public.service';
import { environment } from 'src/environments/environment';

interface INoTaskSign {
  vertical: TrafficSign[];
  horizontal: HorizontalSignaling[];
  device: DeviceSignaling[];
}

@Component({
  selector: 'app-public-report-page',
  templateUrl: './public-report-page.component.html',
  styleUrls: ['./public-report-page.component.css'],
})
export class PublicReportPageComponent implements OnInit {
  loading: boolean = false;

  reportId = '';

  environment = environment;
  reportData: any;
  organization: Organization;
  tasks: Task[] = [];

  noTaskSigns: INoTaskSign = { vertical: [], horizontal: [], device: [] };

  systemModuleEnum = SystemModuleEnum;

  constructor(
    private _publicService: PublicService,
    private _activatedRoute: ActivatedRoute,
    private _catalogService: CatalogService,
  ) { }

  ngOnInit(): void {
    // Recebe o id na url
    this._activatedRoute.params.subscribe((params) => {
      this.reportId = params.id;

      // Recebe os dados do report
      this._publicService.getPublicReport(this.reportId).subscribe((reportRes: any) => {
        this.reportData = reportRes.data;
        this._publicService.getOrg(reportRes.data.organizationId, this.reportId).subscribe((orgRes: Organization) => {
          this.organization = orgRes;

          // Sinalizações sem tarefas
          this.getSignalingsWithoutTask(this.reportData);

          // Tarefas
          reportRes.data.tasksIds.forEach((taskId) => {
            this._publicService.getTask(taskId, this.reportId).subscribe(async (taskRes: Task) => {
              await this.buildTaskForReport(taskRes);
              if (taskRes.teamId) await this.getTeam(taskRes.teamId, taskRes);
              if (taskRes.vehicleId) await this.getVehicle(taskRes.vehicleId, taskRes);
              this.tasks.push(taskRes);
            });
          });
        });
      });
    });
  }

  // Verifica se existem sinalizações que nao possuem tarefas e atribui os valores à noTaskSigns
  getSignalingsWithoutTask(reportData) {
    if (reportData?.trafficSignsIds?.length) {
      this._publicService
        .getTrafficSignsByArray(reportData.trafficSignsIds, this.reportId)
        .subscribe((trafficSignRes: TrafficSign[]) => {
          const verifiedId = new Set<string>();
          trafficSignRes.forEach((sign) => {
            if (!verifiedId.has(sign.verticalSignalingId)) verifiedId.add(sign.verticalSignalingId);
          });
          verifiedId.forEach((signId) => {
            this._publicService
              .getVerticalSignaling(signId, this.reportId)
              .subscribe((verticalRes: VerticalSignaling) => {
                var vertical = verticalRes;
                if (vertical) {
                  vertical.trafficSigns.forEach((trafficSign) => {
                    if (vertical.contractSupportItemId) trafficSign['supportItem'] = vertical.contractSupportItem;
                    trafficSign['verticalSignalingFiles'] = vertical.verticalSignalingFiles;
                    trafficSign['address'] = vertical.address;
                    this.noTaskSigns.vertical.push(trafficSign);
                  });
                }
              });
          });
        });
    }

    if (reportData?.horizontalSignalingsIds?.length) {
      this._publicService
        .getHorizontalSignsByArray(reportData.horizontalSignalingsIds, this.reportId)
        .subscribe((horizontalSignalingRes: HorizontalSignaling[]) => {
          horizontalSignalingRes.forEach((sign) => {
            this._publicService
              .getHorizontalSignaling(sign.id, this.reportId)
              .subscribe((horizontalRes: HorizontalSignaling) => {
                var horizontal = horizontalRes;
                if (horizontal) {
                  this.getMeasurements(horizontal);
                  this.noTaskSigns.horizontal.push(horizontal);
                }
              });
          });
        });
    }

    if (reportData?.deviceSignalingsIds?.length) {
      this._publicService
        .getDeviceSignsByArray(reportData.deviceSignalingsIds, this.reportId)
        .subscribe((deviceSignalingRes: DeviceSignaling[]) => {
          deviceSignalingRes.forEach((sign) => {
            this._publicService.getDeviceSignaling(sign.id, this.reportId).subscribe((deviceRes: DeviceSignaling) => {
              const device = deviceRes;
              if (device) this.noTaskSigns.device.push(device);
            });
          });
        });
    }
  }

  buildTaskForReport(task: Task) {
    task['reportSigns'] = [];
    switch (task.signalingType) {
      // Verticais
      case SignalingTypeEnum.Vertical:
        this._publicService.getTrafficSignsByTaskId(task.id, this.reportId).subscribe((signRes: TrafficSign[]) => {
          signRes.forEach((sign) => {
            this._publicService
              .getVerticalSignaling(sign.verticalSignalingId, this.reportId)
              .subscribe(async (verticalRes: VerticalSignaling) => {
                const vertical = await verticalRes;
                if (vertical) {
                  vertical.trafficSigns.forEach((trafficSign) => {
                    if (vertical.contractSupportItemId) trafficSign['supportItem'] = vertical.contractSupportItem;
                    trafficSign['verticalSignalingFiles'] = vertical.verticalSignalingFiles;
                    trafficSign['address'] = vertical.address;
                    task['reportSigns'].push(trafficSign);
                  });
                }
              });
          });
        });
        return task;
      // Horizontais
      case SignalingTypeEnum.Horizontal:
        this._publicService
          .getHorizontalSignalingsByTaskId(task.id, this.reportId)
          .subscribe(async (signRes: HorizontalSignaling[]) => {
            signRes.forEach((sign) => {
              this._publicService.getHorizontalSignaling(sign.id, this.reportId).subscribe(async (horizontalRes) => {
                const horizontal = await horizontalRes;
                if (horizontal) {
                  this.getMeasurements(horizontal);
                  task['reportSigns'].push(horizontal);
                }
              });
            });
          });
        return task;
      // Dispositivos
      case SignalingTypeEnum.Device:
        this._publicService
          .getDeviceSignalingsByTaskId(task.id, this.reportId)
          .subscribe(async (signRes: DeviceSignaling[]) => {
            signRes.forEach((sign) => {
              this._publicService.getDeviceSignaling(sign.id, this.reportId).subscribe(async (deviceRes) => {
                const device = await deviceRes;
                if (device) task['reportSigns'].push(device);
              });
            });
          });
        return task;
    }
  }

  getMeasurements(sign) {
    this._publicService.getHorizontalSignalingMeasurements(sign.id, this.reportId).subscribe((res: any[]) => {
      let totalCost = 0;
      let totalDimension = 0;
      sign['measurements'] = res;
      sign['measurements'].forEach((measure) => {
        totalCost += (measure?.dimension || 0) * sign?.contractSignItem.unitValue;
        totalDimension += measure?.dimension || 0;
      });
      sign['totalCost'] = totalCost;
      sign['totalDimension'] = totalDimension;
    });
  }

  getTeam(teamId: string, task: Task) {
    this._publicService.getTeam(teamId, this.reportId).subscribe((res: Team) => (task.team = res));
  }

  getVehicle(vehicleId: string, task: Task) {
    this._publicService.getVehicle(vehicleId, this.reportId).subscribe((res: Vehicle) => (task.vehicle = res));
  }

  formatNumber(num: number) {
    return Math.round(num * 100) / 100;
  }

  // Preserva ordem do ngFor | keyvalue
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
